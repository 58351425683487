import React from "react";
import { connect } from "react-redux";
import { Field, Form, reduxForm, formValueSelector } from "redux-form";
import AvatarInput from "./AvatarInput";
import Input from "./Input";
import RenderDatePicker from "./RenderDatePicker";
import { required } from "./Validators";

let ChildInfoEdit = ({
  child,
  avatar,
  handleFormSubmit,
  handleFormCancel,
  handleSubmit,
  currentAvatar,
  gender
}) => {
  return (
    <Form
      onSubmit={handleSubmit(values =>
        handleFormSubmit({
          ...values,
          form: "info"
        })
      )}
    >
      <Field name="uuid" type="hidden" value="" component="input" />
      <section className="form-section form-section--title">
        <h2 className="form-section__title">Basic Information</h2>
        <p className="form-section__intro">
          Here you can view edit and update {child.first_name}'s basic
          information such as name, date of birth, description &amp; profile
          picture.
        </p>
      </section>

      <section className="form-section">
        <div className="standard-inputs standard-inputs--inline">
          <div className="standard-input standard-input--lg standard-input--pair">
            <div className="standard-input--pair__l">
              <Field
                name="first_name"
                targetName="first_name"
                type="text"
                value=""
                label="First Name"
                component={Input}
                validate={required}
              />
            </div>
            <div className="standard-input--pair__r">
              <Field
                name="last_name"
                targetName="last_name"
                type="text"
                value=""
                label="Last Name"
                component={Input}
                validate={required}
              />
            </div>
          </div>
          <div className="standard-input standard-input--lg standard-input--date">
            <label>Date of Birth</label>
            <Field
              name="dob"
              targetName="dob"
              type="date"
              value=""
              component={RenderDatePicker}
              validate={required}
              futureDates={false}
            />
          </div>
        </div>
      </section>

      <section className="form-section">
        <div className="standard-inputs">
          <div className="standard-input standard-input--lg">
            <label htmlFor="gender">Gender</label>
            <p>Is {child.first_name}a boy or a girl?</p>
            <div className="radio-set">
              <div className="standard-radio">
                <div className="edna-radio">
                  <Field
                    name="gender"
                    component="input"
                    type="radio"
                    id="male"
                    parse={value => "male"}
                    checked={gender === "male"}
                  />
                  <div className="styled_radio" />
                </div>
                <label htmlFor="male">Boy</label>
              </div>

              <div className="standard-radio">
                <div className="edna-radio">
                  <Field
                    name="gender"
                    component="input"
                    type="radio"
                    id="female"
                    parse={value => "female"}
                    checked={gender === "female"}
                  />
                  <div className="styled_radio" />
                </div>
                <label htmlFor="female">Girl</label>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="form-section">
        <div className="standard-inputs">
          <div className="standard-input standard-input--lg">
            <label>All about {child.first_name}</label>
            <p>
              A description of {child.first_name}. What they like, what they
              don’t like. Add anything you think would be helpful to anyone who
              hasn't met {child.first_name}.
            </p>
            <Field
              name="all_about"
              type="textarea"
              value=""
              component="textarea"
            />
          </div>
        </div>
      </section>

      <section className="form-section">
        <div className="standard-inputs">
          <div className="standard-input standard-input--lg">
            <label>Profile picture</label>
            <p>
              Add an image of {child.first_name}. This helps you identify them
              quickly and easily will can increase parent interaction
            </p>
            <AvatarInput
              buttonText="Upload Avatar"
              acceptedFormats="jpg,png,gif"
              maxSize="5MB"
              avatar={avatar}
              currentAvatar={currentAvatar}
            />
          </div>
        </div>
      </section>

      <section className="form-section">
        <div className="standard-inputs">
          <div className="standard-input standard-input--lg">
            <label htmlFor="preferred_name">Preferred Name</label>
            <p>
              Does {child.first_name}
              like to be called anything else? Perhaps a nickname or shortened
              version?
            </p>
            <Field
              name="preferred_name"
              targetName="preferred_name"
              type="text"
              value=""
              component={Input}
            />
          </div>
        </div>
      </section>

      <footer className="form-footer">
        <div className="form-footer__btns">
          <button
            type="submit"
            className="btn btn--green btn--lg btn--mob-fixed"
          >
            Save changes
          </button>
          <a
            href={true}
            className="btn btn--red btn--lg btn--mob-fixed"
            onClick={handleFormCancel}
          >
            Cancel
          </a>
        </div>
      </footer>
    </Form>
  );
};

ChildInfoEdit = reduxForm({
  form: "childEditInfo",
  enableReinitialize: true
})(ChildInfoEdit);

const selector = formValueSelector("childEditInfo");
ChildInfoEdit = connect(state => ({
  initialValues: {
    uuid: state.child.data.uuid,
    first_name: state.child.data.first_name,
    last_name: state.child.data.last_name,
    dob: state.child.data.dob,
    gender: state.child.data.gender,
    all_about:
      state.child.data.all_about !== "null" ? state.child.data.all_about : "",
    preferred_name:
      state.child.data.preferred_name !== "null"
        ? state.child.data.preferred_name
        : ""
  },
  gender: selector(state, "gender")
}))(ChildInfoEdit);

export default ChildInfoEdit;
