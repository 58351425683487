import React from "react";

const ChildProfileUPN = ({upn}) => (
   
    <div className='grid__cell'>
        <div className='dataItem'>
            <div className='dataItem__label'>
            Unique pupil number (UPN)
            </div>
            <div className='dataItem__data'>
                {
                    upn === "null" ? "N/A" : upn
                }
            </div>
        </div>
    </div>
    
);

export default ChildProfileUPN;
