const initialState = {
  isLoading: true,
  isComplete: false,
  error: false,
  data: null
};

const onboardingReducer = (state = initialState, action) => {
  switch (action.type) {
  case "GET_INVITE":
    return {
      ...state,
      isLoading: true,
      isComplete: false
    };
  case "GET_INVITE_SUCCESS":
    return {
      ...state,
      data: action.payload.data,
      isLoading: false,
      isComplete: true
    };
  case "GET_INVITE_FAIL":
    return {
      ...state,
      isLoading: false,
      isComplete: true,
      error: true
    };
  case "PROCESS_INVITE":
    return {
      ...state,
      isLoading: true,
      isComplete: false
    };
  case "PROCESS_INVITE_SUCCESS":
    return {
      ...state,
      isLoading: false,
      isComplete: true
    };
  case "PROCESS_INVITE_FAIL":
    return {
      ...state,
      isComplete: true,
      isLoading: false,
      error: true
    };
  default:
    return state;
  }
};

export default onboardingReducer;
