import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ProfileTab from '../components/ProfileTab';
import ListHeader from '../components/ListHeader';
import { getUser, updateUser } from '../actions/userActions';
import Loader from '../components/Loader';
import * as flashActions from '../actions/flashActions';
import { resetSingleUser } from '../actions/userActions';
import { getRoles } from '../actions/addWorkerActions';
import { resetAvatarState } from '../actions/cloudinaryActions';

class UserProfileEditContainer extends React.Component {
  componentDidMount() {
    this.props.getUser(this.props.params.userId).then(() => {
      this.props.getRoles();
    });
  }

  handleUpdateUser = values => {
    if (values.avatar.data !== null) {
      values.avatar_url = values.avatar.data.secure_url;
      delete values.avatar;
    }
    this.props.updateUser(values).then(response => {
      if (response.type === 'UPDATE_USER_SUCCESS') {
        this.props.addMessageToFlash(
          'Profile successfully updated!',
          'success',
          'normal'
        );
      }
      this.props.resetAvatarState();
    });
  };

  handleFormCancel = () => {
    this.props.resetSingleUser();
    browserHistory.replace('/settings');
  };

  render() {
    return (
      <div>
        <ListHeader
          title="Profile"
          icon="settings"
          cancel={this.handleFormCancel}
        />{' '}
        {this.props.singleUser.data !== null &&
        this.props.addWorker.roles.length > 0 ? (
          <ProfileTab
            user={this.props.singleUser}
            handleUpdateUser={this.handleUpdateUser}
            avatar={this.props.avatar}
            currentAvatar={this.props.singleUser.data.person.picture}
            currentUser={this.props.user.data}
            roles={this.props.addWorker.roles}
          />
        ) : (
          <Loader />
        )}
      </div>
    );
  }
}

UserProfileEditContainer.propTypes = {
  getUser: PropTypes.func.isRequired,
  params: PropTypes.shape({ userId: PropTypes.string.isRequired }).isRequired,
  singleUser: PropTypes.shape({
    data: PropTypes.shape({}),
    isLoading: PropTypes.bool.isRequired,
    isComplete: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
  }).isRequired,
};

const mapStateToProps = state => ({
  singleUser: state.singleUser,
  avatar: state.avatar,
  user: state.user,
  addWorker: state.addWorker,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUser,
      updateUser,
      ...flashActions,
      resetSingleUser,
      getRoles,
      resetAvatarState,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfileEditContainer);
