import React from "react";
import { bindActionCreators } from "redux";
import * as CloudinaryActions from "../actions/cloudinaryActions";
import { connect } from "react-redux";

class ObservationImageButton extends React.Component {

    handleFiles = (event) => {
        const files = event.target.files;
        for (var i = 0; i < files.length; i++) {
            const fileName = files[i].name;
            const reader = new FileReader();
            reader.readAsDataURL(files[i]);
            reader.onload = () => {
                const image64 = reader.result;
                this.props.uploadImage(image64, fileName);
            };
        }
    };

    handleClick = () => {
        document.getElementById("fileUpload").click();
    };

    render () {
        return (
          <div className="new-observation__draw__item new-observation__draw__item--photo"
               onClick={this.handleClick}>
                <div>
              Photos
              </div>
              <input type="file" id="fileUpload" multiple style={{"display": "none"}}
                     onChange={this.handleFiles}/>
          </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        cloudinary: state.cloudinary
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        ...CloudinaryActions
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ObservationImageButton);
