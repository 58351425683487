import React from "react";
import {Link} from "react-router";
import PropTypes from "prop-types";

const Welcome = ({onboarding}) => (
  <div className="signUp__main">
    <div className="signUp__text-area signUp__text-area--intro">
      <h1>
        Hi
        <span className="highlight--blue">&nbsp;{onboarding.first_name}&nbsp;</span>
        and welcome to Edna.
      </h1>
      {typeof onboarding.inviter === "undefined"
        ? (
          <div>
            <p>Thanks for signing up, we’re really happy to have you. We just need to
              confirm a couple of things before you get up and running.</p>
            <p>First up, here is the information you gave us at sign up:</p>
          </div>
        )
        : (
          <div>
            <p>
              <span className="highlight--blue">{onboarding.inviter.person.first_name}&nbsp;</span>
              has invited you to join their Edna account for&nbsp;
              <span className="highlight--blue">{onboarding.inviter.school.name}</span>. We just need to confirm a couple of things before you get up and running.
            </p>
            <p>
              First up, here is the information
              <span className="highlight--blue">&nbsp;{onboarding.inviter.person.first_name}&nbsp;</span>
              gave us about you:
            </p>
          </div>
        )}
    </div>
    <div className="signUp__dataConf">
      <div className="grid grid--gutter50">
        <div className="grid__cell">
          <div className="dataItem">
            <div className="dataItem__label">First Name</div>
            <div className="dataItem__data">{onboarding.first_name}</div>
          </div>
        </div>
        <div className="grid__cell">
          <div className="dataItem">
            <div className="dataItem__label">Last Name</div>
            <div className="dataItem__data">{onboarding.last_name}</div>
          </div>
        </div>
      </div>
      <div className="grid grid--gutter50">
        <div className="grid__cell">
          <div className="dataItem">
            <div className="dataItem__label">Email Address</div>
            <div className="dataItem__data">{onboarding.email}</div>
          </div>
        </div>
      </div>
    </div>
    <Link
      to={`/welcome/${onboarding.uuid}/onboarding`}
      className="btn btn--green btn--lg">
      Looks good, lets go!
    </Link>
    <div className="helperText">
      <p>If you need to change any of this information you can do so from the
        Preferences section of your account once you’re signed in.</p>
    </div>
  </div>
);

Welcome.propTypes = {
  onboarding: PropTypes
    .shape({})
    .isRequired
};

export default Welcome;
