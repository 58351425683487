import React from "react";
import InputError from "./InputError";
import PasswordStrength from "./PasswordStrength";

class Input extends React.Component {
  static defaultProps = {
    type: "text",
    name: null,
    label: null,
    hint: null,
    strength: null
  };

  render() {
    const {
      input: {
        value,
        onChange
      }
    } = this.props;
    return (
      <div>
        {this.props.label
          ? <label htmlFor={this.props.name}>{this.props.label}</label>
          : ""}

        <div className="standard-input__hint">{this.props.hint}</div>
        <input
          {...this.props.input}
          type={this.props.type}
          name={this.props.targetName}
          tabIndex={this.props.tabIndex}
          value={value}
          onChange={onChange}
          placeholder={this.props.placeholder}
          className={this.props.meta.touched && this.props.meta.error
            ? "input-error " + this.props.classes
            : this.props.classes}/> {this.props.meta.touched && this.props.meta.error
          ? <InputError errorMessage={this.props.meta.error}/>
          : ""}
        {this.props.targetName === "password"
          ? <PasswordStrength strength={this.props.strength}/>
          : null}
      </div>
    );
  }
}

export default Input;
