import { normalize } from "normalizr";
import { categoriesSchema, characteristics } from "../schemas";

const initialState = {
  data: null,
  isLoading: false,
  isComplete: false,
  display: null,
  coel: null,
  coelMap: null,
};

const curriculumReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CURRICULUM":
      return { ...state, isLoading: true, isComplete: false };
    case "GET_CURRICULUM_SUCCESS":
      return {
        ...state,
        data: normalize(action.payload.data.categories, categoriesSchema),
        display: normalize(action.payload.data.categories, categoriesSchema),
        isLoading: false,
        isComplete: true,
      };
    case "FILTER_CATEGORIES":
      return { ...state, display: normalize(action.payload.data, categoriesSchema) };
    case "GET_COEL":
      return { ...state, isLoading: true, isComplete: false };
    case "GET_COEL_SUCCESS":
      return { ...state, coel: action.payload.data, coelMap: normalize(action.payload.data, characteristics) };
    default:
      return state;
  }
};

export default curriculumReducer;
