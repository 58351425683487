import React from "react";
import { bindActionCreators } from "redux";
import * as CloudinaryActions from "../actions/cloudinaryActions";
import { connect } from "react-redux";
import ImageElement from "./ImageElement";

class ObservationImageInput extends React.Component {
  constructor(props) {
    super(props);
    this.handleImageRemove = this.handleImageRemove.bind(this);
  }

  handleFiles = event => {
    const files = event.target.files;
    for (var i = 0; i < files.length; i++) {
      const fileName = files[i].name;
      const reader = new FileReader();
      reader.readAsDataURL(files[i]);
      reader.onload = () => {
        const image64 = reader.result;
        this.props.uploadImage(image64, fileName);
      };
    }
  };

  handleImageRemove = imageId => {
    let imageIndex = this.props.cloudinary.images.findIndex(
      image => image.cloudinary_id === imageId
    );
    this.props.removeImage(this.props.cloudinary.images[imageIndex]);
  };

  handleClick = () => {
    document.getElementById("fileUpload").click();
  };

  render() {
    const { cloudinary } = this.props;
    return (
      <div className="new-observation__preview new-observation__preview__photos">
        {typeof cloudinary.images !== "undefined"
          ? cloudinary.images.map(image => (
              <div
                className="thumbnail thumbnail--sm thumbnail--cross"
                key={image.id}
                onClick={this.handleImageRemove.bind(this, image.cloudinary_id)}
              >
                <ImageElement
                  thumb_url={`https://res.cloudinary.com/flo-design/image/upload/c_fill,q_auto:good,w_150,h_150/${image.cloudinary_id}.jpg`}
                  isLoading={image.isLoading}
                  key={image.id}
                />
              </div>
            ))
          : ""}
        {cloudinary.images.length > 0 ? (
          <div
            className="thumbnail thumbnail--sm thumbnail--add-img"
            onClick={this.handleClick}
          >
            <input
              type="file"
              id="fileUpload"
              multiple
              style={{ display: "none" }}
              onChange={this.handleFiles}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    cloudinary: state.cloudinary
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      ...CloudinaryActions
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ObservationImageInput);
