import React, {Component} from 'react';
import {injectStripe, CardElement} from "react-stripe-elements";

class CardForm extends Component {
  state = {
    name: ""
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox'
      ? target.checked
      : target.value;
    const name = target.name;

    this.setState({[name]: value});
  }

  handleTokenisation = (event) => {
    event.preventDefault();

    this
      .props
      .stripe
      .createToken({name: this.state.name})
      .then(({token}) => {
        this
          .props
          .sendTokenToServer(token.id);
      });
  }

  addNewCard = (event) => {
    event.preventDefault();
    this.setState({loading: true});
    this
      .props
      .stripe
      .createToken({name: this.state.name})
      .then(({token}) => {
        this
          .props
          .addBillingCard(token.id)
          .then(() => {
            this.setState({loading: false});
          });
      });
  }

  render() {
    let handleCards = null;
    this.props.subscription
      ? handleCards = this.addNewCard
      : handleCards = this.handleTokenisation;
    return (
      <form onSubmit={handleCards}>
        <div className="standard-input standard-input--lg">
          <label>Name on Card</label>
          <input
            type="text"
            name="name"
            onChange={this.handleInputChange}
            value={this.state.name}/>
        </div>
        <div className="standard-input standard-input--lg">
          <label>Card Details</label>

          <CardElement
            style={{
              base: {
                fontSize: '16px',
                fontFamily: '"Lato", "Helvetica Neue", Helvetica, Arial, sans-serif',
                iconColor: '#4A89DC',
                fontWeight: 'bold',
                color: '#4A89DC'
              }
            }}/>
        </div>
        <button
          className={`btn btn--green btn--sm btn--nobrdr ${this.props.billingLoading
            ? "btn--spinner btn--disabled"
            : ""}`}>{this.props.subscription
            ? "Add Card"
            : "Sign up"}</button>
      </form>
    );
  }
}

export default injectStripe(CardForm);
