import React from "react";
import illustration from "../images/assets/faces-observations-empty.svg";

const WorkerObservationsTableEmpty = ({ worker }) => (
  <div>
    <div className="empty-state">
      <img src={illustration} alt="" />
      <h2>{worker.first_name} has not taken any observations yet.</h2>
    </div>
  </div>
);

export default WorkerObservationsTableEmpty;
