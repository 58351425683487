import PropTypes from "prop-types";
import React from "react";

const Pagination = ({ currentPage, numPages, handlePageClick, handlePrev, handleNext }) => {
  if (numPages.length > 0) {
    return (
      <nav className="pagination">
        <div
          className={
            currentPage === 1
              ? "pagination__link pagination__prev pagination__link--disabled"
              : "pagination__link pagination__prev"
          }
          onClick={handlePrev}>
          <svg height="10" viewBox="0 0 6 10" width="6" xmlns="http://www.w3.org/2000/svg">
            <g fill="#FFF">
              <path d="M5.8 8.7C6.1 9 6.1 9.5 5.8 9.8 5.5 10.1 5 10.1 4.7 9.8L0.2 5.5C-0.1 5.2-0.1 4.8 0.2 4.5L4.7 0.2C5-0.1 5.5-0.1 5.8 0.2 6.1 0.5 6.1 1 5.8 1.3L2.1 5 5.8 8.7 5.8 8.7Z" />
            </g>
          </svg>
          Prev
        </div>
        {numPages.map(page => (
          <li
            className={
              currentPage === page
                ? "pagination__link pagination__page pagination__link--active"
                : "pagination__link pagination__page"
            }
            onClick={handlePageClick}
            key={page}
            data-page={page}>
            {page}
          </li>
        ))}
        <li
          className={
            currentPage === numPages.length
              ? "pagination__link pagination__next pagination__link--disabled"
              : "pagination__link pagination__next"
          }
          onClick={handleNext}>
          Next
          <svg height="10" viewBox="0 0 6 10" width="6" xmlns="http://www.w3.org/2000/svg">
            <g fill="#FFF">
              <path d="M0.2 8.7C-0.1 9-0.1 9.5 0.2 9.8 0.5 10.1 1 10.1 1.3 9.8L5.8 5.5C6.1 5.2 6.1 4.8 5.8 4.5L1.3 0.2C1-0.1 0.5-0.1 0.2 0.2 -0.1 0.5-0.1 1 0.2 1.3L3.9 5 0.2 8.7 0.2 8.7Z" />
            </g>
          </svg>
        </li>
      </nav>
    );
  } else {
    return null;
  }
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  numPages: PropTypes.arrayOf(PropTypes.shape({})),
  handlePageClick: PropTypes.func.isRequired,
  handlePrev: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired
};

export default Pagination;
