import React from "react";

const CharacteristicChildEdit = ({
  charChild,
  handleCOELToggle,
  child,
  selectedCoel,
  characteristic
}) => {
  let charClass = "";
  if (selectedCoel.find(x => x.uuid === charChild.uuid) !== undefined) {
    charClass = "select-list--characteristic selected";
  } else {
    charClass = "select-list--characteristic";
  }

  return (
    <li
      className={charClass}
      key={charChild.uuid}
      onClick={handleCOELToggle.bind(this, {
        ...charChild,
        parent: characteristic
      })}
    >
      <div className="characteristic-container">{charChild.name}</div>
    </li>
  );
};

export default CharacteristicChildEdit;
