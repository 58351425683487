// Users & Accounts
export const USER_LOGIN = "USER_LOGIN";
export const IDENTIFY_USER = "IDENTIFY_USER";
export const USER_REFRESH = "USER_REFRESH";
export const USER_LOGOUT = "USER_LOGOUT";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const GET_INVITE = "GET_INVITE";
export const PROCESS_INVITE = "PROCESS_INVITE";
export const SEND_FORM = "SEND_FORM";
export const RESEND_INVITE = "RESEND_INVITE";
export const EDIT_REGISTRATION_EMAIL = "EDIT_REGISTRATION_EMAIL";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const GET_SINGLE_USER = "GET_SINGLE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const EDIT_FORGOTPASSWORD_EMAIL = "EDIT_FORGOTPASSWORD_EMAIL";
export const REHYDRATE_TOKEN = "REHYDRATE_TOKEN";
export const CREATE_WORKER = "CREATE_WORKER";
export const GET_ROLES = "GET_ROLES";
export const GET_INVITES = "GET_INVITES";
export const DELETE_INVITE = "DELETE_INVITE";
export const DELETE_USER = "DELETE_USER";
export const RESET_SINGLE_USER = "RESET_SINGLE_USER";
export const CREATE_SUBSCRIPTION = "CREATE_SUBSCRIPTION";
export const GET_CUSTOMER = "GET_CUSTOMER";
export const GET_SUB = "GET_SUB";
export const GET_INVOICES = "GET_INVOICES";
export const GET_PLANS = "GET_PLANS";
export const UPDATE_PLAN = "UPDATE_PLAN";
export const CANCEL_PLAN = "CANCEL_PLAN";
export const RESUME_PLAN = "RESUME_PLAN";
export const ADD_CARD = "ADD_CARD";
export const DELETE_CARD = "DELETE_CARD";
export const MAKE_CARD_DEFAULT = "MAKE_CARD_DEFAULT";
// Tabs
export const SWITCH_TAB = "SWITCH_TAB";
// Child Profile
export const GET_SINGLE_CHILD = "GET_SINGLE_CHILD";
export const UPDATE_CHILD = "UPDATE_CHILD";
export const DELETE_CHILD = "DELETE_CHILD";
export const GET_PROGRESSION = "GET_PROGRESSION";
export const RESET_PROGRESSION = "RESET_PROGRESSION";
// Child List
export const FETCH_CHILDREN = "FETCH_CHILDREN";
export const FILTER_CHILDREN = "FILTER_CHILDREN";
export const SORT_CHILDREN = "SORT_CHILDREN";
// Child Creation
export const INCREMENT_PAGE = "INCREMENT_PAGE";
export const RESET_ADD_CHILD_STATE = "RESET_ADD_CHILD_STATE";
export const CREATE_CHILD_CONTINUE = "CREATE_CHILD_CONTINUE";
export const CREATE_CHILD_FINISH = "CREATE_CHILD_FINISH";
export const CREATE_CHILD_CANCELLED = "CREATE_CHILD_CANCELLED";
// Observation creation
export const CREATE_OBSERVATION = "CREATE_OBSERVATION";
export const RESET_ADD_OBSERVATION_STATE = "RESET_ADD_OBSERVATION_STATE";
export const ADD_CHILD = "ADD_CHILD";
export const REMOVE_CHILD = "REMOVE_CHILD";
export const POPULATE_STATEMENTS = "POPULATE_STATEMENTS";
export const FILTER_CATEGORIES = "FILTER_CATEGORIES";
export const POPULATE_SECURE = "POPULATE_SECURE";
export const POPULATE_COMMENTS = "POPULATE_COMMENTS";
export const POPULATE_COEL = "POPULATE_COEL";
export const DELETE_OBSERVATION = "DELETE_OBSERVATION";
export const RESET_EDIT_OBSERVATION_STATE = "RESET_EDIT_OBSERVATION_STATE";
// Modals
export const TOGGLE_MODAL = "TOGGLE_MODAL";
// Observations List
export const FETCH_OBSERVATIONS = "FETCH_OBSERVATIONS";
export const FILTER_OBSERVATIONS = "FILTER_OBSERVATIONS";
// Observation detail & edit
export const GET_SINGLE_OBSERVATION = "GET_SINGLE_OBSERVATION";
export const POPULATE_EDIT_STATEMENTS = "POPULATE_EDIT_STATEMENTS";
export const POPULATE_EDIT_COMMENTS = "POPULATE_EDIT_COMMENTS";
export const POPULATE_EDIT_COEL = "POPULATE_EDIT_COEL";
export const POPULATE_EVIDENCE = "POPULATE_EVIDENCE";
export const EDIT_OBSERVATION = "EDIT_OBSERVATION";
export const UPLOAD_EDIT_IMAGE = "UPLOAD_EDIT_IMAGE";
export const REMOVE_EDIT_IMAGE = "REMOVE_EDIT_IMAGE";
// Workers
export const GET_WORKERS = "GET_WORKERS";
export const GET_USER_PROFILE = "GET_USER_PROFILE";
//Carers
export const INVITE_CARER = "INVITE_CARER";
export const GET_CARERS = "GET_CARERS";
export const GET_CARER_INVITES = "GET_CARER_INVITES";
export const ADD_CARER = "ADD_CARER";
export const REMOVE_CARER = "REMOVE_CARER";
export const FILTER_CARERS = "FILTER_CARERS";
export const SET_FLAG = "SET_FLAG";
// Newsfeed Flash messages
export const REMOVE_FLASH_MESSAGE = "REMOVE_FLASH_MESSAGE";
export const ADD_FLASH_MESSAGE = "ADD_FLASH_MESSAGE";
export const CLEAR_FLASH_MESSAGES = "CLEAR_FLASH_MESSAGES";
// Cloudinary
export const UPLOAD_AVATAR = "UPLOAD_AVATAR";
export const RESET_AVATAR = "RESET_AVATAR";
export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const REMOVE_IMAGE = "REMOVE_IMAGE";
// Setup
export const GET_CURRICULUM = "GET_CURRICULUM";
export const GET_COEL = "GET_COEL";
// Newsfeed
export const INCREMENT_LIKES = "INCREMENT_LIKES";
export const DECREMENT_LIKES = "DECREMENT_LIKES";
export const ADD_COMMENT = "ADD_COMMENT";
export const EDIT_COMMENT = "EDIT_COMMENT";
export const DELETE_COMMENT = "DELETE_COMMENT";