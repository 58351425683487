import React from 'react';
import {connect} from "react-redux";

class HasPermission extends React.Component {
  render() {
    const {allowedRoles, user} = this.props;
    if (user !== null && allowedRoles.includes(user.role.name)) {
      return React.cloneElement(this.props.children);
    } else {
      return false;
    }
  }
}

export default connect((state) => ({user: state.user.data}))(HasPermission);
