import React from "react";

const AddChildHeader = ({ page, formCancel }) => (
  <div className="header-local header-local--underline">
    <h1 className="header-local__title header-local__title--children">Add a new child</h1>
    {page === 1 ? (
      <div className="header-local__cta">
        <a href={true} className="btn btn--red btn--sm btn--mob-sq" onClick={formCancel}>
          Cancel
        </a>
      </div>
    ) : (
      ""
    )}
  </div>
);

export default AddChildHeader;
