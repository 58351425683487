import { PropTypes } from "prop-types";
import React from "react";

const Dialog = ({ deleteType, handleDelete, handleToggle, uuid }) => (
  <div>
    <div className="dialog-modal">
      <div className="dialog-modal__main">
        <h4 className="dialog-modal__title">Are sure you want to delete this {deleteType}?</h4>
        <p>
          This will permanently remove this {deleteType}, associated evidence and curriculum information. This action
          can not be undone.
        </p>
      </div>
      <div className="dialog-modal__actions">
        <a href={true} onClick={handleDelete.bind(this, uuid)} className="btn btn--red btn--sm">
          Delete {deleteType[0].toUpperCase() + deleteType.substring(1)}
        </a>
        <a href={true} onClick={handleToggle} className="btn-cancel btn-cancel--sm btn-cancel--grey">
          Cancel
        </a>
      </div>
    </div>
    <div className="modal-overlay modal-open" />
  </div>
);

Dialog.propTypes = {
  deleteType: PropTypes.string.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
  uuid: PropTypes.string.isRequired
};
export default Dialog;
