import React from "react";
import CharacteristicChild from "./CharacteristicChild";

const Characteristic = ({characteristic, handleCOELToggle, child, selectedCoel}) => {
    return (
      <ul className='select-list select-list--characteristics'>
          <li className='list-seperator' key={characteristic.uuid}>
              {characteristic.name}
          </li>
          {characteristic.children.map(charChild => (
            <CharacteristicChild charChild={charChild} key={charChild.uuid} handleCOELToggle={handleCOELToggle} child={child} selectedCoel={selectedCoel}/>
          ))}
      </ul>
    );

};

export default Characteristic;
