import React from "react";
import PropTypes from "prop-types";
import DefaultChildAvatar from "../images/assets/DefaultChildAvatar.svg";

const EditStatement = ({
  area,
  aspect,
  agegroup,
  statement,
  toggleSecure,
  toggleSelected,
  statementSelected,
  childSelected,
  selectedChildren,
  child,
  statementSecure
}) => {
  let secureClass = "";
  if (statementSecure === 1) {
    secureClass = "secure-list--item secure-list--item--selected";
  } else {
    secureClass = "secure-list--item";
  }
  return (
    <li
      className={`select-list--statment select-list--statment--${area.identifier}`}>
      <div
        className="statment-container"
        onClick={toggleSelected.bind(this, statement)}>
        <div className="statment-info">
          <div className="statment-info__text">{statement.name}</div>
          <ul className={`curriculm-meta curriculm-meta--${area.identifier}`}>
            <li>{aspect.name}</li>
            <li>
              {agegroup.min === 61
                ? "Early Learning Goal"
                : ""}
              {agegroup.min === 62
                ? "Exceeding"
                : ""}
              {agegroup.min !== 61 && agegroup.min !== 62
                ? (agegroup.min + " - " + agegroup.max + " months")
                : ""}
            </li>
          </ul>
        </div>
        <div
          className={`tickBox ${statementSelected
          ? "tick"
          : ""}`}/>
      </div>
      {statementSelected
        ? (
          <div className="bubble-text bubble-text--noavatar bubble-text--secure">
            <p>Are they secure in this statement?</p>
            <ul className="secure-list">
              <li
                className={secureClass}
                key={child}
                onClick={toggleSecure.bind(this, statement.uuid)}>
                <div className="avatar avatar--small">
                  {child.picture !== null
                    ? (<img alt={child.first_name + " " + child.last_name} src={child.picture}/>)
                    : (<img alt="default avatar" src={DefaultChildAvatar}/>)}
                </div>
                {child.first_name}{" "} {child.last_name}
              </li>
            </ul>
          </div>
        )
        : ("")}
    </li>
  );
};

EditStatement.propTypes = {
  area: PropTypes.object.isRequired,
  aspect: PropTypes.object.isRequired,
  agegroup: PropTypes.object.isRequired,
  statement: PropTypes.object.isRequired,
  statementSelected: PropTypes.bool.isRequired,
  toggleSecure: PropTypes.func.isRequired,
  toggleSelected: PropTypes.func.isRequired
};

export default EditStatement;
