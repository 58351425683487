import React from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { bindActionCreators } from "redux";
import _ from "underscore";
import * as ChildActions from "../actions/childActions";
import { filterCategories } from "../actions/createObservationActions";
import * as curriculumActions from "../actions/curriculumActions";
import * as FlashActions from "../actions/flashActions";
import * as ModalActions from "../actions/modalActions";
import * as singleObservationActions from "../actions/singleObservationActions";
import Loader from "../components/Loader";
import ObservationEditForm from "../components/ObservationEditForm";

class ObservationEditContainer extends React.Component {
  state = {
    nameTerm: "",
    catNameTerm: "",
    ageTerm: [],
    areaTerm: []
  };

  componentDidMount() {
    this.props.getObservation(this.props.params.observationId).then(() => {
      this.props.getChildren().then(() => {
        this.props.getCurriculum().then(() => {
          this.props.getCoel();
        });
      });
    });
  }

  componentWillUnmount() {
    this.props.resetEditObservationState();
  }

  handleToggleModal = type => {
    this.props.modals.open
      ? this.props.closeModal()
      : this.props.openModal(type);
  };

  handleFileUpload = e => {
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      const image64 = reader.result;
      this.props.uploadImage(image64);
    };
  };

  filterChildren = () => {
    this.props.filterObservationChildren(
      this.props.children.data.entities.children,
      this.props.children.data.result,
      this.state
    );
  };

  handleFormCancel = () => {
    browserHistory.replace(
      `/observations/${this.props.singleObservation.data.uuid}`
    );
  };

  handleFormSubmit = values => {
    values = {
      ...values,
      user: this.props.user.data.uuid
    };
    this.props
      .editObservation(values)
      .then(() => {
        this.props.addMessageToFlash(
          "Success! Your observation was successfully edited",
          "success",
          "normal"
        );
      })
      .then(() => {
        browserHistory.push("/observations");
      });
  };

  filterCategories = () => {
    this.props.filterCategories(
      this.props.categories.data.entities.category,
      this.state
    );
  };

  handleNameFilter = event => {
    this.setState(
      {
        catNameTerm: event.target.value
      },
      this.filterCategories
    );
  };

  handleFilterCategories = event => {
    switch (event.filter) {
      case "age":
        if (this.state.ageTerm.indexOf(event.value) >= 0) {
          this.setState(
            {
              ageTerm: this.state.ageTerm.filter(age => age !== event.value)
            },
            this.filterCategories
          );
        } else {
          this.setState(
            {
              ageTerm: [...this.state.ageTerm, event.value]
            },
            this.filterCategories
          );
        }
        break;
      case "area":
        if (this.state.areaTerm.indexOf(event.value) >= 0) {
          this.setState(
            {
              areaTerm: this.state.areaTerm.filter(area => area !== event.value)
            },
            this.filterCategories
          );
        } else {
          this.setState(
            {
              areaTerm: [...this.state.areaTerm, event.value]
            },
            this.filterCategories
          );
        }
        break;
      default:
        // Do nothing
        break;
    }
  };

  toggleSelected = statement => {
    const statementToAdd = Object.assign({}, statement);
    const index = this.props.singleObservation.data.statements.findIndex(
      x => x.uuid === statement.uuid
    );

    statementToAdd.area = this.props.categories.data.entities.areas[
      statement.area
    ];
    statementToAdd.aspect = this.props.categories.data.entities.aspects[
      statement.aspect
    ];
    statementToAdd.agegroup = this.props.categories.data.entities.agegroups[
      statement.agegroup
    ];
    console.log(statementToAdd);
    if (index !== -1) {
      this.props.populateEditStatements(
        this.props.singleObservation.data.statements.filter(
          entry => entry.uuid !== statementToAdd.uuid
        )
      );
    } else {
      this.props.populateEditStatements([
        ...this.props.singleObservation.data.statements,
        statementToAdd
      ]);
    }
  };

  toggleSecure = secureStatement => {
    this.props.populateEditStatements(
      this.props.singleObservation.data.statements.map(statement => {
        if (statement.uuid === secureStatement) {
          return {
            ...statement,
            secure: +!statement.secure
          };
        }
        return statement;
      })
    );
  };

  handleChildComments = event => {
    this.props.populateEditComment(event.target.value);
  };

  handleCOELToggle = charObject => {
    console.log(charObject);
    const index = this.props.singleObservation.data.coel.findIndex(
      x => x.uuid === charObject.uuid
    );
    if (index !== -1) {
      this.props.populateEditCOEL(
        this.props.singleObservation.data.coel.filter(entry => {
          if (entry.uuid === charObject.uuid) {
            return false;
          }
          return true;
        })
      );
    } else {
      this.props.populateEditCOEL([
        ...this.props.singleObservation.data.coel,
        charObject
      ]);
    }
  };

  handlePopulateEvidence = evidence => {
    this.props.populateEvidence([
      ...this.props.singleObservation.data.evidence,
      evidence
    ]);
  };

  render() {
    const grouped = _.groupBy(this.props.singleObservation.coel, "child");
    return (
      <div>
        {this.props.children.data !== null &&
        this.props.categories.display !== null &&
        this.props.categories.coelMap !== null ? (
          <ObservationEditForm
            cloudinary={this.props.cloudinary.images}
            cloudinaryLoading={this.props.cloudinary.isLoading}
            observation={this.props.singleObservation.data}
            fileUpload={this.handleFileUpload}
            handleFormSubmit={this.handleFormSubmit}
            modal={this.props.modals}
            user={this.props.singleObservation.data.key_person}
            handleToggleModal={this.handleToggleModal}
            handleToggleChild={this.handleToggleChild}
            handleFilterCategories={this.handleFilterCategories}
            handleNameFilter={this.handleNameFilter}
            handleChildComments={this.handleChildComments}
            categories={this.props.categories.display.result}
            categoriesMap={this.props.categories.data.entities.category}
            agegroups={this.props.categories.data.entities.agegroups}
            areas={this.props.categories.data.entities.areas}
            aspects={this.props.categories.data.entities.aspects}
            selectedStatements={this.props.singleObservation.data.statements}
            toggleSecure={this.toggleSecure}
            toggleSelected={this.toggleSelected}
            selectedAgeFilters={this.state.ageTerm}
            selectedAreaFilters={this.state.areaTerm}
            selectedChildren={this.props.singleObservation.data.children}
            coel={this.props.categories.coel}
            selectedCoel={this.props.singleObservation.data.coel}
            handleCOELToggle={this.handleCOELToggle}
            groupedCoel={grouped}
            coelMap={this.props.categories.coelMap.entities.characteristic}
            handleFormCancel={this.handleFormCancel}
            handlePopulateEvidence={this.handlePopulateEvidence}
          />
        ) : (
          <Loader />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  singleObservation: state.singleObservation,
  modals: state.modals,
  children: state.children,
  cloudinary: state.cloudinary,
  categories: state.categories,
  user: state.user
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...singleObservationActions,
      ...ChildActions,
      ...FlashActions,
      ...ModalActions,
      ...curriculumActions,
      filterCategories
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ObservationEditContainer);
