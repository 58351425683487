import React from "react";
import COELEditModal from "./COELEditModal";
import EditCommentModal from "./EditCommentModal";
import EYFSEditModal from "./EYFSEditModal";
import LinkNextStepModal from "./LinkNextStepModal";
import NextStepModal from "./NextStepModal";

const EditModal = ({
  open,
  type,
  child,
  selectedChildren,
  toggleChild,
  toggleModal,
  filterChildren,
  categories,
  agegroups,
  areas,
  aspects,
  toggleSecure,
  toggleSelected,
  selectedStatements,
  filterCategories,
  filterName,
  categoriesMap,
  selectedAgeFilters,
  selectedAreaFilters,
  selectedSecure,
  handleChildComments,
  childComment,
  coel,
  handleCOELToggle,
  observationValues
}) => {
  if (open) {
    switch (type) {
    case "childComments":
      return (
        <EditCommentModal
          child={child}
          toggleModal={toggleModal}
          handleChildComments={handleChildComments}
          childComment={observationValues.childComment}
        />
      );
    case "nextSteps":
      return <NextStepModal toggleModal={toggleModal} />;
    case "linkNextSteps":
      return <LinkNextStepModal toggleModal={toggleModal} />;
    case "eyfs":
      return (
        <EYFSEditModal
          toggleModal={toggleModal}
          categories={categories}
          agegroups={agegroups}
          areas={areas}
          aspects={aspects}
          selectedChildren={selectedChildren}
          toggleSecure={toggleSecure}
          toggleSelected={toggleSelected}
          filterCategories={filterCategories}
          filterName={filterName}
          categoriesMap={categoriesMap}
          ageFilters={selectedAgeFilters}
          areaFilters={selectedAreaFilters}
          child={child}
          selectedStatements={selectedStatements}
        />
      );
    case "coel":
      return (
        <COELEditModal
          toggleModal={toggleModal}
          selectedChildren={selectedChildren}
          child={child}
          characteristics={coel}
          handleCOELToggle={handleCOELToggle}
          selectedCoel={observationValues.coel}
        />
      );
    default:
      return null;
    }
  } else {
    return null;
  }
};

export default EditModal;
