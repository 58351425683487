import PropTypes from "prop-types";
import React from "react";
import ChildEditHeader from "./ChildEditHeader";
import ChildEducationEdit from "./ChildEducationEdit";
import ChildInfoEdit from "./ChildInfoEdit";

const ChildEdit = ({
  activeTab,
  child,
  handleSwitchTab,
  handleFormSubmit,
  avatar,
  handleFormCancel,
  user,
  workers
}) => {
  let tabOutput = null;

  switch (activeTab) {
  case "basic":
    tabOutput = (
      <ChildInfoEdit
        child={child}
        avatar={avatar}
        currentAvatar={child.picture}
        handleFormSubmit={handleFormSubmit}
        handleFormCancel={handleFormCancel}
      />
    );
    break;
  case "education":
    tabOutput = (
      <ChildEducationEdit
        child={child}
        user={user}
        workers={workers}
        handleFormCancel={handleFormCancel}
        handleFormSubmit={handleFormSubmit}
      />
    );
    break;
  default:
    tabOutput = (
      <ChildInfoEdit
        child={child}
        avatar={avatar}
        handleFormSubmit={handleFormSubmit}
        handleFormCancel={handleFormCancel}
      />
    );
    break;
  }
  return (
    <div>
      <ChildEditHeader activeTab={activeTab} child={child} handleSwitchTab={handleSwitchTab} />
      {tabOutput}
    </div>
  );
};

ChildEdit.propTypes = {
  activeTab: PropTypes.string.isRequired,
  handleSwitchTab: PropTypes.func.isRequired,
  handleFormSubmit: PropTypes.func.isRequired
};

export default ChildEdit;
