/* eslint-disable no-unreachable */
const initialState = {
  isLoading: true,
  isComplete: false,
  error: false,
  data: null,
  roles: []
};

const addWorkerReducer = (state = initialState, action) => {
  switch (action.type) {
  case "CREATE_WORKER":
    return {
      ...state,
      isLoading: true,
      isComplete: false
    };
  case "CREATE_WORKER_SUCCESS":
    return {
      ...state,
      isLoading: false,
      isComplete: true,
      data: action.payload.data
    };
  case "CREATE_WORKER_FAIL":
    return {
      ...state,
      isLoading: false,
      isComplete: true,
      error: true
    };
  case "GET_ROLES":
    return {
      ...state,
      isLoading: true,
      isComplete: false
    };
  case "GET_ROLES_SUCCESS":
    return {
      ...state,
      isLoading: false,
      isComplete: true,
      roles: action.payload.data
    };
  case "GET_ROLES_FAIL":
    return {
      ...state,
      isLoading: false,
      isComplete: true,
      error: true
    };
  default:
    return state;
    break;
  }
  return state;
};

export default addWorkerReducer;
