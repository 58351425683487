const initialState = {
  isLoading: false,
  error: false,
  isComplete: false,
  data: null
};

const forgotPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
  case "FORGOT_PASSWORD":
    return {
      ...state,
      isLoading: true,
      isComplete: false
    };
  case "FORGOT_PASSWORD_SUCCESS":
    return {
      ...state,
      data: action.payload.data,
      isLoading: false,
      error: false,
      isComplete: true,
      showThanks: true
    };
  case "FORGOT_PASSWORD_FAIL":
    return {
      ...state,
      isLoading: false,
      error: true,
      isComplete: true
    };
  case "EDIT_FORGOTPASSWORD_EMAIL":
    return {
      ...state,
      showThanks: action.payload.showThanks
    };
  default:
    return state;
  }
};

export default forgotPasswordReducer;
