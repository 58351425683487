import * as axios from "axios";

const cloudinary = {
  baseURL: "https://edna-cloudinary-iota-puce.now.sh/api",
  responseType: "json",
  headers: {
    "Cache-Control": "no-cache"
  }
};

const cloudinaryClient = axios.create(cloudinary);

export default cloudinaryClient;
