import React from "react";
import illustration from "../images/assets/faces-observations-empty.svg";

const ObservationsTableEmpty = ({ childCount, child }) => {
  let emptyState = "";
  if (typeof child !== "undefined") {
    emptyState = (
      <div className="empty-state">
        <img src={illustration} alt="" />
        <h2>{child.first_name} does not have any observations yet</h2>
        <p>Go to the observations page to take a new observation</p>
        <a href="/observations/create" className="btn btn--green btn--lg">
          Add an observation
        </a>
      </div>
    );
  } else if (typeof childCount !== "undefined" && childCount < 1) {
    emptyState = (
      <div className="empty-state">
        <img src={illustration} alt="" />
        <h2>You haven’t created any observations yet.</h2>
        <p>You can’t start taking observations until you add your first child to Edna. To add your first child go to the Children screen or click the button below.</p>
        <a href="/children/create" className="btn btn--green btn--lg">
          Add your first child
        </a>
      </div>
    );
  } else {
    emptyState = (
      <div className="empty-state">
        <img src={illustration} alt="" />
        <h2>You haven’t created any observations yet.</h2>
        <p>
          Looks like you’ve added your first children to Edna, great stuff! You’re now ready to take your first observation. Start by clicking the{" "}
          <span className="bold">New Observation</span> button above.
        </p>
      </div>
    );
  }
  return <div>{emptyState}</div>;
};

export default ObservationsTableEmpty;
