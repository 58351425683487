import React from "react";
import {Field, reduxForm, Form} from "redux-form";
import zxcvbn from "zxcvbn";
import Input from "./Input";
import Loader from "./Loader";
import {required, minLength8} from "./Validators";

class ProcessInvite extends React.Component {
  state = {
    strength: null
  }

  handlePasswordChange = (event) => {
    console.log(event.target.value);
    this.setState({
      strength: zxcvbn(event.target.value).score
    })
  }

  render() {
    const {handleSubmit, handleFormSubmit, onboarding} = this.props;
    return (
      <div className="signUp__main">
        <Form onSubmit={handleSubmit(handleFormSubmit.bind(this))}>
          {onboarding.invite_type === "admin"
            ? (
              <div className="signUp__form-section">
                <div className="signUp__text-area">
                  <h1>Organisation or setting name</h1>
                  <p>This is typically the trading name of your setting, organisation or school.
                    For example Butterflies Pre-school, Little Feet Nursery, Sarah Jane childcare…</p>
                </div>
                <div className="standard-input standard-input--lg">
                  <Field
                    name="setting"
                    targetName="setting"
                    type="text"
                    value=""
                    validate={[required]}
                    component={Input}/>
                </div>
              </div>
            )
            : ("")}
          <div className="signUp__form-section">
            <div className="signUp__text-area">
              <h1>Set your password</h1>
              <p>You’ll use this password plus your email address ({onboarding.email}) to sign into your Edna account.</p>
            </div>
            <div className="standard-input standard-input--lg">
              <Field
                name="password"
                targetName="password"
                type="password"
                value=""
                validate={[required, minLength8]}
                component={Input}
                onChange={this.handlePasswordChange}
                strength={this.state.strength}/>
              <div className="helperText">
                <p>Passwords must be at least 8 characters long but we recommend 12.</p>
              </div>
            </div>
          </div>
          {onboarding.isLoading
            ? (<Loader/>)
            : (
              <button type="submit" className="btn btn--green btn--lg btn--mob-fixed">
                All done!
              </button>
            )}
        </Form>
      </div>
    );
  }
}

export default reduxForm({form: "onboarding"})(ProcessInvite);
