import React from "react";
import ObservationsTable from "./ObservationsTable";

const ObservationProfileList = ({ observations, worker, user }) => (
  <div>
    <ObservationsTable observations={observations.map(observation => observation.uuid)} worker={worker} user={user} />
  </div>
);

export default ObservationProfileList;
