import React from "react";
import PropTypes from "prop-types";
import HasPermission from "../containers/HasPermission";
import GreenButton from "./GreenButton";

const ObservationListHeader = ({
  title,
  singular = null,
  newLink = null,
  icon,
  childCount
}) => (
  <div className="header-local">
    <h1 className={`header-local__title header-local__title--${icon}`}>
      {title}
    </h1>
    {newLink !== null && childCount > 0
      ? <HasPermission allowedRoles={["super_admin", "admin", "worker"]}>
        <GreenButton newLink={newLink} singular={singular}/>
      </HasPermission>
      : ""
    }
  </div>
);

ObservationListHeader.propTypes = {
  title: PropTypes.string.isRequired,
  singular: PropTypes.string,
  newLink: PropTypes.string,
  icon: PropTypes.string.isRequired,
  childCount: PropTypes.number.isRequired
};

export default ObservationListHeader;
