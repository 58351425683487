import * as Sentry from "@sentry/browser";
import React from "react";
import image from "../images/assets/sad-face.svg";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: false };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.captureException(error, { extra: errorInfo });
  }

  render() {
    if (this.state.error) {
      return (
        <div
          className="snap error-screen"
          onClick={() => Sentry.lastEventId() && Sentry.showReportDialog()}
        >
          <div className="error-screen--container">
            <img className="error-screen--img" src={image} alt="Sad face" />

            <h1>Oh Snap!</h1>
            <p>
              Looks like we’re experencing some technical shenanigans (Sorry
              about that), try refreshing the page or hit the button below to
              login again.{" "}
            </p>

            <a href="/logout" className="btn btn--green btn--sm">
              Return to login screen
            </a>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
