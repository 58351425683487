import {TOGGLE_MODAL} from "./actionTypes";

export function closeModal(type) {
    return {
        type: TOGGLE_MODAL,
        payload: {
            type: null,
            open: false
        }
    };
}

export function openModal(type) {
    return {
        type: TOGGLE_MODAL,
        payload: {
            type,
            open: true
        }
    };
}
