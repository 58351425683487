import React from "react";

const NextStepModal = ({ toggleModal }) => (
  <div className="modal modal-open">
    <div className="modal__content">
      <div className="modal__header">
        <h2>Add New Next Steps</h2>
        <a href={true} className="btn-cancel" onClick={toggleModal}>
          Cancel
        </a>
      </div>
      <div className="modal__main">
        <div className="child-comment">
          <form action="psot" className="child-comment__form">
            <label htmlFor="">
              <div className="avatar avatar--small">
                <img alt="avatar" src="images/users/49.jpg" />
              </div>
              <div className="child-comment__text">
                <div className="child-comment__name">Elliot Poulter</div>
                <div className="child-comment__helpertxt">
                  What are the next steps to for Elliot to develop learning or to inform planning?
                </div>
              </div>
            </label>
            <textarea name="" />
          </form>
        </div>
        <div className="child-comment">
          <form action="psot" className="child-comment__form">
            <label htmlFor="">
              <div className="avatar avatar--small">
                <img alt="avatar" src="images/users/49.jpg" />
              </div>
              <div className="child-comment__text">
                <div className="child-comment__name">Elliot Poulter</div>
                <div className="child-comment__helpertxt">
                  What are the next steps to for Elliot to develop learning or to inform planning?
                </div>
              </div>
            </label>
            <textarea name="" />
          </form>
        </div>
      </div>
      <div className="modal__footer">
        <div className="modal__footer__controls">
          <a href={true} className="btn btn--green btn--sm btn--mob-fullw">
            Add Next Steps
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default NextStepModal;
