import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Field, Form, reduxForm } from "redux-form";
import styled from "styled-components";
import Theme from "../theme";
import Avatar from "./Avatar";
import EditModal from "./EditModal";
import Input from "./Input";
import ObservationEditImage from "./ObservationEditImage";
import ObservationEditImageButton from "./ObservationEditImageButton";
import RenderTimePicker from "./RenderTimePicker";
import { required } from "./Validators";

const EditDate = styled.a`
  color: ${Theme.colorBrandGreen};
  margin-left: 10px;
  cursor: pointer;
`;

class ObservationEditForm extends React.Component {
  state = {
    title: null,
    dateEdit: false
  };

  handleInputChange = event => {
    const { target, name } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;

    this.setState({ [name]: value });
  };

  handleToggleDateEdit = () => {
    this.setState({
      dateEdit: !this.state.dateEdit
    });
  };

  render() {
    const {
      observation,
      cloudinaryLoading,
      modal,
      user,
      handleToggleChild,
      handleToggleModal,
      handleChildNameFilter,
      categories,
      aspects,
      areas,
      agegroups,
      toggleSelected,
      toggleSecure,
      selectedStatements,
      handleFilterCategories,
      handleNameFilter,
      categoriesMap,
      selectedAgeFilters,
      selectedAreaFilters,
      selectedSecure,
      handleChildComments,
      coel,
      handleCOELToggle,
      handleFormCancel,
      handlePopulateEvidence
    } = this.props;
    return (
      <Form>
        <div>
          <div className="header-local">
            <h1 className="header-local__title header-local__title--observations">
              Edit Observation
            </h1>
            <div className="header-local__cta">
              <a
                href={true}
                className="btn btn--red btn--sm btn--mob-sq"
                onClick={handleFormCancel}
              >
                Cancel
              </a>
            </div>
          </div>
          <div className="new-observation__header">
            <Avatar
              size="medium"
              userName={
                observation.key_person.first_name +
                " " +
                observation.key_person.last_name
              }
              image={observation.key_person.picture}
              type="user"
            />

            <div className="observation__header__text">
              <h2 className="observation-title">
                {user.first_name}&nbsp; Observed {observation.child.first_name}{" "}
                {this.state.title !== null
                  ? this.state.title
                  : observation.title}
              </h2>
              <div className="observation-meta">
                {this.state.dateEdit === false ? (
                  <div>
                    {moment(observation.date).calendar()}
                    <EditDate onClick={this.handleToggleDateEdit}>
                      Edit
                    </EditDate>
                  </div>
                ) : (
                  <Field
                    label="Enter new date"
                    name="observationDate"
                    targetName="observationDate"
                    type="date"
                    value=""
                    validate={[required]}
                    component={RenderTimePicker}
                  />
                )}
              </div>
            </div>
          </div>
          <section className="new-observation__description">
            <div className="observation-input">
              <Field
                name="title"
                targetName="title"
                type="text"
                value=""
                label="Short description"
                component={Input}
                validate={required}
                placeholder="Briefly, what's happening"
                onChange={this.handleInputChange}
              />
            </div>
            <div className="observation-input">
              <label htmlFor="">Full description</label>
              <Field
                name="content"
                type="textarea"
                value=""
                placeholder="Give some more detail"
                component="textarea"
                cols="30"
                rows="10"
              />
            </div>
          </section>
          <section className="new-observation__led">
            <div className="observation-input">
              <label htmlFor="">Was it an adult or child led activity?</label>
              <div className="radio-set">
                <div className="standard-radio">
                  <div className="edna-radio">
                    <Field
                      name="led_by"
                      component="input"
                      type="radio"
                      value="adult"
                    />
                    <div className="styled_radio" />
                  </div>
                  Adult led
                </div>
                <div className="standard-radio">
                  <div className="edna-radio">
                    <Field
                      name="led_by"
                      component="input"
                      type="radio"
                      value="child"
                    />
                    <div className="styled_radio" />
                  </div>
                  Child led
                </div>
              </div>
            </div>
          </section>
          {observation.evidence.length > 0 ? (
            <ObservationEditImage
              evidence={observation.evidence}
              handlePopulateEvidence={handlePopulateEvidence}
            />
          ) : (
            ""
          )}
          {observation.childComment !== null ? (
            <div className="new-observation__preview new-observation__preview__child-comments">
              <div className="new-observation__preview__title">
                Child Comment
              </div>
              <div
                className="observation-preview-item"
                key={observation.child.uuid}
              >
                <Avatar
                  size="small"
                  userName={
                    observation.child.first_name +
                    " " +
                    observation.child.last_name
                  }
                  image={observation.child.picture}
                  type="child"
                />

                <div className="bubble-text">
                  <h4>
                    {observation.child.first_name}
                    {observation.child.last_name}
                  </h4>
                  <p>{observation.childComment}</p>
                  <a
                    href={true}
                    className="btn-edit"
                    onClick={this.props.handleToggleModal.bind(
                      this,
                      "childComments"
                    )}
                  >
                    Edit
                  </a>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {observation.statements.length > 0 ? (
            <div className="new-observation__preview new-observation__preview__curriculum">
              <div className="new-observation__preview__title">
                EYFS Statements
              </div>
              {observation.statements.map(statement => (
                <div className="observation-preview-item" key={statement.uuid}>
                  <div
                    className={`bubble-text bubble-text--noavatar curriculm-${statement.area.identifier}`}
                  >
                    <h4
                      className={`title-icon title-icon--${statement.area.identifier}`}
                    >
                      {statement.area.name}
                    </h4>
                    <p>{statement.name}</p>
                    <ul
                      className={`curriculm-meta curriculm-meta--${statement.area.identifier}`}
                    >
                      <li>{statement.aspect.name}</li>
                      <li>
                        {statement.agegroup.min}- {statement.agegroup.max}
                        months
                      </li>
                    </ul>
                    <ul className="child-secure">
                      <li className={statement.secure === 1 ? "secure" : ""}>
                        <Avatar
                          border
                          size="small"
                          image={observation.child.picture}
                          userName={
                            observation.child.first_name +
                            " " +
                            observation.child.last_name
                          }
                          type="child"
                        />
                      </li>
                    </ul>
                    <a
                      href={true}
                      className="btn-edit"
                      onClick={this.props.handleToggleModal.bind(this, "eyfs")}
                    >
                      Edit
                    </a>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            ""
          )}
          {typeof observation.linkedSteps !== "undefined" ? (
            <div className="new-observation__preview new-observation__preview__linked-ns">
              <div className="new-observation__preview__title">
                Linked Next Steps
              </div>
              <div className="observation-preview-item">
                <div className="avatar avatar--small">
                  <img alt="avatar" src="images/users/49.jpg" />
                </div>
                <div className="bubble-text linked-ns">
                  <div className="linked-ns__desc">
                    <p>
                      Use different materials to create different sounds. For
                      example rain, thunder etc
                    </p>
                  </div>
                  <div className="linked-ns__date">05-08-2017</div>
                  <div className="linked-ns__person">
                    <div className="person person--secondary" href={true}>
                      <div className="avatar avatar--tiny">
                        <img alt="avatar" src="images/users/47.jpg" />
                      </div>
                      <div className="person__name">Sarah Jane</div>
                    </div>
                  </div>
                  <a
                    href={true}
                    className="btn-edit"
                    onClick={this.props.handleToggleModal.bind(
                      this,
                      "linkedSteps"
                    )}
                  >
                    Edit
                  </a>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {typeof observation.nextSteps !== "undefined" ? (
            <div className="new-observation__preview new-observation__preview__new-ns">
              <div className="new-observation__preview__title">
                New Next Steps
              </div>
              <div className="observation-preview-item">
                <div className="avatar avatar--small">
                  <img alt="avatar" src="images/users/49.jpg" />
                </div>
                <div className="bubble-text new-ns">
                  <p>
                    Use different materials to create different sounds. For
                    example rain, thunder etc
                  </p>
                  <a
                    href={true}
                    className="btn-edit"
                    onClick={this.props.handleToggleModal.bind(
                      this,
                      "nextsteps"
                    )}
                  >
                    Edit
                  </a>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {observation.coel.length > 0 ? (
            <div className="new-observation__preview new-observation__preview__cel">
              <div className="new-observation__preview__title">
                Characteristics of Effective Learning
              </div>
              <div className="observation-preview-item">
                <Avatar
                  size="small"
                  userName={
                    observation.child.first_name +
                    " " +
                    observation.child.last_name
                  }
                  image={observation.child.picture}
                  type="child"
                />
                <div className="bubble-text cel">
                  <ul className="cel__items">
                    {observation.coel.map(coelChild => (
                      <li className="cel__area" key={coelChild.uuid}>
                        {coelChild.parent.name}
                        {coelChild.name}
                      </li>
                    ))}
                  </ul>
                  <a
                    href={true}
                    className="btn-edit"
                    onClick={this.props.handleToggleModal.bind(this, "coel")}
                  >
                    Edit
                  </a>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="new-observation__draw">
            <ObservationEditImageButton
              handlePopulateEvidence={handlePopulateEvidence}
            />{" "}
            <div
              className="new-observation__draw__item new-observation__draw__item--child-comms"
              onClick={this.props.handleToggleModal.bind(this, "childComments")}
            >
              <div>Child Comments</div>
            </div>
            {/* { */}
            {/* selectedChildren.length > 0 ? */}
            {/* <div className='new-observation__draw__item new-observation__draw__item--nextsteps' */}
            {/* onClick={this.props.handleToggleModal.bind(this, "linkNextSteps")}> */}
            {/* Link Next Steps */}
            {/* </div> */}
            {/* : "" */}
            {/* } */}
            {/* { */}
            {/* selectedChildren.length > 0 ? */}
            {/* <div className='new-observation__draw__item new-observation__draw__item--nextstepsnew' */}
            {/* onClick={this.props.handleToggleModal.bind(this, "nextSteps")}> */}
            {/* New Next Steps */}
            {/* </div> */}
            {/* : "" */}
            {/* } */}
            <div
              className="new-observation__draw__item new-observation__draw__item--eyfs"
              onClick={this.props.handleToggleModal.bind(this, "eyfs")}
            >
              <div>EYFS Statements</div>
            </div>
            <div
              className="new-observation__draw__item new-observation__draw__item--charic"
              onClick={this.props.handleToggleModal.bind(this, "coel")}
            >
              <div>Effective learning</div>
            </div>
          </div>
          <footer className="form-footer form-footer--observation">
            <div className="form-footer__btns">
              {(observation.isLoading && observation.status === "published") ||
              cloudinaryLoading ? (
                <a
                  href={true}
                  className="btn btn--green btn--lg btn--mob-fixed btn--spinner btn--disabled"
                >
                  Save changes
                </a>
              ) : (
                <a
                  href={true}
                  className="btn btn--green btn--lg btn--mob-fixed"
                  onClick={this.props.handleSubmit(values =>
                    this.props.handleFormSubmit({
                      ...values,
                      worker: user,
                      status: "published",
                      uuid: observation.uuid,
                      observation
                    })
                  )}
                >
                  Save changes
                </a>
              )}
              {(observation.isLoading && observation.status === "draft") ||
              cloudinaryLoading ? (
                <a
                  href={true}
                  className="btn btn--blue btn--lg btn--mob-fixed btn--spinner btn--disabled"
                >
                  Save changes as draft
                </a>
              ) : (
                <a
                  href={true}
                  className="btn btn--blue btn--lg btn--mob-fixed"
                  onClick={this.props.handleSubmit(values =>
                    this.props.handleFormSubmit({
                      ...values,
                      worker: user,
                      uuid: observation.uuid,
                      status: "draft",
                      observation
                    })
                  )}
                >
                  Save changes as draft
                </a>
              )}
            </div>
          </footer>
          {modal !== null ? (
            <EditModal
              open={modal.open}
              type={modal.type}
              toggleChild={handleToggleChild}
              toggleModal={handleToggleModal}
              filterChildren={handleChildNameFilter}
              filterCategories={handleFilterCategories}
              filterName={handleNameFilter}
              categories={categories}
              categoriesMap={categoriesMap}
              agegroups={agegroups}
              areas={areas}
              aspects={aspects}
              toggleSecure={toggleSecure}
              toggleSelected={toggleSelected}
              selectedStatements={selectedStatements}
              selectedAgeFilters={selectedAgeFilters}
              selectedAreaFilters={selectedAreaFilters}
              childComments={observation.childComment}
              selectedSecure={selectedSecure}
              handleChildComments={handleChildComments}
              coel={coel}
              handleCOELToggle={handleCOELToggle}
              observationValues={observation}
              child={observation.child}
            />
          ) : (
            ""
          )}
          {modal.open ? <div className="modal-overlay modal-open" /> : ""}
        </div>
      </Form>
    );
  }
}

ObservationEditForm.propTypes = {
  observation: PropTypes.object.isRequired,
  handlePopulateEvidence: PropTypes.func.isRequired
};

ObservationEditForm = reduxForm({
  form: "edit-observation",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true
})(ObservationEditForm);

ObservationEditForm = connect(state => ({
  initialValues: {
    observationDate: state.singleObservation.data.date,
    title: state.singleObservation.data.title,
    content: state.singleObservation.data.content,
    led_by: state.singleObservation.data.led_by
  }
}))(ObservationEditForm);

export default ObservationEditForm;
