const initialState = {
  messages: []
};

const flashReducer = (state = initialState, action) => {
  switch (action.type) {
  case "ADD_FLASH_MESSAGE":
    return {
      ...state,
      messages: [...state.messages, action.payload.message]
    };
  case "REMOVE_FLASH_MESSAGE":
    return {
      ...state,
      messages: state.messages.filter(message => message.id !== action.payload.id)
    };
  case "CLEAR_FLASH_MESSAGES":
    return {
      ...state,
      messages: []
    };
  default:
    return state;
  }
};

export default flashReducer;
