/* eslint-disable no-unreachable */
const initialState = {
  isLoading: false,
  isComplete: false,
  error: false,
  data: null
};

const populateGuardians = (state, action) => {
  if (action.payload.data.uuid === state.uuid) {
    return action.payload.data;
  }
  return { ...state };
};

const childReducer = (state = initialState, action) => {
  switch (action.type) {
  case "GET_SINGLE_CHILD":
    return {
      ...state,
      isLoading: true,
      isComplete: false
    };
  case "GET_SINGLE_CHILD_SUCCESS":
    return {
      ...state,
      isLoading: false,
      isComplete: true,
      data: action.payload.data
    };
  case "GET_SINGLE_CHILD_FAIL":
    return {
      ...state,
      isLoading: false,
      isComplete: false,
      error: true
    };
  case "UPDATE_CHILD":
    return {
      ...state,
      isLoading: true,
      isComplete: false
    };
  case "UPDATE_CHILD_SUCCESS":
    return {
      ...state,
      isLoading: false,
      isComplete: true,
      data: action.payload.data
    };
  case "UPDATE_CHILD_FAIL":
    return {
      ...state,
      isLoading: false,
      isComplete: true,
      error: true
    };
  case "DELETE_CHILD":
    return {
      ...state,
      isLoading: true,
      isComplete: false
    };
  case "DELETE_CHILD_SUCCESS":
    return {
      ...state,
      isLoading: false,
      isComplete: true
    };
  case "DELETE_CHILD_FAIL":
    return {
      ...state,
      isLoading: false,
      isComplete: true,
      error: true
    };
  case "ADD_CARER_SUCCESS":
    return {
      ...state,
      data: action.payload.data
    };
  case "REMOVE_CARER_SUCCESS":
    return {
      ...state,
      data: action.payload.data
    };
  case "SET_FLAG_SUCCESS":
    return {
      ...state,
      data: {
        ...state.data,
        guardians: state.data.guardians.map(guardian => populateGuardians(guardian, action))
      }
    };
  default:
    return state;
    break;
  }
  return state;
};

export default childReducer;
