import React from "react";
import Tab from "./Tab";

const ChildProfileTabs = ({handleSwitchTab, isActive, user}) => (

  <nav className='nav-inline nav-local nav-local--greybg'>
    <ul>
      <Tab
        isActive={isActive === "info"
          ? true
          : false}
        tabName="info"
        tabLabel="Info"
        handleSwitchTab={handleSwitchTab}/>
      <Tab
        isActive={isActive === "observations"
          ? true
          : false}
        tabName="observations"
        tabLabel="Observations"
        handleSwitchTab={handleSwitchTab}/>
      {user.data.role.name !== 'parent' ?
        (<Tab
          isActive={isActive === "progression"
            ? true
            : false}
          tabName="progression"
          tabLabel="Progression"
          handleSwitchTab={handleSwitchTab}/>)
        : ""}
    </ul>
  </nav>

);

export default ChildProfileTabs;
