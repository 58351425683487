import React from "react";
import {Field, reduxForm} from "redux-form";
import Input from "./Input";
import {connect} from "react-redux";
import AvatarInput from "./AvatarInput";

class CreateChildForm2 extends React.Component {
  render() {
    const {addChild} = this.props;
    return (
      <div>
        <section className="form-section form-section--title">
          <h2 className="form-section__title">More about {this.props.addChild.data.first_name}</h2>
          <p className="form-section__intro">
            This is where you can enter more detail about {this.props.addChild.data.first_name}. Don’t worry, if you don't know any of
this information yet, it can be updated in the future.
          </p>
        </section>
        <section className="form-section">
          <div className="standard-inputs">
            <div className="standard-input standard-input--lg">
              <label htmlFor="gender">Gender</label>
              <p>OK, first up is {this.props.addChild.data.first_name} a boy or a girl?</p>
              <div className="radio-set">
                <div className="standard-radio">
                  <div className="edna-radio">
                    <label>
                      <Field name="gender" component="input" type="radio" value="boy"/>
                      <div className="styled_radio"/>
                    </label>
                  </div>
                  <label>Boy</label>
                </div>
                <div className="standard-radio">
                  <div className="edna-radio">
                    <label>
                      <Field name="gender" component="input" type="radio" value="girl"/>
                      <div className="styled_radio"/>
                    </label>
                  </div>
                  <label>Girl</label>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="form-section">
          <div className="standard-inputs">
            <div className="standard-input standard-input--lg">
              <label htmlFor="all-about">All about {this.props.addChild.data.first_name}</label>
              <p>
                A description of {this.props.addChild.data.first_name}. What they like, what they don’t like. Add
anything you think would be helpful to anyone who hasn't met{" "} {this.props.addChild.data.first_name}.
              </p>
              <Field name="all_about" type="textarea" value="" component="textarea"/>
            </div>
          </div>
        </section>
        <section className="form-section">
          <div className="standard-inputs">
            <div className="standard-input standard-input--lg">
              <label htmlFor="avatar">Profile picture</label>
              <p>Add an image of {this.props.addChild.data.first_name}. This helps you identify them quickly and
easily will help increase parent interaction.</p>
              <AvatarInput
                buttonText="Upload Avatar"
                acceptedFormats="jpg,png,gif"
                maxSize="5MB"
                avatar={this.props.avatar}/>
            </div>
          </div>
        </section>
        <section className="form-section">
          <div className="standard-inputs">
            <div className="standard-input standard-input--lg">
              <label htmlFor="preferred_name">Preferred Name</label>
              <p>Does {this.props.addChild.data.first_name} like to be called anything else? Perhaps a nickname or shortened version?</p>
              <Field
                name="preferred_name"
                targetName="preferred_name"
                type="text"
                value=""
                component={Input}
                handleChange={this.handleInputChange}/>
            </div>
          </div>
        </section>
        <footer className="form-footer">
          <div className="form-footer__btns">
            {addChild.isLoading && addChild.status === "finish"
              ? (
                <button
                  className="btn btn--blue btn--disabled btn--spinner btn--lg btn--mob-fixed">Create &amp; finish</button>
              )
              : (
                <button
                  className="btn btn--blue btn--lg btn--mob-fixed"
                  onClick={this
                      .props
                      .handleSubmit(values => this.props.handleFormSubmit({
                        ...values,
                        buttonAction: "finish"
                      }))}>
                  Create &amp; finish
                </button>
              )}
            {addChild.isLoading && addChild.status === "continue"
              ? (
                <button
                  className="btn btn--green btn--disabled btn--spinner btn--lg btn--mob-fixed">Create &amp; continue</button>
              )
              : (
                <button
                  className="btn btn--green btn--lg btn--mob-fixed"
                  onClick={this
                      .props
                      .handleSubmit(values => this.props.handleFormSubmit({
                        ...values,
                        buttonAction: "continue"
                      }))}>
                  Create &amp; continue
                </button>
              )}
          </div>
          <div className="form-progress">
            <div className="form-progress--steps">Step 2 of 3</div>
            <div className="form-progress--bar">
              <div
                className="form-progress--done"
                style={{
                  width: "66.6%"
                }}/>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

CreateChildForm2 = reduxForm({form: "create_child", destroyOnUnmount: false, forceUnregisterOnUnmount: true})(CreateChildForm2);

CreateChildForm2 = connect(state => ({initialValues: state.addChild.data}))(CreateChildForm2);

export default CreateChildForm2;
