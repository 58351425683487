import React from "react";

const FlashMessage = ({ handleClose, message }) => (
  <div className={`alert alert--${message.type}`}>
    <h3 className="alert__title">{message.message ? message.message : ""}</h3>
    <a href={true} className="alert__close" onClick={handleClose} id={message.id}>
      Close
    </a>
  </div>
);

export default FlashMessage;
