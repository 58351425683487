import React from "react";

const CancelAccount = ({ toggleCancelModal, subscription }) => {
  if (subscription.cancel_at_period_end === false) {
    return (
      <section className="pref-section">
        <h3>Cancel your account</h3>
        <p>You can cancel your account at any time - Just click the button below.</p>
        <p>
          You will be able to access and use your account upto the end of your current billing cycle after which your
          account will be canceled.
        </p>

        <p>
          Doing this will mean you that you can no longer add, edit or view children, observations or users after your
          current billing cycle.
        </p>

        <p>
          If you need to re-activate your account after you've cancelled dont worry. Just log in to your Edna account
          and sign up for a new pricing plan. All your data will be right where you left it.
        </p>
        <a href={true} className="btn btn--lg btn--empty margin--t" onClick={toggleCancelModal}>
          Cancel my account
        </a>
      </section>
    );
  } else {
    return null;
  }
};

CancelAccount.propTypes = {};

export default CancelAccount;
