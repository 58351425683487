import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {browserHistory} from "react-router";
import * as singleObservationActions from "../actions/singleObservationActions";
import SingleObservation from "../components/SingleObservation";
import * as FlashActions from "../actions/flashActions";
import Loader from "../components/Loader";

class SingleObservationContainer extends React.Component {
  state = {
    contextOpen: false,
    deleteModalOpen: false,
    lightboxIsOpen: false,
    currentImage: 0
  };

  componentWillMount() {
    this
      .props
      .getObservation(this.props.params.observationId);
  }

  handleToggleContextMenu = () => {
    if (this.state.contextOpen) {
      this.setState({contextOpen: false});
    } else {
      this.setState({contextOpen: true});
    }
  };

  handleToggleDeleteModal = () => {
    if (this.state.deleteModalOpen) {
      this.setState({deleteModalOpen: false});
    } else {
      this.setState({deleteModalOpen: true});
    }
  }

  handleDeleteObservation = (uuid) => {
    this
      .props
      .deleteObservation(uuid)
      .then(() => {
        this
          .props
          .addMessageToFlash("Observation was successfully deleted", "success", null);
      })
      .then(() => {
        browserHistory.replace("/observations");
      });
  };

  handleSetEdit = () => {
    if (this.state.edit) {
      this.setState({edit: false});
    } else {
      this.setState({edit: true});
    }
  };

  openLightbox = (index, event) => {
    event.preventDefault();
    this.setState({currentImage: index, lightboxIsOpen: true});
  }

  closeLightbox = () => {
    this.setState({lightboxIsOpen: false, currentImage: 0})
  }

  gotoNext = () => {
    this.setState({
      currentImage: this.state.currentImage + 1
    });
  }

  gotoPrev = () => {
    this.setState({
      currentImage: this.state.currentImage - 1
    })
  }

  render() {
    return this.props.singleObservation.isComplete
      ? (<SingleObservation
        observation={this.props.singleObservation.data}
        user={this.props.user}
        handleToggleContextMenu={this.handleToggleContextMenu}
        handleToggleDeleteModal={this.handleToggleDeleteModal}
        deleteOpen={this.state.deleteModalOpen}
        handleDelete={this.handleDeleteObservation}
        handleEdit={this.handleSetEdit}
        contextOpen={this.state.contextOpen}
        renderGallery={this.renderGallery}
        lightboxIsOpen={this.state.lightboxIsOpen}
        openLightbox={this.openLightbox}
        closeLightbox={this.closeLightbox}
        currentImage={this.state.currentImage}
        gotoNext={this.gotoNext}
        gotoPrev={this.gotoPrev}/>)
      : (<Loader/>);
  }
}

const mapStateToProps = state => ({singleObservation: state.singleObservation, user: state.user});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...FlashActions,
  ...singleObservationActions
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SingleObservationContainer);
