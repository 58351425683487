import React from "react";

const ChildListFilter = ({ workers, handleFilterChange }) => (
  <div className="filters filters--3up">
    <h3 className="filters__title">Filter</h3>
    <form action="" className="filters__form">
      <div className="filters__form__input">
        <label htmlFor="">Child Name</label>
        <input placeholder="Type Name Here" type="text" onChange={handleFilterChange} name="name" />
      </div>
      <div className="filters__form__input">
        <label htmlFor="">Key Person</label>
        <select onChange={handleFilterChange} name="worker">
          <option value="">All</option>
          {typeof workers !== "undefined" || workers !== null
            ? workers.map(worker => {
              if (worker.active) {
                return (
                  <option value={worker.uuid} key={worker.uuid}>
                    {worker.first_name} {worker.last_name.charAt(0)}
                  </option>
                );
              } else {
                return false;
              }
            })
            : ""}
        </select>
      </div>
    </form>
  </div>
);

export default ChildListFilter;
