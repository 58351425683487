
import { GET_INVITES, DELETE_INVITE } from "./actionTypes";


export function getInvites() {
  return {
    type: GET_INVITES,
    payload: {
      request: {
        method: "GET",
        url: "invites",
      },
    },
  };
}


export function deleteInvite(uuid) {
  return {
    type: DELETE_INVITE,
    payload: {
      id: uuid,
      request: {
        method: "DELETE",
        url: `invites/${uuid}`,
      },
    },
  };
}

