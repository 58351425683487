import {
  GET_SINGLE_OBSERVATION,
  POPULATE_EDIT_STATEMENTS,
  POPULATE_EDIT_COMMENTS,
  POPULATE_EDIT_COEL,
  EDIT_OBSERVATION,
  POPULATE_EVIDENCE,
  UPLOAD_EDIT_IMAGE,
  REMOVE_EDIT_IMAGE,
  RESET_EDIT_OBSERVATION_STATE,
  DELETE_OBSERVATION
} from "./actionTypes";
import { hex } from "js-sha1";

export function getObservation(id) {
  return {
    type: GET_SINGLE_OBSERVATION,
    payload: {
      request: {
        method: "GET",
        url: `observations/${id}`
      }
    }
  };
}

export function deleteObservation(uuid) {
  return {
    type: DELETE_OBSERVATION,
    payload: {
      request: {
        method: "DELETE",
        url: `observations/${uuid}`
      }
    }
  };
}

export function populateEditStatements(statements) {
  return {
    type: POPULATE_EDIT_STATEMENTS,
    payload: {
      statements
    }
  };
}

export function populateEditComment(comment) {
  return {
    type: POPULATE_EDIT_COMMENTS,
    payload: {
      comment
    }
  };
}

export function populateEditCOEL(coel) {
  return {
    type: POPULATE_EDIT_COEL,
    payload: {
      coel
    }
  };
}

export function populateEvidence(evidence) {
  return {
    type: POPULATE_EVIDENCE,
    payload: {
      evidence
    }
  };
}

export function editObservation(values) {
  return {
    type: EDIT_OBSERVATION,
    payload: {
      request: {
        method: "POST",
        url: `observations/${values.uuid}`,
        data: values
      }
    }
  };
}

export function uploadImage(file, fileName) {
  const timestamp = new Date().getTime();
  const fileStamp = hex(fileName + timestamp);
  return {
    type: UPLOAD_EDIT_IMAGE,
    payload: {
      client: "cloudinary",
      request: {
        method: "POST",
        url: "/upload",
        data: {
          file,
          tags: fileStamp
        }
      }
    }
  };
}

export function removeImage(imageId) {
  return {
    type: REMOVE_EDIT_IMAGE,
    payload: {
      client: "cloudinary",
      request: {
        method: "POST",
        url: "/destroy",
        data: {
          cloudinary_id: imageId
        }
      }
    }
  };
}

export function resetEditObservationState() {
  return { type: RESET_EDIT_OBSERVATION_STATE };
}
