import React from "react";
import ChildModal from "./ChildModal";
import CommentModal from "./CommentModal";
import LinkNextStepModal from "./LinkNextStepModal";
import NextStepModal from "./NextStepModal";
import EYFSModal from "./EYFSModal";
import COELModal from "./COELModal";
import PropTypes from "prop-types";

const Modal = ({
  open,
  type,
  childMap,
  children,
  childEntities,
  selectedChildren,
  toggleChild,
  toggleModal,
  filterChildren,
  categories,
  agegroups,
  areas,
  aspects,
  toggleSecure,
  toggleSelected,
  selectedStatements,
  filterCategories,
  filterName,
  categoriesMap,
  selectedAgeFilters,
  selectedAreaFilters,
  selectedSecure,
  handleChildComments,
  childComments,
  coel,
  handleCOELToggle,
  observationValues
}) => {
  if (open) {
    switch (type) {
      case "children":
        return (
          <ChildModal
            childMap={childMap}
            children={children}
            childEntities={childEntities}
            toggleChild={toggleChild}
            selectedChildren={selectedChildren}
            filterChildren={filterChildren}
            toggleModal={toggleModal}
          />
        );
      case "childComments":
        return (
          <CommentModal
            children={childEntities}
            selectedChildren={selectedChildren}
            toggleModal={toggleModal}
            handleChildComments={handleChildComments}
            childComments={childComments}
          />
        );
      case "nextSteps":
        return <NextStepModal toggleModal={toggleModal} />;
      case "linkNextSteps":
        return <LinkNextStepModal toggleModal={toggleModal} />;
      case "eyfs":
        return (
          <EYFSModal
            toggleModal={toggleModal}
            categories={categories}
            agegroups={agegroups}
            areas={areas}
            aspects={aspects}
            selectedChildren={selectedChildren}
            toggleSecure={toggleSecure}
            toggleSelected={toggleSelected}
            selectedStatements={selectedStatements}
            filterCategories={filterCategories}
            filterName={filterName}
            categoriesMap={categoriesMap}
            ageFilters={selectedAgeFilters}
            areaFilters={selectedAreaFilters}
            children={childEntities}
            selectedSecure={selectedSecure}
          />
        );
      case "coel":
        return (
          <COELModal
            toggleModal={toggleModal}
            selectedChildren={selectedChildren}
            children={childEntities}
            characteristics={coel}
            handleCOELToggle={handleCOELToggle}
            selectedCoel={observationValues.coel}
          />
        );
      default:
        return null;
    }
  } else {
    return null;
  }
};

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  type: PropTypes.string,
  childMap: PropTypes.array.isRequired,
  children: PropTypes.object.isRequired,
  selectedChildren: PropTypes.array,
  toggleChild: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  filterChildren: PropTypes.func.isRequired,
  filterCategories: PropTypes.func.isRequired,
  handleChildComments: PropTypes.func.isRequired,
  categories: PropTypes.array,
  agegroups: PropTypes.object.isRequired,
  areas: PropTypes.object,
  aspects: PropTypes.object,
  toggleSecure: PropTypes.func.isRequired,
  toggleSelected: PropTypes.func.isRequired,
  selectedStatements: PropTypes.array.isRequired,
  filterName: PropTypes.func.isRequired,
  categoriesMap: PropTypes.object.isRequired,
  selectedAgeFilters: PropTypes.array,
  selectedAreaFilters: PropTypes.array,
  selectedSecure: PropTypes.array,
  handleCOELToggle: PropTypes.func.isRequired
};

export default Modal;
