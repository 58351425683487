import PropTypes from "prop-types";
import React from "react";
import Avatar from "./Avatar";

const Statement = ({
  area,
  aspect,
  agegroup,
  statement,
  toggleSecure,
  toggleSelected,
  statementSelected,
  childSelected,
  selectedChildren,
  children,
  selectedSecure
}) => (
  <li
    className={`select-list--statment select-list--statment--${area.identifier}`}
  >
    <div
      className="statment-container"
      onClick={toggleSelected.bind(this, statement.uuid)}
    >
      <div className="statment-info">
        <div className="statment-info__text">{statement.name}</div>
        <ul className={`curriculm-meta curriculm-meta--${area.identifier}`}>
          <li>{aspect.name}</li>
          <li>
            {agegroup.min === 61 ? "Early Learning Goal" : ""}
            {agegroup.min === 62 ? "Exceeding" : ""}
            {agegroup.min !== 61 && agegroup.min !== 62
              ? agegroup.min + " - " + agegroup.max + " months"
              : ""}
          </li>
        </ul>
      </div>
      <div className={`tickBox ${statementSelected ? "tick" : ""}`} />
    </div>
    {statementSelected ? (
      <div className="bubble-text bubble-text--noavatar bubble-text--secure">
        <p>Are they secure in this statement?</p>
        <ul className="secure-list">
          {selectedChildren.map(child => {
            let secureClass = "";
            if (
              selectedSecure.find(
                secure =>
                  secure.child === child && secure.statement === statement.uuid
              ) !== undefined
            ) {
              secureClass = "secure-list--item secure-list--item--selected";
            } else {
              secureClass = "secure-list--item";
            }

            return (
              <li
                className={secureClass}
                key={child}
                onClick={toggleSecure.bind(this, {
                  child,
                  statement: statement.uuid
                })}
              >
                <Avatar
                  size="tiny"
                  userName={
                    children[child].first_name + " " + children[child].last_name
                  }
                  image={children[child].picture}
                  type="child"
                />
                {children[child].first_name}
              </li>
            );
          })}
        </ul>
      </div>
    ) : (
      ""
    )}
  </li>
);

Statement.propTypes = {
  area: PropTypes.object.isRequired,
  aspect: PropTypes.object.isRequired,
  agegroup: PropTypes.object.isRequired,
  statement: PropTypes.object.isRequired,
  statementSelected: PropTypes.bool.isRequired,
  toggleSecure: PropTypes.func.isRequired,
  toggleSelected: PropTypes.func.isRequired
};

export default Statement;
