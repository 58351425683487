import React from "react";

const ChildProfileMeals = ({meals}) => (

  <div className='grid__cell'>
      <div className='dataItem'>
          <div className='dataItem__label'>
              Free school meals?
          </div>
          <div className='dataItem__data'>
              {
                  meals === 1 ? "Yes" : "No"
              }
          </div>
      </div>
  </div>

);

export default ChildProfileMeals;
