import React from "react";
import Avatar from "./Avatar";
import ContextMenu from "./ContextMenu";

const ChildCarers = ({
  carers,
  childId,
  invites,
  handleFlags,
  handleRemoveParent,
  handleToggleCarerContext,
  carerContextOpen,
  handleResendInvite,
  handleDeleteInvite
}) => (
  <div className="grid grid--gutter50">
    <div className="grid__cell">
      <div className="dataItem">
        <div className="dataItem__label">Carers</div>
        <ul className="data-list data-list--carers">
          {carers.map(guardian => (
            <li className="data-list__item data-list--carers__item" key={guardian.email}>
              <div className="data-list__item__col data-list--carers__name">
                <a href={`/user/${guardian.user_uuid}`} className="carer-mainInfo">
                  <Avatar
                    size="small"
                    userName={guardian.first_name + " " + guardian.last_name}
                    image={guardian.picture}
                    type="carer"
                  />
                  <div className="carer-mainInfo__nameEmail">
                    <div className="carer-mainInfo__name">
                      {guardian.first_name}
                      &nbsp;
                      {guardian.last_name}
                    </div>
                    <div className="carer-mainInfo__email">{guardian.email}</div>
                  </div>
                </a>
              </div>

              <div className="data-list--carers__secondary">
                <div className="data-list__item__col data-list--carers__tags">
                  <ul className="carer-tags">
                    <li
                      className={
                        guardian.children.find(x => x.uuid === childId).parent === 1 ? "tag tag--green" : "tag"
                      }
                      onClick={handleFlags}
                      data-flag="parentOf"
                      data-parentid={guardian.uuid}>
                      Parent
                    </li>
                    <li
                      className={
                        guardian.children.find(x => x.uuid === childId).primary === 1 ? "tag tag--blue" : "tag"
                      }
                      onClick={handleFlags}
                      data-flag="primary"
                      data-parentid={guardian.uuid}>
                      Primary
                    </li>
                    <li
                      className={guardian.children.find(x => x.uuid === childId).dropoff ? "tag tag--purple" : "tag"}
                      onClick={handleFlags}
                      data-flag="dropoff"
                      data-parentid={guardian.uuid}>
                      Pick up / Drop off
                    </li>
                  </ul>
                </div>

                <div className="data-list__item__col data-list--carers__phone">
                  <div className="carer-info__phone">
                    Phone:&nbsp;
                    <span className="bold">{guardian.contact_number}</span>
                  </div>
                </div>
              </div>

              <div className="data-list__item__col data-list--carers__menu">
                <ContextMenu
                  items={[
                    {
                      text: "Edit carer details",
                      action: `/user/${guardian.user_uuid}/edit`,
                      red: "false",
                      id: 1
                    },
                    {
                      text: "Remove carer from child",
                      action: handleRemoveParent,
                      id: 2,
                      red: "true"
                    }
                  ]}
                  handleClick={handleToggleCarerContext}
                  contextId={guardian.uuid}
                  position="centerRight"
                  open={carerContextOpen === guardian.uuid ? true : false}
                />
              </div>
            </li>
          ))}
          {invites !== null
            ? invites.map(invite => (
              <li className="data-list__item data-list--carers__item " key={invite.uuid}>
                <div className="data-list__item__col carer-mainInfo data-list--carers__name">
                  <Avatar size="small" userName={invite.first_name + " " + invite.last_name} image="" type="carer" />
                  <div className="carer-mainInfo__nameEmail">
                    <div className="carer-mainInfo__name">
                      {invite.first_name}
                        &nbsp;
                      {invite.last_name}
                    </div>
                    <div className="carer-mainInfo__email">{invite.email}</div>
                  </div>
                </div>

                <div className="data-list--carers__secondary">
                  <div className="data-list__item__col">Invite Sent</div>
                </div>

                <div className="data-list__item__col data-list--carers__menu">
                  <ContextMenu
                    items={[
                      {
                        text: "Resend Invite",
                        action: handleResendInvite.bind(this, invite.uuid),
                        red: "false",
                        id: 1
                      },
                      {
                        text: "Delete Invite",
                        action: handleDeleteInvite.bind(this, invite.uuid),
                        id: 2,
                        red: "true"
                      }
                    ]}
                    handleClick={handleToggleCarerContext}
                    contextId={invite.uuid}
                    position="centerRight"
                    open={carerContextOpen === invite.uuid ? true : false}
                  />
                </div>
              </li>
            ))
            : ""}
        </ul>
      </div>
    </div>
  </div>
);

export default ChildCarers;
