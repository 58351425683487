import moment from "moment";
import React,{ Component } from 'react';
import DatePicker from "react-datepicker";

class RenderTimePicker extends Component {
  static propTypes = {
    placeholder: ""
  }

  handleChange = (date) => {
    this
        .props
        .input
        .onChange(moment(date).format("DD-MM-YYYY HH:mm"));
  }

  handleTouchStart = () => {
    this
        .props
        .onClick();
  }

  render() {
    const {
      input,
      placeholder,
      meta: {
        touched,
        error
      }
    } = this.props;

    return (
      <DatePicker 
        {...input}
        className={touched && error
          ? "input-error"
          : ""}
        placeholder={placeholder}
        dateFormat="DD-MM-YYYY"
        openToDate={moment(new Date())}
        showYearDropdown
        scrollableYearDropdown
        yearDropdownItemNumber={4}
        popperPlacement="bottom-start"
        selected={input.value
          ? moment(input.value, "DD-MM-YYYY HH:mm")
          : null}
        onChange={this.handleChange}
        readOnly={true}
        onTouchStart={this.onTouchStart}
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={30}
        shouldCloseOnSelect={false}
        maxDate={moment(new Date())}
      />
    )
  }
}

export default RenderTimePicker;