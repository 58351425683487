import React from "react";
import { Link } from "react-router";
import logo from "../images/assets/edna-logo-dark.svg";

const ResetPasswordThanks = () => (
  <div className="signUp signUp--wForm">
    <header className="signUp__header">
      <img alt="edna logo" className="signUp__header__logo" src={logo} />
    </header>

    <div className="signUp__main">
      <div className="signUp__text-area signUp__text-area--intro">
        <h1>Happy new password day!</h1>
        <p>
          Congratulations! You’ve successfully re-set the password for your Edna account. All you need to do now is head over to the login screen and log in using your shiny new password.
        </p>
      </div>

      <Link to="/login" className="btn btn--green btn--lg">
        Log in to your account
      </Link>

      <div className="helperText">
        <p>
          Still having problems? No worries, just drop our team a line on <a href="mailto:support@ednaapp.com">support@ednaapp.com </a> and we’ll get you sorted out.
        </p>
      </div>
    </div>
  </div>
);

export default ResetPasswordThanks;
