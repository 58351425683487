import PropTypes from "prop-types";
import React from "react";
import styled, { keyframes } from "styled-components";
import Icons from "../images/icons/qc-icons.svg";
import Theme from "../theme";


const Bounce = keyframes`
  50%{
    transform: scale(1.2);
  }
  75%{
    transform: scale(1.1);
  }
  100%{
    transform: scale(1.2);
  }
`




const Container = styled.div`
  flex: 1 100%;
  @media screen and (min-width: ${Theme.bpMedium}) {
    margin: 0 55px;
  }
`;

const Divider = styled.div`
  text-align: center;
  color: ${Theme.colorGreyMidLt};
  position: relative;
  margin: 10px 0;
  > div {
    background: ${Theme.colorSysWhite};
    padding: 0 10px;
    margin: 0 auto;
    display: inline-block;
    position: relative;
  }
  &:before {
    content: "";
    width: 100%;
    height: 1px;
    background: ${Theme.colorGreyMidLt};
    position: absolute;
    top: 10px;
    left: 0;
    /* z-index: -1; */
  }
`;
const Buttons = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
  margin: 0 -5px;
  flex-wrap: wrap;
  @media screen and (min-width: ${Theme.bpMedium}) {
    flex-wrap: no-wrap;
  }
`;
const QcButton = styled.button`
  text-align: left;
  border: none;
  background-color: ${Theme.colorGreyLight};
  border-radius: 20px;
  line-height: 30px;
  padding: 0 35px 0 12px;
  position: relative;
  cursor: pointer;
  margin: 0 5px 10px 5px;
  flex: 1 1 46%;
  @media screen and (min-width: ${Theme.bpMedium}) {
    margin: 0 5px 0 0;
    flex: 0 auto;
  }
  &:after {
    background-image: url(${Icons});
    background-repeat: no-repeat;
    content: "";
    display: block;
    position: absolute;
    right: 10px;
    transform: scale(1);
  }

  &:hover{
    &:after{
      animation: ${Bounce} .5s ease-in-out 1;
      animation-fill-mode: forwards;
    }
  }
`;

const QcWellDoneButton = styled(QcButton)`
  &:after {
    background-position: top left;
    width: 17px;
    height: 15px;
    top: 7px;
  }
`;
const QcLoveButton = styled(QcButton)`
  &:after {
    background-position: top right;
    width: 17px;
    height: 15px;
    top: 9px;
  }
`;
const QcSuperButton = styled(QcButton)`
  &:after {
    background-position: bottom right;
    width: 18px;
    height: 17px;
    top: 6px;
  }
`;
const QcWowButton = styled(QcButton)`
  &:after {
    background-position: bottom left;
    width: 19px;
    height: 19px;
    top: 6px;
  }
`;

const QuickComments = ({ handleCommentPosting, quickCommentSubmitting, observationId }) => {
  return (
    <Container>
      <Divider>
        <div>Or use quick comments</div>
      </Divider>
      <Buttons>
        <QcWellDoneButton
          disabled={quickCommentSubmitting}
          onClick={() => {
            handleCommentPosting({ comment: "Well Done!" }, observationId);
          }}>
          Well Done!
        </QcWellDoneButton>
        <QcLoveButton
          disabled={quickCommentSubmitting}
          onClick={() => {
            handleCommentPosting({ comment: "Love It!" }, observationId);
          }}>
          Love it!
        </QcLoveButton>
        <QcSuperButton
          disabled={quickCommentSubmitting}
          onClick={() => {
            handleCommentPosting({ comment: "Super!" }, observationId);
          }}>
          Super!
        </QcSuperButton>
        <QcWowButton
          disabled={quickCommentSubmitting}
          onClick={() => {
            handleCommentPosting({ comment: "WOW!" }, observationId);
          }}>
          WOW!
        </QcWowButton>
      </Buttons>
    </Container>
  );
};

QuickComments.propTypes = {
  handleCommentPosting: PropTypes.func.isRequired,
  quickCommentSubmitting: PropTypes.bool.isRequired,
  observationId: PropTypes.string.isRequired
};

export default QuickComments;
