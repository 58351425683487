import React from "react";
import { bindActionCreators } from "redux";
import * as evidenceActions from "../actions/singleObservationActions";
import { connect } from "react-redux";

class ObservationEditImageButton extends React.Component {
  handleFiles = event => {
    const files = event.target.files;
    for (var i = 0; i < files.length; i++) {
      const fileName = files[i].name;
      const reader = new FileReader();
      reader.readAsDataURL(files[i]);
      reader.onload = () => {
        const image64 = reader.result;
        this.props.uploadImage(image64, fileName).then(response => {
          this.props.handlePopulateEvidence(response.payload.data);
        });
      };
    }
  };

  handleClick = () => {
    document.getElementById("fileUpload").click();
  };

  render() {
    return (
      <div
        className="new-observation__draw__item new-observation__draw__item--photo"
        onClick={this.handleClick}
      >
        <div>Photos</div>
        <input
          type="file"
          id="fileUpload"
          multiple
          style={{ display: "none" }}
          onChange={this.handleFiles}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    cloudinary: state.cloudinary
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      ...evidenceActions
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(
  ObservationEditImageButton
);
