import { EventTypes } from "redux-segment";
import { DELETE_USER,GET_SINGLE_USER,IDENTIFY_USER,RESET_SINGLE_USER,UPDATE_USER,USER_LOGIN,USER_LOGOUT,USER_REFRESH } from "./actionTypes";

export function userLogin(values, userId) {
  return {
    type: USER_LOGIN,
    payload: {
      request: {
        method: "POST",
        url: "/login",
        data: values
      }
    }
  };
}

export function userLoggedIn(user){
  return {
    type: IDENTIFY_USER,
    meta: {
      analytics: {
        eventType: EventTypes.identify,
        eventPayload: {
          userId: user.uuid,
          traits: {
            email: user.email,
            name: user.person.first_name + " " + user.person.last_name,
            school: user.school.name
          }
        }
      }
    }
  };
}

export function userRefresh() {
  return {
    type: USER_REFRESH,
    payload: {
      request: {
        method: "GET",
        url: "/refresh"
      }
    }
  };
}

export function userLogout() {
  return {type: USER_LOGOUT};
}

export function getUser(uuid) {
  return {
    type: GET_SINGLE_USER,
    payload: {
      request: {
        method: "GET",
        url: `users/${uuid}`
      }
    }
  };
}

export function resetSingleUser() {
  return {type: RESET_SINGLE_USER};
}

export function updateUser(values) {
  return {
    type: UPDATE_USER,
    payload: {
      request: {
        method: "POST",
        url: `users/${values.uuid}`,
        data: values
      }
    }
  };
}

export function deleteUser(uuid) {
  return {
    type: DELETE_USER,
    payload: {
      request: {
        method: "DELETE",
        url: `users/${uuid}`
      }
    }
  };
}
