import PropTypes from "prop-types";
import React from "react";

const ChildProfileFreeChildcare = ({ freeChildcare }) => (
  <div className="grid__cell">
    <div className="dataItem">
      <div className="dataItem__label">Free childcare for 2-year-olds?</div>
      <div className="dataItem__data">{freeChildcare === 1 ? "Yes" : "No"}</div>
    </div>
  </div>
);

ChildProfileFreeChildcare.propTypes = {
  freeChildcare: PropTypes.number.isRequired
};

export default ChildProfileFreeChildcare;
