import { EventTypes } from "redux-segment";
import {
  ADD_CHILD,
  CREATE_OBSERVATION,
  FILTER_CATEGORIES,
  FILTER_CHILDREN,
  POPULATE_COEL,
  POPULATE_COMMENTS,
  POPULATE_SECURE,
  POPULATE_STATEMENTS,
  REMOVE_CHILD,
  RESET_ADD_OBSERVATION_STATE
} from "./actionTypes";

export function createObservation(values, userId) {
  return {
    type: CREATE_OBSERVATION,
    payload: {
      request: {
        method: "POST",
        url: "observations",
        data: values
      }
    },
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: "Observation Created",
          child: values.child,
          options: {
            userId: userId
          }
        }
      }
    }
  };
}

export function resetAddObservationState() {
  return {
    type: RESET_ADD_OBSERVATION_STATE
  };
}

export function addChild(child) {
  return {
    type: ADD_CHILD,
    payload: {
      child
    }
  };
}

export function removeChild(child) {
  return {
    type: REMOVE_CHILD,
    payload: {
      child
    }
  };
}

export function populateStatements(statements) {
  return {
    type: POPULATE_STATEMENTS,
    payload: {
      statements
    }
  };
}

export function setSecure(event) {
  return {
    type: POPULATE_SECURE,
    payload: {
      event
    }
  };
}

export function populateComments(comments) {
  return {
    type: POPULATE_COMMENTS,
    payload: {
      comments
    }
  };
}

export function populateCOEL(characteristics) {
  return {
    type: POPULATE_COEL,
    payload: {
      characteristics
    }
  };
}

export function filterObservationChildren(childMap, children, state) {
  const filteredChildren = children.filter(child => {
    if (state.nameTerm === "") {
      return childMap[child];
    }

    if (state.nameTerm !== "") {
      const childName = `${childMap[child].first_name} ${childMap[child].last_name}`;
      if (childName.toLowerCase().match(state.nameTerm.toLowerCase())) {
        return childMap[child];
      }
    }
    return false;
  });

  return {
    type: FILTER_CHILDREN,
    payload: {
      data: filteredChildren.filter(child => child !== undefined)
    }
  };
}

export function filterCategories(categories, state) {
  const filteredCategories = Object.keys(categories).filter(category => {
    let nameReturn,
      ageReturn,
      areaReturn = null;
    if (
      state.catNameTerm === "" &&
      state.ageTerm.length <= 0 &&
      state.areaTerm.length <= 0
    ) {
      return categories[category];
    }

    if (state.catNameTerm !== "") {
      nameReturn = false;
      if (
        categories[category].name
          .toLowerCase()
          .match(state.catNameTerm.toLowerCase())
      ) {
        nameReturn = true;
      }
    }

    if (state.ageTerm.length > 0) {
      ageReturn = false;
      state.ageTerm.forEach(term => {
        if (categories[category].agegroup.match(term)) {
          ageReturn = true;
        }
      });
    }

    if (state.areaTerm.length > 0) {
      areaReturn = false;
      state.areaTerm.forEach(term => {
        if (categories[category].area.match(term)) {
          areaReturn = true;
        }
      });
    }

    if (nameReturn === false || ageReturn === false || areaReturn === false) {
      return false;
    }
    return categories[category];
  });

  return {
    type: FILTER_CATEGORIES,
    payload: {
      data: filteredCategories
    }
  };
}
