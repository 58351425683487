import PropTypes from "prop-types";
import React from "react";

const ChildProfileEYPP = ({ eypp }) => (
  <div className="grid__cell">
    <div className="dataItem">
      <div className="dataItem__label">Early years pupil premium?</div>
      <div className="dataItem__data">{eypp === 1 ? "Yes" : "No"}</div>
    </div>
  </div>
);

ChildProfileEYPP.propTypes = {
  eypp: PropTypes.number.isRequired
};

export default ChildProfileEYPP;
