import { hex } from "js-sha1";
import {
  REMOVE_IMAGE,
  RESET_AVATAR,
  UPLOAD_AVATAR,
  UPLOAD_IMAGE
} from "./actionTypes";

export function resetAvatarState() {
  return { type: RESET_AVATAR };
}

export function uploadAvatar(file, fileName) {
  const timestamp = new Date().getTime();
  const fileStamp = hex(fileName + timestamp);
  return {
    type: UPLOAD_AVATAR,
    payload: {
      client: "cloudinary",
      request: {
        method: "POST",
        url: "/avatar",
        data: {
          file,
          tags: fileStamp
        }
      }
    }
  };
}

export function uploadImage(file, fileName) {
  const timestamp = new Date().getTime();
  const fileStamp = hex(fileName + timestamp);
  return {
    type: UPLOAD_IMAGE,
    payload: {
      client: "cloudinary",
      request: {
        method: "POST",
        url: "/upload",
        data: {
          file,
          tags: fileStamp
        }
      }
    }
  };
}

export function removeImage(image) {
  return {
    type: REMOVE_IMAGE,
    payload: {
      client: "cloudinary",
      request: {
        method: "POST",
        url: "/destroy",
        data: {
          cloudinary_id: image.cloudinary_id
        }
      }
    }
  };
}
