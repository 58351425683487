import * as Sentry from "@sentry/browser";
import createSentryMiddleware from "redux-sentry-middleware";
import { browserHistory } from "react-router";
import { routerMiddleware, syncHistoryWithStore } from "react-router-redux";
import { applyMiddleware, compose, createStore } from "redux";
import { multiClientMiddleware } from "redux-axios-middleware";
import logger from "redux-logger";
import { createTracker } from "redux-segment";
import persistState from "redux-sessionstorage";
import { apiClient, apiOptions } from "./clients/api";
import cloudinaryClient from "./clients/cloudinary";
import { stripeClient, stripeOptions } from "./clients/stripe";
import rootReducer from "./reducers/index";

const tracker = createTracker();

Sentry.init({
  dsn: "https://9ce46c112d1149e0a508541c8fd73c7b@sentry.io/234626"
});

const clients = {
  default: {
    client: apiClient,
    options: apiOptions
  },
  cloudinary: {
    client: cloudinaryClient
  },
  stripe: {
    client: stripeClient,
    options: stripeOptions
  }
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let middleware = null;
if (process.env.NODE_ENV === "production") {
  middleware = applyMiddleware(
    routerMiddleware(browserHistory),
    createSentryMiddleware(Sentry, {}),
    multiClientMiddleware(clients),
    tracker
  );
} else {
  middleware = applyMiddleware(
    logger(),
    routerMiddleware(browserHistory),
    multiClientMiddleware(clients)
  );
}

const store = createStore(
  rootReducer,
  composeEnhancers(middleware, persistState("user"))
);

if (window.Cypress) {
  window.store = store;
}

export const history = syncHistoryWithStore(browserHistory, store);

export default store;
