import React from 'react';
import moment from "moment";

const CurrentPlan = ({trial, subscription}) => {
  if (trial.isSameOrAfter() && subscription === null) {
    return (
      <section className="pref-section">
        <h3>Your current plan</h3>
        <p>You are currently on the &nbsp;
          <span className="bold">30-day free trial</span>. We hope you're enjoying it! This is due to end &nbsp;
          <span className="bold">{trial
              .endOf('day')
              .fromNow()}</span>
          &nbsp; on the &nbsp;
          <span className="bold">{trial.format('DD-MM-YY')}</span>.</p>
        <p>After that point you will no longer be able to add new observations, children
          or users to Edna.</p>
        <p>If you wish to continue using Edna after your free trial has ended, you will
          need to sign up for one of our price plans detailed below and add a payment
          method.</p>
      </section>
    );
  } else if (trial.isSameOrAfter() && subscription !== null && subscription.cancel_at_period_end === false) {
    let tax = ((subscription.plan.amount / 100) * subscription.tax_percent);
    let price = ((subscription.plan.amount + tax) * subscription.quantity) / 100;
    return (
      <section className="pref-section">
        <h3>Your current plan</h3>
        <p>You are currently still on your &nbsp;
          <span className="bold">30-day free trial</span>. This is due to end &nbsp;
          <span className="bold">{trial
              .endOf('day')
              .fromNow()}</span>
          &nbsp; on the &nbsp;
          <span className="bold">{trial.format('DD-MM-YY')}</span>.
        </p>
        <p>You've chosen to go on to the &nbsp;<span className="bold">{subscription.plan.nickname}</span>
          &nbsp;plan once your trial has ended. You will be billed&nbsp;
          <span className="bold">£{price.toFixed(2)}&nbsp;(inc. VAT)</span>&nbsp; on&nbsp;
          <span className="bold">{moment
              .unix(subscription.current_period_end)
              .format('DD-MM-YY')}</span>. Thank you for choosing Edna!
        </p>

      </section>
    );
  } else if (subscription.cancel_at_period_end === true) {
    return (
      <section className="pref-section">
        <h3>Your current plan</h3>
        <p>You are currently on the &nbsp;<span className="bold">{subscription.plan.nickname}</span>&nbsp;plan. You have cancelled your plan, which will end on &nbsp;<span className="bold">{moment
              .unix(subscription.current_period_end)
              .format('DD-MM-YY')}</span>.
After that point you will no longer be able to add new observations, children or
users to Edna.
        </p>
      </section>
    );
  } else {
    let tax = ((subscription.plan.amount / 100) * subscription.tax_percent);
    let price = ((subscription.plan.amount + tax) * subscription.quantity) / 100;
    return (
      <section className="pref-section">
        <h3>Your current plan</h3>
        <p>You are currently on the &nbsp;<span className="bold">{subscription.plan.nickname}</span>&nbsp;plan. Your plan will renew on &nbsp;
          <span className="bold">{moment
              .unix(subscription.current_period_end)
              .format('DD-MM-YY')}</span>&nbsp; for &nbsp;<span className="bold">£{price.toFixed(2)}&nbsp;(inc. VAT)</span>.
        </p>
      </section>
    );
  }
};

export default CurrentPlan;
