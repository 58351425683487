import moment from "moment";
import React from "react";
import PlanPostChange from "./PlanPostChange";
import PlanPreChange from "./PlanPreChange";

const PlanModal = ({
  email,
  newPlan,
  subscription,
  customer,
  childCount,
  toggleModal,
  handleUpdatePlan,
  planChanged,
  loading
}) => {
  let renewal = moment.unix(subscription.current_period_end);
  let card = customer.sources.data[0];
  return (
    <div>
      <div className="modal modal-open modal--confirmation">
        <div className="modal__content">
          <div className="modal__header">
            <h2>Conformation</h2>
            <a href={true} className="btn-cancel" onClick={toggleModal}>
              Cancel
            </a>
          </div>

          {!planChanged ? (
            <PlanPreChange
              nickname={newPlan.nickname}
              planID={newPlan.id}
              amount={newPlan.amount}
              interval={newPlan.interval}
              childCount={childCount}
              cardBrand={card.brand}
              cardLast4={card.last4}
              renewal={renewal}
            />
          ) : (
            <PlanPostChange plan={subscription} email={email} />
          )}

          <div className="modal__footer">
            <div className="modal__footer__controls">
              {planChanged ? (
                <a href={true} className="btn btn--green btn--sm btn--mob-fullw" onClick={toggleModal}>
                  Back to the app!
                </a>
              ) : (
                <a
                  href={true}
                  className={`btn btn--green btn--sm btn--mob-fullw ${loading ? "btn--spinner btn--disabled" : ""}`}
                  onClick={handleUpdatePlan}>
                  Looks good! Sign me up
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="modal-overlay modal-open" />
    </div>
  );
};

export default PlanModal;
