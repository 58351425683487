import PropTypes from "prop-types";
import React from "react";
import ChildListItem from "./ChildListItem";
import ChildrenListEmpty from "./ChildrenListEmpty";

const ChildrenProfileList = ({ childList, handleSort, worker }) => (
  <div>
    <ul className="data-list data-list--children">
      <li className="data-list__item data-list__header">
        <div className="data-list--children__child">
          <a href={true} onClick={handleSort} name="name">
            Name
          </a>
        </div>
        <div className="data-list--children__age">
          <a href={true} onClick={handleSort} name="age">
            Age
          </a>
        </div>
        <div className="data-list--children__person">
          <a href={true} onClick={handleSort} name="worker">
            Key Person
          </a>
        </div>
        <div className="data-list--children__time-table">Timetable</div>
      </li>
      {childList.length > 0 ? (
        childList.map(child => <ChildListItem child={child} key={child.uuid} />)
      ) : (
        <ChildrenListEmpty worker={worker} />
      )}
    </ul>
  </div>
);

ChildrenProfileList.propTypes = {
  childList: PropTypes.arrayOf(PropTypes.shape({})),
  handleSort: PropTypes.func.isRequired,
  worker: PropTypes.shape({})
};

export default ChildrenProfileList;
