import React from 'react';
import PropTypes from 'prop-types';
import Plans from "./Plans";
import PaymentMethodsNoSource from './PaymentMethodsNoSource';

const AccountLockedAdmin = ({sendTokenToServer, handleChangePlan, plans, user, billingLoading}) => {
  return (
    <div>
      <div className="header-local">
        <h1 className="header-local__title header-local__title--frozen">Account Frozen</h1>
      </div>
      <div className="pref-section">
        <h3>This Edna account is frozen.</h3>
        <p>This means you cannot add, edit or view children, observations or users.</p>
        <p>This account is frozen either because the free trial has ended and you
          haven't signed up for a plan yet, the subscription has been cancelled by a
          super-admin or the last payment has failed.</p>

        <h3>Want to unfreeze your account?</h3>
        <p>Don't worry, it's super easy to unfreeze your account. Choose a pricing plan
          below and add a payment method. All your account info and data will be right
          where you left it.</p>
      </div>

      <div className="form-section">
        <h3>Choose your pricing plan</h3>
        <p>Choose a pricing plan that best fits your needs. Only look after a small
          number of children and want flexability? Our standard plan is made for you! Look
          after lots of children or want a fixed monthly or yearly price? Our unlimited
          plan is ideal for your needs!</p>

        <Plans handleChangePlan={handleChangePlan} plans={plans} user={user}/>
        <PaymentMethodsNoSource
          sendTokenToServer={sendTokenToServer}
          billingLoading={billingLoading}/>
      </div>
    </div>
  );
};

AccountLockedAdmin.propTypes = {
  sendTokenToServer: PropTypes.func.isRequired,
  handleChangePlan: PropTypes.func.isRequired
};

export default AccountLockedAdmin;
