import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as CloudinaryActions from "../actions/cloudinaryActions";
import ImageElement from "./ImageElement";
import LoaderSmall from "./LoaderSmall";

export class AvatarInput extends React.Component {
  handleFiles = event => {
    const files = event.target.files;
    for (let i = 0; i < files.length; i++) {
      const fileName = files[i].name;
      const reader = new FileReader();
      reader.readAsDataURL(files[i]);
      reader.onload = () => {
        const image64 = reader.result;
        this.props.uploadAvatar(image64, fileName);
      };
    }
  };

  handleClick = () => {
    document.getElementById("fileUpload").click();
  };

  render() {
    const {
      buttonText,
      maxSize,
      acceptedFormats,
      avatar,
      currentAvatar
    } = this.props;
    return (
      <div className="avatar-upload">
        <div
          className="avatar avatar--large avatar--default"
          style={{
            background: `url(${currentAvatar}) no-repeat center center`,
            backgroundSize: "contain"
          }}
        >
          {avatar.isLoading ? <LoaderSmall /> : ""}
          {avatar.data !== null ? (
            <ImageElement
              thumb_url={avatar.data.secure_url}
              isLoading={avatar.isLoading}
              key={avatar.data.id}
            />
          ) : (
            ""
          )}
        </div>

        <div className="avatar-upload__controls">
          <input
            type="file"
            id="fileUpload"
            multiple
            style={{ display: "none" }}
            onChange={this.handleFiles}
          />

          <a
            href={true}
            className="btn btn--green btn--sm btn--nobrdr"
            onClick={this.handleClick}
          >
            {buttonText}
          </a>
          <p>
            Maximum file size {maxSize}. Accepted file formats are:{" "}
            {acceptedFormats}
          </p>
        </div>
      </div>
    );
  }
}

AvatarInput.propTypes = {
  acceptedFormats: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  maxSize: PropTypes.number.isRequired,
  avatar: PropTypes.object
};

const mapStateToProps = state => ({
  cloudinary: state.cloudinary
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...CloudinaryActions
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AvatarInput);
