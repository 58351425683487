import React from "react";
import styled, { css, keyframes } from "styled-components";
import btnSpinner from "../images/icons/btn-spinner.png";
import Theme from "../theme";

const spin = keyframes`
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
`;

const spinner = css`
  &:after {
    content: "";
    display: inline-block;
    width: 22px;
    height: 22px;
    background: url("${btnSpinner}") no-repeat center center;
    position: relative;
    top: 5px;
    margin-left: 15px;
    animation: ${spin} 1s infinite linear;
    transform: translateZ(0);
  }
`;

const mobFixed = css`
  border: 0;
  border-radius: 0;
  line-height: 50px;
  bottom: 0;
  left: 0;
  padding: 0;
  margin: 0 -20px;
`;

const mobSq = css`
  line-height: 50px;
  border-width: 0;
  border-radius: 0;
`;

const mobFullw = css`
  border: 0;
  border-radius: 0;
  line-height: 50px;
  bottom: 0;
  left: 0;
  padding: 0;
  margin: 0;
  width: 100%;
`;

const Button = styled.a`
  ${props => props.spinner && spinner}
  font-family: ${Theme.fontPrimary};
  font-size: ${props => (props.small ? "12px" : "16px")};
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  color: ${props => (props.empty ? Theme.colorGreyMid : props.disabled ? Theme.colorGreyLightLt : Theme.colorSysWhite)};
  margin-right: 20px;
  transition: 0.3s;
  cursor: ${props => (props.disabled ? "default" : "pointer")};
  font-weight: 700;
  line-height: ${props => (props.small ? "38px" : "44px")};
  padding: 0 20px;
  border-radius: ${props => (props.small ? "3px" : "5px")};
  border-style: solid;
  border-width: ${props => (props.small ? "2px" : "3px")};

  
  background: ${props =>
    (props.green && Theme.colorBrandGreenLt) ||
    (props.blue && Theme.colorBrandBlueLt) ||
    (props.red && Theme.colorBrandRedLt) ||
    (props.empty && Theme.colorSysWhite) ||
    (props.disabled && Theme.colorGreyMidLt)};

  border-color: ${props =>
    (props.green && Theme.colorBrandGreen) ||
    (props.blue && Theme.colorBrandBlue) ||
    (props.red && Theme.colorBrandRed) ||
    (props.empty && Theme.colorGreyMid) ||
    (props.disabled && Theme.colorGreyMidLt)};
  
  box-shadow: 0px 0px 0px 0px ${props =>
    (props.green && "rgba(55,188,155,0.5)") ||
    (props.blue && "rgba(74,137,220,0.5)") ||
    (props.red && "rgba(218,68,83,0.5)") ||
    (props.empty && "rgba(170,178,189,0.5)")};

  &:hover {
    color: #ffffff;
    @media screen and (min-width: ${Theme.bpMedium}){
      box-shadow: 0px 0px 0px 5px ${props =>
    (props.green && "rgba(55,188,155,0.2)") ||
        (props.blue && "rgba(74,137,220,0.2)") ||
        (props.red && "rgba(218,68,83,0.2)") ||
        (props.empty && "rgba(170,178,189,0.2)")};
      background: ${props => (props.disabled ? Theme.colorGreyMidLt : Theme.colorSysWhite)};
      color: ${props =>
    (props.green && Theme.colorBrandGreen) ||
        (props.blue && Theme.colorBrandBlue) ||
        (props.red && Theme.colorBrandRed) ||
        (props.empty && Theme.colorGreyDarkLt)};
      border-color: ${props =>
    (props.green && Theme.colorBrandGreen) ||
        (props.blue && Theme.colorBrandBlue) ||
        (props.red && Theme.colorBrandRed) ||
        (props.empty && Theme.colorGreyDark) ||
        (props.disabled && Theme.colorGreyMidLt)};
    }
  }

  
  @media screen and (min-width: ${Theme.bpMedium}){
    font-size: ${props => (props.small ? "16px" : "16px")};
  }

  @media screen and (max-width: ${Theme.bpMedium}){
    ${props => props.mobSq && mobSq}
    ${props => props.mobFullw && mobFullw}
    ${props => props.mobFixed && mobFixed}
  }


`;

const BtnLink = ({
  text,
  onClick,
  green,
  blue,
  red,
  empty,
  spinner,
  disabled,
  small,
  mobSq,
  mobFullw,
  mobFixed,
  href
}) => {
  return (
    <Button
      green={green}
      blue={blue}
      red={red}
      empty={empty}
      onClick={onClick}
      spinner={spinner}
      disabled={disabled}
      small={small}
      mobSq={mobSq}
      mobFullw={mobFullw}
      mobFixed={mobFixed}
      href={href}>
      {text}
    </Button>
  );
};

export default BtnLink;
