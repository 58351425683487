import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as FlashActions from "../actions/flashActions";
import FlashMessage from "../components/FlashMessage";

class FlashMessageContainer extends React.Component {
    constructor(props) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose(event) {
        this.props.removeMessageFromFlash(event.target.id);
    }

    render() {
        return (
            <div className='alerts'>
                {
                    this.props.flashMessages.messages.length > 0 ?
                        this.props.flashMessages.messages.map((message) => (
                            <FlashMessage message={message} handleClose={this.handleClose} key={message.id}/>
                        ))
                        :
                        ""
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        flashMessages: state.flashMessages
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        ...FlashActions
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(FlashMessageContainer);
