import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { Elements, StripeProvider } from "react-stripe-elements";
import HasPermission from "../containers/HasPermission";
import CardForm from "./CardForm";

const PaymentMethods = ({
  subscription,
  trial,
  sendTokenToServer,
  customer,
  paymentContextOpen,
  handlePaymentContextToggle,
  handleDeleteCard,
  handleMakeCardDefault,
  addBillingCard
}) => {
  let contextClass = "";
  let defaultCard = null;
  if (customer !== null && customer.sources.data.length > 0) {
    defaultCard = customer.sources.data.filter(x => x.id === customer.default_source).pop();
  }
  return (
    <section className="form-section">
      {defaultCard !== null ? (
        <div>
          <h3>Payment method</h3>
          <p>
            Your future payments will be taken from your&nbsp;
            <span className="bold">{defaultCard.brand}&nbsp; card</span>
            &nbsp;ending in&nbsp;
            <span className="bold">{defaultCard.last4}</span>
          </p>
        </div>
      ) : null}
      {customer !== null && customer.sources.data.length > 0 ? (
        <ul className="data-list data-list--paymentCards">
          {customer.sources.data.map(card => {
            if (paymentContextOpen === card.id) {
              contextClass = "context-menu__menu context-menu__menu--open";
            } else {
              contextClass = "context-menu__menu";
            }
            return (
              <li className="data-list__item data-list--paymentCards__item" key={card.id}>
                <div className="data-list__item__col data-list--creditCard">
                  <div className={`creditCard creditCard--${card.brand.toLowerCase()}`}>
                    <div className="creditCard__name">{card.name}</div>
                    <div className="creditCard__meta">
                      {card.brand}&nbsp; ending &nbsp;{card.last4}&nbsp; expires end&nbsp; {card.exp_month}/
                      {card.exp_year}{" "}
                      {card.id === customer.default_source ? (
                        <span className="creditCard__default">Default card</span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <HasPermission allowedRoles={["super_admin", "admin"]}>
                  <div className="data-list__item__col">
                    {card.id !== customer.default_source ? (
                      <div className="context-menu">
                        <div
                          className="context-menu__btn"
                          onClick={handlePaymentContextToggle}
                          key={card.id}
                          id={card.id}>
                          <ul className={contextClass}>
                            <li className="context-menu__item">
                              <a href={true} onClick={handleMakeCardDefault} id={card.id}>
                                Make Default
                              </a>
                            </li>
                            <li className="context-menu__item context-menu__item--del">
                              <a href={true} onClick={handleDeleteCard} id={card.id}>
                                Remove Card
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </HasPermission>
              </li>
            );
          })}
        </ul>
      ) : (
        <div />
      )}

      <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PK}>
        <Elements>
          <div className="standard-inputs">
            <div className="standard-input standard-input--lg">
              {customer !== null && customer.sources.data.length > 0 ? (
                <div>
                  <label>Add a new card</label>
                  <p>
                    If your current card is expiring soon or you just want to add another payment method you can so
                    here.
                  </p>
                </div>
              ) : (
                <div>
                  <label>Add your payment method</label>
                  <p>We accept Visa, Mastercard and American Express.</p>
                </div>
              )}

              <CardForm
                trial={trial}
                sendTokenToServer={sendTokenToServer}
                subscription={subscription !== null ? true : false}
                addBillingCard={addBillingCard}
                customer={customer}
              />
              <div className="stripe-secure" />
            </div>
          </div>
        </Elements>
      </StripeProvider>
    </section>
  );
};

PaymentMethods.propTypes = {
  subscription: PropTypes.shape({}),
  trial: PropTypes.instanceOf(moment),
  sendTokenToServer: PropTypes.func.isRequired,
  customer: PropTypes.shape({}),
  paymentContextOpen: PropTypes.string,
  handlePaymentContextToggle: PropTypes.func.isRequired,
  handleDeleteCard: PropTypes.func.isRequired,
  handleMakeCardDefault: PropTypes.func.isRequired,
  addBillingCard: PropTypes.func.isRequired
};

export default PaymentMethods;
