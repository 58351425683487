import PropTypes from "prop-types";
import React from "react";

const ResumePlan = ({ handleResumePlan }) => {
  return (
    <section className="pref-section">
      <h3>Resume your subscription</h3>
      <p>
        Looks like you've cancelled your account. If you didn't mean to do this or you've changed your mind, click the
        button below
      </p>
      <a href={true} className="btn btn--lg btn--empty margin--t" onClick={handleResumePlan}>
        Resume my subscription
      </a>
    </section>
  );
};

ResumePlan.propTypes = {
  handleResumePlan: PropTypes.func.isRequired
};

export default ResumePlan;
