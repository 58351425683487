import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getChildren } from '../actions/childActions';
import * as SetupActions from '../actions/curriculumActions';
import * as ObservationActions from '../actions/observationActions';
import * as WorkerActions from '../actions/workerActions';
import Loader from '../components/Loader';
import ObservationListHeader from '../components/ObservationListHeader';
import ObservationsList from '../components/ObservationsList';
import Pagination from '../components/Pagination';

class ObservationsContainer extends React.Component {
  state = {
    nameTerm: '',
    workerTerm: '',
    statusTerm: '',
    currentPage: 1,
    itemsPerPage: 25,
  };

  componentDidMount() {
    if (this.props.user.authenticated) {
      this.props.getObservations().then(() => {
        this.props.getWorkers().then(() => {
          this.props.getChildren();
        });
      });
    }
  }

  handleFilterChange = event => {
    switch (event.target.name) {
      case 'name':
        this.setState(
          {
            nameTerm: event.target.value,
          },
          this.filterObservations
        );
        break;
      case 'worker':
        this.setState(
          {
            workerTerm: event.target.value,
          },
          this.filterObservations
        );
        break;
      case 'status':
        this.setState(
          {
            statusTerm: event.target.value,
          },
          this.filterObservations
        );
        break;
      default:
        break;
    }
  };

  filterObservations = event => {
    this.props.filterObservations(
      this.props.observations.data.entities,
      this.state
    );
  };

  handlePageClick = event => {
    this.setState({
      currentPage: Number(event.target.dataset.page),
    });
  };

  handlePrev = () => {
    if (this.state.currentPage < 2) {
      return;
    }

    this.setState({
      currentPage: this.state.currentPage - 1,
    });
  };

  handleNext = () => {
    this.setState({
      currentPage: this.state.currentPage + 1,
    });
  };

  render() {
    let display = '';
    if (
      this.props.observations.isLoading ||
      this.props.children.isLoading ||
      this.props.workers.isLoading
    ) {
      display = (
        <div>
          <ObservationListHeader
            singular="Observation"
            title="Observations"
            newLink="/observations/create"
            icon="observations"
          />
          <Loader />
        </div>
      );
    } else {
      const { currentPage, itemsPerPage } = this.state;
      const indexOfLastItem = currentPage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      const numPages = [];
      for (
        let i = 1;
        i <= Math.ceil(this.props.observations.display.length / itemsPerPage);
        i++
      ) {
        numPages.push(i);
      }
      const observationsToDisplay = this.props.observations.display.result.slice(
        indexOfFirstItem,
        indexOfLastItem
      );

      display = (
        <div>
          <ObservationListHeader
            singular="Observation"
            title="Observations"
            newLink="/observations/create"
            icon="observations"
            childCount={
              this.props.children.data !== null
                ? this.props.children.data.result.length
                : 0
            }
          />
          <ObservationsList
            observations={observationsToDisplay}
            workers={this.props.workers.data}
            handleFilterChange={this.handleFilterChange}
            childCount={
              this.props.children.data !== null
                ? this.props.children.data.result.length
                : 0
            }
            user={this.props.user.data}
          />
          <Pagination
            currentPage={this.state.currentPage}
            handlePageClick={this.handlePageClick}
            handleNext={this.handleNext}
            handlePrev={this.handlePrev}
            numPages={numPages}
          />
        </div>
      );
    }

    return display;
  }
}

const mapStateToProps = state => ({
  observations: state.observations,
  workers: state.workers,
  user: state.user,
  children: state.children,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...ObservationActions,
      ...WorkerActions,
      ...SetupActions,
      getChildren,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ObservationsContainer);
