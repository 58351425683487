import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { bindActionCreators } from "redux";
import * as flashActions from "../actions/flashActions";
import { getObservations } from "../actions/observationActions";
import * as UserActions from "../actions/userActions";
import Loader from "../components/Loader";
import ParentProfile from "../components/ParentProfile";
import UserProfile from "../components/UserProfile";

class UserProfileContainer extends React.Component {
  state = {
    activeTab: "children",
    nameTerm: "",
    groupTerm: "",
    sortType: "",
    sortDirection: "",
    contextOpen: false,
    deleteModalOpen: false
  };

  componentWillMount() {
    this.props.getUser(this.props.params.userId);
    this.props.getObservations();
  }

  sortChildren = () => {
    this.props.sortChildren(this.props.children.display.entities.children, this.state);
  };

  handleChildSort = event => {
    switch (event.target.name) {
    case "name":
      switch (this.state.sortDirection) {
      case "":
        this.setState(
          {
            sortType: "name",
            sortDirection: "asc"
          },
          this.sortChildren
        );
        break;
      case "asc":
        this.setState(
          {
            sortType: "name",
            sortDirection: "desc"
          },
          this.sortChildren
        );
        break;
      case "desc":
        this.setState(
          {
            sortType: "name",
            sortDirection: ""
          },
          this.sortChildren
        );
        break;
      default:
        return false;
      }
      break;
    case "age":
      switch (this.state.sortDirection) {
      case "":
        this.setState(
          {
            sortType: "age",
            sortDirection: "asc"
          },
          this.sortChildren
        );
        break;
      case "asc":
        this.setState(
          {
            sortType: "age",
            sortDirection: "desc"
          },
          this.sortChildren
        );
        break;
      case "desc":
        this.setState(
          {
            sortType: "age",
            sortDirection: ""
          },
          this.sortChildren
        );
        break;
      default:
        return false;
      }
      break;
    default:
      return false;
    }
  };

  handleToggleContextMenu = () => {
    if (this.state.contextOpen) {
      this.setState({ contextOpen: false });
    } else {
      this.setState({ contextOpen: true });
    }
  };

  handleToggleDeleteModal = () => {
    if (this.state.deleteModalOpen) {
      this.setState({ deleteModalOpen: false });
    } else {
      this.setState({ deleteModalOpen: true });
    }
  };

  handleDelete = uuid => {
    this.props
        .deleteUser(uuid)
        .then(() => {
          this.props.addMessageToFlash("User was successfully deleted", "success", null);
        })
        .then(() => {
          browserHistory.replace("/observations");
        });
  };

  handleTabChange = tabName => {
    switch (tabName) {
    case "children":
      this.setState({ activeTab: "children" });
      break;
    case "observations":
      this.setState({ activeTab: "observations" });
      break;
    default:
      return true;
    }
  };

  render() {
    const { singleUser } = this.props;
    if (singleUser.data === null) {
      return <Loader />;
    } else {
      if (singleUser.data.role.name === "parent") {
        return (
          <ParentProfile
            user={singleUser.data}
            handleTabChange={this.handleTabChange}
            activeTab={this.state.activeTab}
            handleChildSort={this.handleChildSort}
            handleFilterChange={this.handleFilterChange}
            handleToggleDeleteModal={this.handleToggleDeleteModal}
            handleToggleContextMenu={this.handleToggleContextMenu}
            deleteModalOpen={this.state.deleteModalOpen}
            contextOpen={this.state.contextOpen}
            handleDelete={this.handleDelete}
            currentUser={this.props.user.data.uuid}
          />
        );
      } else {
        return (
          <UserProfile
            user={this.props.singleUser.data}
            handleTabChange={this.handleTabChange}
            activeTab={this.state.activeTab}
            handleChildSort={this.handleChildSort}
            handleFilterChange={this.handleFilterChange}
            handleToggleDeleteModal={this.handleToggleDeleteModal}
            handleToggleContextMenu={this.handleToggleContextMenu}
            deleteModalOpen={this.state.deleteModalOpen}
            contextOpen={this.state.contextOpen}
            handleDelete={this.handleDelete}
            currentUser={this.props.user.data.uuid}
          />
        );
      }
    }
  }
}

UserProfileContainer.propTypes = {
  getUser: PropTypes.func.isRequired,
  params: PropTypes.shape({ userId: PropTypes.string.isRequired }).isRequired,
  singleUser: PropTypes.shape({
    data: PropTypes.shape({}),
    isLoading: PropTypes.bool.isRequired,
    isComplete: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired
  }),
  user: PropTypes.shape({
    data: PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      picture: PropTypes.string,
      school_name: PropTypes.string
    }).isRequired
  }).isRequired
};

UserProfileContainer.defaultProps = {
  singleUser: {
    isLoading: false,
    isComplete: false,
    error: false,
    data: null
  }
};

const mapStateToProps = state => ({ singleUser: state.singleUser, user: state.user });

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...UserActions,
      ...flashActions,
      getObservations
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfileContainer);
