import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import HasPermission from "../containers/HasPermission";
import BillingHistory from "./BillingHistory";
import CancelAccount from "./CancelAccount";
import CancelModal from "./CancelModal";
import ChangePlan from "./ChangePlan";
import CurrentPlan from "./CurrentPlan";
import PaymentMethods from "./PaymentMethods";
import PlanModal from "./PlanModal";
import Plans from "./Plans";
import ResumePlan from "./ResumePlan";

const BillingTab = ({
  user,
  trial,
  sendTokenToServer,
  handleChangePlan,
  subscription,
  customer,
  invoices,
  plans,
  handleUpdatePlan,
  handlePlanUpdateState,
  handlePlanUpdateModal,
  newPlan,
  updatePlanModalOpen,
  childCount,
  toggleModal,
  planChanged,
  loading,
  cancelModalOpen,
  handleCancelPlan,
  toggleCancelModal,
  handleResumePlan,
  handleCancelConfirmationChange,
  paymentContextOpen,
  handlePaymentContextToggle,
  handleMakeCardDefault,
  handleDeleteCard,
  addBillingCard
}) => {
  let output = "";
  if (trial.isSameOrAfter() && subscription === null) {
    output = (
      <div>
        <CurrentPlan trial={trial} subscription={subscription} />
        <div className="form-section">
          <h3>Choose your pricing plan</h3>
          <p>
            Choose a pricing plan that best fits your needs. Only look after a small number of children and want
            flexability? Our standard plan is made for you! Look after lots of children or want a fixed monthly or
            yearly price? Our unlimited plan is ideal for your needs!
          </p>
          <Plans handleChangePlan={handleChangePlan} plans={plans} user={user} />

          <PaymentMethods
            subscription={subscription}
            trial={trial}
            sendTokenToServer={sendTokenToServer}
            customer={customer}
            paymentContextOpen={paymentContextOpen}
            handlePaymentContextToggle={handlePaymentContextToggle}
            handleDeleteCard={handleDeleteCard}
            handleMakeCardDefault={handleMakeCardDefault}
            addBillingCard={addBillingCard}
          />
        </div>
      </div>
    );
  } else {
    output = (
      <div>
        <CurrentPlan trial={trial} subscription={subscription} />{" "}
        {subscription.cancel_at_period_end === true && moment.unix(subscription.current_period_end).isSameOrAfter() ? (
          <ResumePlan handleResumePlan={handleResumePlan} />
        ) : (
          <ChangePlan
            handleUpdatePlan={handleUpdatePlan}
            plans={plans}
            handlePlanUpdateState={handlePlanUpdateState}
            handlePlanUpdateModal={handlePlanUpdateModal}
            subscription={subscription}
            newPlan={newPlan}
            user={user}
          />
        )}
        <PaymentMethods
          subscription={subscription}
          sendTokenToServer={sendTokenToServer}
          customer={customer}
          paymentContextOpen={paymentContextOpen}
          handlePaymentContextToggle={handlePaymentContextToggle}
          handleDeleteCard={handleDeleteCard}
          handleMakeCardDefault={handleMakeCardDefault}
          addBillingCard={addBillingCard}
        />
        <BillingHistory invoices={invoices} />
        <CancelAccount toggleCancelModal={toggleCancelModal} subscription={subscription} />
      </div>
    );
  }
  return (
    <HasPermission allowedRoles={["super_admin"]}>
      <div>
        {updatePlanModalOpen ? (
          <PlanModal
            newPlan={newPlan}
            subscription={subscription}
            customer={customer}
            childCount={childCount}
            toggleModal={toggleModal}
            handleUpdatePlan={handleUpdatePlan}
            planChanged={planChanged}
            email={user.email}
            loading={loading}
          />
        ) : (
          ""
        )}
        {cancelModalOpen ? (
          <CancelModal
            handleCancelPlan={handleCancelPlan}
            toggleCancelModal={toggleCancelModal}
            handleCancelConfirmationChange={handleCancelConfirmationChange}
          />
        ) : (
          ""
        )}
        <section className="form-section form-section--title">
          <h2 className="form-section__title">Billing</h2>
          <p className="form-section__intro">
            Here you can view and update your current plan and payment information as well as view your billing history
            and cancel your acount if you wish.
          </p>
        </section>
        {output}
      </div>
    </HasPermission>
  );
};

BillingTab.propTypes = {
  user: PropTypes.shape().isRequired,
  cancelModalOpen: PropTypes.bool.isRequired,
  childCount: PropTypes.number.isRequired,
  customer: PropTypes.shape({}),
  handleCancelConfirmationChange: PropTypes.func.isRequired,
  handleCancelPlan: PropTypes.func.isRequired,
  handleChangePlan: PropTypes.func.isRequired,
  handlePlanUpdateModal: PropTypes.func.isRequired,
  handlePlanUpdateState: PropTypes.func.isRequired,
  handleResumePlan: PropTypes.func.isRequired,
  handleUpdatePlan: PropTypes.func.isRequired,
  invoices: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
  newPlan: PropTypes.shape({}),
  paymentContextOpen: PropTypes.string,
  planChanged: PropTypes.bool.isRequired,
  plans: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  sendTokenToServer: PropTypes.func.isRequired,
  subscription: PropTypes.shape({}),
  toggleCancelModal: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  trial: PropTypes.instanceOf(moment),
  updatePlanModalOpen: PropTypes.bool.isRequired,
  handlePaymentContextToggle: PropTypes.func.isRequired,
  handleMakeCardDefault: PropTypes.func.isRequired,
  handleDeleteCard: PropTypes.func.isRequired,
  addBillingCard: PropTypes.func.isRequired
};

export default BillingTab;
