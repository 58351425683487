import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as RegisterActions from "../actions/registerActions";
import RegisterForm from "../components/RegisterForm";
import RegisterThanks from "../components/RegisterThanks";

class RegisterContainer extends React.Component {
  handleFormSubmit = values => this.props.sendForm(values);

  handleResend = () => {
    this.props.resendInvite(this.props.register.data.uuid);
  };

  handleEditEmail = () => {
    this.props.editEmail();
  };

  render() {
    return (
      <div>
        {this.props.register.showThanks ? (
          <RegisterThanks
            handleResend={this.handleResend}
            handleEditEmail={this.handleEditEmail}
            first_name={this.props.register.data.first_name}
            uuid={this.props.register.data.uuid}
            email={this.props.register.data.email}
            resendComplete={this.props.register.resendComplete}
          />
        ) : (
          <RegisterForm handleFormSubmit={this.handleFormSubmit} {...this.props} />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  register: state.register,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...RegisterActions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(RegisterContainer);
