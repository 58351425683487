import PropTypes from "prop-types";
import React from "react";
import { Field, Form, reduxForm } from "redux-form";
import BtnLink from "./BtnLink";
import Carer from "./Carer";
import Input from "./Input";
import { email, required } from "./Validators";

export const ParentModal = ({
  child,
  handleSubmit,
  handleToggle,
  handleAddParent,
  handleRemoveParent,
  handleParentInvite,
  carers,
  carerMap,
  filterCarers,
  parentsLoading
}) => {
  return (
    <div>
      <div className="modal modal--med modal-open">
        <Form onSubmit={handleSubmit(handleParentInvite)}>
          <div className="modal__content">
            <div className="modal__header">
              <h2>
                Add a carer for&nbsp;
                {child.first_name}
              </h2>
              <a href={true} className="btn-cancel" onClick={handleToggle}>
                Cancel
              </a>
            </div>
            <div className="modal__main">
              {carers ? (
                <div className="modal__section">
                  <h3>Add an existing carer account</h3>
                  <p>
                    Select an existing carer account to add to{" "}
                    {child.first_name}
                    ’s profile. We’ll send them an email informing them of this
                    change.
                  </p>

                  <div className="standard-input standard-input--lg">
                    <label htmlFor="">Search existing carers</label>
                    <input
                      type="text"
                      placeholder="Search carers by name"
                      className="standard-input__search"
                      onChange={filterCarers}
                    />
                  </div>

                  <ul className="data-list data-list--addCarer">
                    {carerMap.map(carer => (
                      <Carer
                        key={carers[carer].uuid}
                        carer={carers[carer]}
                        child={child}
                        handleAddParent={handleAddParent}
                        handleRemoveParent={handleRemoveParent}
                        loading={
                          parentsLoading.indexOf(carer) !== -1 ? true : false
                        }
                      />
                    ))}
                  </ul>

                  <BtnLink onClick={handleToggle} green small text="Done" />

                  <div className="modal__divider modal__divider--addCarer" />
                </div>
              ) : (
                <div className="modal__section">
                  <h3>Add an existing carer account</h3>
                  <p>
                    Select an existing carer account to add to{" "}
                    {child.first_name}
                    ’s profile. We’ll send them an email informing them of this
                    change.
                  </p>

                  <div className="standard-input standard-input--lg">
                    <label htmlFor="">Search existing carers</label>
                    <input
                      type="text"
                      placeholder="Search carers by name"
                      className="standard-input__search"
                      onChange={filterCarers}
                    />
                  </div>

                  <ul className="data-list data-list--addCarer">
                    <p>No accounts found</p>
                  </ul>
                </div>
              )}
              <div className="modal__section">
                <h3>Invite a new parent / carer to Edna</h3>
                <p>
                  Enter the details of the new carer you want to invite below.
                  We’ll send an invite to the email address you provide for them
                  to complete registration. All fields are required.
                </p>

                <div className="standard-input standard-input--lg standard-input--pair">
                  <div className="standard-input--pair__l">
                    <div>
                      <Field
                        name="first_name"
                        targetName="first_name"
                        type="text"
                        value=""
                        label="First name"
                        hint=""
                        component={Input}
                        validate={[required]}
                        tabIndex="1"
                        placeholder="First name"
                      />
                    </div>
                  </div>
                  <div className="standard-input--pair__r">
                    <div>
                      <Field
                        name="last_name"
                        targetName="last_name"
                        type="text"
                        value=""
                        label="Last name"
                        hint=""
                        component={Input}
                        validate={[required]}
                        tabIndex="2"
                        placeholder="Last name"
                      />
                    </div>
                  </div>
                </div>

                <div className="standard-input standard-input--lg">
                  <Field
                    name="email"
                    targetName="email"
                    type="email"
                    value=""
                    label="Email address"
                    hint=""
                    component={Input}
                    validate={[required, email]}
                    tabIndex="3"
                    placeholder="carer@email.com"
                    classes="standard-input__fullw"
                  />
                </div>

                <BtnLink
                  onClick={handleSubmit(handleParentInvite)}
                  green
                  small
                  text="Invite carer"
                />
              </div>
            </div>

            {/* <div className="modal__footer">
              <div className="modal__footer__controls">
                <button type="submit" className="btn btn--green btn--lg btn--mob-fullw">Send invite</button>
              </div>
            </div> */}
          </div>
        </Form>
      </div>
      <div className="modal-overlay modal-open" />
    </div>
  );
};

ParentModal.propTypes = {
  child: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string,
    dob: PropTypes.string,
    age: PropTypes.number,
    preferred_name: PropTypes.string,
    all_about: PropTypes.string,
    guardians: PropTypes.arrayOf(
      PropTypes.shape({
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired
      })
    )
  }),
  handleToggle: PropTypes.func.isRequired,
  handleAddParent: PropTypes.func.isRequired
};

export default reduxForm({ form: "parent-form" })(ParentModal);
