import PropTypes from "prop-types";
import React,{ Component } from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { bindActionCreators } from "redux";
import { addMessageToFlash } from "../actions/flashActions";

/**
 * HOC that Handles whether or not the user is allowed to see the page.
 * @param {array} allowedRoles - user roles that are allowed to see the page.
 * @returns {Component}
 */
export default function Authorisation(allowedRoles) {
  return (WrappedComponent) => {
    class WithAuthorization extends Component {
      static propTypes = {
        user: PropTypes.object
      };

      notAuthorised = () => {
        this
            .props
            .addMessageToFlash("You are not authorised to view that page", "error", "null");
        browserHistory.goBack();
        return null;
      }

      render() {
        const {user, route, params} = this.props;
        if(user.role.name === 'parent' && route.path === 'children/:childId') {
          if(user.children.indexOf(params.childId) !== -1) {
            return <WrappedComponent {...this.props} />;
          }
        }
        if (allowedRoles.includes(user.role.name)) {
          return <WrappedComponent {...this.props}/>;
        } 
          
        return this.notAuthorised();
      }
    }

    const mapStateToProps = state => ({user: state.user.data});

    const mapDispatchToProps = dispatch => bindActionCreators({
      addMessageToFlash
    }, dispatch);

    return connect(mapStateToProps, mapDispatchToProps)(WithAuthorization);
  };
}
