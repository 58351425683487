import React from "react";
import styled from "styled-components";
import Theme from "../theme";
import smile from "../images/assets/DefaultChildAvatar.svg";
import BtnLink from "./BtnLink";
import PropTypes from "prop-types";

const Container = styled.div`
  color: ${Theme.colorGreyDark};
  font-family: ${Theme.fontPrimary};

  @media screen and (min-width: ${Theme.bpMedium}) {
    max-width: 650px;
  }
`;

const Intro = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
  @media screen and (min-width: ${Theme.bpMedium}) {
    margin-bottom: 50px;
  }

  .smile {
    display: none;
    @media screen and (min-width: ${Theme.bpMedium}) {
      img {
        width: 100%;
      }
      display: block;
      width: 60px;
      height: 60px;
      min-width: 60px;
      margin: 0 45px 0 0;
    }
  }

  .content {
    border-bottom: 1px solid ${Theme.colorGreyLight};
    padding-bottom: 30px;
    @media screen and (min-width: ${Theme.bpMedium}) {
      border-bottom: 3px solid ${Theme.colorGreyLight};
      padding-bottom: 50px;
    }
  }

  h2 {
    font-size: 0.875rem;
    font-weight: 400;
    margin: 0;
    font-weight: 600;
    @media screen and (min-width: ${Theme.bpMedium}) {
      font-size: 1.5rem;
    }
  }

  p {
    font-size: 0.75rem;
    line-height: 1.125rem;
    margin-bottom: 20px;
    @media screen and (min-width: ${Theme.bpMedium}) {
      font-size: 1rem;
      line-height: 1.5rem;
      margin-bottom: 40px;
    }
  }
`;

const FakeNewsItem = styled.div`
  .fakeHeader {
    width: 100%;
    display: flex;
    margin-bottom: 25px;
  }

  .headerText {
    width: 100%;
  }

  .fakeAvatars {
    position: relative;
    margin: 0 24px 0 0;
    @media screen and (min-width: ${Theme.bpMedium}) {
      margin: 0 40px 0 0;
    }
  }

  .fakeContent {
    margin: 0 0 30px 0;
    border-bottom: 1px solid ${Theme.colorGreyLight};
    padding-bottom: 20px;
    @media screen and (min-width: ${Theme.bpMedium}) {
      margin: 0 0 50px 100px;
      border-bottom: 3px solid ${Theme.colorGreyLight};
      padding-bottom: 50px;
    }
  }

  .avatarBig {
    width: 40px;
    height: 40px;
    min-width: 40px;
    background: ${Theme.colorGreyLight};
    border-radius: 30px;
    @media screen and (min-width: ${Theme.bpMedium}) {
      width: 60px;
      height: 60px;
      min-width: 60px;
      border-radius: 40px;
    }
  }

  .avatarSmall {
    width: 30px;
    height: 30px;
    min-width: 30px;
    background: ${Theme.colorGreyLight};
    border-radius: 20px;
    border: 2px solid ${Theme.colorSysWhite};
    position: absolute;
    top: 25px;
    left: 25px;
    @media screen and (min-width: ${Theme.bpMedium}) {
      width: 40px;
      height: 40px;
      min-width: 40px;
      border-radius: 30px;
      top: 40px;
      left: 40px;
    }
  }

  .line {
    width: 100%;
    height: 10px;
    background: ${Theme.colorGreyLight};
    margin-bottom: 12px;
    &--title {
      height: 24px;
    }
    &--short {
      width: 70%;
    }
    @media screen and (min-width: ${Theme.bpMedium}) {
      height: 12px;
      &--title {
        height: 24px;
      }
    }
  }
`;

const NewsFeedEmpty = ({ hasChildren, role }) => {
  if (role === "parent") {
    return (
      <Container>
        <Intro>
          <div className="smile">
            <img src={smile} alt="Welcome to your news feed!" />
          </div>
          <div className="content">
            <h2>Hi and welcome to your newsfeed.</h2>
            <p>
              Observations of children you are linked with will show up here. Looks like there isnt anything here at the
              moment. Check back soon for updates!
            </p>
          </div>
        </Intro>

        <FakeNewsItem>
          <div className="fakeHeader">
            <div className="fakeAvatars">
              <div className="avatarBig" />
              <div className="avatarSmall" />
            </div>
            <div className="headerText">
              <div className="line line--title" />
              <div className="line line--short" />
            </div>
          </div>

          <div className="fakeContent">
            <div className="line" />
            <div className="line" />
            <div className="line" />
            <div className="line line--short" />
          </div>
        </FakeNewsItem>

        <FakeNewsItem>
          <div className="fakeHeader">
            <div className="fakeAvatars">
              <div className="avatarBig" />
              <div className="avatarSmall" />
            </div>
            <div className="headerText">
              <div className="line line--title" />
              <div className="line line--short" />
            </div>
          </div>

          <div className="fakeContent">
            <div className="line" />
            <div className="line" />
            <div className="line" />
            <div className="line line--short" />
          </div>
        </FakeNewsItem>

        <FakeNewsItem>
          <div className="fakeHeader">
            <div className="fakeAvatars">
              <div className="avatarBig" />
              <div className="avatarSmall" />
            </div>
            <div className="headerText">
              <div className="line line--title" />
              <div className="line line--short" />
            </div>
          </div>

          <div className="fakeContent">
            <div className="line" />
            <div className="line" />
            <div className="line" />
            <div className="line line--short" />
          </div>
        </FakeNewsItem>
      </Container>
    );
  } else {
    if (hasChildren === false) {
      return (
        <Container>
          <Intro>
            <div className="smile">
              <img src={smile} alt="Welcome to your news feed!" />
            </div>
            <div className="content">
              <h2>Hi and welcome to your newsfeed.</h2>
              <p>
                Observations that you add to Edna will show up here. Before you can start adding observations you need
                to add some children. Why not add your first one now? Simply head over to the Children page or use the
                button below.
              </p>
              <BtnLink green small text="Add your first child now" href="children/create" />
            </div>
          </Intro>

          <FakeNewsItem>
            <div className="fakeHeader">
              <div className="fakeAvatars">
                <div className="avatarBig" />
                <div className="avatarSmall" />
              </div>
              <div className="headerText">
                <div className="line line--title" />
                <div className="line line--short" />
              </div>
            </div>

            <div className="fakeContent">
              <div className="line" />
              <div className="line" />
              <div className="line" />
              <div className="line line--short" />
            </div>
          </FakeNewsItem>

          <FakeNewsItem>
            <div className="fakeHeader">
              <div className="fakeAvatars">
                <div className="avatarBig" />
                <div className="avatarSmall" />
              </div>
              <div className="headerText">
                <div className="line line--title" />
                <div className="line line--short" />
              </div>
            </div>

            <div className="fakeContent">
              <div className="line" />
              <div className="line" />
              <div className="line" />
              <div className="line line--short" />
            </div>
          </FakeNewsItem>

          <FakeNewsItem>
            <div className="fakeHeader">
              <div className="fakeAvatars">
                <div className="avatarBig" />
                <div className="avatarSmall" />
              </div>
              <div className="headerText">
                <div className="line line--title" />
                <div className="line line--short" />
              </div>
            </div>

            <div className="fakeContent">
              <div className="line" />
              <div className="line" />
              <div className="line" />
              <div className="line line--short" />
            </div>
          </FakeNewsItem>
        </Container>
      );
    } else {
      return (
        <Container>
          <Intro>
            <div className="smile">
              <img src={smile} alt="Welcome to your news feed!" />
            </div>
            <div className="content">
              <h2>Hi and welcome to your newsfeed.</h2>
              <p>
                Observations that you add to Edna will show up here. Looks like you’ve added your first children, great
                job! You’re now ready to start taking observations. Why not take your first one now? Head on over to the
                Observations page or use the button below.
              </p>
              <BtnLink green small text="Add your first observation now" href="observations/create" />
            </div>
          </Intro>

          <FakeNewsItem>
            <div className="fakeHeader">
              <div className="fakeAvatars">
                <div className="avatarBig" />
                <div className="avatarSmall" />
              </div>
              <div className="headerText">
                <div className="line line--title" />
                <div className="line line--short" />
              </div>
            </div>

            <div className="fakeContent">
              <div className="line" />
              <div className="line" />
              <div className="line" />
              <div className="line line--short" />
            </div>
          </FakeNewsItem>

          <FakeNewsItem>
            <div className="fakeHeader">
              <div className="fakeAvatars">
                <div className="avatarBig" />
                <div className="avatarSmall" />
              </div>
              <div className="headerText">
                <div className="line line--title" />
                <div className="line line--short" />
              </div>
            </div>

            <div className="fakeContent">
              <div className="line" />
              <div className="line" />
              <div className="line" />
              <div className="line line--short" />
            </div>
          </FakeNewsItem>

          <FakeNewsItem>
            <div className="fakeHeader">
              <div className="fakeAvatars">
                <div className="avatarBig" />
                <div className="avatarSmall" />
              </div>
              <div className="headerText">
                <div className="line line--title" />
                <div className="line line--short" />
              </div>
            </div>

            <div className="fakeContent">
              <div className="line" />
              <div className="line" />
              <div className="line" />
              <div className="line line--short" />
            </div>
          </FakeNewsItem>
        </Container>
      );
    }
  }
};

NewsFeedEmpty.propTypes = {
  hasChildren: PropTypes.bool.isRequired
};

export default NewsFeedEmpty;
