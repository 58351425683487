import { schema } from "normalizr";

const childSchema = new schema.Entity("children", {}, { idAttribute: "uuid" });
export const children = [childSchema];

const carerSchema = new schema.Entity("carers", {}, { idAttribute: "uuid" });
export const carers = [carerSchema];

const areaSchema = new schema.Entity("areas", {}, { idAttribute: "uuid" });
const aspectSchema = new schema.Entity("aspects", {}, { idAttribute: "uuid" });
const agegroupSchema = new schema.Entity("agegroups", {}, { idAttribute: "uuid" });

const categorySchema = new schema.Entity(
  "category",
  {
    area: areaSchema,
    aspect: aspectSchema,
    agegroup: agegroupSchema
  },
  {
    idAttribute: "uuid"
  }
);

export const categoriesSchema = [categorySchema];

const characteristicSchema = new schema.Entity("characteristic", {}, { idAttribute: "uuid" });
const characteristicChildren = [characteristicSchema];
characteristicSchema.define({ children: characteristicChildren });

export const characteristics = [characteristicSchema];

const personSchema = new schema.Entity("key_person", {}, { idAttribute: "uuid" });
const commentSchema = new schema.Entity("comments", {}, { idAttribute: "uuid" });

export const observationSchema = new schema.Entity(
  "observations",
  {
    child: childSchema,
    key_person: personSchema,
    coel: [characteristicSchema],
    statements: categoriesSchema,
    comments: [commentSchema]
  },
  { idAttribute: "uuid" }
);

export const observations = [observationSchema];
