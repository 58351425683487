import { EventTypes } from "redux-segment";
import { GET_INVITE,PROCESS_INVITE } from "./actionTypes";

export function getInvite(id) {
  return {
    type: GET_INVITE,
    payload: {
      request: {
        method: "GET",
        url: `invite/${id}`
      }
    }
  };
}

export function processInvite(values) {
  return {
    type: PROCESS_INVITE,
    payload: {
      request: {
        method: "POST",
        url: "invite",
        data: values
      }
    },
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: "Signup from Invite",
          email: values.email,
          inviteId: values.inviteId
        }
      }
    }
  };
}
