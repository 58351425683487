import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";
import ellipsis from "../images/icons/ico_ellipsis.svg";
import Theme from "../theme";

const Container = styled.div`
  font-family: ${Theme.fontPrimary};
  position: absolute;
  right: 0;
  top: 0;
  white-space: nowrap;
  text-align: left;
`;

const Trigger = styled.div`
  width: 18px;
  height: 4px;
  background: url(${ellipsis}) no-repeat center center;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  padding: 10px 5px;
  margin: -10px -5px;
`;

const Menu = styled.ul`
  list-style: none;
  font-size: 0.875rem;
  background: ${Theme.colorSysWhite};
  border: 1px solid ${Theme.colorGreyMidLt};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.11);
  border-radius: 3px;
  padding: 0px 10px;
  margin: 10px 0 0 0;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
  transform: scale(1.1);
  z-index: 10;
  position: absolute;
  right: 0;
  top: 0;
  ${props => props.open && open};
`;
const open = css`
  visibility: visible;
  opacity: 1;
  transform: scale(1);
  z-index: 1988;
`;

const MenuItem = styled.li`
  cursor: pointer;
  a {
    font-size: 0.875rem;
    font-weight: 400;
    padding: 10px 0;
    border-bottom: 1px solid ${Theme.colorGreyMidLt};
    display: block;
    color: ${Theme.colorGreyDark};
    transition: 0.3s;
    color: ${props => (props.red === "true" ? Theme.colorBrandRed : Theme.colorGreyDark)};
    &:hover {
      color: ${Theme.colorBrandGreen};
    }
  }

  &:last-of-type {
    a {
      border-bottom: 0;
    }
  }
`;

const ContextMenu = ({ open, items, position, handleClick, contextId }) => {
  return (
    <Container position={position}>
      <Trigger onClick={handleClick} data-id={contextId} />
      <Menu open={open}>
        {items.map(item => (
          <MenuItem key={item.id} red={item.red}>
            {typeof item.action === "string" ? (
              <a href={item.action}>{item.text}</a>
            ) : (
              <a href={true} onClick={item.action} data-contextid={contextId} red={item.red}>
                {item.text}
              </a>
            )}
          </MenuItem>
        ))}
      </Menu>
    </Container>
  );
};

ContextMenu.propTypes = {
  open: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
      red: PropTypes.string
    })
  ),
  position: PropTypes.oneOf(["topRight", "centerRight"]).isRequired,
  handleClick: PropTypes.func.isRequired,
  contextId: PropTypes.string
};

export default ContextMenu;
