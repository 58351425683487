import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import FlashMessageContainer from "../containers/FlashMessageContainer";
import HasPermission from "../containers/HasPermission";
import Avatar from "./Avatar";

export class Header extends React.Component {
  state = {
    slideout: false
  };

  adminSlideOut = e => {
    e.preventDefault();
    if (this.state.slideout === false) {
      this.setState({ slideout: true });
    } else {
      this.setState({ slideout: false });
    }
  };

  adminSlideOut = () => {
    if (this.state.slideout === false) {
      this.setState({ slideout: true });
    } else {
      this.setState({ slideout: false });
    }
  };

  render() {
    const slideOutClass = classNames("app-header__navs", {
      adminOpen: this.state.slideout
    });
    const mobileSlideOutClass = classNames("nav-secondary", {
      "nav-secondary--open": this.state.slideout
    });
    return (
      <div>
        <header className="app-header">
          <h1 className="app-header__title">
            {this.props.user.data ? (
              <Link to="/">{this.props.user.data.school.name}</Link>
            ) : (
              ""
            )}
          </h1>
          <div className={slideOutClass}>
            <nav className="nav-inline nav-global">
              <ul>
                <li>
                  <Link to="/">Newsfeed</Link>
                </li>
                <HasPermission
                  allowedRoles={["super_admin", "admin", "worker"]}
                >
                  <span>
                    <li>
                      <Link to="/observations">Observations</Link>
                    </li>
                    <li>
                      <Link to="/children">Children</Link>
                    </li>
                  </span>
                </HasPermission>
              </ul>
              <button className="mob-nav-btn" onClick={this.adminSlideOut}>
                More
              </button>
            </nav>
            <div className={mobileSlideOutClass}>
              <button
                className="person person--admin"
                onClick={this.adminSlideOut}
              >
                <Avatar
                  size="small"
                  userName={
                    this.props.user.data.person.first_name +
                    " " +
                    this.props.user.data.person.last_name
                  }
                  image={this.props.user.data.person.picture}
                  type="user"
                />

                <div className="person__name">
                  {this.props.user.data
                    ? this.props.user.data.person.first_name
                    : ""}
                </div>
              </button>
              <nav className="nav-admin">
                <ul>
                  <li>
                    <Link to="/settings">Preferences</Link>
                  </li>
                  <li>
                    <Link to="/logout">Log out</Link>
                  </li>
                  <li>
                    <button
                      className="nav-admin__close"
                      onClick={this.adminSlideOut}
                    >
                      Close
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </header>
        <FlashMessageContainer />
      </div>
    );
  }
}

const mapStateToProps = state => ({ user: state.user });

export default connect(mapStateToProps)(Header);
