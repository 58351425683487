import React from "react";
import RegisterHeader from "./RegisterHeader";

const RegisterThanks = ({ first_name, email, handleResend, handleEditEmail, resendComplete }) => (
  <div className="signUp">
    <RegisterHeader />
    <div className="signUp__main">
      <div className="signUp__text-area signUp__text-area--intro">
        <h1>Thanks {first_name}, you’re awesome!</h1>
        <p>
          We’ve sent an email to the address below, head over there and click the link in the email to finish signing
          up. See you soon!
        </p>
      </div>
      <div className="signUp__givenEmail">
        <div className="signUp__givenEmail__label">Your Email</div>
        <div className="signUp__givenEmail__address">{email}</div>
      </div>
    </div>
    <div className="signUp__help">
      <h3>Help! I didn’t get an email</h3>
      <p>
        OK, no biggie. First of all, check the email address above. If it is correct then you can re-send the email by
        clicking the 're-send email' button. If you need to edit the address, click the 'edit email' button. Easy-peasy.
      </p>
      <a href={true} onClick={handleResend} className="btn btn--empty btn--sm">
        Re-send email
      </a>
      <a href={true} onClick={handleEditEmail} className="btn btn--empty btn--sm">
        Edit email
      </a>
      {resendComplete ? "Email Re-sent!" : ""}
    </div>
    <div className="smallprint">
      <p>
        Still having problems? No worries, just drop our team a line on
        <a href="mailto:support@ednaapp.com"> support@ednaapp.com </a>
        and we’ll get you sorted out.
      </p>
    </div>
  </div>
);

export default RegisterThanks;
