import React from "react";
import { Link } from "react-router";
import Avatar from "./Avatar";

class ChildListItem extends React.Component {
  calculateAttendance = day => {
    if (day.morn === 1 && day.aft === 1) {
      return "attendance--full";
    } else if (day.morn !== 1 && day.aft === 1) {
      return "attendance--aft";
    } else if (day.morn === 1 && day.aft !== 1) {
      return "attendance--morn";
    } else if (day.morn !== 1 && day.aft !== 1) {
      return "attendance--none";
    }
  };

  render() {
    const { child } = this.props;
    return (
      <li className="data-list__item data-list--children__item">
        <div className="data-list__item__col data-list--children__child">
          <Link className="person person--primary" to={`children/${child.uuid}`}>
            <Avatar
              size="small"
              userName={child.first_name + " " + child.last_name}
              image={child.picture}
              type="child"
            />

            <div className="person__name">
              {child.first_name} {child.last_name}
            </div>
          </Link>
        </div>
        <div className="data-list__item__col data-list--children__age">{child.age}&nbsp; Months</div>
        <div className="data-list__item__col data-list--children__person">
          {child.key_person ? (
            <a className="person person--secondary" href={`/user/${child.key_person.user.uuid}`}>
              <Avatar
                size="tiny"
                userName={child.key_person.first_name + " " + child.key_person.last_name}
                image={child.key_person.picture}
                type="user"
              />

              <div className="person__name">
                {child.key_person.first_name} {child.key_person.last_name.charAt(0)}
              </div>
            </a>
          ) : (
            <div className="person__name">None</div>
          )}
        </div>
        <div className="data-list__item__col data-list--children__time-table">
          <div className="time-table">
            <div className="time-table__day">
              M
              <div className={`attendance ${this.calculateAttendance(child.timetable.monday)}`} />
            </div>
            <div className="time-table__day">
              T
              <div className={`attendance ${this.calculateAttendance(child.timetable.tuesday)}`} />
            </div>
            <div className="time-table__day">
              W
              <div className={`attendance ${this.calculateAttendance(child.timetable.wednesday)}`} />
            </div>
            <div className="time-table__day">
              T
              <div className={`attendance ${this.calculateAttendance(child.timetable.thursday)}`} />
            </div>
            <div className="time-table__day">
              F
              <div className={`attendance ${this.calculateAttendance(child.timetable.friday)}`} />
            </div>
          </div>
        </div>
      </li>
    );
  }
}

export default ChildListItem;
