import { EDIT_FORGOTPASSWORD_EMAIL, FORGOT_PASSWORD } from "./actionTypes";

export function getPasswordReset (values) {
    return {
        type: FORGOT_PASSWORD,
        payload: {
            request: {
                method: "POST",
                url: "password/email",
                data: values
            }
        }
    };
}

export function editEmail() {
    return {
        type: EDIT_FORGOTPASSWORD_EMAIL,
        payload: {
            showThanks: false
        }
    };
}
