import React from "react";
import FlashMessageContainer from "../containers/FlashMessageContainer";

const LoggedOut = props => (
  <div className="container">
    <FlashMessageContainer />
    {props.children}
  </div>
);

export default LoggedOut;
