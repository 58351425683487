import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router";

const GreenButton = ({ newLink, singular }) => {
  return (
    <div className="header-local__cta">
      <Link className="btn btn--green btn--sm btn--mob-sq" to={newLink}>
        New {singular}
      </Link>
    </div>
  );
};

GreenButton.propTypes = {
  newLink: PropTypes.string.isRequired,
  singular: PropTypes.string.isRequired
};

export default GreenButton;
