import moment from "moment";
import React from "react";

const ProgressDisplay = ({ progression, curriculum, handleGroupClick, openTabs }) => (
  <div className="child-progression">
    {Object.keys(progression).map(agegroup => {
      const agegroupProgressWidth = (progression[agegroup].secure / progression[agegroup].total) * 100;
      let agegroupActiveClass = "progress-header progress-header--primary";
      if (openTabs.indexOf(agegroup) >= 0) {
        agegroupActiveClass = "progress-header progress-header--primary progress-header--primary__active";
      }
      return (
        <div className="child-progression__age-group" key={agegroup}>
          <div className={agegroupActiveClass} onClick={e => handleGroupClick(e, agegroup)}>
            <div className="progress-header__title">
              {curriculum.agegroups[agegroup].min === 61 ? "Early Learning Goals" : ""}
              {curriculum.agegroups[agegroup].min === 62 ? "Exceeding" : ""}
              {curriculum.agegroups[agegroup].min !== 61 && curriculum.agegroups[agegroup].min !== 62
                ? curriculum.agegroups[agegroup].min + " - " + curriculum.agegroups[agegroup].max + " Months"
                : ""}
            </div>
            <div className="progress-header__numbers">
              <span className="number-secure">
                {progression[agegroup].secure < 10 ? `0${progression[agegroup].secure}` : progression[agegroup].secure}
              </span>
              <span className="number-total">{progression[agegroup].total}</span>
            </div>
            <div className="progress-header__progress-bar">
              <div
                className="progress-header__progress"
                style={{
                  width: `${agegroupProgressWidth}%`
                }}
              />
            </div>
          </div>
          {openTabs.indexOf(agegroup) >= 0
            ? Object.keys(progression[agegroup]).map(area => {
              if (area !== "secure" && area !== "total") {
                const areaProgressWidth =
                    (progression[agegroup][area].secure / progression[agegroup][area].total) * 100;
                return (
                  <div
                    className={`child-progression__area child-progression__area--${
                      curriculum.areas[area].identifier
                    }`}
                    key={area}>
                    <div className="progress-header progress-header--secondary">
                      <div className="progress-header__title">{curriculum.areas[area].name}</div>
                      <div className="progress-header__numbers">
                        <span className="number-secure">
                          {progression[agegroup][area].secure < 10
                            ? `0${progression[agegroup][area].secure}`
                            : progression[agegroup][area].secure}
                        </span>
                        <span className="number-total">{progression[agegroup][area].total}</span>
                      </div>
                      <div className="progress-header__progress-bar">
                        <div
                          className="progress-header__progress"
                          style={{
                            width: `${areaProgressWidth}%`
                          }}
                        />
                      </div>
                    </div>
                    {Object.keys(progression[agegroup][area]).map(aspect => {
                      if (aspect !== "secure" && aspect !== "total") {
                        const aspectProgressWidth =
                            (progression[agegroup][area][aspect].secure / progression[agegroup][area][aspect].total) *
                            100;
                        let aspectActiveClass = "progress-header progress-header--tertiary";
                        if (openTabs.indexOf(agegroup + aspect) >= 0) {
                          aspectActiveClass =
                              "progress-header progress-header--tertiary progress-header--tertiary__active";
                        }
                        return (
                          <div className="child-progression__aspect" key={aspect}>
                            <div className={aspectActiveClass} onClick={e => handleGroupClick(e, agegroup + aspect)}>
                              <div className="progress-header__title progress-header__title">
                                {curriculum.aspects[aspect].name}
                              </div>
                              <div className="progress-header__numbers">
                                <span className="number-secure">
                                  {progression[agegroup][area][aspect].secure < 10
                                    ? `0${progression[agegroup][area][aspect].secure}`
                                    : progression[agegroup][area][aspect].secure}
                                </span>
                                <span className="number-total">{progression[agegroup][area][aspect].total}</span>
                              </div>
                              <div className="progress-header__progress-bar">
                                <div
                                  className="progress-header__progress"
                                  style={{
                                    width: `${aspectProgressWidth}%`
                                  }}
                                />
                              </div>
                            </div>
                            {openTabs.indexOf(agegroup + aspect) >= 0 ? (
                              <div className="child-progression__statements">
                                <div className="statements-header">
                                  <div className="statements-header__title">Statement</div>
                                  <div className="statements-header__observations">Observations</div>
                                  <div className="statements-header__secure">Secure</div>
                                </div>
                                {Object.keys(progression[agegroup][area][aspect]).map(statement => {
                                  if (statement !== "secure" && statement !== "total") {
                                    let isSecure = null;
                                    {
                                      const secureCount = progression[agegroup][area][aspect][
                                          statement
                                      ].observations.filter(observation => observation.pivot.secure === 1).length;
                                      secureCount > 0
                                        ? (isSecure = "secure-tick secure-tick--secure")
                                        : (isSecure = "secure-tick");
                                    }
                                    return (
                                      <div className="child-progression__statement" key={statement}>
                                        <div className="cp-statement">
                                          <div className="cp-statement__text">
                                            {progression[agegroup][area][aspect][statement].name}
                                          </div>
                                          <div className="cp-statement__meta">
                                            <div className="obvs-qty">
                                              {progression[agegroup][area][aspect][statement].observations.length < 10
                                                ? `0${
                                                  progression[agegroup][area][aspect][statement].observations.length
                                                }`
                                                : progression[agegroup][area][aspect][statement].observations.length}
                                              <span className="obvs-qty__label">Observations</span>
                                            </div>
                                            <div className="obvs-view" onClick={e => handleGroupClick(e, statement)}>
                                                View
                                            </div>
                                          </div>
                                          <div className="cp-statement__secure">
                                            <div className={isSecure} />
                                          </div>
                                        </div>

                                        {openTabs.indexOf(statement) >= 0 ? (
                                          <div className="cp-statement__observations">
                                            {progression[agegroup][area][aspect][statement].observations.map(
                                              observation => {
                                                let secureClass = "";
                                                observation.pivot.secure === 1
                                                  ? (secureClass = "obvs-secure-tick obvs-secure-tick--secure")
                                                  : (secureClass = "obvs-secure-tick");
                                                return (
                                                  <div className="cp-statement__observation" key={observation.uuid}>
                                                    <div className="obvs-date">
                                                      {moment(observation.date).format("DD-MM-YYYY")}
                                                    </div>
                                                    <div className="obvs-title">
                                                      <a href={`/observations/${observation.uuid}`}>
                                                        {observation.title}
                                                      </a>
                                                    </div>
                                                    <div className="obvs-author">
                                                      <a
                                                        href={`/user/${observation.personable.user.uuid}`}
                                                        className="person person--tertiry">
                                                        <div className="avatar avatar--tiny">
                                                          {" "}
                                                          <img
                                                            src={observation.personable.picture}
                                                            alt="User avatar"
                                                          />
                                                        </div>
                                                        <div className="person__name">
                                                          {observation.personable.first_name}{" "}
                                                          {observation.personable.last_name.charAt(0).toUpperCase()}
                                                        </div>
                                                      </a>
                                                    </div>
                                                    <div className={`obvs-status obvs-status--${observation.status}`}>
                                                      {observation.status.charAt(0).toUpperCase() +
                                                          observation.status.slice(1)}
                                                    </div>
                                                    <div className="obvs-secure">
                                                      <div className={secureClass} />
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            )}
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    );
                                  } else {
                                    return false;
                                  }
                                })}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        );
                      } else {
                        return false;
                      }
                    })}
                  </div>
                );
              } else {
                return false;
              }
            })
            : ""}
        </div>
      );
    })}
  </div>
);

export default ProgressDisplay;
