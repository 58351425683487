/* eslint-disable no-unreachable */
const initialState = {
  error: false,
  isLoading: false,
  isComplete: false,
  page: 1,
  data: null,
  avatar: null,
  status: null
};

const addChildReducer = (state = initialState, action) => {
  switch (action.type) {
  case "CREATE_CHILD_CONTINUE":
    return {
      ...state,
      isLoading: true,
      isComplete: false,
      status: "continue"
    };
  case "CREATE_CHILD_CONTINUE_SUCCESS":
    return {
      ...state,
      isLoading: false,
      isComplete: true,
      data: action.payload.data,
      status: null
    };
  case "CREATE_CHILD_CONTINUE_FAIL":
    return {
      ...state,
      isLoading: false,
      isComplete: true,
      error: true,
      status: null
    };
  case "CREATE_CHILD_FINISH":
    return {
      ...state,
      isLoading: true,
      isComplete: false,
      status: "finish"
    };
  case "CREATE_CHILD_FINISH_SUCCESS":
    return {
      ...state,
      isLoading: false,
      isComplete: true,
      status: null
    };
  case "CREATE_CHILD_FINISH_FAIL":
    return {
      ...state,
      isLoading: false,
      isComplete: true,
      error: true,
      status: null
    };
  case "INCREMENT_PAGE":
    return {
      ...state,
      page: action.page
    };
  case "RESET_ADD_CHILD_STATE":
    return {
      ...state,
      data: null,
      avatar: null,
      status: null,
      isComplete: false,
      isLoading: false,
      error: false,
      page: 1
    };
  default:
    return state;
    break;
  }
};

export default addChildReducer;
