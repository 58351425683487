import React from "react";
import queryString from "query-string";
import ResetPassword from "../components/ResetPassword";
import ResetPasswordThanks from "../components/ResetPasswordThanks";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as PasswordResetActions from "../actions/resetPasswordActions";
import FlashMessageContainer from "./FlashMessageContainer";

export class ResetPasswordContainer extends React.Component {
  handleFormSubmit = values => {
    const query = queryString.parse(window.location.search);
    const token = query.token;
    const email = query.email;
    values = { ...values, token, email };
    this.props.resetPassword(values);
  };

  render() {
    return (
      <div>
        <FlashMessageContainer />
        {this.props.reset.showThanks ? (
          <ResetPasswordThanks />
        ) : (
          <ResetPassword handleFormSubmit={this.handleFormSubmit} />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  reset: state.reset
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...PasswordResetActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordContainer);
