const initialState = {
  isLoading: false,
  isComplete: false,
  data: null
};

const populateEvidence = (state, action) => {
  switch (action.type) {
    case "UPLOAD_EDIT_IMAGE_SUCCESS":
      if (action.payload.data.tags[0] === state.cloudinary_id) {
        return {
          cloudinary_id: action.payload.data.public_id,
          tag: action.payload.data.tags[0],
          isLoading: false
        };
      }
      return {
        ...state
      };

    case "UPLOAD_EDIT_IMAGE_FAIL":
      if (action.payload.data.tags[0] === state.cloudinary_id) {
        return { isLoading: false, error: true };
      }
      return {
        ...state
      };

    default:
      return state;
  }
};

const observationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_SINGLE_OBSERVATION":
      return {
        ...state,
        isLoading: true,
        isComplete: false
      };
    case "GET_SINGLE_OBSERVATION_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isComplete: true,
        data: action.payload.data
      };
    case "GET_SINGLE_OBSERVATION_FAIL":
      return {
        ...state,
        isLoading: false,
        isComplete: true,
        error: true
      };
    case "DELETE_OBSERVATION":
      return {
        ...state,
        isLoading: true,
        isComplete: false
      };
    case "DELETE_OBSERVATION_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isComplete: true
      };
    case "DELETE_OBSERVATION_FAIL":
      return {
        ...state,
        isLoading: false,
        isComplete: true,
        error: true
      };
    case "POPULATE_EDIT_STATEMENTS":
      return {
        ...state,
        data: {
          ...state.data,
          statements: action.payload.statements
        }
      };
    case "POPULATE_EDIT_SECURE":
      return {
        ...state
      };
    case "POPULATE_EDIT_COMMENTS":
      return {
        ...state,
        data: {
          ...state.data,
          childComment: action.payload.comment
        }
      };
    case "POPULATE_EDIT_COEL":
      return {
        ...state,
        data: {
          ...state.data,
          coel: action.payload.coel
        }
      };
    case "POPULATE_EVIDENCE":
      return {
        ...state,
        data: {
          ...state.data,
          evidence: action.payload.evidence
        }
      };
    case "UPLOAD_EDIT_IMAGE":
      return {
        ...state,
        data: {
          ...state.data,
          evidence: [
            ...state.data.evidence,
            {
              isLoading: true,
              cloudinary_id: action.payload.request.data.tags
            }
          ]
        }
      };
    case "UPLOAD_EDIT_IMAGE_SUCCESS":
      return {
        ...state,
        data: {
          ...state.data,
          evidence: state.data.evidence.filter(
            x => x.cloudinary_id !== action.payload.data.tags[0]
          )
        }
      };
    case "UPLOAD_EDIT_IMAGE_FAIL":
      return {
        ...state,
        data: {
          ...state.data,
          evidence: state.data.evidence.map(evidence =>
            populateEvidence(evidence, action)
          )
        },
        error: true,
        errors: action.payload.error
      };
    case "REMOVE_EDIT_IMAGE":
      return {
        ...state,
        data: {
          ...state.data,
          evidence: state.data.evidence.filter(
            image =>
              image.cloudinary_id !== action.payload.request.data.cloudinary_id
          )
        }
      };
    case "REMOVE_EDIT_IMAGE_SUCCESS":
      return {
        ...state
      };
    case "EDIT_OBSERVATION":
      return {
        ...state,
        isLoading: true,
        status: action.payload.request.data.status
      };
    case "EDIT_OBSERVATION_SUCCESS":
      return {
        ...state
      };
    case "EDIT_OBSERVATION_FAIL":
      return {
        ...state,
        isLoading: false,
        isComplete: false
      };
    case "RESET_EDIT_OBSERVATION_STATE":
      return {
        ...state,
        isLoading: false,
        isComplete: false,
        data: null
      };
    default:
      return state;
  }
};

export default observationReducer;
