import { normalize } from "normalizr";
import { carers } from "../schemas";

const initialState = {
  isLoading: false,
  isComplete: false,
  data: null
};

const carerReducer = (state = initialState, action) => {
  switch (action.type) {
  case "GET_CARERS":
    return {
      ...state,
      isLoading: true,
      isComplete: false
    };
  case "GET_CARERS_SUCCESS":
    return {
      ...state,
      isLoading: false,
      isComplete: true,
      data: normalize(action.payload.data, carers),
      display: normalize(action.payload.data, carers)
    };
  case "FILTER_CARERS":
    return {
      ...state,
      display: normalize(action.payload.data, carers)
    };
  case "INVITE_CARER":
    return {
      ...state,
      isLoading: true,
      isComplete: false
    };
  case "INVITE_CARER_SUCCESS":
    return {
      ...state,
      isLoading: false,
      isComplete: true
    };
  case "INVITE_CARER_FAIL":
    return {
      ...state,
      isLoading: false,
      isComplete: true
    };
  default:
    return {
      ...state
    };
  }
};

export default carerReducer;
