import PropTypes from "prop-types";
import React from "react";
import ObservationFilters from "./ObservationFilters";
import ObservationsTable from "./ObservationsTable";

const ObservationsList = ({ observations, handleFilterChange, workers, childCount, user }) => {
  return (
    <div>
      <ObservationFilters handleFilterChange={handleFilterChange} workers={workers} />
      <ObservationsTable observations={observations} childCount={childCount} user={user} />
    </div>
  );
};

ObservationsList.propTypes = {
  observations: PropTypes.array.isRequired
};

export default ObservationsList;
