const initialState = {
    isLoading: false,
    error: false,
    complete: false,
    showThanks: false
};

const resetPasswordReducer = (state = initialState, action) => {
    switch (action.type) {
        case "RESET_PASSWORD":
            return {
                ...state,
                isLoading: true,
                isComplete: false
            };
        case "RESET_PASSWORD_SUCCESS":
            return {
                ...state,
                data: action.payload.data,
                isLoading: false,
                error: false,
                isComplete: true,
                showThanks: true
            };
        case "RESET_PASSWORD_FAILED":
            return {
                ...state,
                isLoading: false,
                error: true,
                isComplete: true
            };
        default:
            return state;
    }
};

export default resetPasswordReducer;
