import React from "react";
import illustration from "../images/assets/faces-children-empty.svg";

const ChildrenListEmpty = ({ worker }) => (
  <div className="empty-state">
    <img src={illustration} alt="" />
    {typeof worker !== "undefined" ? (
      <h2>{worker.first_name} is not a key worker for any children.</h2>
    ) : (
      <div>
        <h2>It doesn’t look like you’ve added any children yet.</h2>
        <p>
          Why not add your first one now? It’s dead easy, start by clicking the <span className="bold">New Child</span> button at the top of the page.
        </p>
      </div>
    )}
  </div>
);

export default ChildrenListEmpty;
