import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import zxcvbn from "zxcvbn";
import Input from "./Input";
import UserAvatarInput from "./UserAvatarInput";
import { email, minLength8, required } from "./Validators";

class ProfileTab extends React.Component {
  state = {
    strength: null
  };

  handlePasswordChange = event => {
    console.log(event.target.value);
    this.setState({
      strength: zxcvbn(event.target.value).score
    });
  };

  render() {
    const { user, currentUser, roles, superadmins } = this.props;
    let roleOutput = null;
    if (user.data.role.name === "super_admin" && superadmins === 1) {
      roleOutput = (
        <p>
          You are the only Super Admin for this Edna account and therefore can
          not edit your role.{" "}
          <a
            href="https://ednaapp.com/support/user-roles/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more about user roles
          </a>
        </p>
      );
    } else if (
      user.data.role.name === "admin" ||
      user.data.role.name === "super_admin" ||
      currentUser.role.name === "super_admin" ||
      currentUser.role.name === "admin"
    ) {
      roleOutput = (
        <div className="standard-input standard-input--lg">
          <label htmlFor="role">Role</label>
          <Field name="role" component="select" label="Role" defaultValue="">
            <option disabled="disabled" hidden="" value="">
              Select a role...
            </option>
            {roles.map(role =>
              currentUser.role.name !== "super_admin" &&
              role.name === "super_admin" ? (
                  ""
                ) : (
                  <option value={role.uuid} key={role.uuid}>
                    {role.display_name}
                  </option>
                )
            )}
          </Field>
        </div>
      );
    }
    return (
      <div>
        <section className="form-section form-section--title">
          <h2 className="form-section__title">
            {user.data.uuid !== currentUser.uuid
              ? user.data.person.first_name + "'s "
              : "Your "}
            Profile
          </h2>
          <p className="form-section__intro">
            Here you can view, edit and update{" "}
            {user.data.uuid !== currentUser.uuid
              ? user.data.person.first_name + "'s "
              : "your "}
            personal details such as name, email address & password
          </p>
        </section>
        <section className="form-section">
          <div className="standard-inputs">
            <div className="standard-input standard-input--lg">
              <label htmlFor="">
                {user.data.uuid !== currentUser.uuid
                  ? user.data.person.first_name + "'s "
                  : "Your "}
                Details
              </label>
              <p>
                {user.data.uuid !== currentUser.uuid
                  ? user.data.person.first_name + "'s "
                  : "Your "}
                first name, last name & email address. All of these are
                required.{" "}
                {user.data.uuid !== currentUser.uuid
                  ? user.data.person.first_name + " uses "
                  : "You use "}
                this email address to log in to{" "}
                {user.data.uuid !== currentUser.uuid ? "their " : "your "}
                Edna account
              </p>
              <div className="standard-input standard-input--lg standard-input--pair">
                <div className="standard-input--pair__l">
                  <Field
                    name="first_name"
                    targetName="first_name"
                    type="text"
                    value=""
                    label="First name"
                    component={Input}
                    placeholder="First name"
                  />
                </div>
                <div className="standard-input--pair__r">
                  <Field
                    name="last_name"
                    targetName="last_name"
                    type="text"
                    value=""
                    label="Last name"
                    component={Input}
                    placeholder="Last name"
                  />
                </div>
              </div>
              <div className="standard-input standard-input--lg">
                <Field
                  name="email"
                  targetName="email"
                  type="text"
                  value=""
                  label="Email"
                  component={Input}
                  validate={[email, required]}
                  placeholder="Email"
                />
              </div>
              {user.data.role.name === "parent" ? (
                <div className="standard-input standard-input--lg">
                  <Field
                    name="contact_number"
                    targetName="contact_number"
                    value=""
                    label="Contact Number"
                    component={Input}
                    placeholder="Contact Number"
                  />
                </div>
              ) : (
                ""
              )}
              {roleOutput}
            </div>
          </div>
        </section>
        <section className="form-section">
          <div className="standard-inputs">
            <div className="standard-input standard-input--lg">
              <label htmlFor="">Profile picture</label>
              <p>
                Upload a profile picture here. This helps identify{" "}
                {user.data.uuid !== currentUser.uuid
                  ? user.data.person.first_name + " "
                  : "you "}
                quickly and easily and will help increase parent interaction.
              </p>
              <UserAvatarInput
                buttonText="Upload Avatar"
                acceptedFormats="jpg,png,gif"
                maxSize="5MB"
                avatar={this.props.avatar}
                currentAvatar={this.props.currentAvatar}
              />
            </div>
          </div>
        </section>
        <section className="form-section">
          <div className="standard-inputs">
            <div className="standard-input standard-input--lg">
              <label htmlFor="">
                {user.data.uuid !== currentUser.uuid
                  ? user.data.person.first_name + "'s "
                  : "Your "}
                Password
              </label>
              <p>
                If you would like to update{" "}
                {user.data.uuid !== currentUser.uuid
                  ? user.data.person.first_name + "'s "
                  : "your "}
                password, enter a new password twice below. Passwords must be at
                least 8 characters long but we recommend 12.
              </p>
              <p>Leave these boxes blank if you don't want to make changes.</p>
              <div className="standard-input standard-input--lg">
                <Field
                  name="password"
                  targetName="password"
                  type="password"
                  value=""
                  label="Password"
                  component={Input}
                  validate={[minLength8]}
                  placeholder="Password"
                  onChange={this.handlePasswordChange}
                  strength={this.state.strength}
                />
              </div>
              <div className="standard-input standard-input--lg">
                <Field
                  name="password_confirmation"
                  targetName="password_confirmation"
                  type="password"
                  value=""
                  label="Confirm Password"
                  component={Input}
                  placeholder="Confirm Password"
                />
              </div>
            </div>
          </div>
        </section>
        <footer className="form-footer">
          <div className="form-footer__btns">
            {this.props.user.isLoading || this.props.avatar.isLoading ? (
              <a
                href={true}
                className="btn btn--disabled btn--spinner btn--lg btn--mob-fullw"
              >
                Save changes
              </a>
            ) : (
              <a
                href={true}
                className="btn btn--green btn--lg btn--mob-fullw"
                onClick={this.props.handleSubmit(values =>
                  this.props.handleUpdateUser({
                    ...values,
                    avatar: this.props.avatar,
                    uuid: this.props.user.data.uuid
                  })
                )}
              >
                Save changes
              </a>
            )}
          </div>
        </footer>
      </div>
    );
  }
}

ProfileTab = reduxForm({ form: "profile" })(ProfileTab);

ProfileTab = connect(state => ({
  initialValues: {
    first_name: state.singleUser.data.person.first_name,
    last_name: state.singleUser.data.person.last_name,
    email: state.singleUser.data.person.email,
    contact_number: state.singleUser.data.person.contact_number,
    role: state.singleUser.data.role.uuid
  }
}))(ProfileTab);

export default ProfileTab;
