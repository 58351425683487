import PropTypes from "prop-types";
import React from "react";

const Plans = ({ handleChangePlan, plans, user }) => (
  <div className="pricing-plans">
    <form onChange={handleChangePlan}>
      {plans.map(plan => {
        let planPrice = plan.amount / 100;
        let planPriveVAT = (plan.amount * 0.2 + plan.amount) / 100;
        if (plan.nickname === "Free") {
          if (
            user.email === "craig@flodesign.co.uk" ||
            user.email === "russ@flodesign.co.uk" ||
            user.email === "flourishchildcare@gmail.com" ||
            user.email === "kseyconsultancy@gmail.com" ||
            user.email === "Hundall@hotmail.com" ||
            user.email === "sharonkey33@ntlworld.com"
          ) {
            return (
              <div className={`pricing-plans__plan pricing-plans--${plan.nickname.toLowerCase()}`} key={plan.id}>
                <label
                  htmlFor={plan.nickname
                      .toLowerCase()
                      .replace(/\s/g, "")
                      .replace("standard", "standard-plan")}>
                  <div className="plan__price">
                    <h3 className="plan__title">
                      <span className="bold">{plan.nickname}</span>
                      &nbsp;Plan
                    </h3>
                    <div className="plan__ammount">£{planPrice.toFixed(0)}</div>
                    <div className="plan__info">
                      {plan.nickname === "Standard" ? "Per child, " : ""}
                      per&nbsp;{plan.interval}
                      <br />
                      billed&nbsp;{plan.interval === "month" ? "monthly" : "annually"}
                    </div>
                    <div className="plan__vat">£{planPriveVAT.toFixed(2)}&nbsp; inc VAT</div>
                  </div>
                  <div className="plan__desc">
                    {plan.interval === "month" ? (
                      plan.metadata.description
                    ) : (
                      <p>
                        Add unlimited children for one flat annual fee. Choose this option and get&nbsp;
                        <span className="highlight--green">2 months free</span>&nbsp; compared to monthly billing!
                      </p>
                    )}
                  </div>
                  <div className="edna-radio">
                    <input
                      type="radio"
                      name="plan"
                      id={plan.nickname
                          .toLowerCase()
                          .replace(/\s/g, "")
                          .replace("standard", "standard-plan")}
                      value={plan.id}
                    />
                    <div className="styled_radio" />
                  </div>
                </label>
              </div>
            );
          }
        } else {
          return (
            <div className={`pricing-plans__plan pricing-plans--${plan.nickname.toLowerCase()}`} key={plan.id}>
              <label
                htmlFor={plan.nickname
                    .toLowerCase()
                    .replace(/\s/g, "")
                    .replace("standard", "standard-plan")}>
                <div className="plan__price">
                  <h3 className="plan__title">
                    <span className="bold">{plan.nickname}</span>
                    &nbsp;Plan
                  </h3>
                  <div className="plan__ammount">£{planPrice.toFixed(0)}</div>
                  <div className="plan__info">
                    {plan.nickname === "Standard" ? "Per child, " : ""}
                    per&nbsp;{plan.interval}
                    <br />
                    billed&nbsp;{plan.interval === "month" ? "monthly" : "annually"}
                  </div>
                  <div className="plan__vat">£{planPriveVAT.toFixed(2)}&nbsp; inc VAT</div>
                </div>
                <div className="plan__desc">
                  {plan.interval === "month" ? (
                    plan.metadata.description
                  ) : (
                    <p>
                      Add unlimited children for one flat annual fee. Choose this option and get&nbsp;
                      <span className="highlight--green">2 months free</span>&nbsp; compared to monthly billing!
                    </p>
                  )}
                </div>
                <div className="edna-radio">
                  <input
                    type="radio"
                    name="plan"
                    id={plan.nickname
                        .toLowerCase()
                        .replace(/\s/g, "")
                        .replace("standard", "standard-plan")}
                    value={plan.id}
                  />
                  <div className="styled_radio" />
                </div>
              </label>
            </div>
          );
        }
        return false;
      })}
    </form>
  </div>
);

Plans.propTypes = {
  handleChangePlan: PropTypes.func.isRequired,
  plans: PropTypes.arrayOf(PropTypes.shape),
  user: PropTypes.shape({})
};
export default Plans;
