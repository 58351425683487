import {RESET_PASSWORD} from "./actionTypes";

export function resetPassword(values) {
    return {
        type: RESET_PASSWORD,
        payload: {
            request: {
                method: "POST",
                url: "password/reset",
                data: values
            }
        }
    };
}