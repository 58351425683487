import moment from "moment";
import React from "react";
import { browserHistory } from "react-router";
import styled from "styled-components";
import HasPermission from "../containers/HasPermission";
import Theme from "../theme";
import Avatar from "./Avatar";

const Avatars = styled.div`
  position: relative;
  flex: 0 0 auto;
  margin: 0px 0 10px 0;
  div {
    box-sizing: content-box;
    &:nth-child(2) {
      position: absolute;
      top: 30px;
      left: -12px;
    }
  }

  @media screen and (min-width: ${Theme.bpMedium}) {
    margin: 0px 25px 0 0;
    div {
      &:nth-child(2) {
        position: absolute;
        top: 80px;
        left: 75px;
      }
    }
  }
`;

const ChildProfileHeader = ({
  child,
  worker,
  workers,
  contextOpen,
  handleToggleContextMenu,
  handleDelete,
  handleToggleDeleteModal,
  handleToggleParentModal
}) => {
  let contextClass = "";
  if (contextOpen) {
    contextClass = "context-menu__menu context-menu__menu--open";
  } else {
    contextClass = "context-menu__menu";
  }
  return (
    <div className="header-profile">
      <Avatars>
        <Avatar
          border
          size="large"
          userName={child.first_name + " " + child.last_name}
          image={child.picture}
          type="child"
        />
        {worker ? (
          <Avatar
            border
            size="small"
            userName={worker.first_name + " " + worker.last_name}
            image={worker.picture}
            type="user"
          />
        ) : (
          ""
        )}
      </Avatars>

      <div className="header-profile__data">
        <div className="header-profile__info">
          <h1 className="header-profile__title">
            {child.first_name} {child.last_name}
          </h1>
          <dl className="header-profile__info-list">
            <span>
              <dt>DOB:</dt>
              <dd>{moment(child.dob).format("DD/MM/YYYY")}</dd>
            </span>
            <span>
              <dt>Age:</dt>
              <dd>
                {child.age}
                months
              </dd>
            </span>
            <span>
              <dt>Start Date:</dt>
              <dd>{moment(child.start_date).format("DD/MM/YYYY")}</dd>
            </span>
          </dl>
        </div>
        <div className="header-profile__meta">
          <dl className="header-profile__info-list">
            <span>
              <dt>Key person:</dt>
              <dd>
                {worker ? (
                  <a href={`/user/${worker.user.uuid}`}>
                    {worker.first_name} {worker.last_name.charAt(0)}
                  </a>
                ) : (
                  "None"
                )}
              </dd>
            </span>
          </dl>
        </div>
        <HasPermission allowedRoles={["super_admin", "admin"]}>
          <div className="context-menu">
            <div className="context-menu__btn" onClick={handleToggleContextMenu} />
            <ul className={contextClass}>
              <li className="context-menu__item">
                <a href={true} onClick={handleToggleParentModal}>
                  Add a parent or guardian for {child.first_name}
                </a>
              </li>
              <li className="context-menu__item">
                <a
                  href={true}
                  onClick={() => {
                    browserHistory.replace(`/children/${child.uuid}/edit`);
                  }}>
                  Edit {child.first_name}'s profile
                </a>
              </li>
              <li className="context-menu__item context-menu__item--del">
                <a href={true} onClick={handleToggleDeleteModal}>
                  Delete {child.first_name}'s profile
                </a>
              </li>
            </ul>
          </div>
        </HasPermission>
      </div>
    </div>
  );
};

export default ChildProfileHeader;
