const initialState = {
  data: null,
  isLoading: true,
  isComplete: false,
  error: false,
};

const singleUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_SINGLE_USER":
      return {
        ...state,
        isLoading: true,
        isComplete: false,
      };
    case "GET_SINGLE_USER_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isComplete: true,
        data: action.payload.data,
      };
    case "GET_SINGLE_USER_FAIL":
      return {
        ...state,
        isLoading: false,
        isComplete: true,
      };
    case "RESET_SINGLE_USER":
      return {
        ...state,
        data: null,
        isLoading: false,
        isComplete: false,
      };
    case "UPDATE_USER":
      return {
        ...state,
        isLoading: true,
        isComplete: false,
      };
    case "UPDATE_USER_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isComplete: true,
        data: action.payload.data,
      };
    case "UPDATE_USER_FAIL":
      return {
        ...state,
        isLoading: false,
        isComplete: false,
      };
    case "DELETE_USER":
      return {
        ...state,
        isLoading: true,
        isComplete: false,
      };
    case "DELETE_USER_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isComplete: true,
      };
    default:
      return state;
  }
};

export default singleUserReducer;
