import moment from "moment";
import { PropTypes } from "prop-types";
import React from "react";
import Lightbox from "react-images";
import { Link } from "react-router";
import styled from "styled-components";
import ObservationPermission from "../containers/ObservationPermission";
import Theme from "../theme";
import Avatar from "./Avatar";
import ChildComment from "./ChildComment";
import Dialog from "./Dialog";

const Avatars = styled.div`
  margin: 0 24px 0 0;
  padding-bottom: 15px;
  position: relative;
  @media screen and (min-width: ${Theme.bpMedium}) {
    margin: 0 34px 0 0;
    padding-bottom: 0px;
  }

  > div:last-of-type {
    position: absolute;
    top: 25px;
    left: 25px;
    @media screen and (min-width: ${Theme.bpMedium}) {
      top: 40px;
      left: 40px;
    }
  }
`;

moment.locale("en", {
  calendar: {
    lastDay: "[Yesterday at] LT",
    sameDay: "[Today at] LT",
    nextDay: "[Tomorrow at] LT",
    lastWeek: "[last] dddd [at] LT",
    nextWeek: "dddd [at] LT",
    sameElse: "DD/MM/YY [at] LT"
  }
});

const SingleObservation = ({
  observation,
  handleToggleContextMenu,
  handleToggleDeleteModal,
  contextOpen,
  handleDelete,
  handleEdit,
  deleteOpen,
  user,
  renderGallery,
  closeLightbox,
  lightboxIsOpen,
  currentImage,
  openLightbox,
  gotoNext,
  gotoPrev
}) => {
  let contextClass = "";
  if (contextOpen) {
    contextClass = "context-menu__menu context-menu__menu--open";
  } else {
    contextClass = "context-menu__menu";
  }

  let deleteModal = "";
  if (deleteOpen) {
    deleteModal = (
      <Dialog
        deleteType="observation"
        handleDelete={handleDelete}
        handleToggle={handleToggleDeleteModal}
        uuid={observation.uuid}
      />
    );
  }

  let images = [];
  if (observation.evidence.length > 0) {
    images = observation.evidence.map(evidence => {
      return {
        src: `https://res.cloudinary.com/flo-design/image/upload/c_fit,q_auto:good,w_2000,h_2000/${evidence.cloudinary_id}.jpg`,
        uuid: evidence.uuid
      };
    });
  }

  return (
    <div className="observation">
      {deleteModal}
      <header className="observation__header">
        <Avatars>
          <Avatar
            size="medium"
            userName={
              observation.key_person.first_name +
              " " +
              observation.key_person.last_name
            }
            image={observation.key_person.picture}
            type="user"
          />
          <Avatar
            border
            size="small"
            userName={
              observation.child.first_name + " " + observation.child.last_name
            }
            image={observation.child.picture}
            type="child"
          />
        </Avatars>
        <div className="observation__header__text">
          <h2 className="observation-title">
            <a href={`/user/${observation.key_person.user.uuid}`}>
              {observation.key_person.first_name}
            </a>
            {" observed "}
            <a href={`/children/${observation.child.uuid}`}>
              {observation.child.first_name}{" "}
            </a>
            {observation.title}
          </h2>
          <div className="observation-meta">
            <div className="observation-meta__item observation-meta__time">
              {moment(observation.date).calendar()}
            </div>
            {/* <div className='observation-meta__item observation-meta__comments'>
                      {observation.comments.length} {observation.comments.length === 1 ? "comment" : "comments"}
                  </div> */}
            <div className="observation-meta__item observation-meta__status">
              {observation.status[0].toUpperCase()}
              {observation.status.slice(1)}
            </div>
          </div>
        </div>
        <ObservationPermission
          allowedRoles={["super_admin", "admin"]}
          observation={observation.key_person.user.uuid}
        >
          <div className="context-menu">
            <div
              className="context-menu__btn"
              onClick={handleToggleContextMenu}
            />
            <ul className={contextClass}>
              <li className="context-menu__item">
                <Link to={`/observations/${observation.uuid}/edit`}>
                  Edit this observation
                </Link>
              </li>
              <li className="context-menu__item context-menu__item--del">
                <a href={true} onClick={handleToggleDeleteModal}>
                  Delete this observation
                </a>
              </li>
            </ul>
          </div>
        </ObservationPermission>
      </header>
      <div className="observation__content">
        <div className="observation__content__imgs">
          {observation.evidence.map((image, index) => (
            <a
              href={`https://res.cloudinary.com/flo-design/image/upload/c_fit,q_auto:good,w_2000,h_2000/${image.cloudinary_id}.jpg`}
              onClick={e => openLightbox(index, e)}
              key={index}
              className="observation-img"
            >
              <img
                alt="evidence"
                src={`https://res.cloudinary.com/flo-design/image/upload/c_fit,q_auto:good,w_500,h_500/${image.cloudinary_id}.jpg`}
              />
            </a>
          ))}
          <Lightbox
            images={images}
            currentImage={currentImage}
            isOpen={lightboxIsOpen}
            onClose={closeLightbox}
            onClickNext={gotoNext}
            onClickPrev={gotoPrev}
          />
        </div>
        <div className="observation__content__text">
          <p>{observation.content}</p>
        </div>
        {observation.childComment !== null ? (
          <ChildComment
            comment={observation.childComment}
            child={observation.child}
          />
        ) : (
          ""
        )}
      </div>
      {/* <div className='observation__actions'>
          <div className='observation__actions__like-comment'>
              <a href={true} className='btn-like' href=''>
              <svg height='21' viewbox='0 0 25 21' width='25' xmlns='http://www.w3.org/2000/svg'>
              <g fill='#AAB2BD'>
              <path
              d='M7.4 19.3C9 19.5 9.9 19.9 10.7 20.3 11.6 20.6 12.4 21 14 21L19.5 21C20.6 21 21.5 20.1 21.5 19 21.5 18.6 21.4 18.2 21.2 17.9 21.9 17.6 22.5 16.9 22.5 16 22.5 15.6 22.4 15.2 22.2 14.9 22.9 14.6 23.5 13.9 23.5 13 23.5 12.6 23.4 12.2 23.2 11.9 23.9 11.6 24.5 10.9 24.5 10 24.5 8.9 23.6 8 22.5 8L16.2 8C16.6 6.7 17.4 4 17 2.5 16.3 0.2 15 0 14.6 0 13.7 0 13 0.8 13 1.8 13 5 10.1 9.8 7.5 9.8 7.2 9.8 7 10 7 10.3L7 18.8C7 19.1 7.2 19.3 7.4 19.3'></path>
              <path
              d='M4 18.3C4.3 18.3 4.5 18.5 4.5 18.8 4.5 19.1 4.3 19.3 4 19.3 3.7 19.3 3.5 19.1 3.5 18.8 3.5 18.5 3.7 18.3 4 18.3M1 21L6 21C6.3 21 6.5 20.8 6.5 20.5L6.5 9.5C6.5 9.2 6.3 9 6 9L1 9C0.7 9 0.5 9.2 0.5 9.5L0.5 20.5C0.5 20.8 0.7 21 1 21'></path>
              </g>
              </svg>
              Like
              </a>
              <a href={true} className='btn-comment btn-comment--active' href=''>
                  <svg height='20' viewbox='0 0 25 20' width='25' xmlns='http://www.w3.org/2000/svg'>
                      <g fill='#AAB2BD'>
                          <path
                            d='M17 9C16.5 9 16 8.6 16 8 16 7.5 16.5 7 17 7 17.6 7 18 7.5 18 8 18 8.6 17.6 9 17 9M13 9C12.5 9 12 8.6 12 8 12 7.5 12.5 7 13 7 13.6 7 14 7.5 14 8 14 8.6 13.6 9 13 9M9 9C8.5 9 8 8.6 8 8 8 7.5 8.5 7 9 7 9.6 7 10 7.5 10 8 10 8.6 9.6 9 9 9M23 0L2 0C1.2 0 0.5 0.7 0.5 1.5L0.5 14.5C0.5 15.3 1.2 16 2 16L3.5 16 3.5 19.5C3.5 19.7 3.7 19.9 3.8 20 3.9 20 4 20 4 20 4.2 20 4.3 20 4.4 19.9L8.2 16 23 16C23.9 16 24.5 15.3 24.5 14.5L24.5 1.5C24.5 0.7 23.9 0 23 0'></path>
                      </g>
                  </svg>
                  Comment
              </a>
          </div>
          <div className='curriculum-areas'>
              {observation.statements.map(category => (
                <div className={`curriculum-area curriculum-area__${category.area.identifier}`}
                     key={category.uuid}></div>
              ))}
          </div>
      </div> */}
      {/* <div className='observation__comments'>
          {observation.comments.length > 0 ? <ObservationComments comments={observation.comments}/> : ""
          }
          <div className='comment-new'>
              <div className='comment-new__avatar'>
                  <div className='avatar avatar--small'>
                      <img alt='avatar' src={user.data.picture}/>
                  </div>
              </div>
              <div className='comment-new__form'>
                  <form action=''>
                      <div className='standard-input'>
                          <input placeholder='What do you think?' type='text'/>
                      </div>
                  </form>
              </div>
          </div>
      </div> */}
      {observation.statements.length > 0 ? (
        <div className="new-observation__preview new-observation__preview__curriculum">
          <div className="new-observation__preview__title">EYFS Statements</div>
          {observation.statements.map(category => (
            <div className="observation-preview-item" key={category.uuid}>
              <div
                className={`bubble-text bubble-text--noavatar curriculm-${category.area.identifier}`}
              >
                <h4
                  className={`title-icon title-icon--${category.area.identifier}`}
                >
                  {category.area.name}
                </h4>
                <p>{category.name}</p>
                <ul
                  className={`curriculm-meta curriculm-meta--${category.area.identifier}`}
                >
                  <li>{category.aspect.name}</li>
                  <li>
                    {category.agegroup.min}- {category.agegroup.max}
                    months
                  </li>
                </ul>

                {category.secure !== 1 ? (
                  <div className="secure-flag">Not Secure</div>
                ) : (
                  <div className="secure-flag secure-flag--secure">Secure</div>
                )}
              </div>
            </div>
          ))}
        </div>
      ) : (
        ""
      )}
      {observation.coel.length > 0 ? (
        <div className="new-observation__preview new-observation__preview__cel">
          <div className="new-observation__preview__title">
            Characteristics of Effective Learning
          </div>
          {observation.coel.map(characteristic => (
            <div className="observation-preview-item" key={characteristic.uuid}>
              <div className="bubble-text cel bubble-text--noavatar">
                <ul className="cel__items">
                  <li className="cel__area">{characteristic.parent.name}</li>
                  <li className="cel__statment">{characteristic.name}</li>
                </ul>
              </div>
            </div>
          ))}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
SingleObservation.propTypes = {
  observation: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  handleToggleContextMenu: PropTypes.func.isRequired,
  handleToggleDeleteModal: PropTypes.func.isRequired,
  contextOpen: PropTypes.bool.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  deleteOpen: PropTypes.bool.isRequired
};

export default SingleObservation;
