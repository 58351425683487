import React from "react";
import Avatar from "./Avatar";
import Characteristic from "./Characteristic";

const COELModal = ({ toggleModal, selectedChildren, children, characteristics, handleCOELToggle, selectedCoel }) => (
  <div className="modal modal-open">
    <div className="modal__content">
      <div className="modal__header">
        <h2>Add Characteristics of Effective Learning</h2>
        <a href={true} className="btn-cancel" onClick={toggleModal}>
          Cancel
        </a>
      </div>

      <div className="modal__main">
        {selectedChildren.map(child => (
          <div className="characteristics-child" key={children[child].uuid}>
            <div className="select-list__child-header">
              <Avatar
                size="small"
                userName={children[child].first_name + " " + children[child].last_name}
                image={children[child].picture}
                type="child"
              />
              <div className="select-list__child-header--text">
                <div className="child-header__name">
                  {children[child].first_name} {children[child].last_name}
                </div>
                <div className="child-header__helpertxt">
                  How did {children[child].first_name}
                  engage with people and their environment?
                </div>
              </div>
            </div>
            {characteristics.map(characteristic => (
              <Characteristic
                characteristic={characteristic}
                key={characteristic.uuid}
                handleCOELToggle={handleCOELToggle}
                child={child}
                selectedCoel={selectedCoel}
              />
            ))}
          </div>
        ))}
      </div>
      <div className="modal__footer">
        <div className="modal__footer__controls">
          <a href={true} className="btn btn--green btn--sm btn--mob-fullw" onClick={toggleModal}>
            Add
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default COELModal;
