import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import * as NewsfeedActions from "../actions/newsfeedActions";
import { getObservations } from "../actions/observationActions";
import Loader from "../components/Loader";
import NewsFeedEmpty from "../components/NewsFeedEmpty";
import NewsFeedObservation from "../components/NewsFeedObservation";
import Theme from "../theme";

const NewsFeedOuter = styled.div`
  background: ${Theme.colorGreyLight};
  @media screen and (min-width: ${Theme.bpMedium}) {
    background: none;
  }
`;

class NewsfeedContainer extends React.Component {
  state = {
    lightboxOpen: "none",
    currentImage: 0,
    editComment: null,
    commentOpen: null,
    quickCommentSubmitting: false
  };

  componentDidMount = () => {
    this.props.getObservations();
  };

  openLightbox = (index, event) => {
    event.preventDefault();
    this.setState({
      currentImage: index,
      lightboxOpen: event.target.dataset.id
    });
  };

  closeLightbox = () => {
    this.setState({ lightboxOpen: "none", currentImage: 0 });
  };

  gotoNext = () => {
    this.setState({
      currentImage: this.state.currentImage + 1
    });
  };

  gotoPrev = () => {
    this.setState({
      currentImage: this.state.currentImage - 1
    });
  };

  handleSetEditComment = event => {
    let commentId = event.target.dataset.commentid;
    if (commentId === this.state.editComment) {
      this.setState({ editComment: null });
    } else {
      this.setState({ editComment: commentId });
    }
  };

  handleCommentOpen = (event, observationId) => {
    if (observationId === this.state.commentOpen) {
      this.setState({ commentOpen: null });
    } else {
      this.setState({ commentOpen: observationId });
    }
  };

  handleCommentPosting = (values, observationId) => {
    this.setState({
      quickCommentSubmitting: true
    });
    this.props.addComment(values.comment, observationId).then(() => {
      this.setState({ commentOpen: null, quickCommentSubmitting: false });
    });
  };

  handleEditComment = (values, commentId) => {
    this.props.editComment(values.comment, commentId).then(() => {
      this.setState({ editComment: null });
    });
  };

  handleDeleteComment = event => {
    event.preventDefault();
    let commentId = event.target.dataset.commentid;
    this.props.deleteComment(commentId);
  };

  render() {
    const { observations } = this.props;
    if (observations.isLoading) {
      return <Loader />;
    } else {
      if (observations.data !== null && observations.data.result.length > 0) {
        return (
          <NewsFeedOuter>
            {observations.data.result.map(observation => {
              return (
                <NewsFeedObservation
                  key={observation}
                  observationId={observation}
                  openLightbox={this.openLightbox}
                  closeLightbox={this.closeLightbox}
                  lightboxOpen={this.state.lightboxOpen}
                  currentImage={this.state.currentImage}
                  gotoNext={this.gotoNext}
                  gotoPrev={this.gotoPrev}
                  editComment={this.state.editComment}
                  handleDeleteComment={this.handleDeleteComment}
                  handleCommentPosting={this.handleCommentPosting}
                  handleSetEditComment={this.handleSetEditComment}
                  handleEditComment={this.handleEditComment}
                  commentOpen={this.state.commentOpen}
                  handleCommentOpen={this.handleCommentOpen}
                  quickCommentSubmitting={this.state.quickCommentSubmitting}
                />
              );
            })}
          </NewsFeedOuter>
        );
      } else {
        return (
          <NewsFeedEmpty
            hasChildren={this.props.user.data.childCount > 0 ? true : false}
            role={this.props.user.data.role.name}
          />
        );
      }
    }
  }
}

NewsfeedContainer.propTypes = {
  observations: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    data: PropTypes.shape({
      entities: PropTypes.shape({}),
      result: PropTypes.arrayOf(PropTypes.string)
    }),
    display: PropTypes.shape({
      entities: PropTypes.shape({}),
      result: PropTypes.arrayOf(PropTypes.string)
    })
  }).isRequired,
  user: PropTypes.shape({
    data: PropTypes.shape({
      childCount: PropTypes.number.isRequired,
      role: PropTypes.shape({
        name: PropTypes.string.isRequired
      })
    })
  }),
  addComment: PropTypes.func.isRequired,
  editComment: PropTypes.func.isRequired,
  deleteComment: PropTypes.func.isRequired,
  getObservations: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  observations: state.observations,
  user: state.user
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getObservations,
      ...NewsfeedActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsfeedContainer);
