import React from "react";
import image from "../images/assets/sad-face-404.svg";

const NotFound = props => {
  return (
    <div className="snap error-screen">
      <div className="error-screen--container">
        <img className="error-screen--img" src={image} alt="Sad face 404 Not Found" />

        <h1>Not found</h1>
        <p>Sorry, it looks like we couldn’t find what you’re looking for. Click the button below to go back.</p>

        <a href={true} className="btn btn--green btn--sm">
          Return to to the app
        </a>
      </div>
    </div>
  );
};

export default NotFound;
