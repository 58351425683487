import React from "react";
import RegisterHeader from "./RegisterHeader";

const ForgotThanks = ({ email, handleResend, handleEdit }) => (
  <div className="signUp">
    <RegisterHeader />
    <div className="signUp__main">
      <div className="signUp__text-area signUp__text-area--intro">
        <h1>Fear not! You’ll have access to Edna soon. </h1>
        <p>
          We’ve emailed you some super simple instructions on how to re-set your Edna password. Head on over to your
          inbox for the below address, click the link in the email and we’ll have you back up and running in no time.{" "}
        </p>
      </div>
      <div className="signUp__givenEmail">
        <div className="signUp__givenEmail__label">Your Email</div>
        <div className="signUp__givenEmail__address">{email}</div>
      </div>
    </div>
    <div className="signUp__help">
      <h3>Help! I didn’t get an email</h3>
      <p>
        OK, no biggie. First of all, check the email address above. If it is correct then you can re-send the email by
        clicking the 're-send email' button. If you need to edit the address, click the 'edit email' button. Easy-peasy.
      </p>
      <a href={true} className="btn btn--empty btn--sm" onClick={handleResend.bind(this, email)}>
        Re-send email
      </a>
      <a href={true} className="btn btn--empty btn--sm" onClick={handleEdit}>
        Edit email
      </a>
    </div>
    <div className="smallprint">
      <p>
        Still having problems? No worries, just drop our team a line on
        <a href="mailto:support@ednaapp.com"> support@ednaapp.com </a>
        and we’ll get you sorted out.
      </p>
    </div>
  </div>
);

export default ForgotThanks;
