const initialState = {
    type: null,
    open: false,
};

const modalReducer = (state = initialState, action) => {
    switch(action.type){
    case "TOGGLE_MODAL":
        return {...state, type: action.payload.type, open: action.payload.open};
    default:
        return state;
    }
};

export default modalReducer;