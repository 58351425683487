import React from "react";
import { Field, Form, reduxForm } from "redux-form";
import Input from "./Input";
import { required } from "./Validators";
import { connect } from "react-redux";
import RenderDatePicker from "./RenderDatePicker";

class ChildProfileEdit extends React.Component {
  render() {
    const { handleFormSubmit } = this.props;
    return (
      <Form onSubmit={this.props.handleSubmit(handleFormSubmit)}>
        <div className="standard-input standard-input--lg standard-input--date">
          <Field name="first_name" targetName="first_name" type="text" value="" label="First Name" component={Input} validate={required} handleChange={this.handleInputChange} />
        </div>
        <div className="standard-input standard-input--lg standard-input--date">
          <Field name="last_name" targetName="last_name" type="text" value="" label="Last Name" component={Input} validate={required} handleChange={this.handleInputChange} />
        </div>
        <div className="standard-input standard-input--lg standard-input--date">
          <label htmlFor="dob">Date of Birth</label>
          <Field name="dob" targetName="dob" type="date" value="" component={RenderDatePicker} handleChange={this.handleInputChange} />
        </div>
        <div className="standard-input standard-input--lg standard-input--date">
          <label htmlFor="all_about">Description</label>
          <Field name="all_about" type="textarea" value="" component="textarea" handleChange={this.handleInputChange} />
        </div>
        <button type="submit" className="btn btn--green btn--lg btn--mob-fixed">
          Save changes
        </button>
      </Form>
    );
  }
}

ChildProfileEdit = reduxForm({
  form: "edit-child",
})(ChildProfileEdit);

ChildProfileEdit = connect(state => ({
  initialValues: state.child.data,
}))(ChildProfileEdit);

export default ChildProfileEdit;
