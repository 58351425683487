import React from 'react';
import {connect} from "react-redux";

class ObservationPermission extends React.Component {

  render() {
    const {allowedRoles, user, observation} = this.props;
    if (allowedRoles.includes(user.role.name) || user.uuid === observation) {
      return React.cloneElement(this.props.children);
    } else {
      return false;
    }
  }
}

export default connect(state => ({user: state.user.data}))(ObservationPermission);
