import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import DatePicker from "react-datepicker";

// import "react-datepicker/dist/react-datepicker.css";

class RenderDatePicker extends React.Component {
  static defaultProps = {
    placeholder: ""
  };

  handleChange = date => {
    this.props.input.onChange(moment(date).format("YYYY-MM-DD"));
  };

  handleTouchStart = () => {
    this.props.onClick();
  };

  render() {
    const {
      input,
      placeholder,
      meta: { touched, error }
    } = this.props;

    return (
      <div>
        {this.props.futureDates ? (
          <DatePicker
            {...input}
            className={touched && error ? "input-error" : ""}
            placeholder={placeholder}
            dateFormat="DD-MM-YYYY"
            openToDate={moment(new Date())}
            showYearDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={4}
            popperPlacement="bottom-end"
            selected={input.value ? moment(input.value, "DD-MM-YYYY") : null}
            onChange={this.handleChange}
            readOnly={true}
            onTouchStart={this.onTouchStart}
          />
        ) : (
          <DatePicker
            {...input}
            className={touched && error ? "input-error" : ""}
            placeholder={placeholder}
            dateFormat="DD-MM-YYYY"
            openToDate={moment(new Date())}
            showYearDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={4}
            popperPlacement="bottom-end"
            maxDate={moment(new Date())}
            selected={input.value ? moment(input.value, "DD-MM-YYYY") : null}
            onChange={this.handleChange}
            readOnly={true}
            onTouchStart={this.onTouchStart}
          />
        )}
        {touched && error && <span>{error}</span>}
      </div>
    );
  }
}

RenderDatePicker.propTypes = {
  input: PropTypes.shape({ onChange: PropTypes.func.isRequired, value: PropTypes.string.isRequired }).isRequired,
  meta: PropTypes.shape({ touched: PropTypes.bool, error: PropTypes.bool }),
  placeholder: PropTypes.string,
  maxDates: PropTypes.bool.isRequired
};

export default RenderDatePicker;
