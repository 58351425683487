import React from 'react';
import PropTypes from 'prop-types';
import Moment from "moment";

const PlanPostChange = ({plan, email}) => {
  return (
    <div className="modal__main modal__main--pad">
      <div className="conformation-title conformation-title--thanks ">
        <h2>You Rock!</h2>
        <h1>Thank You!</h1>
      </div>

      <p>You&#39;ve now signed up to the&nbsp;
        <span className="bold">{plan.items.data[0].plan.nickname}&nbsp; plan</span>. We&#39;ll bill you {plan.items.data[0].plan.interval}ly starting on &nbsp;{Moment
          .unix(plan.current_period_end)
          .format('DD/MM/YYYY')}.</p>
      {/* <p>We&#39;ve sent you an email confirmation to &nbsp;<span className="bold">{email}</span>&nbsp;so keep your eyes peeled for that.</p> */}
      <p>Thank you once again and we hope you enjoy using Edna!</p>
    </div>
  );
};

PlanPostChange.propTypes = {
  plan: PropTypes
    .shape({})
    .isRequired,
  email: PropTypes.string.isRequired
};

export default PlanPostChange;
