import PropTypes from "prop-types";
import React from "react";

const Tab = ({ isActive, tabName, tabLabel, handleSwitchTab }) => (
  <li>
    <a href={true} className={isActive ? "active" : ""} name={tabName} onClick={handleSwitchTab}>
      {tabLabel}
    </a>
  </li>
);

Tab.propTypes = {
  isActive: PropTypes.bool.isRequired,
  tabName: PropTypes.string.isRequired,
  tabLabel: PropTypes.string.isRequired,
  handleSwitchTab: PropTypes.func.isRequired
};

export default Tab;
