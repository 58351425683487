import React from "react";
import Statement from "./Statement";

const EYFSModal = ({
  toggleModal,
  categories,
  children,
  selectedChildren,
  areas,
  aspects,
  agegroups,
  toggleSecure,
  toggleSelected,
  selectedStatements,
  filterCategories,
  filterName,
  categoriesMap,
  ageFilters,
  areaFilters,
  selectedSecure
}) => (
  <div className="modal modal-open">
    <div className="modal__content">
      <div className="modal__header">
        <h2>Add EYFS Statements</h2>
        <a href={true} className="btn-cancel" onClick={toggleModal}>
          Cancel
        </a>
      </div>
      <div className="modal__main">
        <input
          className="modal-search"
          placeholder="Search for EYFS statements"
          type="text"
          onChange={filterName}
        />
        <div className="tap-filters tap-filters--eyfs">
          <div className="tap-filters__group">
            <div className="tap-filters__title">Filter by age (Months)</div>
            <ul className="tap-filters__list">
              {Object.keys(agegroups).map(agegroup => {
                let classGubbins = "";
                ageFilters.indexOf(agegroup) >= 0
                  ? (classGubbins =
                      "tap-filters__list__item tap-filters__list__item--selected")
                  : (classGubbins = "tap-filters__list__item");
                return (
                  <li
                    key={agegroups[agegroup].uuid}
                    className={classGubbins}
                    onClick={filterCategories.bind(this, {
                      filter: "age",
                      value: agegroups[agegroup].uuid
                    })}
                  >
                    {agegroups[agegroup].min === 61
                      ? "Early Learning Goals"
                      : ""}
                    {agegroups[agegroup].min === 62 ? "Exceeding" : ""}
                    {agegroups[agegroup].min !== 61 &&
                    agegroups[agegroup].min !== 62
                      ? agegroups[agegroup].min +
                        " - " +
                        agegroups[agegroup].max
                      : ""}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="tap-filters__group">
            <div className="tap-filters__title">
              Filter by area of learning & development
            </div>
            <ul className="tap-filters__list">
              {Object.keys(areas).map(area => {
                let classGubbins = "";
                areaFilters.indexOf(areas[area].uuid) >= 0
                  ? (classGubbins = `tap-filters__list__item tap-filters__list__item--selected tap-filters__list__item--${areas[area].identifier}`)
                  : (classGubbins = `tap-filters__list__item tap-filters__list__item--${areas[area].identifier}`);
                return (
                  <li
                    key={areas[area].uuid}
                    className={classGubbins}
                    onClick={filterCategories.bind(this, {
                      filter: "area",
                      value: areas[area].uuid
                    })}
                  >
                    {areas[area].short_name !== null
                      ? areas[area].short_name
                      : areas[area].name}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        {Object.keys(areas).map(area => {
          const categoriesPerArea = categories.filter(
            category =>
              areas[categoriesMap[category].area].uuid === areas[area].uuid
          );
          return (
            <div key={areas[area].uuid}>
              {categoriesPerArea.length > 0 ? (
                <ul className="select-list select-list__eyfs">
                  <li
                    className={`list-seperator list-seperator--icon list-seperator--${areas[area].identifier}`}
                    key={areas[area].uuid}
                  >
                    {areas[area].name}
                  </li>
                  {categories.map(statement => {
                    if (
                      areas[categoriesMap[statement].area].uuid ===
                      areas[area].uuid
                    ) {
                      return (
                        <Statement
                          area={areas[categoriesMap[statement].area]}
                          aspect={aspects[categoriesMap[statement].aspect]}
                          agegroup={
                            agegroups[categoriesMap[statement].agegroup]
                          }
                          statement={categoriesMap[statement]}
                          key={statement}
                          toggleSecure={toggleSecure}
                          toggleSelected={toggleSelected}
                          selectedChildren={selectedChildren}
                          statementSelected={
                            selectedStatements.indexOf(statement) >= 0
                          }
                          children={children}
                          selectedSecure={selectedSecure}
                        />
                      );
                    }
                    return null;
                  })}
                </ul>
              ) : (
                ""
              )}
            </div>
          );
        })}
      </div>
      <div className="modal__footer">
        <div className="modal__footer__controls">
          <a
            href={true}
            className="btn btn--green btn--sm btn--mob-fullw"
            onClick={toggleModal}
          >
            Add Statements
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default EYFSModal;
