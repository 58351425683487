const initialState = {
  isLoading: false,
  isComplete: false,
  subscription: null,
  customer: null,
  invoices: null,
  plans: null
};

const billingReducer = (state = initialState, action) => {
  switch (action.type) {
  case "GET_SUB":
    return {
      ...state,
      isLoading: true,
      isComplete: false
    };
  case "GET_SUB_SUCCESS":
    return {
      ...state,
      isLoading: false,
      isComplete: true,
      subscription: action.payload.data
    };
  case "GET_CUSTOMER":
    return {
      ...state,
      isLoading: true,
      isComplete: false
    };
  case "GET_CUSTOMER_SUCCESS":
    return {
      ...state,
      isLoading: false,
      isComplete: true,
      customer: action.payload.data
    };
  case "GET_INVOICES":
    return {
      ...state,
      isLoading: true,
      isComplete: false
    };
  case "GET_INVOICES_SUCCESS":
    return {
      ...state,
      isLoading: false,
      isComplete: false,
      invoices: action.payload.data.data
    };
  case "GET_PLANS":
    return {
      ...state,
      isLoading: true,
      isComplete: false
    };
  case "GET_PLANS_SUCCESS":
    return {
      ...state,
      isLoading: false,
      isComplete: true,
      plans: action.payload.data.data.sort((a, b) => parseFloat(a.amount) - parseFloat(b.amount))
    };
  case "CANCEL_PLAN":
    return {
      ...state,
      isLoading: true,
      isComplete: false
    };
  case "CANCEL_PLAN_SUCCESS":
    return {
      ...state,
      isLoading: false,
      isComplete: true
    };
  case "CANCEL_PLAN_FAIL":
    return {
      ...state,
      isLoading: false,
      isComplete: false
    };
  case "RESUME_PLAN":
    return {
      ...state,
      isLoading: true,
      isComplete: false
    };
  case "RESUME_PLAN_SUCCESS":
    return {
      ...state,
      isLoading: false,
      isComplete: true
    };
  case "ADD_CARD":
    return {
      ...state,
      isLoading: true,
      isComplete: false
    };
  case "ADD_CARD_SUCCESS":
    return {
      ...state,
      isLoading: false,
      isComplete: true
    };
  case "DELETE_CARD":
    return {
      ...state,
      isLoading: true,
      isComplete: false
    };
  case "DELETE_CARD_SUCCESS":
    return {
      ...state,
      isLoading: false,
      isComplete: true
    };
  case "MAKE_CARD_DEFAULT":
    return {
      ...state,
      isLoading: true,
      isComplete: false
    };
  case "MAKE_CARD_DEFAULT_SUCCESS":
    return {
      ...state,
      isLoading: false,
      isComplete: true
    };
  default:
    return state;
  }
};

export default billingReducer;
