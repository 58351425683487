import React from "react";
import HasPermission from "../containers/HasPermission";
import GreenButton from "./GreenButton";

const ChildListHeader = ({ title, singular = null, newLink = null, icon, permissions }) => (
  <div className="header-local">
    <h1 className={`header-local__title header-local__title--${icon}`}>{title}</h1>
    {newLink !== null ? (
      <HasPermission allowedRoles={["super_admin", "admin"]}>
        <GreenButton newLink={newLink} singular={singular} />
      </HasPermission>
    ) : (
      ""
    )}
  </div>
);

export default ChildListHeader;
