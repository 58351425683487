import { routerReducer } from "react-router-redux";
import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import addChildReducer from "./addChild";
import addObservationReducer from "./addObservation";
import addWorkerReducer from "./addWorker";
import avatarReducer from "./avatars";
import billingReducer from "./billing";
import carerReducer from "./carers";
import childrenReducer from "./children";
import cloudinaryReducer from "./cloudinary";
import curriculumReducer from "./curriculum";
import dashboardReducer from "./dashboard";
import filtersReducer from "./filters";
import flashReducer from "./flash";
import forgotPasswordReducer from "./forgotPassword";
import groupsReducer from "./groups";
import invitesReducer from "./invites";
import modalReducer from "./modals";
import newsfeedReducer from "./newsfeed";
import observationReducer from "./observation";
import observationsReducer from "./observations";
import onboardingReducer from "./onboarding";
import peopleReducer from "./people";
import progressReducer from "./progression";
import registerReducer from "./register";
import resetPasswordReducer from "./resetPassword";
import childReducer from "./singleChild";
import singleUserReducer from "./singleUser";
import tabsReducer from "./tabs";
import usersReducer from "./users";
import workerReducer from "./worker";
import workersReducer from "./workers";

const appReducer = combineReducers({
  addChild: addChildReducer,
  addObservation: addObservationReducer,
  avatar: avatarReducer,
  addWorker: addWorkerReducer,
  billing: billingReducer,
  carers: carerReducer,
  categories: curriculumReducer,
  child: childReducer,
  children: childrenReducer,
  cloudinary: cloudinaryReducer,
  dashboard: dashboardReducer,
  register: registerReducer,
  filters: filtersReducer,
  groups: groupsReducer,
  newsfeed: newsfeedReducer,
  people: peopleReducer,
  progression: progressReducer,
  onboarding: onboardingReducer,
  workers: workersReducer,
  worker: workerReducer,
  flashMessages: flashReducer,
  forgotPassword: forgotPasswordReducer,
  tabs: tabsReducer,
  invites: invitesReducer,
  form: formReducer.plugin({
    create_child: (state, action) => {
      switch (action.type) {
      case "CREATE_CHILD_FINISH":
        return undefined;
      case "CREATE_CHILD_CANCELLED":
        return undefined;
      default:
        return state;
      }
    }
  }),
  modals: modalReducer,
  observations: observationsReducer,
  reset: resetPasswordReducer,
  routing: routerReducer,
  singleUser: singleUserReducer,
  singleObservation: observationReducer,
  user: usersReducer
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    const { routing } = state;
    state = {
      routing
    };
  }
  return appReducer(state, action);
};

export default rootReducer;
