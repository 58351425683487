const initialState = {
  children: [],
  statements: [],
  secure: [],
  childComments: {},
  coel: [],
  status: null,
  isLoading: false
};

/* eslint-disable no-unreachable */
const addObservationReducer = (state = initialState, action) => {
  switch (action.type) {
  case "CREATE_OBSERVATION":
    return {
      ...state,
      isLoading: true,
      isComplete: false,
      status: action.payload.request.data.status
    };
  case "CREATE_OBSERVATION_SUCCESS":
    return {
      ...state,
      isLoading: false,
      isComplete: true,
      status: action.payload.status
    };
  case "CREATE_OBSERVATION_FAIL":
    return {
      ...state,
      isLoading: false,
      isComplete: true,
      error: true,
      status: action.error.response.status
    };
  case "RESET_ADD_OBSERVATION_STATE":
    return {
      ...state,
      isLoading: false,
      isComplete: false,
      error: false,
      data: null,
      children: [],
      statements: [],
      secure: [],
      childComments: [],
      coel: []
    };
  case "ADD_CHILD":
    return {
      ...state,
      children: [
        ...state.children,
        action.payload.child
      ]
    };
  case "REMOVE_CHILD":
    return {
      ...state,
      children: state
          .children
          .filter((uuid) => uuid !== action.payload.child)
    };
  case "POPULATE_STATEMENTS":
    return {
      ...state,
      statements: action.payload.statements
    };
  case "POPULATE_SECURE":
    return {
      ...state,
      secure: action.payload.event
    };
  case "POPULATE_COMMENTS":
    return {
      ...state,
      childComments: action.payload.comments
    };
  case "POPULATE_COEL":
    return {
      ...state,
      coel: action.payload.characteristics
    };
  default:
    return state;
  }
  return state;
};

export default addObservationReducer;