import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getCurriculum } from "../actions/curriculumActions";
import { getProgression } from "../actions/progressionActions";
import Loader from "./Loader";
import ProgressDisplay from "./ProgressDisplay";

class ChildProgression extends React.Component {
  constructor(props) {
    super(props);
    this.handleGroupClick = this.handleGroupClick.bind(this);
  }

  state = {
    openTabs: [],
  };

  componentWillMount() {
    if (this.props.progression.data === null) {
      this.props.getCurriculum().then(() => {
        this.props.getProgression(this.props.child.uuid);
      });
    }
  }

  handleGroupClick = (event, tab) => {
    event.stopPropagation();
    if (this.state.openTabs.indexOf(tab) >= 0) {
      this.setState({
        openTabs: this.state.openTabs.filter(openTab => openTab !== tab),
      });
    } else {
      this.setState({
        openTabs: [...this.state.openTabs, tab],
      });
    }
  };

  render() {
    const { categories, progression } = this.props;
    return (
      <div>
        {categories.data === null || progression.data === null ? (
          <Loader />
        ) : (
          <ProgressDisplay progression={progression.data[0]} curriculum={categories.data.entities} handleGroupClick={this.handleGroupClick} openTabs={this.state.openTabs} />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  categories: state.categories,
  progression: state.progression,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCurriculum,
      getProgression,
    },
    dispatch,
  );

ChildProgression.propTypes = {
  getCurriculum: PropTypes.func.isRequired,
  getProgression: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChildProgression);
