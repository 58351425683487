import { ADD_COMMENT,DECREMENT_LIKES,DELETE_COMMENT,EDIT_COMMENT,INCREMENT_LIKES } from "./actionTypes";

export function incrementLikes(values) {
  return {
    type: INCREMENT_LIKES,
    payload: {
      request: {
        method: "POST",
        url: "likes",
        data: values
      }
    }
  }
}

export function decrementLikes(values) {
  return {
    type: DECREMENT_LIKES,
    payload: {
      request: {
        method: "DELETE",
        url: "likes",
        data: values
      }
    }
  }
}

export function addComment(comment, observationId) {
  return {
    type: ADD_COMMENT,
    payload: {
      request: {
        method: "POST",
        url: "comments",
        data: {comment, observation: observationId}
      }
    }
  }
}

export function editComment(comment, commentId) {
  return {
    type: EDIT_COMMENT,
    payload: {
      request: {
        method: "POST",
        url: `comments/${commentId}`,
        data: {comment}
      }
    }
  }
}

export function deleteComment(commentId) {
  return {
    type: DELETE_COMMENT,
    payload: {
      request: {
        method: "DELETE",
        url: `comments/${commentId}`
      }
    }
  }
}