const initialState = {
  isLoading: false,
  isComplete: false,
  data: null,
  error: false,
};

const progressReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_PROGRESSION":
      return { ...state, isLoading: true, isComplete: false };
    case "GET_PROGRESSION_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isComplete: true,
        data: action.payload.data,
      };
    case "GET_PROGRESSION_FAIL":
      return {
        ...state,
        isLoading: false,
        isComplete: true,
        error: true,
      };
    case "RESET_PROGRESSION":
      return {
        ...state, data: null, isLoading: false, isComplete: false,
      };
    default:
      return state;
  }
};

export default progressReducer;
