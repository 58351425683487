import React from "react";
import { connect } from "react-redux";
import { userLogout } from "../actions/userActions";
import { bindActionCreators } from "redux";
import { browserHistory } from "react-router";

class Logout extends React.Component {
  componentWillMount() {
    // Dispatch logout action
    this.props.userLogout();
  }

  componentDidMount() {
    browserHistory.push("/login");
  }

  render() {
    return <div />;
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    userLogout,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
