// import defaultImg from '../images/users/48.jpg';
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import Theme from "../theme";

const Container = styled.div`
  border-radius: 50%;
  overflow: hidden;
  box-sizing: border-box;
  background-color: ${Theme.colorGreyLight} !important;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  border: ${props => (props.border ? "3px solid #ffffff" : "0px")};
  display: flex;
  justify-content: center;
  align-items: center;
  > img {
    width: 100%;
  }

  width: ${props =>
    (props.size === "tiny" && Theme.avaTiny) ||
    (props.size === "small" && Theme.avaSmall) ||
    (props.size === "medium" && Theme.avaMedium) ||
    (props.size === "large" && Theme.avaLarge)};
  height: ${props =>
    (props.size === "tiny" && Theme.avaTiny) ||
    (props.size === "small" && Theme.avaSmall) ||
    (props.size === "medium" && Theme.avaMedium) ||
    (props.size === "large" && Theme.avaLarge)};
  min-width: ${props =>
    (props.size === "tiny" && Theme.avaTiny) ||
    (props.size === "small" && Theme.avaSmall) ||
    (props.size === "medium" && Theme.avaMedium) ||
    (props.size === "large" && Theme.avaLarge)};

  > div {
    font-size: ${props =>
    (props.size === "tiny" && "10px") ||
      (props.size === "small" && "14px") ||
      (props.size === "medium" && "16px") ||
      (props.size === "large" && "20px")};
  }

  @media screen and (min-width: ${Theme.bpMedium}) {
    width: ${props =>
    (props.size === "small" && Theme.avaMedium) ||
      (props.size === "medium" && Theme.avaLarge) ||
      (props.size === "large" && Theme.avaHuge)};
    height: ${props =>
    (props.size === "small" && Theme.avaMedium) ||
      (props.size === "medium" && Theme.avaLarge) ||
      (props.size === "large" && Theme.avaHuge)};
    min-width: ${props =>
    (props.size === "small" && Theme.avaMedium) ||
      (props.size === "medium" && Theme.avaLarge) ||
      (props.size === "large" && Theme.avaHuge)};

    > div {
      font-size: ${props =>
    (props.size === "tiny" && "10px") ||
        (props.size === "small" && "18px") ||
        (props.size === "medium" && "24px") ||
        (props.size === "large" && "40px")};
    }
  }
`;

const Initials = styled.div`
  font-family: ${Theme.fontPrimary};
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  color: ${props =>
    (props.type === "child" && Theme.colorBrandGreen) ||
    (props.type === "user" && Theme.colorBrandBlue) ||
    (props.type === "carer" && Theme.colorBrandPurple)};
`;

const Avatar = ({ image, userName, size, border, type }) => {
  if (image !== "null" && image !== null) {
    return (
      <Container size={size} border={border} type={type}>
        <img src={image} alt={userName} title={userName} />
      </Container>
    );
  } else {
    let matches = userName.match(/\b(\w)/g); // ['J','S','O','N']
    let initials = matches.join(""); // JSON
    return (
      <Container size={size} border={border}>
        <Initials type={type}>{initials}</Initials>
      </Container>
    );
  }
};

Avatar.propTypes = {
  image: PropTypes.string,
  userName: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["tiny", "small", "medium", "large"]).isRequired,
  type: PropTypes.oneOf(["child", "user", "carer"]).isRequired,
  border: PropTypes.bool
};

// Avatar.defaultProps ={
//   image: defaultImg
// }

export default Avatar;
