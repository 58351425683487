const initialState = {
  isLoading: false,
  isComplete: false,
  data: null
};

const inviteReducer = (state = initialState, action) => {
  switch (action.type) {
  case "GET_INVITES":
    return {
      ...state,
      isLoading: true,
      isComplete: false
    };
  case "GET_INVITES_SUCCESS":
    return {
      ...state,
      isLoading: false,
      isComplete: true,
      data: action.payload.data
    };
  case "GET_CARER_INVITES":
    return {
      ...state,
      isLoading: true,
      isComplete: false
    };
  case "GET_CARER_INVITES_SUCCESS":
    return {
      ...state,
      isLoading: false,
      isComplete: true,
      data: action.payload.data
    };
  case "INVITE_CARER_SUCCESS":
    return {
      ...state,
      data: [...state.data, action.payload.data]
    };
  case "DELETE_INVITE":
    return {
      ...state,
      isLoading: true,
      isComplete: false,
      data: state.data.filter(invite => invite.uuid !== action.payload.id)
    };
  case "DELETE_INVITE_SUCCESS":
    return {
      ...state,
      isLoading: false,
      isComplete: true
    };
  default:
    return state;
  }
};

export default inviteReducer;
