const Theme = {
  // Brand Colors
  colorBrandRed: "#DA4453",
  colorBrandRedLt: "#ED5565",
  colorBrandBlue: "#4A89DC",
  colorBrandBlueLt: "#5D9CEC",
  colorBrandGreen: "#37BC9B",
  colorBrandGreenLt: "#48CFAD",
  colorBrandYellow: "#F6BB42",
  colorBrandPurple: "#967ADC",

  // Greys
  colorGreyDark: "#434A54",
  colorGreyDarkLt: "#656D78",
  colorGreyMid: "#AAB2BD",
  colorGreyMidLt: "#CCD1D9",
  colorGreyLight: "#E6E9ED",
  colorGreyLightLt: "#F5F7FA",

  // system
  colorSysRed: "#BF263C",
  colorSysOrange: "#E9573F",
  colorSysGreen: "#8CC152",
  colorSysBlue: "#3BAFDA",
  colorSysPurple: "#6A50A7",
  colorSysBrown: "#7B7163",
  colorSysYellow: "#E0C341",
  colorSysWhite: "#ffffff",

  // curriculum
  colorArt: "#967ADC",
  colorPsed: "#7B7163",
  colorComms: "#FC6E51",
  colorPhydev: "#4FC1E9",
  colorLit: "#8CC152",
  colorMaths: "#D8334A",
  colorWorld: "#E0C341",

  // ------------------------------------------------
  // Fonts-------------------------------------------
  // ------------------------------------------------

  fontPrimary: '"Lato", "Helvetica Neue", Helvetica, Arial, sans-serif',

  // ------------------------------------------------
  // Breakpoints-------------------------------------
  // ------------------------------------------------ Breakpoints
  bpXsmall: "20rem", //320px
  bpSmall: "30rem", //480px
  bpMedium: "46.88rem", //750px
  bpLarge: "60rem", // 960px
  bpXlarge: "80rem", // 1280px

  // avatar sizes
  avaTiny: "20px",
  avaSmall: "30px",
  avaMedium: "40px",
  avaLarge: "60px",
  avaHuge: "100px"
};

export default Theme;
