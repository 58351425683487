import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import Input from "./Input";
import { required } from "./Validators";

let SettingTab = ({ handleUpdateUser, user, handleSubmit }) => (
  <div>
    <section className="form-section form-section--title">
      <h2 className="form-section__title">Your Setting</h2>
      <p className="form-section__intro">Here you can view edit and update your setting details.</p>
    </section>
    <section className="form-section">
      <div className="standard-inputs">
        <div className="standard-input standard-input--lg">
          <label htmlFor="">Your Setting name</label>
          <p>
            The name of your setting. This is normally your trading name but you can use anything you’d like. This will
            be displayed in the header of the app.
          </p>
          <div className="standard-input standard-input--lg">
            <Field
              name="setting"
              targetName="setting"
              type="text"
              value=""
              label="Setting name"
              component={Input}
              validate={required}
              placeholder="Setting name"
            />
          </div>
        </div>
      </div>
    </section>
    <footer className="form-footer">
      <div className="form-footer__btns">
        {user.isLoading ? (
          <a href={true} className="btn btn--disabled btn--spinner btn--lg btn--mob-fullw">
            Save changes
          </a>
        ) : (
          <a
            href={true}
            className="btn btn--green btn--lg btn--mob-fullw"
            onClick={handleSubmit(values =>
              handleUpdateUser({
                ...values,
                setting_uuid: user.data.school.uuid,
                uuid: user.data.uuid
              })
            )}>
            Save changes
          </a>
        )}
      </div>
    </footer>
  </div>
);

SettingTab = reduxForm({
  form: "setting"
})(SettingTab);

SettingTab = connect(state => ({
  initialValues: {
    setting: state.user.data.school.name
  }
}))(SettingTab);

export default SettingTab;
