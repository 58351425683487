import React from "react";

const ChildProfileSEN = ({sen}) => (
   
    <div className='grid__cell'>
        <div className='dataItem'>
            <div className='dataItem__label'>
                Special educational needs?
            </div>
            <div className='dataItem__data'>
                {
                    sen === 1 ? "Yes" : "No"
                }
            </div>
        </div>
    </div>
    
);

export default ChildProfileSEN;
