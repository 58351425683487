import { PropTypes } from "prop-types";
import React from "react";

const CommentActions = ({
  isAdmin,
  ownComment,
  isEditing,
  commentId,
  createdAt,
  handleDeleteComment,
  handleSetEditComment
}) => {
  if (ownComment) {
    return (
      <div className="actions">
        <div className="author-controls">
          {isEditing ? (
            <a href={true} onClick={handleSetEditComment} data-commentid={commentId}>
              Cancel
            </a>
          ) : (
            <a href={true} onClick={handleSetEditComment} data-commentid={commentId}>
              Edit
            </a>
          )}
          <a href={true} onClick={handleDeleteComment} data-commentid={commentId}>
            Delete
          </a>
        </div>
        <div className="time-stamp">{createdAt}</div>
      </div>
    );
  } else if (isAdmin) {
    return (
      <div className="actions">
        <div className="author-controls">
          <a href={true} onClick={handleDeleteComment} data-commentid={commentId}>
            Delete
          </a>
        </div>
        <div className="time-stamp">{createdAt}</div>
      </div>
    );
  } else {
    return (
      <div className="actions">
        <div className="author-controls" />
        <div className="time-stamp">{createdAt}</div>
      </div>
    );
  }
};

CommentActions.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  ownComment: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  commentId: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  handleDeleteComment: PropTypes.func.isRequired,
  handleSetEditComment: PropTypes.func.isRequired
};

export default CommentActions;
