import React from "react";

const InputError = ({errorMessage}) => (
    <div className="input-msg input-msg--error">
        {errorMessage
            ? errorMessage
            : ""}
    </div>
);

export default InputError;
