import { FETCH_OBSERVATIONS, FILTER_OBSERVATIONS } from "./actionTypes";

export function getObservations() {
  return {
    type: FETCH_OBSERVATIONS,
    payload: {
      request: {
        url: "/observations",
        method: "GET"
      }
    }
  };
}

export function filterObservations(observations, state) {
  let filteredObservations = Object.keys(observations.observations).map(observation => {
    let currentObs = observations.observations[observation];
    let child = observations.children[currentObs.child];
    let keyWorker = observations.key_person[currentObs.key_person];

    let shouldReturn = false;
    if (state.nameTerm === "" && state.workerTerm === "" && state.statusTerm === "") {
      return currentObs;
    }

    if (child !== null && state.nameTerm !== "") {
      const childName = child.first_name + " " + child.last_name;
      if (childName.toLowerCase().match(state.nameTerm.toLowerCase())) {
        shouldReturn = true;
      }
    }
    if (state.workerTerm !== "") {
      if (keyWorker.uuid.match(state.workerTerm)) {
        shouldReturn = true;
      }
    }
    if (state.statusTerm !== "") {
      if (currentObs.status.match(state.statusTerm)) {
        shouldReturn = true;
      }
    }

    if (shouldReturn === true) {
      return currentObs;
    } else {
      return null;
    }
  });

  return {
    type: FILTER_OBSERVATIONS,
    payload: {
      data: filteredObservations
    }
  };
}
