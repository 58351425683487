import React from "react";
import { Provider } from "react-redux";
import { IndexRoute, Route, Router } from "react-router";
import App from "./components/App";
import ChildrenList from "./components/ChildrenList";
import ErrorBoundary from "./components/ErrorBoundary";
import LoggedOut from "./components/LoggedOut";
import Logout from "./components/Logout";
import NotFound from "./components/NotFound";
import ProcessInvite from "./components/ProcessInvite";
import Welcome from "./components/Welcome";
import ChildEditContainer from "./containers/ChildEditContainer";
// Containers
import ChildrenContainer from "./containers/ChildrenContainer";
import CreateChildrenContainer from "./containers/CreateChildrenContainer";
import CreateObservationsContainer from "./containers/CreateObservationsContainer";
import CreateWorkerContainer from "./containers/CreateWorkerContainer";
import ForgotPasswordContainer from "./containers/ForgotPasswordContainer";
import LoginContainer from "./containers/LoginContainer";
import ObservationEditContainer from "./containers/ObservationEditContainer";
import ObservationsContainer from "./containers/ObservationsContainer";
import OnboardingContainer from "./containers/OnboardingContainer";
import RegisterContainer from "./containers/RegisterContainer";
import ResetPasswordContainer from "./containers/ResetPasswordContainer";
import SettingsContainer from "./containers/SettingsContainer";
import SingleChildContainer from "./containers/SingleChildContainer";
import SingleObservationContainer from "./containers/SingleObservationContainer";
import UserProfileContainer from "./containers/UserProfileContainer";
import UserProfileEditContainer from "./containers/UserProfileEditContainer";
import AccountLockedContainer from "./containers/AccountLockedContainer";
import NewsfeedContainer from "./containers/NewsfeedContainer";
// Authorisation
import { RouterAdmin, RouterWorker } from "./roles";
import store, { history } from "./store";

const Routes = () => (
  <ErrorBoundary>
    <Provider store={store}>
      <Router history={history}>
        <Route component={LoggedOut}>
          <Route path="register" component={RegisterContainer} />
          <Route path="login" component={LoginContainer} />
          <Route path="password/forgot" component={ForgotPasswordContainer} />
          <Route path="password/reset" component={ResetPasswordContainer} />
          <Route path="welcome/:inviteId" component={OnboardingContainer}>
            <IndexRoute component={Welcome} />
            <Route path="onboarding" component={ProcessInvite} />
          </Route>
          <Route path="logout" component={Logout} />
        </Route>
        <Route path="/" component={App}>
          <IndexRoute component={NewsfeedContainer} />
          <Route
            path="user/:userId"
            component={RouterWorker(UserProfileContainer)}
          />
          <Route
            path="user/:userId/edit"
            component={RouterAdmin(UserProfileEditContainer)}
          />
          <Route
            path="children/create"
            component={RouterAdmin(CreateChildrenContainer)}
          />
          <Route path="children" component={RouterWorker(ChildrenContainer)}>
            <IndexRoute component={ChildrenList} />
          </Route>
          <Route
            path="children/:childId"
            component={RouterWorker(SingleChildContainer)}
          />
          <Route
            path="children/:childId/edit"
            component={RouterAdmin(ChildEditContainer)}
          />
          <Route
            path="workers/add"
            component={RouterAdmin(CreateWorkerContainer)}
          />
          <Route
            path="observations"
            component={RouterWorker(ObservationsContainer)}
          />
          <Route
            path="observations/create"
            component={RouterWorker(CreateObservationsContainer)}
          />
          <Route
            path="observations/:observationId"
            component={RouterWorker(SingleObservationContainer)}
          />
          <Route
            path="observations/:observationId/edit"
            component={RouterWorker(ObservationEditContainer)}
          />
          <Route path="settings" component={SettingsContainer} />
          <Route path="locked" component={AccountLockedContainer} />
        </Route>
        <Route path="*" component={NotFound} />
      </Router>
    </Provider>
  </ErrorBoundary>
);

export default Routes;
