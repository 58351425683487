import { normalize } from "normalizr";
import { children } from "../schemas";

/* eslint-disable no-unreachable */
const initialState = {
  isLoading: true,
  isComplete: false,
  error: false,
  display: null,
  data: null
};

const childrenReducer = (state = initialState, action) => {
  switch (action.type) {
  case "FETCH_CHILDREN":
    return {
      ...state,
      isLoading: true,
      isComplete: false
    };
  case "FETCH_CHILDREN_SUCCESS":
    return {
      ...state,
      isComplete: true,
      isLoading: false,
      data: normalize(action.payload.data.children, children),
      display: normalize(action.payload.data.children, children)
    };
  case "FETCH_CHILDREN_FAIL":
    return {
      ...state,
      isComplete: true,
      isLoading: false,
      error: true
    };
    break;
  case "FILTER_CHILDREN":
    return {
      ...state,
      display: normalize(action.payload.data, children)
    };
  case "SORT_CHILDREN":
    return {
      ...state,
      display: normalize(action.payload.data, children)
    };
  default:
    break;
  }
  return state;
};

export default childrenReducer;
