import { FETCH_CHILDREN,FILTER_CHILDREN,SORT_CHILDREN } from "./actionTypes";

export function getChildren() {
  return {
    type: FETCH_CHILDREN,
    payload: {
      request: {
        method: "GET",
        url: "children"
      }
    }
  };
}

export function filterChildren(childMap, children, state) {
  const filteredChildren = children.map(child => {
    let shouldReturn = false;
    if (state.nameTerm === "" && state.workerTerm === "") {
      return childMap[child];
    }

    if (state.nameTerm !== "") {
      const childName = `${childMap[child].first_name} ${childMap[child].last_name}`;
      if (childName.toLowerCase().match(state.nameTerm.toLowerCase())) {
        shouldReturn = true;
      }
    }
    if (state.workerTerm !== "") {
      if (childMap[child].key_person !== null) {
        if (childMap[child].key_person.uuid.match(state.workerTerm)) {
          shouldReturn = true;
        }
      }
    }

    if (shouldReturn === true) {
      return childMap[child];
    } else {
      return null;
    }
  });

  return {
    type: FILTER_CHILDREN,
    payload: {
      data: filteredChildren.filter(child => typeof child !== "undefined")
    }
  };
}

export function sortChildren(childMap, state) {
  let sortedChildren = [];
  if (state.sortDirection === "") {
    sortedChildren = Object.keys(childMap).sort((a, b) => {
      const dateA = new Date(childMap[a].created_at.date);
      const dateB = new Date(childMap[b].created_at.date);
      return dateA - dateB;
    });
  } else {
    switch (state.sortType) {
    case "name":
      sortedChildren = Object.keys(childMap).sort((a, b) => {
        const nameA = childMap[a].last_name.toLowerCase();
        const nameB = childMap[b].last_name.toLowerCase();
        switch (state.sortDirection) {
        case "asc":
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        case "desc":
          if (nameA < nameB) {
            return 1;
          }
          if (nameA > nameB) {
            return -1;
          }
          return 0;
        default:
          return 0;
        }
      });
      break;
    case "worker":
      sortedChildren = Object.keys(childMap).sort((a, b) => {
        if (childMap[a].key_person === null || childMap[b].key_person === null) {
          return 0;
        }
        const nameA = childMap[a].key_person.last_name.toLowerCase();
        const nameB = childMap[b].key_person.last_name.toLowerCase();
        switch (state.sortDirection) {
        case "asc":
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        case "desc":
          if (nameA < nameB) {
            return 1;
          }
          if (nameA > nameB) {
            return -1;
          }
          return 0;
        default:
          return 0;
        }
      });
      break;
    case "age":
      sortedChildren = Object.keys(childMap).sort((a, b) => {
        switch (state.sortDirection) {
        case "asc":
          return childMap[a].age - childMap[b].age;
        case "desc":
          return childMap[b].age - childMap[a].age;
        default:
          return 0;
        }
      });
      break;
    default:
      return false;
    }
  }

  const children = [];

  sortedChildren.forEach(child => {
    children.push(childMap[child]);
  });

  return {
    type: SORT_CHILDREN,
    payload: {
      data: children
    }
  };
}
