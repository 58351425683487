const initialState = {
  isLoading: false,
  error: false,
  isComplete: false,
  showThanks: false,
  data: null,
  resendComplete: false
};

const registerReducer = (state = initialState, action) => {
  switch (action.type) {
  case "SEND_FORM":
    return {
      ...state,
      isLoading: true,
      isComplete: false
    };
  case "SEND_FORM_SUCCESS":
    return {
      ...state,
      data: action.payload.data,
      isLoading: false,
      isComplete: true,
      showThanks: true
    };
  case "SEND_FORM_FAIL":
    return {
      ...state,
      isLoading: false,
      error: true,
      isComplete: true
    };
  case "RESEND_INVITE":
    return {
      ...state,
      isLoading: true,
      isComplete: false
    };
  case "RESEND_INVITE_SUCCESS":
    return {
      ...state,
      isLoading: false,
      isComplete: true,
      resendComplete: true
    };
  case "RESEND_INVITE_FAIL":
    return {
      ...state,
      isLoading: false,
      isComplete: true,
      error: true
    };
  case "EDIT_REGISTRATION_EMAIL":
    return {
      ...state,
      showThanks: false,
      isLoading: false,
      isComplete: false
    };
  default:
    return state;
  }
};

export default registerReducer;
