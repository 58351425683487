import React from "react";

const LinkNextStepModal = ({ toggleModal }) => (
  <div className="modal modal-open">
    <div className="modal__content">
      <div className="modal__header">
        <h2>Link Next Step</h2>
        <a href={true} className="btn-cancel" onClick={toggleModal}>
          Cancel
        </a>
      </div>
      <div className="modal__main">
        <div className="select-list__child-header">
          <div className="avatar avatar--small">
            <img alt="avatar" src="images/users/child_boy_02.jpg" />
          </div>
          <div className="select-list__child-header--text">
            <div className="child-header__name">Dave Smith</div>
            <div className="child-header__helpertxt">Open next steps for Dave</div>
          </div>
        </div>
        <ul className="select-list select-list--next-steps">
          <li className="select-list--next-step">
            <div className="select-list--next-step__content">
              <div className="select-list--next-step__desc">
                Use different materials to create different sounds. For example rain, thunder etc
              </div>
              <div className="select-list--next-step__datePerson">29-12-17 by Sarah Jane</div>
            </div>
            <div className="selected tick" />
          </li>
          <li className="select-list--next-step">
            <div className="select-list--next-step__content">
              <div className="select-list--next-step__desc">
                Use different materials to create different sounds. For example rain, thunder etc
              </div>
              <div className="select-list--next-step__datePerson">29-12-17 by Sarah Jane</div>
            </div>
            <div className="selected" />
          </li>
        </ul>
        <div className="select-list__child-header">
          <div className="avatar avatar--small">
            <img alt="avatar" src="images/users/child_girl_02.jpg" />
          </div>
          <div className="select-list__child-header--text">
            <div className="child-header__name">Sally Smith</div>
            <div className="child-header__helpertxt">Open next steps for Sally</div>
          </div>
        </div>
        <ul className="select-list select-list--next-steps">
          <li className="select-list--next-step">
            <div className="select-list--next-step__content">
              <div className="select-list--next-step__desc">
                Use different materials to create different sounds. For example rain, thunder etc
              </div>
              <div className="select-list--next-step__datePerson">29-12-17 by Sarah Jane</div>
            </div>
            <div className="selected tick" />
          </li>
        </ul>
      </div>
      <div className="modal__footer">
        <div className="modal__footer__controls">
          <a href={true} className="btn btn--green btn--sm btn--mob-fullw">
            Link Next Steps
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default LinkNextStepModal;
