import PropTypes from "prop-types";
import React from "react";
import DefaultUserAvatar from "../images/assets/DefaultUserAvatar.svg";

const AccountLockedWorker = ({ workers }) => {
  return (
    <div>
      <div className="header-local">
        <h1 className="header-local__title header-local__title--frozen">Account Frozen</h1>
      </div>

      <div className="pref-section">
        <h3>This Edna account is frozen.</h3>
        <p>This means you cannot add, edit or view children, observations or users.</p>
        <p>
          This account if frozen either because the free trial has ended, the subscription has been cancelled by a
          super-admin or the last payment has failed.
        </p>

        <h3>How to un-freeze the account</h3>
        <p>
          Super-admin privileges are needed to unfreeze this account. A user with super-admin privileges needs to log
          in, select a subscription plan and add a payment method. Below is a list of super-admin users for this
          account.
        </p>
      </div>

      <div className="pref-section">
        <h3>Super Admins</h3>
        <ul className="data-list data-list--users">
          {workers.map(worker => {
            if (worker.role === "Super Admin") {
              return (
                <li className="data-list__item data-list--users__item">
                  <div className="data-list__item__col data-list--users__name">
                    <div className="person person--primary">
                      <div className="avatar avatar--small">
                        {worker.picture !== null ? (
                          <img src={worker.picture} alt="" />
                        ) : (
                          <img src={DefaultUserAvatar} alt="default" />
                        )}
                      </div>
                      <div className="person__name">
                        {worker.first_name}&nbsp;{worker.last_name}
                      </div>
                    </div>
                  </div>
                  <div className="data-list__item__col">{worker.email}</div>
                </li>
              );
            } else {
              return null;
            }
          })}
        </ul>
      </div>
    </div>
  );
};

AccountLockedWorker.propTypes = {
  workers: PropTypes.arrayOf(
    PropTypes.shape({
      picture: PropTypes.string,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired
    })
  ).isRequired
};

export default AccountLockedWorker;
