import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import * as settingActions from '../actions/settingActions';
import { resetAvatarState } from '../actions/cloudinaryActions';
import { getInvites, deleteInvite } from '../actions/inviteActions';
import * as FlashActions from '../actions/flashActions';
import { getUser, updateUser } from '../actions/userActions';
import { getWorkers } from '../actions/workerActions';
import { resendInvite } from '../actions/registerActions';
import ListHeader from '../components/ListHeader';
import Tab from '../components/Tab';
import ProfileTab from '../components/ProfileTab';
import SettingTab from '../components/SettingTab';
import UsersTab from '../components/UsersTab';
import BillingTab from '../components/BillingTab';
import CarersTab from '../components/CarersTab';
import Loader from '../components/Loader';
import HasPermission from './HasPermission';
import { getRoles } from '../actions/addWorkerActions';
import { getCarers } from '../actions/carerActions';
import * as BillingActions from '../actions/BillingActions';

class SettingsContainer extends React.Component {
  state = {
    tabs: {
      activeTab: 'profile',
    },
    contextOpen: null,
    paymentContextOpen: null,
    cancelModalOpen: false,
    plan: null,
    updatePlanModalOpen: false,
    planChanged: false,
    newPlan: null,
    modalLoading: false,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    const {
      getUser,
      getWorkers,
      getInvites,
      getRoles,
      getSub,
      getCustomer,
      getInvoices,
      getPlans,
      getCarers,
      user,
    } = this.props;
    getUser(user.data.uuid).then(() => {
      if (
        user.data.role.name !== 'worker' &&
        user.data.role.name !== 'parent'
      ) {
        getWorkers().then(() => {
          getInvites().then(() => {
            getRoles().then(() => {
              getPlans().then(() => {
                getCarers();
              });
            });
          });
        });

        if (user.data.school.subscriptions.length > 0) {
          getSub(user.data.school.subscriptions[0].stripe_id)
            .then(response => {
              getCustomer(response.payload.data.customer);
              getInvoices(response.payload.data.customer);
            })
            .catch(response => {
              console.log(response);
            });
        }
      }
    });
  }

  componentWillUnmount() {
    this.props.resetAvatarState();
  }

  switchTab = event => {
    this.setState({
      tabs: {
        activeTab: event.target.name,
      },
    });
    window.scrollTo(0, 0);
  };

  handleToggleContextMenu = event => {
    const { contextOpen } = this.state;
    if (contextOpen) {
      this.setState({ contextOpen: null });
    } else {
      this.setState({ contextOpen: event.target.id });
    }
  };

  handlePaymentContextToggle = event => {
    const { paymentContextOpen } = this.state;
    if (paymentContextOpen) {
      this.setState({ paymentContextOpen: null });
    } else {
      this.setState({ paymentContextOpen: event.target.id });
    }
  };

  handleDeleteCard = event => {
    this.props
      .removeCard(this.props.billing.customer.id, event.target.id)
      .then(() => {
        if (this.props.user.data.school.subscriptions.length > 0) {
          this.props
            .getSub(this.props.user.data.school.subscriptions[0].stripe_id)
            .then(response => {
              this.props.getCustomer(response.payload.data.customer);
              this.props.getInvoices(response.payload.data.customer);
            });
        }
      })
      .then(() => {
        this.props.addMessageToFlash(
          'Card removed successfully',
          'success',
          null
        );
      });
  };

  handleMakeCardDefault = event => {
    event.preventDefault();
    this.props
      .makeDefaultCard(this.props.billing.customer.id, event.target.id)
      .then(() => {
        if (this.props.user.data.school.subscriptions.length > 0) {
          this.props
            .getSub(this.props.user.data.school.subscriptions[0].stripe_id)
            .then(response => {
              this.props.getCustomer(response.payload.data.customer);
              this.props.getInvoices(response.payload.data.customer);
            })
            .then(() => {
              this.props.addMessageToFlash(
                'Default Card changed',
                'success',
                null
              );
            });
        }
      })
      .catch(response => {
        this.props.addMessageToFlash(
          response.payload.error.message,
          'error',
          'null'
        );
      });
  };

  handleUpdateUser = values => {
    this.props.clearFlashMessages();
    if (typeof values.avatar !== 'undefined' && values.avatar.data !== null) {
      values.avatar_url = values.avatar.data.secure_url;
      delete values.avatar;
    }
    this.props.updateUser(values).then(response => {
      if (response.type === 'UPDATE_USER_SUCCESS') {
        this.props.addMessageToFlash(
          'Profile successfully updated!',
          'success',
          'normal'
        );
      }
      this.props.resetAvatarState();
    });
  };

  handleResendEmail = uuid => {
    this.props.resendInvite(uuid).then(() => {
      this.props.addMessageToFlash(
        'Invite has been resent',
        'success',
        'normal'
      );
    });
    this.handleToggleContextMenu();
  };

  handleDelete = uuid => {
    this.props.deleteInvite(uuid).then(() => {
      this.props.addMessageToFlash(
        'Invite was successfully deleted',
        'success',
        null
      );
    });
  };

  sendTokenToServer = token => {
    this.props
      .sendToken(token, this.state.plan, this.props.user.data.school.uuid)
      .then(() => {
        if (this.props.user.data.school.subscriptions.length > 0) {
          this.props
            .getSub(this.props.user.data.school.subscriptions[0].stripe_id)
            .then(response => {
              this.props.getCustomer(response.payload.data.customer);
              this.props.getInvoices(response.payload.data.customer);
            });
        }
      });
  };

  handleChangePlan = event => {
    this.setState({ plan: event.target.value });
  };

  handleUpdatePlan = () => {
    this.setState({ modalLoading: true });
    this.props
      .updatePlan(this.state.newPlan.id, this.props.user.data.school.uuid)
      .then(response => {
        const { subscriptions } = response.payload.data;
        this.setState({ planChanged: true, modalLoading: false });
        this.props.getSub(subscriptions[0].stripe_id).then(response => {
          this.setState({ newPlan: null });
        });
      });
  };

  handlePlanUpdateState = event => {
    const { billing } = this.props;
    this.setState({
      newPlan: billing.plans.filter(x => x.id === event.target.value)[0],
    });
  };

  handleResumePlan = event => {
    event.preventDefault();
    this.props.resumePlan(this.props.user.data.school.uuid).then(() => {
      this.props.addMessageToFlash(
        'Hooray! Subscription resumed successfully!',
        'success',
        'null'
      );
      if (this.props.user.data.school.subscriptions.length > 0) {
        this.props
          .getSub(this.props.user.data.school.subscriptions[0].stripe_id)
          .then(response => {
            this.props.getCustomer(response.payload.data.customer);
            this.props.getInvoices(response.payload.data.customer);
          });
      }
    });
  };

  handlePlanUpdateModal = event => {
    event.preventDefault();
    this.setState({ updatePlanModalOpen: true });
  };

  handleToggleModal = () => {
    this.setState({
      updatePlanModalOpen: !this.state.updatePlanModalOpen,
      planChanged: false,
      modalLoading: false,
    });
  };

  handleToggleCancelModal = e => {
    e.preventDefault();
    this.setState({
      cancelModalOpen: !this.state.cancelModalOpen,
    });
  };

  handleCancelConfirmationChange = e => {
    this.props.clearFlashMessages();
    this.setState({ cancelConfirmation: e.target.value });
  };

  handleCancelPlan = e => {
    e.preventDefault();
    if (this.state.cancelConfirmation !== 'Cancel') {
      this.props.addMessageToFlash(
        'The cancel confirmation does not match',
        'error',
        null
      );
    } else {
      this.props.cancelPlan(this.props.user.data.school.uuid).then(() => {
        this.props.addMessageToFlash('You have unsubscribed', 'success', null);
        if (this.props.user.data.school.subscriptions.length > 0) {
          this.props
            .getSub(this.props.user.data.school.subscriptions[0].stripe_id)
            .then(response => {
              this.props.getCustomer(response.payload.data.customer);
              this.props.getInvoices(response.payload.data.customer);
            });
        }
      });
      this.setState({ cancelModalOpen: false });
    }
  };

  addCard = token => {
    this.props
      .addBillingCard(token, this.props.user.data.school.uuid)
      .then(() => {
        if (this.props.user.data.school.subscriptions.length > 0) {
          this.props
            .getSub(this.props.user.data.school.subscriptions[0].stripe_id)
            .then(response => {
              this.props.getCustomer(response.payload.data.customer);
              this.props.getInvoices(response.payload.data.customer);
            })
            .then(() => {
              this.props.addMessageToFlash(
                'Card added successfully',
                'success',
                null
              );
            });
        }
      });
  };

  render() {
    let TabOutput = '';
    let superAdmins = null;
    const {
      singleUser,
      invites,
      workers,
      addWorker,
      avatar,
      user,
      billing,
      carers,
    } = this.props;
    if (workers.data !== null) {
      superAdmins = workers.data.filter(x => x.role === 'Super Admin').length;
    }
    if (user.data.role.name === 'worker' && singleUser.isLoading) {
      TabOutput = <Loader />;
    } else if (user.data.role.name === 'parent' && singleUser.isLoading) {
      TabOutput = <Loader />;
    } else if (
      user.data.role.name !== 'worker' &&
      user.data.role.name !== 'parent' &&
      (singleUser.isLoading ||
        invites.isLoading ||
        workers.isLoading ||
        addWorker.isLoading ||
        billing.isLoading ||
        carers.isLoading)
    ) {
      TabOutput = <Loader />;
    } else {
      switch (this.state.tabs.activeTab) {
        case 'profile':
          TabOutput = (
            <ProfileTab
              user={singleUser}
              handleUpdateUser={this.handleUpdateUser}
              avatar={avatar}
              currentAvatar={singleUser.data.person.picture}
              currentUser={user.data}
              roles={addWorker.roles}
              superadmins={superAdmins}
            />
          );
          break;
        case 'setting':
          TabOutput = (
            <SettingTab
              user={singleUser}
              handleUpdateUser={this.handleUpdateUser}
            />
          );
          break;
        case 'users':
          TabOutput = (
            <UsersTab
              workers={workers.data}
              invites={invites.data}
              handleToggleContextMenu={this.handleToggleContextMenu}
              contextOpen={this.state.contextOpen}
              handleResendEmail={this.handleResendEmail}
              deleteOpen={this.state.deleteModalOpen}
              handleDelete={this.handleDelete}
            />
          );
          break;
        case 'carers':
          TabOutput = (
            <CarersTab
              carers={carers.data.entities.carers}
              carerMap={carers.data.result}
              invites={invites.data}
              handleToggleContextMenu={this.handleToggleContextMenu}
              contextOpen={this.state.contextOpen}
              handleResendEmail={this.handleResendEmail}
              deleteOpen={this.state.deleteModalOpen}
              handleDelete={this.handleDelete}
            />
          );
          break;
        case 'billing':
          TabOutput = (
            <BillingTab
              user={user.data}
              trial={moment(
                user.data.school.trial_ends_at,
                'YYYY-MM-DD HH:mm:ss'
              )}
              sendTokenToServer={this.sendTokenToServer}
              handleChangePlan={this.handleChangePlan}
              subscription={billing.subscription}
              customer={billing.customer}
              invoices={billing.invoices}
              plans={billing.plans}
              billingLoading={billing.isLoading}
              childCount={singleUser.data.childCount}
              handleUpdatePlan={this.handleUpdatePlan}
              handlePlanUpdateState={this.handlePlanUpdateState}
              handlePlanUpdateModal={this.handlePlanUpdateModal}
              newPlan={this.state.newPlan}
              updatePlanModalOpen={this.state.updatePlanModalOpen}
              toggleModal={this.handleToggleModal}
              planChanged={this.state.planChanged}
              loading={this.state.modalLoading}
              toggleCancelModal={this.handleToggleCancelModal}
              cancelModalOpen={this.state.cancelModalOpen}
              handleCancelPlan={this.handleCancelPlan}
              handleCancelConfirmationChange={
                this.handleCancelConfirmationChange
              }
              handleResumePlan={this.handleResumePlan}
              paymentContextOpen={this.state.paymentContextOpen}
              handlePaymentContextToggle={this.handlePaymentContextToggle}
              handleMakeCardDefault={this.handleMakeCardDefault}
              handleDeleteCard={this.handleDeleteCard}
              addBillingCard={this.addCard}
            />
          );
          break;
        default:
          TabOutput = '';
          break;
      }
    }
    return (
      <div>
        <ListHeader title="Preferences" icon="settings" />
        <nav className="nav-inline nav-local nav-local--greybg ">
          <ul>
            <Tab
              isActive={this.state.tabs.activeTab === 'profile'}
              tabName="profile"
              tabLabel="Profile"
              handleSwitchTab={this.switchTab}
            />
            <HasPermission allowedRoles={['super_admin']}>
              <Tab
                isActive={this.state.tabs.activeTab === 'setting'}
                tabName="setting"
                tabLabel="Setting"
                handleSwitchTab={this.switchTab}
              />
            </HasPermission>
            <HasPermission allowedRoles={['super_admin', 'admin']}>
              <Tab
                isActive={this.state.tabs.activeTab === 'users'}
                tabName="users"
                tabLabel="Users"
                handleSwitchTab={this.switchTab}
              />
            </HasPermission>
            <HasPermission allowedRoles={['super_admin', 'admin']}>
              <Tab
                isActive={this.state.tabs.activeTab === 'carers'}
                tabName="carers"
                tabLabel="Parents / Carers"
                handleSwitchTab={this.switchTab}
              />
            </HasPermission>
            <HasPermission allowedRoles={['super_admin']}>
              <Tab
                isActive={this.state.tabs.activeTab === 'billing'}
                tabName="billing"
                tabLabel="Billing"
                handleSwitchTab={this.switchTab}
              />
            </HasPermission>
          </ul>
        </nav>

        {TabOutput}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  settings: state.settings,
  user: state.user,
  singleUser: state.singleUser,
  avatar: state.avatar,
  workers: state.workers,
  invites: state.invites,
  addWorker: state.addWorker,
  billing: state.billing,
  carers: state.carers,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...settingActions,
      ...FlashActions,
      getUser,
      updateUser,
      getWorkers,
      getInvites,
      resendInvite,
      deleteInvite,
      resetAvatarState,
      getRoles,
      getCarers,
      ...BillingActions,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SettingsContainer);
