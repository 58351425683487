import { EventTypes } from "redux-segment";
import { CREATE_CHILD_CANCELLED,CREATE_CHILD_CONTINUE,CREATE_CHILD_FINISH,INCREMENT_PAGE,RESET_ADD_CHILD_STATE } from "./actionTypes";

export function incrementPage(currentPage) {
  return {
    type: INCREMENT_PAGE,
    page: currentPage + 1
  };
}

export function resetAddChildState() {
  return {
    type: RESET_ADD_CHILD_STATE
  };
}

export function cancelChildAddition() {
  return {
    type: CREATE_CHILD_CANCELLED
  };
}

export function saveAndContinue(values) {
  return {
    type: CREATE_CHILD_CONTINUE,
    payload: {
      page: values.page + 1,
      request: {
        method: "POST",
        url: "children",
        data: values
      }
    }
  };
}

export function saveAndFinish(values, userId) {
  return {
    type: CREATE_CHILD_FINISH,
    payload: {
      request: {
        method: "POST",
        url: "children",
        data: values
      }
    },
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: "Child Created"
        },
        options: {
          userId: userId
        }
      }
    }
  };
}
