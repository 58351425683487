/* eslint-disable no-unreachable */
const initialState = {
  data: null,
  isLoading: false,
  isComplete: false
};

const avatarReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPLOAD_AVATAR":
      return {
        ...state,
        isLoading: true,
        isComplete: false
      };
    case "UPLOAD_AVATAR_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isComplete: true,
        data: action.payload.data
      };
    case "UPLOAD_AVATAR_FAIL":
      return {
        ...state,
        isLoading: false,
        isComplete: false,
        error: true
      };
    case "RESET_AVATAR":
      return {
        ...state,
        data: null,
        isLoading: false,
        isComplete: false
      };
    default:
      return state;
      break;
  }
};

export default avatarReducer;
