import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { browserHistory } from "react-router";
import * as ChildEditActions from "../actions/singleChildActions";
import { resetAvatarState } from "../actions/cloudinaryActions";
import * as WorkerActions from "../actions/workerActions";
import * as FlashActions from "../actions/flashActions";
import ChildEdit from "../components/ChildEdit";
import Loader from "../components/Loader";

class ChildEditContainer extends React.Component {
  constructor(props) {
    super(props);
    this.handleSwitchTab = this.handleSwitchTab.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  state = {
    activeTab: "basic"
  };

  componentWillMount() {
    this.props.getChild(this.props.params.childId).then(() => {
      this.props.getWorkers();
    });
  }

  componentWillUnmount() {
    this.props.resetAvatarState();
  }

  handleSwitchTab = tab => {
    this.setState({
      activeTab: tab
    });
  };

  handleFormSubmit = values => {
    if (this.props.avatar.data !== null) {
      values.avatar = this.props.avatar.data.secure_url;
    }

    this.props
      .updateChild(values)
      .then(() => {
        this.props.addMessageToFlash(
          "Success! The child's profile has been edited",
          "success",
          "normal"
        );
      })
      .then(() => {
        this.props.resetAvatarState();
      })
      .then(() => {
        browserHistory.replace(`children/${this.props.child.data.uuid}`);
      });
  };

  handleFormCancel = () => {
    browserHistory.replace("/children");
  };

  render() {
    return (
      <div>
        {this.props.child.data !== null && this.props.workers.data !== null ? (
          <ChildEdit
            child={this.props.child.data}
            activeTab={this.state.activeTab}
            handleSwitchTab={this.handleSwitchTab}
            handleFormSubmit={this.handleFormSubmit}
            handleFormCancel={this.handleFormCancel}
            avatar={this.props.avatar}
            user={this.props.user.data}
            workers={this.props.workers.data}
          />
        ) : (
          <Loader />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  child: state.child,
  avatar: state.avatar,
  user: state.user,
  workers: state.workers
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...ChildEditActions,
      ...WorkerActions,
      ...FlashActions,
      resetAvatarState
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ChildEditContainer);
