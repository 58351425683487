import React from "react";

const ChildProfilePreferredName = ({preferredname}) => (
   
    <div className='grid__cell'>
        <div className='dataItem'>
            <div className='dataItem__label'>
            Preferred Name
            </div>
            <div className='dataItem__data'>
                {
                    preferredname === "null" ? "N/A" : preferredname
                }
            </div>
        </div>
    </div>
    
);

export default ChildProfilePreferredName;