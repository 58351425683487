export const required = value => (value
  ? undefined
  : "This field is required");

export const maxLength = max => value => (value && value.length > max
  ? `Must be ${max} characters or less`
  : undefined);

export const minLength = min => value => (value && value.length < min
  ? `Must be ${min} characters or more`
  : undefined);

export const minLength8 = minLength(8);

export const maxLength15 = maxLength(15);

export const number = value => (value && isNaN(Number(value))
  ? "Must be a number"
  : undefined);

export const minValue = min => value => (value && value < min
  ? `Must be at least ${min}`
  : undefined);

export const email = value => (value && !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)
  ? "This field requires a valid email"
  : undefined);