import React from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { bindActionCreators } from "redux";
import * as CarerActions from "../actions/carerActions";
import { addMessageToFlash } from "../actions/flashActions";
import { deleteInvite } from "../actions/inviteActions";
import { getObservations } from "../actions/observationActions";
import { resetProgressionState } from "../actions/progressionActions";
import { resendInvite } from "../actions/registerActions";
import * as SingleChildActions from "../actions/singleChildActions";
import * as WorkerActions from "../actions/workerActions";
import ChildProfile from "../components/ChildProfile";
import Loader from "../components/Loader";

class SingleChildContainer extends React.Component {
  state = {
    contextOpen: false,
    carerContextOpen: null,
    deleteModalOpen: false,
    tab: {
      isActive: "info"
    },
    parentModalOpen: false,
    parentsLoading: []
  };

  componentDidMount() {
    this.props.getObservations();
    this.props.getChild(this.props.params.childId);
    this.props.getWorkers();
    this.props.getCarers();
    if (this.props.user.data.role.name !== "parent") {
      this.props.getCarerInvites(this.props.params.childId);
    }
  }

  componentWillUnmount() {
    this.props.resetProgressionState();
  }

  handleSwitchTab = event => {
    this.setState({
      tab: {
        isActive: event.target.name
      }
    });
  };

  handleResendInvite = uuid => {
    this.props.resendInvite(uuid).then(() => {
      this.props.addMessageToFlash("Invite has been resent", "success", "normal");
    });
    this.handleToggleContextMenu();
  };

  handleDeleteInvite = uuid => {
    this.props.deleteInvite(uuid).then(() => {
      this.props.addMessageToFlash("Invite was successfully deleted", "success", null);
    });
  };

  handleFormSubmit = values => {
    this.props.updateChild(values).then(() => {
      this.setState({ edit: false });
    });
  };

  handleToggleContextMenu = () => {
    this.setState({
      contextOpen: !this.state.contextOpen
    });
  };

  handleToggleCarerContext = event => {
    if (this.state.carerContextOpen === null || this.state.carerContextOpen !== event.target.dataset.id) {
      this.setState({ carerContextOpen: event.target.dataset.id });
    } else {
      this.setState({ carerContextOpen: null });
    }
  };

  handleToggleDeleteModal = () => {
    this.setState({
      deleteModalOpen: !this.state.deleteModalOpen
    });
  };

  handleToggleParentModal = () => {
    this.setState({
      parentModalOpen: !this.state.parentModalOpen,
      contextOpen: false
    });
  };

  handleAddParent = event => {
    let id = event.target.dataset.contextid;
    this.setState({
      parentsLoading: [...this.state.parentsLoading, id]
    });
    this.props.addCarer(this.props.child.data.uuid, id).then(() => {
      this.setState({
        parentsLoading: this.state.parentsLoading.filter(x => {
          return x !== id;
        })
      });
    });
  };

  handleRemoveParent = event => {
    let id = event.target.dataset.contextid;
    this.setState({
      parentsLoading: [...this.state.parentsLoading, id]
    });
    this.props.removeCarer(this.props.child.data.uuid, id).then(() => {
      this.setState({
        parentsLoading: this.state.parentsLoading.filter(x => {
          return x !== id;
        })
      });
    });
  };

  handleParentInvite = values => {
    values = {
      ...values,
      child: this.props.child.data.uuid,
      inviter: this.props.user.data.uuid
    };
    this.props.inviteCarer(values).then(response => {
      if (!response.error) {
        this.props.addMessageToFlash("Carer has been invited successfully!", "success", "null");
        this.handleToggleParentModal();
        this.handleToggleContextMenu();
      }
    });
  };

  handleDelete = uuid => {
    this.props
        .deleteChild(uuid)
        .then(() => {
          this.props.addMessageToFlash("Child was successfully deleted", "success", null);
        })
        .then(() => {
          browserHistory.replace("/children");
        });
  };

  handleFilterCarers = event => {
    this.props.filterCarers(this.props.carers.data.entities.carers, this.props.carers.data.result, event.target.value);
  };

  handleFlags = event => {
    event.preventDefault();
    if (this.props.user.data.role.name === "worker" || this.props.user.data.role.name === "parent") {
      this.props.addMessageToFlash("You are not authorised to perform that action", "error", "normal");
      return false;
    }
    const childId = this.props.child.data.uuid;
    const { flag, parentid } = event.target.dataset;
    this.props.setFlagOnCarer(parentid, childId, flag);
  };

  render() {
    const { child, workers, carers, user } = this.props;
    return (
      <div>
        {child.isComplete && workers.isComplete && carers.isComplete ? (
          <ChildProfile
            child={this.props.child.data}
            worker={this.props.child.data.key_person}
            handleSwitchTab={this.handleSwitchTab}
            tabs={this.state.tab}
            edit={this.state.edit}
            workers={this.props.workers.data}
            handleFormSubmit={this.handleFormSubmit}
            contextOpen={this.state.contextOpen}
            handleToggleCarerContext={this.handleToggleCarerContext}
            handleToggleContextMenu={this.handleToggleContextMenu}
            handleToggleDeleteModal={this.handleToggleDeleteModal}
            handleDelete={this.handleDelete}
            deleteOpen={this.state.deleteModalOpen}
            handleToggleParentModal={this.handleToggleParentModal}
            parentOpen={this.state.parentModalOpen}
            handleAddParent={this.handleAddParent}
            handleRemoveParent={this.handleRemoveParent}
            handleParentInvite={this.handleParentInvite}
            carers={carers.display.entities.carers}
            carerMap={carers.display.result}
            filterCarers={this.handleFilterCarers}
            handleFlags={this.handleFlags}
            parentsLoading={this.state.parentsLoading}
            carerContextOpen={this.state.carerContextOpen}
            handleResendInvite={this.handleResendInvite}
            handleDeleteInvite={this.handleDeleteInvite}
            invites={this.props.invites.data || null}
            user={user}
          />
        ) : (
          <Loader />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  child: state.child,
  workers: state.workers,
  carers: state.carers,
  user: state.user,
  invites: state.invites
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...SingleChildActions,
      ...WorkerActions,
      ...CarerActions,
      addMessageToFlash,
      resetProgressionState,
      resendInvite,
      deleteInvite,
      getObservations
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleChildContainer);
