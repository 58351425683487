import { EventTypes } from "redux-segment";
import { EDIT_REGISTRATION_EMAIL,RESEND_INVITE,SEND_FORM } from "./actionTypes";

export function sendForm(values) {
  return {
    type: SEND_FORM,
    payload: {
      request: {
        method: "POST",
        url: "register",
        data: values
      }
    },
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: "User Signup",
          email: values.email
        }
      }
    }
  };
}

export function resendInvite(uuid) {
  return {
    type: RESEND_INVITE,
    payload: {
      request: {
        url: `register/${uuid}`,
        method: "GET"
      }
    }
  };
}

export function editEmail() {
  return {
    type: EDIT_REGISTRATION_EMAIL
  };
}
