import { GET_COEL, GET_CURRICULUM } from "./actionTypes";

export function getCurriculum() {
  return {
    type: GET_CURRICULUM,
    payload: {
      request: {
        method: "GET",
        url: "categories",
      },
    },
  };
}

export function getCoel() {
  return {
    type: GET_COEL,
    payload: {
      request: {
        method: "GET",
        url: "coel",
      },
    },
  };
}
