import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import Loader from "./Loader";

const BillingHistory = ({ invoices }) => {
  if (invoices !== null && invoices.length > 0) {
    return (
      <section className="pref-section">
        <h3>Billing history</h3>
        <ul className="data-list data-list--invoices">
          <li className="data-list__item data-list__header">
            <div className="data-list__item__col data-list--invoices--date">Date</div>
            <div className="data-list__item__col data-list--invoices--invNum">Invoice number</div>
            <div className="data-list__item__col data-list--invoices--amount">Amount</div>
          </li>
          {invoices !== null && invoices.length > 0 ? (
            invoices.map(invoice => (
              <li className="data-list__item data-list--invoices__item" key={invoice.id}>
                <div className="data-list__item__col data-list--invoices--date">
                  {moment.unix(invoice.date).format("DD-MM-YY")}
                </div>
                <div className="data-list__item__col data-list--invoices--invNum">{invoice.number}</div>
                <div className="data-list__item__col data-list--invoices--amount">
                  £{(invoice.total / 100).toFixed(2)}
                </div>
              </li>
            ))
          ) : (
            <li />
          )}
        </ul>
      </section>
    );
  } else {
    return <Loader />;
  }
};

BillingHistory.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.shape({}))
};

export default BillingHistory;
