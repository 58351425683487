import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Field, Form, reduxForm } from "redux-form";
import Input from "./Input";
import RenderDatePicker from "./RenderDatePicker";

let ChildEducationEdit = ({
  child,
  user,
  workers,
  handleFormSubmit,
  handleFormCancel,
  handleSubmit,
  key_worker
}) => (
  <Form
    onSubmit={handleSubmit(values =>
      handleFormSubmit({
        ...values,
        form: "education"
      })
    )}
  >
    <Field name="uuid" type="hidden" value="" component="input" />
    <section className="form-section form-section--title">
      <h2 className="form-section__title">Education Information</h2>
      <p className="form-section__intro">
        Here you can view edit and update {child.first_name}'s education
        information such as start date, key person and timetable.
      </p>
    </section>

    <section className="form-section">
      <div className="standard-inputs">
        <div className="standard-input standard-input--lg">
          <label>Start date</label>
          <p>
            The date {child.first_name}&nbsp; is due to start at{" "}
            {user.school.name}&nbsp;or the date they started. If left blank this
            will default to today's date.
          </p>
          <Field
            name="start_date"
            targetName="start_date"
            component={RenderDatePicker}
            futureDates
          />
        </div>
      </div>
    </section>

    <section className="form-section">
      <div className="standard-inputs">
        <div className="standard-input standard-input--lg">
          <label>Key Person</label>
          <p>
            Select who will be {child.first_name}'s key person at{" "}
            {user.school.name}.
          </p>
          <Field name="key_person" component="select">
            <option disabled="disabled" hidden="" selected="selected" value="">
              Select key person...
            </option>
            {workers.map(
              (worker => {
                if (worker.active) {
                  return (
                    <option value={worker.uuid} key={worker.uuid}>
                      {worker.first_name}
                      {worker.last_name.charAt(0)}
                    </option>
                  );
                }
              }: "")
            )}
          </Field>
        </div>
      </div>
    </section>

    <section className="form-section">
      <div className="standard-inputs">
        <div className="standard-input standard-input--lg">
          <label htmlFor="">Timetable</label>
          <p>
            Select the sessions that {child.first_name}&nbsp;is due to attend{" "}
            {user.school.name}.
          </p>
          <ul className="radio-list">
            <li className="radio-list__item">
              <div className="radio-list__item__label">Monday</div>
              <div className="radio-set">
                <div className="standard-radio">
                  <div className="edna-radio">
                    <Field
                      name="mon_morn"
                      component="input"
                      type="checkbox"
                      value={1}
                      parse={value => Number(value)}
                    />
                    <div className="styled_radio" />
                  </div>
                  Morn
                </div>
                <div className="standard-radio">
                  <div className="edna-radio">
                    <Field
                      name="mon_aft"
                      component="input"
                      type="checkbox"
                      value={1}
                      parse={value => Number(value)}
                    />
                    <div className="styled_radio" />
                  </div>
                  Aft
                </div>
              </div>
            </li>
            <li className="radio-list__item">
              <div className="radio-list__item__label">Tuesday</div>
              <div className="radio-set">
                <div className="standard-radio">
                  <div className="edna-radio">
                    <Field
                      name="tues_morn"
                      component="input"
                      type="checkbox"
                      value={1}
                      parse={value => Number(value)}
                    />
                    <div className="styled_radio" />
                  </div>
                  Morn
                </div>
                <div className="standard-radio">
                  <div className="edna-radio">
                    <Field
                      name="tues_aft"
                      component="input"
                      type="checkbox"
                      value={1}
                      parse={value => Number(value)}
                    />
                    <div className="styled_radio" />
                  </div>
                  Aft
                </div>
              </div>
            </li>
            <li className="radio-list__item">
              <div className="radio-list__item__label">Wednesday</div>
              <div className="radio-set">
                <div className="standard-radio">
                  <div className="edna-radio">
                    <Field
                      name="wed_morn"
                      component="input"
                      type="checkbox"
                      value={1}
                      parse={value => Number(value)}
                    />
                    <div className="styled_radio" />
                  </div>
                  Morn
                </div>
                <div className="standard-radio">
                  <div className="edna-radio">
                    <Field
                      name="wed_aft"
                      component="input"
                      type="checkbox"
                      value={1}
                      parse={value => Number(value)}
                    />
                    <div className="styled_radio" />
                  </div>
                  Aft
                </div>
              </div>
            </li>

            <li className="radio-list__item">
              <div className="radio-list__item__label">Thursday</div>
              <div className="radio-set">
                <div className="standard-radio">
                  <div className="edna-radio">
                    <Field
                      name="thur_morn"
                      component="input"
                      type="checkbox"
                      value={1}
                      parse={value => Number(value)}
                    />
                    <div className="styled_radio" />
                  </div>
                  Morn
                </div>
                <div className="standard-radio">
                  <div className="edna-radio">
                    <Field
                      name="thur_aft"
                      component="input"
                      type="checkbox"
                      value={1}
                      parse={value => Number(value)}
                    />
                    <div className="styled_radio" />
                  </div>
                  Aft
                </div>
              </div>
            </li>

            <li className="radio-list__item">
              <div className="radio-list__item__label">Friday</div>
              <div className="radio-set">
                <div className="standard-radio">
                  <div className="edna-radio">
                    <Field
                      name="fri_morn"
                      component="input"
                      type="checkbox"
                      value={1}
                      parse={value => Number(value)}
                    />
                    <div className="styled_radio" />
                  </div>
                  Morn
                </div>
                <div className="standard-radio">
                  <div className="edna-radio">
                    <Field
                      name="fri_aft"
                      component="input"
                      type="checkbox"
                      value={1}
                      parse={value => Number(value)}
                    />
                    <div className="styled_radio" />
                  </div>
                  Aft
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <section className="form-section">
      <div className="standard-inputs">
        <div className="standard-input standard-input--lg">
          <label htmlFor="">Special educational needs or disabilities?</label>
          <p>
            Does {child.first_name}&nbsp; have any special educational needs or
            disabilities? if so select ‘yes’ below.
          </p>
          <div className="radio-set">
            <div className="standard-radio">
              <div className="edna-radio">
                <Field
                  name="sen"
                  component="input"
                  type="radio"
                  parse={value => Number(value)}
                  value={1}
                />
                <div className="styled_radio" />
              </div>
              Yes
            </div>
            <div className="standard-radio">
              <div className="edna-radio">
                <Field
                  name="sen"
                  component="input"
                  type="radio"
                  parse={value => Number(value)}
                  value={0}
                />
                <div className="styled_radio" />
              </div>
              No
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="form-section">
      <div className="standard-inputs">
        <div className="standard-input standard-input--lg">
          <label htmlFor="">Unique Pupil Number (UPN)</label>
          <p>
            Enter {child.first_name}'s Unique Pupil Number. Information on UPNs
            and how to generate them can be found&nbsp;
            <a
              href="https://www.gov.uk/government/publications/unique-pupil-numbers"
              target="_blank"
              rel="noopener noreferrer"
            >
              here.
            </a>
          </p>
          <Field name="upn" targetName="upn" value="" component={Input} />
        </div>
      </div>
    </section>

    <section className="form-section">
      <div className="standard-inputs">
        <div className="standard-input standard-input--lg">
          <label htmlFor="">Free school meals</label>
          <p>Does {child.first_name}&nbsp;receive free school meals?</p>
          <div className="radio-set">
            <div className="standard-radio">
              <div className="edna-radio">
                <Field
                  name="meals"
                  component="input"
                  type="radio"
                  value={1}
                  parse={value => Number(value)}
                />
                <div className="styled_radio" />
              </div>
              Yes
            </div>
            <div className="standard-radio">
              <div className="edna-radio">
                <Field
                  name="meals"
                  component="input"
                  type="radio"
                  value={0}
                  parse={value => Number(value)}
                />
                <div className="styled_radio" />
              </div>
              No
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="form-section">
      <div className="standard-inputs">
        <div className="standard-input standard-input--lg">
          <label htmlFor="">ESL</label>
          <p>
            Does {child.first_name}&nbsp;speak English as a second language?
          </p>
          <div className="radio-set">
            <div className="standard-radio">
              <div className="edna-radio">
                <Field
                  name="esl"
                  component="input"
                  type="radio"
                  value={1}
                  parse={value => Number(value)}
                />
                <div className="styled_radio" />
              </div>
              Yes
            </div>
            <div className="standard-radio">
              <div className="edna-radio">
                <Field
                  name="esl"
                  component="input"
                  type="radio"
                  value={0}
                  parse={value => Number(value)}
                />
                <div className="styled_radio" />
              </div>
              No
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="form-section">
      <div className="standard-inputs">
        <div className="standard-input standard-input--lg">
          <label htmlFor="">EYPP</label>
          <p>Does {child.first_name}&nbsp;receive EYPP assistance?</p>
          <div className="radio-set">
            <div className="standard-radio">
              <div className="edna-radio">
                <Field
                  name="EYPP"
                  component="input"
                  type="radio"
                  value={1}
                  parse={value => Number(value)}
                />
                <div className="styled_radio" />
              </div>
              Yes
            </div>
            <div className="standard-radio">
              <div className="edna-radio">
                <Field
                  name="EYPP"
                  component="input"
                  type="radio"
                  value={0}
                  parse={value => Number(value)}
                />
                <div className="styled_radio" />
              </div>
              No
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="form-section">
      <div className="standard-inputs">
        <div className="standard-input standard-input--lg">
          <label htmlFor="">Free childcare</label>
          <p>Does {child.first_name}&nbsp;receive free childcare assistance?</p>
          <div className="radio-set">
            <div className="standard-radio">
              <div className="edna-radio">
                <Field
                  name="free_childcare"
                  component="input"
                  type="radio"
                  value={1}
                  parse={value => Number(value)}
                />
                <div className="styled_radio" />
              </div>
              Yes
            </div>
            <div className="standard-radio">
              <div className="edna-radio">
                <Field
                  name="free_childcare"
                  component="input"
                  type="radio"
                  value={0}
                  parse={value => Number(value)}
                />
                <div className="styled_radio" />
              </div>
              No
            </div>
          </div>
        </div>
      </div>
    </section>

    <footer className="form-footer">
      <div className="form-footer__btns">
        <button type="submit" className="btn btn--green btn--lg btn--mob-fixed">
          Save changes
        </button>
        <a
          href={true}
          className="btn btn--red btn--lg btn--mob-fixed"
          onClick={handleFormCancel}
        >
          Cancel
        </a>
      </div>
    </footer>
  </Form>
);

ChildEducationEdit.propTypes = {
  child: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  workers: PropTypes.array.isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
  handleFormCancel: PropTypes.func.isRequired
};

ChildEducationEdit = reduxForm({ form: "child-edit-education" })(
  ChildEducationEdit
);

ChildEducationEdit = connect(state => ({
  initialValues: {
    uuid: state.child.data.uuid,
    start_date: state.child.data.start_date,
    key_person:
      state.child.data.key_person !== null
        ? state.child.data.key_person.uuid
        : null,
    mon_morn: state.child.data.timetable.monday.morn,
    mon_aft: state.child.data.timetable.monday.aft,
    tues_morn: state.child.data.timetable.tuesday.morn,
    tues_aft: state.child.data.timetable.tuesday.aft,
    wed_morn: state.child.data.timetable.wednesday.morn,
    wed_aft: state.child.data.timetable.wednesday.aft,
    thur_morn: state.child.data.timetable.thursday.morn,
    thur_aft: state.child.data.timetable.thursday.aft,
    fri_morn: state.child.data.timetable.friday.morn,
    fri_aft: state.child.data.timetable.friday.aft,
    sen: state.child.data.sen,
    upn: state.child.data.upn !== "null" ? state.child.data.upn : "",
    meals: state.child.data.meals,
    esl: state.child.data.esl,
    EYPP: state.child.data.EYPP,
    free_childcare: state.child.data.free_childcare
  }
}))(ChildEducationEdit);

export default ChildEducationEdit;
