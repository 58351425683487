import React from "react";

class Timetable extends React.Component{

    calculateAttendance = (day) => {
        if(day.morn === 1 && day.aft === 1){
            return 'attendance--full';
        } else if(day.morn !== 1 && day.aft === 1){
            return 'attendance--aft';
        } else if(day.morn === 1 && day.aft !== 1){
            return 'attendance--morn';
        } else if(day.morn !== 1 && day.aft !== 1){
            return 'attendance--none';
        }
    }

    render() {
        const {timetable} = this.props;
        return (
            <div className='grid grid--gutter50'>
                <div className='grid__cell'>
                    <div className='dataItem dataItem--inline'>
                        <div className='dataItem__label'>
                            Timetable
                        </div>
                        <div className='dataItem__data'>
                            <div className='time-table'>
                                <div className='time-table__day'>
                                    M
                                    <div
                                        className={`attendance ${this.calculateAttendance(timetable.monday)}`}></div>
                                </div>
                                <div className='time-table__day'>
                                    T
                                    <div
                                        className={`attendance ${this.calculateAttendance(timetable.tuesday)}`}></div>
                                </div>
                                <div className='time-table__day'>
                                    W
                                    <div
                                        className={`attendance ${this.calculateAttendance(timetable.wednesday)}`}></div>
                                </div>
                                <div className='time-table__day'>
                                    T
                                    <div
                                        className={`attendance ${this.calculateAttendance(timetable.thursday)}`}></div>
                                </div>
                                <div className='time-table__day'>
                                    F
                                    <div
                                        className={`attendance ${this.calculateAttendance(timetable.friday)}`}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Timetable;
