import * as axios from "axios";
import * as Sentry from "@sentry/browser";
import { generate } from "shortid";
import { browserHistory } from "react-router";
import jwtDecode from "jwt-decode";
import {
  ADD_FLASH_MESSAGE,
  REHYDRATE_TOKEN,
  CLEAR_FLASH_MESSAGES
} from "../actions/actionTypes";

const api = {
  baseURL: process.env.REACT_APP_API_URL,
  responseType: "json",
  returnRejectedPromiseOnError: true
};

export const apiOptions = {
  interceptors: {
    request: [
      ({ getState, dispatch, action }, config) => {
        if (getState().user.token) {
          config.headers.Authorization = `Bearer ${getState().user.token}`;
        }
        return config;
      }
    ],
    response: [
      {
        success: ({ dispatch }, response) => {
          setTimeout(() => {
            dispatch({ type: CLEAR_FLASH_MESSAGES });
          }, 5000);
          if (response.headers.authorization) {
            let decoded = jwtDecode(
              response.headers.authorization.replace("Bearer ", "")
            );
            dispatch({
              type: REHYDRATE_TOKEN,
              token: response.headers.authorization.replace("Bearer ", ""),
              subscribed: decoded.subscribed
            });
          }
          return response;
        },
        error: ({ dispatch }, error) => {
          if (
            error.response.status === 500 &&
            error.response.data.message === "Unauthenticated."
          ) {
            Sentry.captureException(error);
            browserHistory.replace("/logout");
            dispatch({
              type: ADD_FLASH_MESSAGE,
              payload: {
                message: {
                  id: generate(),
                  message:
                    "You have been logged out after a period of inactivity. Please login to continue",
                  type: "information",
                  severity: "normal"
                }
              }
            });
          } else {
            Object.keys(error.response.data.errors).map(err =>
              dispatch({
                type: ADD_FLASH_MESSAGE,
                payload: {
                  message: {
                    id: generate(),
                    message: error.response.data.errors[err][0],
                    type: "error",
                    severity: "major"
                  }
                }
              })
            );
            setTimeout(() => {
              dispatch({ type: CLEAR_FLASH_MESSAGES });
            }, 5000);
            // Refresh token
            if (error.response.headers.authorization) {
              let decoded = jwtDecode(
                error.response.headers.authorization.replace("Bearer ", "")
              );
              dispatch({
                type: REHYDRATE_TOKEN,
                token: error.response.headers.authorization.replace(
                  "Bearer ",
                  ""
                ),
                subscribed: decoded.subscribed
              });
            }
          }
          return Promise.reject(error);
        }
      }
    ]
  }
};

export const apiClient = axios.create(api);
