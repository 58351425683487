import * as axios from "axios";
import {CLEAR_FLASH_MESSAGES} from "../actions/actionTypes";

const stripe = {
  baseURL: "https://api.stripe.com/v1",
  responseType: "json",
  headers: {
    "Authorization": `Bearer ${process.env.REACT_APP_STRIPE_SK}`,
    'Content-type': 'application/x-www-form-urlencoded'
  }
};

export const stripeOptions = {
  interceptors: {
    request: [({
        getState,
        dispatch,
        action
      }, config) => {
        setTimeout(() => {
          dispatch({type: CLEAR_FLASH_MESSAGES});
        }, 5000);
        return config;
      }
    ]
  }
};

export const stripeClient = axios.create(stripe);
