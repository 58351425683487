import React from "react";
import Moment from "react-moment";
import { connect } from "react-redux";
import { Field, Form, reduxForm } from "redux-form";
import Avatar from "./Avatar";
import Input from "./Input";
import Modal from "./Modal";
import ObservationImageButton from "./ObservationImageButton";
import ObservationImageInput from "./ObservationImageInput";
import { required } from "./Validators";

class CreateObservationsForm extends React.Component {
  state = {
    title: null
  };

  handleInputChange = event => {
    const { type, checked, value, name } = event.target;
    const datum = type === "checkbox" ? checked : value;

    this.setState({ [name]: datum });
  };

  render() {
    const {
      cloudinary,
      cloudinaryLoading,
      childMap,
      childEntities,
      modal,
      user,
      observationValues,
      handleToggleChild,
      handleToggleModal,
      handleChildNameFilter,
      categories,
      aspects,
      areas,
      agegroups,
      toggleSelected,
      toggleSecure,
      selectedStatements,
      handleFilterCategories,
      handleNameFilter,
      categoriesMap,
      selectedAgeFilters,
      selectedAreaFilters,
      selectedChildren,
      selectedSecure,
      handleChildComments,
      coel,
      handleCOELToggle,
      groupedCoel,
      coelMap,
      handleFormCancel
    } = this.props;
    let divClass = "";
    return (
      <div>
        <div className="header-local">
          <h1 className="header-local__title header-local__title--observations">
            New Observation
          </h1>
          <div className="header-local__cta">
            <a
              href={true}
              className="btn btn--red btn--sm btn--mob-sq"
              onClick={handleFormCancel}
            >
              Cancel
            </a>
          </div>
        </div>
        <div className="new-observation__header">
          <Avatar
            size="medium"
            image={user.data.person.picture}
            userName={
              user.data.person.first_name + " " + user.data.person.last_name
            }
            type="user"
          />

          <div className="observation__header__text">
            <h2 className="observation-title">
              {user.data.person.first_name} Observed{" "}
              {observationValues.children.map((child, index, childList) => {
                if (childList.indexOf(child) === childList.length - 1) {
                  if (childList.length === 1) {
                    return childEntities[child].first_name;
                  }
                  return `& ${childEntities[child].first_name}`;
                }
                if (childList.length === 2) {
                  return `${childEntities[child].first_name} `;
                }
                return `${childEntities[child].first_name}, `;
              })}{" "}
              {this.state.title !== null ? this.state.title : "..."}
            </h2>
            <div className="observation-meta">
              <Moment calendar />
            </div>
          </div>
        </div>
        <Form>
          <div className="child-select">
            <div className="child-select__title">Add Children</div>
            <div className="child-select__children">
              {typeof childMap !== "undefined"
                ? childMap.map(child => {
                    observationValues.children.indexOf(child) >= 0
                      ? (divClass = "child-select__child child-selected")
                      : (divClass = "child-select__child");
                    return (
                      <div
                        className={divClass}
                        key={childEntities[child].uuid}
                        onClick={this.props.handleToggleChild.bind(
                          this,
                          childEntities[child].uuid
                        )}
                      >
                        <Avatar
                          border
                          size="medium"
                          image={childEntities[child].picture}
                          userName={
                            childEntities[child].first_name +
                            " " +
                            childEntities[child].last_name
                          }
                          type="child"
                        />
                        <div className="child-select__child__name">
                          {childEntities[child].first_name}
                        </div>
                      </div>
                    );
                  })
                : ""}
              <div
                className="child-select__view-all"
                onClick={this.props.handleToggleModal.bind(this, "children")}
                data-modal="children"
              >
                <div className="child-select__view-all__btn" />
                <div className="child-select__view-all__txt">Full List</div>
              </div>
            </div>
          </div>
          <section className="new-observation__description">
            <div className="observation-input">
              <Field
                name="title"
                targetName="title"
                type="text"
                value=""
                label="Short description"
                component={Input}
                validate={required}
                onChange={this.handleInputChange}
                placeholder="Briefly, what's happening"
              />
            </div>
            <div className="observation-input">
              <label htmlFor="">Full description</label>
              <Field
                name="content"
                type="textarea"
                value=""
                placeholder="Give some more detail"
                component="textarea"
                cols="30"
                rows="10"
              />
            </div>
          </section>
          <section className="new-observation__led">
            <div className="observation-input">
              <label htmlFor="">Was it an adult or child led activity?</label>
              <div className="radio-set">
                <div className="standard-radio">
                  <div className="edna-radio">
                    <Field
                      name="led"
                      component="input"
                      type="radio"
                      value="adult"
                    />
                    <div className="styled_radio" />
                  </div>
                  Adult led
                </div>
                <div className="standard-radio">
                  <div className="edna-radio">
                    <Field
                      name="led"
                      component="input"
                      type="radio"
                      value="child"
                    />
                    <div className="styled_radio" />
                  </div>
                  Child led
                </div>
              </div>
            </div>
          </section>
          {cloudinary.length > 0 ? (
            <ObservationImageInput multiple="multiple" />
          ) : (
            ""
          )}
          {Object.keys(observationValues.childComments).length > 0 ? (
            <div className="new-observation__preview new-observation__preview__child-comments">
              <div className="new-observation__preview__title">
                Child Comments
              </div>
              {Object.keys(observationValues.childComments).map(key => (
                <div className="observation-preview-item" key={key}>
                  <Avatar
                    size="small"
                    image={childEntities[key].picture}
                    userName={
                      childEntities[key].first_name +
                      " " +
                      childEntities[key].last_name
                    }
                    type="child"
                  />

                  <div className="bubble-text">
                    <h4>
                      {childEntities[key].first_name}{" "}
                      {childEntities[key].last_name}
                    </h4>
                    <p>{observationValues.childComments[key]}</p>
                    <a
                      href={true}
                      className="btn-edit"
                      onClick={this.props.handleToggleModal.bind(
                        this,
                        "childComments"
                      )}
                    >
                      Edit
                    </a>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            ""
          )}
          {Object.keys(selectedStatements).length > 0 ? (
            <div className="new-observation__preview new-observation__preview__curriculum">
              <div className="new-observation__preview__title">
                EYFS Statements
              </div>
              {selectedStatements.map(statement => (
                <div className="observation-preview-item" key={statement}>
                  <div
                    className={`bubble-text bubble-text--noavatar curriculm-${areas[categoriesMap[statement].area].identifier}`}
                  >
                    <h4
                      className={`title-icon title-icon--${areas[categoriesMap[statement].area].identifier}`}
                    >
                      {areas[categoriesMap[statement].area].name}
                    </h4>
                    <p>{categoriesMap[statement].name}</p>
                    <ul
                      className={`curriculm-meta curriculm-meta--${areas[categoriesMap[statement].area].identifier}`}
                    >
                      <li>{aspects[categoriesMap[statement].aspect].name}</li>
                      <li>
                        {agegroups[categoriesMap[statement].agegroup].min}-{" "}
                        {agegroups[categoriesMap[statement].agegroup].max}{" "}
                        months
                      </li>
                    </ul>
                    <ul className="child-secure">
                      {selectedChildren.map(child => {
                        let secureClass = "";
                        if (
                          selectedSecure.find(
                            secureStatement =>
                              secureStatement.child === child &&
                              secureStatement.statement === statement
                          ) !== undefined
                        ) {
                          secureClass = "secure";
                        } else {
                          secureClass = "notSecure";
                        }
                        return (
                          <li key={child} className={secureClass}>
                            <Avatar
                              border
                              size="small"
                              image={childEntities[child].picture}
                              userName={
                                childEntities[child].first_name +
                                " " +
                                childEntities[child].last_name
                              }
                              type="child"
                            />
                          </li>
                        );
                      })}
                    </ul>
                    <a
                      href={true}
                      className="btn-edit"
                      onClick={this.props.handleToggleModal.bind(this, "eyfs")}
                    >
                      Edit
                    </a>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            ""
          )}
          {typeof observationValues.linkedSteps !== "undefined" ? (
            <div className="new-observation__preview new-observation__preview__linked-ns">
              <div className="new-observation__preview__title">
                Linked Next Steps
              </div>
              <div className="observation-preview-item">
                <div className="avatar avatar--small">
                  <img alt="avatar" src="images/users/49.jpg" />
                </div>
                <div className="bubble-text linked-ns">
                  <div className="linked-ns__desc">
                    <p>
                      Use different materials to create different sounds. For
                      example rain, thunder etc
                    </p>
                  </div>
                  <div className="linked-ns__date">05-08-2017</div>
                  <div className="linked-ns__person">
                    <div className="person person--secondary" href={true}>
                      <div className="avatar avatar--tiny">
                        <img alt="avatar" src="images/users/47.jpg" />
                      </div>
                      <div className="person__name">Sarah Jane</div>
                    </div>
                  </div>
                  <a
                    href={true}
                    className="btn-edit"
                    onClick={this.props.handleToggleModal.bind(
                      this,
                      "linkedSteps"
                    )}
                  >
                    Edit
                  </a>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {typeof observationValues.nextSteps !== "undefined" ? (
            <div className="new-observation__preview new-observation__preview__new-ns">
              <div className="new-observation__preview__title">
                New Next Steps
              </div>
              <div className="observation-preview-item">
                <div className="avatar avatar--small">
                  <img alt="avatar" src="images/users/49.jpg" />
                </div>
                <div className="bubble-text new-ns">
                  <p>
                    Use different materials to create different sounds. For
                    example rain, thunder etc
                  </p>
                  <a
                    href={true}
                    className="btn-edit"
                    onClick={this.props.handleToggleModal.bind(
                      this,
                      "nextsteps"
                    )}
                  >
                    Edit
                  </a>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {Object.keys(groupedCoel).length > 0 ? (
            <div className="new-observation__preview new-observation__preview__cel">
              <div className="new-observation__preview__title">
                Characteristics of Effective Learning
              </div>
              {Object.keys(groupedCoel).map(coelChild => (
                <div className="observation-preview-item" key={coelChild}>
                  <Avatar
                    size="small"
                    type="child"
                    image={childEntities[coelChild].picture}
                    userName={
                      childEntities[coelChild].first_name +
                      " " +
                      childEntities[coelChild].last_name
                    }
                  />
                  <div className="bubble-text cel">
                    <ul className="cel__items">
                      {groupedCoel[coelChild].map(item => {
                        const characteristic = coelMap[item.characteristic];
                        const parent = coelMap[characteristic.parent_id];
                        return (
                          <li className="cel__area" key={item.characteristic}>
                            {parent.name}
                            {characteristic.name}
                          </li>
                        );
                      })}
                    </ul>
                    <a
                      href={true}
                      className="btn-edit"
                      onClick={this.props.handleToggleModal.bind(this, "coel")}
                    >
                      Edit
                    </a>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            ""
          )}
        </Form>
        <div className="new-observation__draw">
          <ObservationImageButton />{" "}
          {selectedChildren.length > 0 ? (
            <div
              className="new-observation__draw__item new-observation__draw__item--child-comms"
              onClick={this.props.handleToggleModal.bind(this, "childComments")}
            >
              <div>Child Comments</div>
            </div>
          ) : (
            ""
          )}
          {/* { */}
          {/* selectedChildren.length > 0 ? */}
          {/* <div className='new-observation__draw__item new-observation__draw__item--nextsteps' */}
          {/* onClick={this.props.handleToggleModal.bind(this, "linkNextSteps")}> */}
          {/* Link Next Steps */}
          {/* </div> */}
          {/* : "" */}
          {/* } */}
          {/* { */}
          {/* selectedChildren.length > 0 ? */}
          {/* <div className='new-observation__draw__item new-observation__draw__item--nextstepsnew' */}
          {/* onClick={this.props.handleToggleModal.bind(this, "nextSteps")}> */}
          {/* New Next Steps */}
          {/* </div> */}
          {/* : "" */}
          {/* } */}
          {selectedChildren.length > 0 ? (
            <div
              className="new-observation__draw__item new-observation__draw__item--eyfs"
              onClick={this.props.handleToggleModal.bind(this, "eyfs")}
            >
              <div>EYFS Statements</div>
            </div>
          ) : (
            ""
          )}
          {selectedChildren.length > 0 ? (
            <div
              className="new-observation__draw__item new-observation__draw__item--charic"
              onClick={this.props.handleToggleModal.bind(this, "coel")}
            >
              <div>COEL</div>
            </div>
          ) : (
            ""
          )}
        </div>
        <footer className="form-footer form-footer--observation">
          <div className="form-footer__btns">
            {(observationValues.isLoading &&
              observationValues.status === "published") ||
            cloudinaryLoading ? (
              <button className="btn btn--green btn--lg btn--mob-fixed btn--spinner btn--disabled">
                Post now
              </button>
            ) : (
              <button
                className="btn btn--green btn--lg btn--mob-fixed"
                onClick={this.props.handleSubmit(values =>
                  this.props.handleFormSubmit({
                    ...values,
                    evidence: cloudinary,
                    comments: observationValues.childComments,
                    statements: selectedStatements,
                    coel: groupedCoel,
                    children: selectedChildren,
                    worker: user,
                    status: "published",
                    secure: selectedSecure
                  })
                )}
              >
                Post now
              </button>
            )}
            {(observationValues.isLoading &&
              observationValues.status === "draft") ||
            cloudinaryLoading ? (
              <button className="btn btn--blue btn--lg btn--mob-fixed btn--spinner btn--disabled">
                Save as draft
              </button>
            ) : (
              <button
                className="btn btn--blue btn--lg btn--mob-fixed"
                onClick={this.props.handleSubmit(values =>
                  this.props.handleFormSubmit({
                    ...values,
                    evidence: cloudinary,
                    comments: observationValues.childComments,
                    statements: selectedStatements,
                    coel: groupedCoel,
                    children: selectedChildren,
                    worker: user,
                    status: "draft",
                    secure: selectedSecure
                  })
                )}
              >
                Save as draft
              </button>
            )}
          </div>
        </footer>
        {modal !== null ? (
          <Modal
            open={modal.open}
            type={modal.type}
            childMap={childMap}
            childEntities={childEntities}
            selectedChildren={observationValues.children}
            toggleChild={handleToggleChild}
            toggleModal={handleToggleModal}
            filterChildren={handleChildNameFilter}
            filterCategories={handleFilterCategories}
            filterName={handleNameFilter}
            categories={categories}
            categoriesMap={categoriesMap}
            agegroups={agegroups}
            areas={areas}
            aspects={aspects}
            toggleSecure={toggleSecure}
            toggleSelected={toggleSelected}
            selectedStatements={selectedStatements}
            selectedAgeFilters={selectedAgeFilters}
            selectedAreaFilters={selectedAreaFilters}
            childComments={observationValues.childComments}
            selectedSecure={selectedSecure}
            handleChildComments={handleChildComments}
            coel={coel}
            handleCOELToggle={handleCOELToggle}
            observationValues={observationValues}
          />
        ) : (
          ""
        )}
        {modal.open ? <div className="modal-overlay modal-open" /> : ""}
      </div>
    );
  }
}

CreateObservationsForm = reduxForm({
  form: "create-observation",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true
})(CreateObservationsForm);

CreateObservationsForm = connect(state => ({
  initialValues: state.addObservation.data
}))(CreateObservationsForm);

export default CreateObservationsForm;
