import {
  ADD_CARER,
  FILTER_CARERS,
  GET_CARERS,
  GET_CARER_INVITES,
  INVITE_CARER,
  REMOVE_CARER,
  SET_FLAG,
} from './actionTypes';

export function getCarers() {
  return {
    type: GET_CARERS,
    payload: {
      request: {
        method: 'GET',
        url: 'carers',
      },
    },
  };
}

export function getCarerInvites(uuid) {
  return {
    type: GET_CARER_INVITES,
    payload: {
      request: {
        method: 'GET',
        url: `children/${uuid}/invites`,
      },
    },
  };
}

export function addCarer(child, carer) {
  return {
    type: ADD_CARER,
    payload: {
      request: {
        method: 'GET',
        url: `children/${child}/carers/${carer}`,
      },
    },
  };
}

export function removeCarer(child, carer) {
  return {
    type: REMOVE_CARER,
    payload: {
      request: {
        method: 'DELETE',
        url: `children/${child}/carers/${carer}`,
      },
    },
  };
}

export function inviteCarer(values) {
  return {
    type: INVITE_CARER,
    payload: {
      request: {
        method: 'POST',
        url: 'carers',
        data: values,
      },
    },
  };
}

export function setFlagOnCarer(parentId, childId, flag) {
  return {
    type: SET_FLAG,
    payload: {
      request: {
        method: 'POST',
        url: `carers/${parentId}`,
        data: { flag, childId },
      },
    },
  };
}

export function filterCarers(carerMap, carers, term) {
  let filteredCarers = carers.map(carer => {
    if (term === '') {
      return carerMap[carer];
    }

    const carerName =
      carerMap[carer].first_name + ' ' + carerMap[carer].last_name;
    if (carerName.toLowerCase().match(term.toLowerCase())) {
      return carerMap[carer];
    }
    return null;
  });

  return {
    type: FILTER_CARERS,
    payload: {
      data: filteredCarers.filter(
        carer => carer !== undefined && carer !== null
      ),
    },
  };
}
