import React from "react";

const ChildEditHeader = ({ child, activeTab, handleSwitchTab }) => (
  <div>
    <div className="header-local">
      <h1 className="header-local__title header-local__title--children">Edit {child.first_name}'s Profile</h1>
    </div>

    <nav className="nav-inline nav-local nav-local--greybg">
      <ul>
        <li>
          <a
            href={true}
            className={activeTab === "basic" ? "active" : ""}
            onClick={handleSwitchTab.bind(this, "basic")}>
            Basic Info
          </a>
        </li>
        <li>
          <a
            href={true}
            className={activeTab === "education" ? "active" : ""}
            onClick={handleSwitchTab.bind(this, "education")}>
            Education Info
          </a>
        </li>
      </ul>
    </nav>
  </div>
);

export default ChildEditHeader;
