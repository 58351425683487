import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import icon from "../images/icons/actionsSprite.png";
import Theme from "../theme";

const Comment = styled.a`
  font-family: ${Theme.fontPrimary};
  font-size: 0.75rem;
  color: ${Theme.colorGreyDarkLt};
  cursor: pointer;
  font-weight: 600;
  display: flex;
  max-width: 100px;
  margin-right: 15px;
  &:before {
    content: "";
    font-size: 0.875rem;
    display: inline-block;
    width: 17px;
    height: 17px;
    background-image: url(${icon});
    background-size: 100%;
    background-position: 0px -32px;
    background-repeat: no-repeat;
    margin-right: 5px;
  }
`;

const CommentBtn = props => {
  return <Comment onClick={e => props.handleCommentOpen(e, props.observationId)}>Comment</Comment>;
};

CommentBtn.propTypes = {
  handleCommentOpen: PropTypes.func.isRequired,
  observationId: PropTypes.string.isRequired
};

export default CommentBtn;
