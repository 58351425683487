import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { bindActionCreators } from "redux";
import Footer from "./Footer";
import Header from "./Header";
import Loader from "./Loader";

class App extends React.Component {
  componentDidMount() {
    if (this.props.user !== null) {
      if (this.props.user.isLoading === false && !this.props.user.subscribed) {
        browserHistory.replace("/locked");
      }
    }

    if (!this.props.user.authenticated) {
      browserHistory.replace("/login");
    }

    moment.locale("en", {
      calendar: {
        lastDay: "[Yesterday]",
        sameDay: "[Today]",
        nextDay: "[Tomorrow]",
        lastWeek: "dddd",
        nextWeek: "dddd",
        sameElse: "DD/MM/YY"
      }
    });
  }

  componentDidUpdate() {
    if (this.props.user !== null && this.props.location.pathname !== "/locked") {
      if (this.props.user.isLoading === false && !this.props.user.subscribed) {
        browserHistory.replace("/locked");
      }
    }

    if (!this.props.user.authenticated && this.props.location.pathname !== "/login") {
      browserHistory.replace("/login");
    }
  }

  render() {
    if (!this.props.user.authenticated) {
      return <Loader />;
    } else {
      return (
        <div className="outer-app">
          <Header />
          <div className="container container--app">{this.props.children}</div>
          <Footer />
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({ user: state.user });

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
