import React from "react";
import Avatar from "./Avatar";

const CommentModal = ({ toggleModal, selectedChildren, children, handleChildComments, childComments }) => (
  <div className="modal modal-open">
    <div className="modal__content">
      <div className="modal__header">
        <h2>Child Comments</h2>
        <a href={true} className="btn-cancel" onClick={toggleModal}>
          Cancel
        </a>
      </div>
      <div className="modal__main">
        {selectedChildren.map(child => (
          <div className="child-comment" key={child}>
            <form action="psot" className="child-comment__form">
              <label htmlFor={`${children[child].first_name}--comment`}>
                <Avatar
                  size="small"
                  userName={children[child].first_name + " " + children[child].last_name}
                  image={children[child].picture}
                  type="child"
                />

                <div className="child-comment__text">
                  <div className="child-comment__name">
                    {children[child].first_name} {children[child].last_name}
                  </div>
                  <div className="child-comment__helpertxt">
                    Did {children[child].first_name} say anything about the activity?
                  </div>
                </div>
              </label>
              <textarea
                name={`${children[child].first_name}--comment`}
                onChange={handleChildComments.bind(this, child)}>
                {childComments[child]}
              </textarea>
            </form>
          </div>
        ))}
      </div>
      <div className="modal__footer">
        <div className="modal__footer__controls">
          <button className="btn btn--green btn--sm btn--mob-fullw" onClick={toggleModal}>
            Add Comments
          </button>
        </div>
      </div>
    </div>
  </div>
);

export default CommentModal;
