import { GET_PROGRESSION, RESET_PROGRESSION } from "./actionTypes";

export function getProgression(uuid) {
  return {
    type: GET_PROGRESSION,
    payload: {
      request: {
        method: "GET",
        url: `progressions/${uuid}`,
      },
    },
  };
}

export function resetProgressionState() {
  return {
    type: RESET_PROGRESSION,
  };
}
