import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { bindActionCreators } from "redux";
import { resetAvatarState } from "../actions/cloudinaryActions";
import * as CreateChildActions from "../actions/createChildActions";
import { getWorkers } from "../actions/workerActions";
import AddChildHeader from "../components/AddChildHeader";
import CreateChildForm1 from "../components/CreateChildForm1";
import CreateChildForm2 from "../components/CreateChildForm2";
import CreateChildForm3 from "../components/CreateChildForm3";

class CreateChildrenContainer extends React.Component {
  constructor(props) {
    super(props);
    this.handleFormCancel = this.handleFormCancel.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentWillMount() {
    this.props.getWorkers();
  }

  componentWillUnmount() {
    this.props.resetAvatarState();
  }

  handleFormCancel = () => {
    this.props.cancelChildAddition();
    browserHistory.replace("/children");
  };

  handleFormSubmit = (values) => {
    const formData = new FormData();
    Object.keys(values).forEach((key) => {
      formData.append(key, values[key]);
    });
    if (values.dob._isAMomentObject) {
      const dobString = values.dob.format("YYYY-MM-DD");
      formData.append("dob", dobString);
    }
    if (values.start_date) {
      if (values.start_date._isAMomentObject) {
        const startDateString = values.start_date.format("YYYY-MM-DD");
        formData.append("start_date", startDateString);
      }
    }

    if (this.props.avatar.data !== null) {
      formData.append("avatar", this.props.avatar.data.secure_url);
    }

    if (values.buttonAction === "finish") {
      this.props
          .saveAndFinish(formData, this.props.user.data.uuid)
          .then(() => {
            this.props.resetAddChildState();
          })
          .then(() => {
            this.props.resetAvatarState();
          })
          .then(() => {
            browserHistory.replace("/children");
          });
    } else if (values.buttonAction === "continue") {
      this.props.saveAndContinue(formData).then(() => {
        this.props.incrementPage(this.props.addChild.page);
      });
    }
  };

  render() {
    const { page } = this.props.addChild;
    return (
      <div>

        <AddChildHeader page={page} formCancel={this.handleFormCancel} />

        {page === 1 && <CreateChildForm1 handleFormSubmit={this.handleFormSubmit} addChild={this.props.addChild} />}
        {page === 2 && <CreateChildForm2 handleFormSubmit={this.handleFormSubmit} addChild={this.props.addChild} avatar={this.props.avatar}/>}
        {page === 3 && <CreateChildForm3 handleFormSubmit={this.handleFormSubmit} addChild={this.props.addChild} workers={this.props.workers} user={this.props.user}/>}
      </div>
    );
  }
}

CreateChildrenContainer.propTypes = {
  cancelChildAddition: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  addChild: state.addChild,
  workers: state.workers,
  user: state.user,
  avatar: state.avatar
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...CreateChildActions,
      resetAvatarState,
      getWorkers
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CreateChildrenContainer);
