import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as ForgotPasswordActions from "../actions/forgotPasswordActions";
import ForgotPasswordForm from "../components/ForgotPasswordForm";
import ForgotThanks from "../components/ForgotThanks";
import FlashMessageContainer from "./FlashMessageContainer";

class ForgotPasswordContainer extends React.Component {
    handleFormSubmit = (values) => {
        this.props.getPasswordReset(values);
    };

    handleResend = (email) => {
        this.props.getPasswordReset({email});
    };

    handleEditEmail = () => {
        this.props.editEmail();
    };

    render () {
        return (
          <div>
              <FlashMessageContainer />
              {this.props.forgotPassword.showThanks ?
                <ForgotThanks email={this.props.forgotPassword.data.email} handleResend={this.handleResend}
                              handleEdit={this.handleEditEmail}/>
                :
                <ForgotPasswordForm handleFormSubmit={this.handleFormSubmit} errors={this.props.forgotPassword.errors} email={this.props.forgotPassword.data}/>
              }
          </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        forgotPassword: state.forgotPassword,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        ...ForgotPasswordActions
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordContainer);
