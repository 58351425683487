import PropTypes from "prop-types";
import React from "react";

const CancelModal = ({ toggleCancelModal, handleCancelPlan, handleCancelConfirmationChange }) => {
  return (
    <form onSubmit={handleCancelPlan}>
      <div className="modal modal-open">
        <div className="modal__content">
          <div className="modal__header">
            <h2>Confirmation</h2>
            <a href={true} className="btn-cancel" onClick={toggleCancelModal}>
              Close
            </a>
          </div>
          <div className="modal__main modal__main--pad">
            <div className="conformation-title conformation-title--cancel ">
              <h2>Oh no.</h2>
              <h1>Don't go</h1>
            </div>

            <p>We understand that somtimes tough decisions need to be made but we hope we can welcome you back soon!</p>
            <p>
              Cancelling your account will mean that you'll no longer have access to Edna at the end of your current
              billing cycle. You won't be able to view, edit or create new observations, children or users.
            </p>
            <p>To cancel your account type 'Cancel' in the box below and then click 'Cancel my account'</p>
            <div className="standard-input">
              <label htmlFor="cancel_confirmation">Type 'Cancel' below</label>
              <input type="text" name="cancel_confirmation" onChange={handleCancelConfirmationChange} />
            </div>
          </div>
          <div className="modal__footer">
            <div className="modal__footer__controls">
              <a href={true} className="green-link" onClick={toggleCancelModal}>
                No, I've changed my mind
              </a>
              <button className="btn btn--red btn--sm btn--mob-fullw" type="submit">
                Yes, cancel my account
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-overlay modal-open" />
    </form>
  );
};

CancelModal.propTypes = {
  toggleCancelModal: PropTypes.func.isRequired,
  handleCancelPlan: PropTypes.func.isRequired,
  handleCancelConfirmationChange: PropTypes.func.isRequired
};

export default CancelModal;
