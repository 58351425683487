/* eslint-disable no-unreachable */
const initialState = {
  isLoading: false,
  images: []
};

const images = (state, action) => {
  switch (action.type) {
    case "UPLOAD_IMAGE":
      return {
        ...state,
        images: [
          ...state.images,
          {
            id: action.payload.request.data.tags
          }
        ],
        isLoading: true
      };
    case "UPLOAD_IMAGE_SUCCESS":
      if (action.payload.data.tags[0] === state.id) {
        return {
          cloudinary_id: action.payload.data.cloudinary_id,
          tag: action.payload.data.tags[0],
          isLoading: false
        };
      }
      return {
        ...state
      };

    default:
      return state;
  }
};

const cloudinaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPLOAD_IMAGE":
      return {
        ...state,
        isLoading: true,
        images: [
          ...state.images,
          {
            isLoading: true,
            id: action.payload.request.data.tags
          }
        ]
      };
    case "UPLOAD_IMAGE_SUCCESS":
      return {
        ...state,
        isLoading: false,
        images: state.images.map(image => images(image, action))
      };
    case "UPLOAD_IMAGE_FAIL":
      return {
        ...state,
        isLoading: false,
        isComplete: true,
        error: true
      };
    case "REMOVE_IMAGE":
      return {
        ...state,
        images: state.images.filter(
          image =>
            image.cloudinary_id !== action.payload.request.data.cloudinary_id
        )
      };
    case "REMOVE_IMAGE_SUCCESS":
      return {
        ...state
      };
    case "RESET_ADD_OBSERVATION_STATE":
      return {
        ...state,
        images: []
      };
    default:
      return state;
      break;
  }
};

export default cloudinaryReducer;
