import React from 'react';
import PropTypes from 'prop-types';
import {StripeProvider, Elements} from "react-stripe-elements";
import CardForm from "./CardForm";

const PaymentMethods = ({trial, sendTokenToServer, billingLoading}) => {
  return (

    <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PK}>
      <Elements>
        <div className="standard-inputs">
          <div className="standard-input standard-input--lg">
            <label>Add your payment method</label>
            <p>We accept Visa, Mastercard and American Express.</p>
            <CardForm
              trial={true}
              sendTokenToServer={sendTokenToServer}
              billingLoading={billingLoading}/>
            <div className="stripe-secure"></div>
          </div>
        </div >
      </Elements>
    </StripeProvider>

  );
};

PaymentMethods.propTypes = {
  sendTokenToServer: PropTypes.func.isRequired,
  billingLoading: PropTypes.bool
};

export default PaymentMethods;
