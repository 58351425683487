import React from "react";
import { connect } from "react-redux";
import { getObservation } from "../reducers/observations";
import Avatar from "./Avatar";

const ObservationItem = ({ observationId, observation }) => {
  const categoriesList = observation.statements.filter(
    (s => a => !s.has(a.area.uuid) && s.add(a.area.uuid))(new Set())
  );
  return (
    <li className="data-list__item data-list__item--observation">
      <div className="data-list__item__col data-list--observations__observation">
        <a className="observation-info" href={`/observations/${observationId}`}>
          <Avatar
            size="small"
            userName={
              observation.child.first_name + " " + observation.child.last_name
            }
            image={observation.child.picture}
            type="child"
          />

          <div className="observation-info__details">
            <div className="observation-info__details__description">
              <span className="person__name">
                {observation.child.first_name}&nbsp;
                {observation.child.last_name}
              </span>{" "}
              {observation.title}
            </div>
            <div className="observation-info__details__meta">
              <div className="observation-info__comments">
                {observation.comments.length}{" "}
                {observation.comments.length < 2 ? "Comment" : "Comments"}
              </div>
            </div>
          </div>
        </a>
      </div>
      <div className="data-list--observations__meta">
        <div className="data-list__item__col data-list--observations__date">
          {observation.createdAt}
        </div>
        <div className="data-list__item__col data-list--observations__person">
          <a
            className="person person--secondary"
            href={`/user/${observation.keyPerson.user.uuid}`}
          >
            <Avatar
              size="tiny"
              userName={
                observation.keyPerson.first_name +
                " " +
                observation.keyPerson.last_name
              }
              image={observation.keyPerson.picture}
              type="user"
            />

            <div className="person__name">
              {observation.keyPerson.first_name}&nbsp;
              {observation.keyPerson.last_name.charAt(0)}
            </div>
          </a>
        </div>
        <div
          className={`data-list__item__col data-list--observations__status status--${observation.status}`}
        >
          {observation.status}
        </div>
        <div className="data-list__item__col data-list--observations__curriculum">
          <div className="curriculum-areas">
            {categoriesList.map(category => (
              <div
                className={`curriculum-area curriculum-area__${category.area.identifier}`}
                key={category.uuid}
              />
            ))}
          </div>
        </div>
      </div>
    </li>
  );
};

const mapDispatchToProps = dispatch => ({});

const mapStateToProps = (state, props) => ({
  observation: getObservation(state, props.observationId)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ObservationItem);
