import React from "react";
import { bindActionCreators } from "redux";
import * as evidenceActions from "../actions/singleObservationActions";
import { connect } from "react-redux";
import ImageElement from "./ImageElement";
import PropTypes from "prop-types";

class ObservationEditImage extends React.Component {
  constructor(props) {
    super(props);
    this.handleImageRemove = this.handleImageRemove.bind(this);
    this.handleFiles = this.handleFiles.bind(this);
  }

  handleFiles = event => {
    const files = event.target.files;
    for (var i = 0; i < files.length; i++) {
      const fileName = files[i].name;
      const reader = new FileReader();
      reader.readAsDataURL(files[i]);
      reader.onload = () => {
        const image64 = reader.result;
        this.props.uploadImage(image64, fileName).then(response => {
          this.props.handlePopulateEvidence(response.payload.data);
        });
      };
    }
  };

  handleImageRemove = imageId => {
    this.props.removeImage(imageId);
  };

  handleClick = () => {
    document.getElementById("fileUpload").click();
  };

  render() {
    const { evidence } = this.props;
    return (
      <div className="new-observation__preview new-observation__preview__photos">
        {typeof evidence !== "undefined"
          ? evidence.map(image => (
              <div
                className="thumbnail thumbnail--sm thumbnail--cross"
                key={image.id}
                onClick={this.handleImageRemove.bind(this, image.cloudinary_id)}
              >
                <ImageElement
                  thumb_url={`https://res.cloudinary.com/flo-design/image/upload/c_fill,q_auto:good,w_150,h_150/${image.cloudinary_id}.jpg`}
                  isLoading={image.isLoading}
                  key={image.id}
                />
              </div>
            ))
          : ""}
        {typeof evidence !== "undefined" && evidence.length > 0 ? (
          <div
            className="thumbnail thumbnail--sm thumbnail--add-img"
            onClick={this.handleClick}
          >
            <input
              type="file"
              id="fileUpload"
              multiple
              style={{ display: "none" }}
              onChange={this.handleFiles}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

ObservationEditImage.propTypes = {
  evidence: PropTypes.array.isRequired,
  handlePopulateEvidence: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    cloudinary: state.cloudinary
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      ...evidenceActions
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ObservationEditImage);
