import jwtDecode from "jwt-decode";
import { createSelector } from "reselect";

const initialState = {
  isLoading: false,
  isComplete: false,
  authenticated: false,
  error: false,
  data: null,
  subscribed: false
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
  case "USER_LOGIN":
    return {
      ...state,
      isLoading: true,
      isComplete: false,
      error: false
    };
  case "USER_REFRESH":
    return {
      ...state,
      isLoading: true,
      isComplete: false
    };
  case "USER_REFRESH_SUCCESS":
    let refreshDecoded = jwtDecode(action.payload.data.token);
    return {
      ...state,
      authenticated: true,
      isLoading: false,
      isComplete: true,
      data: action.payload.data.user,
      token: action.payload.data.token,
      subscribed: refreshDecoded.subscribed
    };
  case "USER_LOGIN_SUCCESS":
    let decoded = jwtDecode(action.payload.data.token);
    return {
      ...state,
      authenticated: true,
      isLoading: false,
      isComplete: true,
      data: action.payload.data.user,
      token: action.payload.data.token,
      subscribed: decoded.subscribed
    };
  case "USER_LOGIN_FAIL":
    return {
      ...state,
      authenticated: false,
      isLoading: false,
      isComplete: false,
      error: true
    };
  case "USER_LOGOUT":
    return {
      ...state,
      authenticated: false,
      user: null
    };
  case "REHYDRATE_TOKEN":
    return {
      ...state,
      token: action.token,
      subscribed: action.subscribed
    };
  case "UPDATE_USER_SUCCESS":
    return {
      ...state,
      isLoading: false,
      isComplete: true,
      data: action.payload.data.uuid === state.data.uuid ? action.payload.data : state.data
    };
  case "CREATE_SUBSCRIPTION":
    return {
      ...state,
      isLoading: true,
      isComplete: false
    };
  case "CREATE_SUBSCRIPTION_SUCCESS":
    return {
      ...state,
      isLoading: false,
      isComplete: true,
      data: {
        ...state.data,
        school: action.payload.data
      }
    };
  case "UPDATE_PLAN_SUCCESS":
    return {
      ...state,
      isLoading: false,
      isComplete: true,
      data: {
        ...state.data,
        school: action.payload.data
      }
    };
  case "CANCEL_PLAN_SUCCESS":
    return {
      ...state,
      data: {
        ...state.data,
        school: action.payload.data
      }
    };
  case "DELETE_CHILD_SUCCESS":
    return {
      ...state,
      data: {
        ...state.data,
        childCount: state.data.childCount - 1
      }
    };
  case "CREATE_CHILD_FINISH_SUCCESS":
    return {
      ...state,
      data: {
        ...state.data,
        childCount: state.data.childCount + 1
      }
    };
  default:
    return state;
  }
};

export default usersReducer;

const getUserName = state => state.user.data.person.first_name + " " + state.user.data.person.last_name;

const getUserId = state => state.user.data.uuid;

const getUserImage = state => state.user.data.person.picture;

const getRole = state => state.user.data.role;

export const getUser = createSelector(
  getUserName,
  getUserId,
  getUserImage,
  getRole,
  (userName, userId, picture, role) => ({ userId, name: userName, picture, role })
);
