import React from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { bindActionCreators } from "redux";
import * as UserActions from "../actions/userActions";
import LoginForm from "../components/LoginForm";

class LoginContainer extends React.Component {
  handleFormSubmit = (values) => {
    this.props
        .userLogin(values)
        .then((response) => {
          if (typeof response.error === "undefined") {
            this.props.userLoggedIn(response.payload.data.user);
          }
          browserHistory.push("/");
        })
        .catch();
  };

  render() {
    return (
      <div>
        <LoginForm sendForm={this.handleFormSubmit} user={this.props.user} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...UserActions
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
