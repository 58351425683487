import React from "react";
import Input from "./Input";
import RegisterHeader from "./RegisterHeader";
import Loader from "./Loader";
import { Field, reduxForm, Form } from "redux-form";
import { connect } from "react-redux";
import { required, email } from "./Validators";
import InputError from "./InputError";
import { Link } from "react-router";

class RegisterForm extends React.Component {
  state = {
    first_name: null,
    last_name: null,
    email: null,
  };

  render() {
    const emailErrors = [];
    const forenameErrors = [];
    const surnameErrors = [];
    return (
      <div className="signUp signUp--wForm">
        <RegisterHeader />
        <div className="signUp__main">
          <div className="signUp__text-area signUp__text-area--intro">
            <h1>Try Edna now - Free</h1>
            <p>No credit card details required, sign up now and try Edna free for 30 days.</p>
          </div>
          <Form onSubmit={this.props.handleSubmit(this.props.handleFormSubmit)}>
            <div className="standard-inputs">
              <div className="standard-input standard-input--lg standard-input--pair">
                <div className="standard-input--pair__l">
                  <Field name="first_name" targetName="first_name" type="text" label="First Name" component={Input} validate={required} />
                  <InputError errorMessage={forenameErrors} />
                </div>
                <div className="standard-input--pair__r">
                  <Field name="last_name" targetName="last_name" type="text" label="Surname" component={Input} validate={required} />
                  <InputError errorMessage={surnameErrors} />
                </div>
              </div>
              <div className="standard-input standard-input--lg">
                <Field
                  name="email"
                  targetName="email"
                  component={Input}
                  type="text"
                  label="Email"
                  hint="We’ll send a confirmation email to this address"
                  validate={[email, required]}
                />
                <InputError errorMessage={emailErrors} />
              </div>
            </div>
            {this.props.register.isLoading ? (
              <Loader />
            ) : (
              <button className="btn btn--green btn--lg btn--mob-fixed" type="submit">
                Sign up
              </button>
            )}
          </Form>
        </div>
        <div className="no-account">
          Already have account?
          <Link className="btn btn--sm btn--empty" to="/login">
            Sign in now
          </Link>
        </div>
      </div>
    );
  }
}

RegisterForm = reduxForm({
  form: "register",
  enableReinitialize: true,
})(RegisterForm);

RegisterForm = connect(state => ({
  initialValues: state.register.data,
}))(RegisterForm);

export default RegisterForm;
