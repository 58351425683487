import PropTypes from "prop-types";
import React from "react";
import Avatar from "./Avatar";
const ChildModal = ({
  childMap,
  children,
  childEntities,
  toggleChild,
  toggleModal,
  filterChildren,
  selectedChildren,
  divClass
}) => (
  <div className="modal modal-open">
    <div className="modal__content">
      <div className="modal__header">
        <h2>Add Children</h2>
        <a href={true} className="btn-cancel" onClick={toggleModal}>
          Cancel
        </a>
      </div>
      <div className="modal__main">
        <form action="" className="child-search-form">
          <input
            className="modal-search modal-search--children"
            onChange={filterChildren}
            placeholder="Search childs name"
            type="text"
          />
        </form>
        <ul className="select-list select-list--children">
          {childMap.map(child => {
            selectedChildren.indexOf(child) >= 0 ? (divClass = "tickBox tick") : (divClass = "tickBox");
            return (
              <li
                className="select-list--child"
                onClick={toggleChild.bind(this, childEntities[child].uuid)}
                key={childEntities[child].uuid}>
                <div className="person person--primary">
                  <Avatar
                    size="small"
                    image={childEntities[child].picture}
                    type="child"
                    userName={childEntities[child].first_name + " " + childEntities[child].last_name}
                  />
                  <div className="person__name">
                    {childEntities[child].first_name} {childEntities[child].last_name}
                  </div>
                </div>
                <div className={divClass} />
              </li>
            );
          })}
        </ul>
      </div>
      <div className="modal__footer">
        <div className="modal__footer__controls">
          <div className="modal-feedback">
            {selectedChildren.length} {selectedChildren.length === 1 ? "child" : "children"} selected
          </div>
          <a href={true} className="btn btn--green btn--sm btn--mob-fullw" onClick={toggleModal}>
            Add Children
          </a>
        </div>
      </div>
    </div>
  </div>
);

ChildModal.propTypes = {
  childMap: PropTypes.array.isRequired,
  children: PropTypes.object.isRequired,
  toggleChild: PropTypes.func,
  filterChildren: PropTypes.func.isRequired,
  selectedChildren: PropTypes.array
};

export default ChildModal;
