import { generate } from "shortid";
import { REMOVE_FLASH_MESSAGE, ADD_FLASH_MESSAGE, CLEAR_FLASH_MESSAGES } from "./actionTypes";

export function addMessageToFlash(message, type, severity) {
  return {
    type: ADD_FLASH_MESSAGE,
    payload: {
      message: {
        id: generate(),
        message,
        type,
        severity,
      },
    },
  };
}

export function removeMessageFromFlash(id) {
  return {
    type: REMOVE_FLASH_MESSAGE,
    payload: {
      id,
    },
  };
}

export function clearFlashMessages() {
  return {
    type: CLEAR_FLASH_MESSAGES,
  };
}
