import PropTypes from "prop-types";
import React from "react";

const ChildProfileESL = ({ esl }) => (
  <div className="grid__cell">
    <div className="dataItem">
      <div className="dataItem__label">English as an additional language?</div>
      <div className="dataItem__data">{esl === 1 ? "Yes" : "No"}</div>
    </div>
  </div>
);

ChildProfileESL.propTypes = {
  ESL: PropTypes.number.isRequired
};

export default ChildProfileESL;
