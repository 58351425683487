import PropTypes from "prop-types";
import React from "react";
import { browserHistory } from "react-router";
import DefaultUserAvatar from "../images/assets/DefaultUserAvatar.svg";
import ChildrenProfileList from "./ChildrenProfileList";
import Dialog from "./Dialog";
import ObservationProfileList from "./ObservationProfileList";

const ParentProfile = ({
  user,
  handleTabChange,
  activeTab,
  handleChildSort,
  handleToggleDeleteModal,
  handleToggleContextMenu,
  contextOpen,
  deleteModalOpen,
  handleDelete,
  currentUser
}) => {
  let contextClass = "";
  if (contextOpen) {
    contextClass = "context-menu__menu context-menu__menu--open";
  } else {
    contextClass = "context-menu__menu";
  }
  let deleteModal = "";
  if (deleteModalOpen) {
    deleteModal = (
      <Dialog deleteType="user" handleDelete={handleDelete} handleToggle={handleToggleDeleteModal} uuid={user.uuid} />
    );
  }
  return (
    <div>
      {deleteModal}
      <div className="header-profile header-profile--user">
        <div className="avatar avatar--large">
          {user.person.picture !== null ? (
            <img src={user.person.picture} alt={`${user.person.first_name} ${user.person.last_name}`} />
          ) : (
            <img alt="default avatar" src={DefaultUserAvatar} />
          )}
        </div>
        <div className="header-profile__data">
          <div className="header-profile__info">
            <h1 className="header-profile__title">
              {user.person.first_name}
              {user.person.last_name}
            </h1>
            <dl className="header-profile__info-list">
              <span>
                <dt>Status:</dt>
                <dd>{user.active ? "Active" : "Inactive"}</dd>
              </span>
              <span>
                <dt>Role:</dt>
                <dd>{user.role.display_name}</dd>
              </span>
              <span>
                <dt>Key Person for</dt>
                <dd>
                  {user.person.children.length}{" "}
                  {user.person.children.length === 0 || user.person.children.length > 1 ? "Children" : "Child"}
                </dd>
              </span>
            </dl>
          </div>

          {/* <div className="header-profile__meta">
          <dl className="header-profile__info-list">
            <span>
              <dt>Last Observation:</dt>
              <dd>Today @ 13:47</dd>
            </span>
          </dl>
        </div> */}
        </div>
        {user.uuid !== currentUser ? (
          <div className="context-menu">
            <div className="context-menu__btn" onClick={handleToggleContextMenu} />
            <ul className={contextClass}>
              <li className="context-menu__item">
                <a
                  href={true}
                  onClick={() => {
                    browserHistory.replace(`/user/${user.uuid}/edit`);
                  }}>
                  Edit {user.person.first_name}'s profile
                </a>
              </li>
              <li className="context-menu__item context-menu__item--del">
                <a href={true} onClick={handleToggleDeleteModal}>
                  Delete {user.person.first_name}'s profile
                </a>
              </li>
            </ul>
          </div>
        ) : (
          ""
        )}
      </div>

      <nav className="nav-inline nav-local nav-local--greybg">
        <ul>
          {user.active ? (
            <li>
              <a
                href={true}
                className={activeTab === "children" ? "active" : ""}
                name="children"
                onClick={handleTabChange.bind(this, "children")}>
                Children
              </a>
            </li>
          ) : (
            ""
          )}
          <li>
            <a
              href={true}
              className={activeTab === "observations" ? "active" : ""}
              name="observations"
              onClick={handleTabChange.bind(this, "observations")}>
              Observations
            </a>
          </li>
        </ul>
      </nav>
      {activeTab === "children" && user.active ? (
        <ChildrenProfileList childList={user.person.children} handleSort={handleChildSort} worker={user.person} />
      ) : (
        <ObservationProfileList observations={user.person.observations} worker={user.person} user={user} />
      )}
    </div>
  );
};

ParentProfile.propTypes = {
  user: PropTypes.shape({
    role: PropTypes.shape({ display_name: PropTypes.string.isRequired }).isRequired,
    person: PropTypes.shape({
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      children: PropTypes.array.isRequired,
      observations: PropTypes.array.isRequired
    })
  }).isRequired,
  handleTabChange: PropTypes.func.isRequired
};

export default ParentProfile;
