import React from "react";

const ChangePlan = ({ handlePlanUpdateState, handlePlanUpdateModal, plans, subscription, newPlan, user }) => {
  return (
    <section className="form-section">
      <div className="standard-inputs">
        <div className="standard-input standard-input--lg">
          <label>Change your plan</label>
          <p>If you'd like to change your plan simply select a new plan to change to at renewal time.</p>
          <select name="plan" label="Change your plan" onChange={handlePlanUpdateState} defaultValue="select">
            <option disabled value="select">
              Select a new plan...
            </option>
            {plans.reverse().map(plan => {
              let price = plan.amount / 100;
              if (plan.id !== subscription.plan.id || subscription.cancel_at_period_end === true) {
                if (plan.nickname !== "Free") {
                  return (
                    <option value={plan.id} key={plan.id}>
                      {plan.nickname}
                      &nbsp;Plan (£{price.toFixed(2)}&nbsp;{plan.id === "plan_D5fcw28hyGHLhh" ? "per child " : ""}
                      per {plan.interval === "year" ? "year" : "month"})
                    </option>
                  );
                } else {
                  if (user.email === "craig@flodesign.co.uk" || user.email === "russ@flodesign.co.uk") {
                    return (
                      <option value={plan.id} key={plan.id}>
                        {plan.nickname}
                        &nbsp;Plan (£{price.toFixed(2)}&nbsp;{plan.id === "plan_D5fcw28hyGHLhh" ? "per child " : ""}
                        per {plan.interval === "year" ? "year" : "month"})
                      </option>
                    );
                  }
                }
              }
              return false;
            })}
          </select>
        </div>
        {newPlan !== null ? (
          <a href={true} className="btn btn--green btn--sm btn--nobrdr" onClick={handlePlanUpdateModal}>
            See details of this change
          </a>
        ) : (
          ""
        )}
      </div>
    </section>
  );
};
export default ChangePlan;
