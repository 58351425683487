import React from "react";
import logo from "../images/assets/edna-logo-dark.svg";

class RegisterHeader extends React.Component {
  render() {
    return (
      <header className="signUp__header">
        <img className="signUp__header__logo" alt="edna logo" src={logo} />
      </header>
    );
  }
}

export default RegisterHeader;
