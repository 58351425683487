import { PropTypes } from "prop-types";
import React from "react";
import { Field, reduxForm } from "redux-form";
import Input from "./Input";
import { email, required } from "./Validators";

let CreateWorkerForm = ({ handleFormSubmit, handleSubmit, roles, user }) => (
  <div>
    <div className="header-local header-local--underline">
      <h1 className="header-local__title header-local__title--settings">Add a new staff member</h1>
      <div className="header-local__cta">
        <a className="btn btn--red btn--sm btn--mob-sq" href="/settings">
          Cancel
        </a>
      </div>
    </div>

    <section className="form-section form-section--title">
      <h2 className="form-section__title">Staff member details</h2>
      <p className="form-section__intro">
        Enter the new staff members name, email address &amp; role. We’ll send an invite to the email address you
        provide for the new staff member to complete registration.
      </p>
    </section>
    <section className="form-section">
      <div className="standard-inputs standard-inputs--inline">
        <div className="standard-input standard-input--lg standard-input--pair">
          <div className="standard-input--pair__l">
            <Field
              name="invitee_first_name"
              targetName="invitee_first_name"
              type="text"
              value=""
              label="First Name"
              component={Input}
              validate={required}
            />
          </div>
          <div className="standard-input--pair__r">
            <Field
              name="invitee_last_name"
              targetName="invitee_last_name"
              type="text"
              value=""
              label="Last Name"
              component={Input}
              validate={required}
            />
          </div>
        </div>
      </div>

      <div className="standard-input standard-input--lg">
        <Field
          name="invitee_email"
          targetName="invitee_email"
          type="text"
          value=""
          hint="We’ll send an invitation to this address"
          label="Email"
          component={Input}
          validation={{
            required,
            email
          }}
        />
      </div>

      <div className="standard-input standard-input--lg">
        <label htmlFor="role">Role</label>
        <Field name="role" component="select" label="Role" defaultValue="">
          <option disabled="disabled" hidden="" value="">
            Select a role...
          </option>
          {roles.map(role =>
            user.data.role.name !== "super_admin" && role.name === "super_admin" ? (
              ""
            ) : (
              <option value={role.uuid}>{role.display_name}</option>
            )
          )}
        </Field>
      </div>
    </section>
    <section className="form-section">
      <div className="info-box">
        <p>
          <span className="bold">Role with it! </span>
          Different roles offer varying access levels throughout Edna. A Super Admin has full access to the system,
          including billing info. Admins can add & edit children & observations. Regular Staff can create and edit their
          own observations.
        </p>

        <a href="https://ednaapp.com/support/user-roles/">Learn more about user roles</a>
      </div>
    </section>

    <footer className="form-footer">
      <div className="form-footer__btns">
        <button
          className="btn btn--green btn--lg btn--mob-fixed"
          onClick={handleSubmit(values =>
            handleFormSubmit({
              ...values,
              invite_type: "worker"
            })
          )}>
          Create new staff member
        </button>

        <a href="/settings" className="btn btn--red btn--lg btn--mob-fixed">
          Cancel
        </a>
      </div>
    </footer>
  </div>
);

CreateWorkerForm.propTypes = {
  handleFormSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      name: PropTypes.string,
      display_name: PropTypes.string.isRequired
    })
  ).isRequired
};

CreateWorkerForm = reduxForm({ form: "create-worker" })(CreateWorkerForm);

export default CreateWorkerForm;
