import React from "react";
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import Input from "./Input";
import {required} from "./Validators";
import RenderDatePicker from "./RenderDatePicker";

class CreateChildForm1 extends React.Component {
  render() {
    const {addChild} = this.props;
    return (
      <div>
        <section className="form-section form-section--title">
          <h2 className="form-section__title">Name & Date of Birth</h2>
          <p className="form-section__intro">
            This is the minimum information that you need to enter in order to add a new
            child to Edna. In a rush? Just fill in this section and hit 'Create and finish'.
            Come back and do the rest later by clicking ‘Edit’ on the child's profile page.
          </p>
        </section>
        <section className="form-section">
          <div className="standard-inputs standard-inputs--inline">
            <div className="standard-input standard-input--lg standard-input--pair">
              <div className="standard-input--pair__l">
                <Field
                  name="first_name"
                  targetName="first_name"
                  type="text"
                  value=""
                  label="First Name"
                  component={Input}
                  validate={required}/>
              </div>
              <div className="standard-input--pair__r">
                <Field
                  name="last_name"
                  targetName="last_name"
                  type="text"
                  value=""
                  label="Last Name"
                  component={Input}
                  validate={[required]}/>
              </div>
            </div>
            <div className="standard-input standard-input--lg standard-input--date">
              <label htmlFor="dob">Date of Birth</label>
              <Field
                name="dob"
                targetName="dob"
                type="date"
                value=""
                validate={[required]}
                component={RenderDatePicker}/>
            </div>
          </div>
        </section>
        <footer className="form-footer">
          <div className="form-footer__btns">
            {addChild.isLoading && addChild.status === "finish"
              ? (
                <button
                  className="btn btn--blue btn--disabled btn--spinner btn--lg btn--mob-fixed">Create &amp; finish</button>
              )
              : (
                <button
                  className="btn btn--blue btn--lg btn--mob-fixed"
                  onClick={this
                  .props
                  .handleSubmit(values => this.props.handleFormSubmit({
                    ...values,
                    buttonAction: "finish"
                  }))}>
                  Create &amp; finish
                </button>
              )}
            {addChild.isLoading && addChild.status === "continue"
              ? (
                <button
                  className="btn btn--green btn--disabled btn--spinner btn--lg btn--mob-fixed">Create &amp; continue</button>
              )
              : (
                <button
                  className="btn btn--green btn--lg btn--mob-fixed"
                  onClick={this
                  .props
                  .handleSubmit(values => this.props.handleFormSubmit({
                    ...values,
                    buttonAction: "continue"
                  }))}>
                  Create &amp; continue
                </button>
              )}
          </div>
          <div className="form-progress">
            <Field name="uuid" type="hidden" value="" component="input"/>
            <div className="form-progress--steps">Step 1 of 3</div>
            <div className="form-progress--bar">
              <div
                className="form-progress--done"
                style={{
                width: "33.3%"
              }}/>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

CreateChildForm1 = reduxForm({form: "create_child", destroyOnUnmount: false, forceUnregisterOnUnmount: true})(CreateChildForm1);

CreateChildForm1 = connect(state => ({initialValues: state.addChild.data}))(CreateChildForm1);

export default CreateChildForm1;
