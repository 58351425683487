import merge from "lodash.merge";
import unset from "lodash.unset";
import moment from "moment";
import { normalize } from "normalizr";
import createCachedSelector from "re-reselect";
import { createSelector } from "reselect";
import { observations, observationSchema } from "../schemas";

const initialState = {
  isLoading: true,
  data: null,
  display: null
};

const removeComments = (state, action) => {
  let observation = state.data.entities.observations[action.payload.data.observation];
  observation.comments.splice(observation.comments.indexOf(action.payload.data.uuid), 1);
  let comments = state.data.entities.comments;
  unset(comments, state.data.entities.comments[action.payload.data.uuid]);
  return {
    ...state.data.entities,
    comments: comments,
    observations: merge({}, state.data.entities.observations, observation)
  };
};

const addOrEditComment = (state, data) => {
  let normalisedData = normalize(data, observationSchema);
  if (state.data.result.indexOf(normalisedData.result) !== -1) {
    return {
      entities: merge({}, state.data.entities, normalisedData.entities),
      result: [...state.data.result]
    };
  } else {
    return {
      entities: merge({}, state.data.entities, normalisedData.entities),
      result: [...state.data.result, normalisedData.result]
    };
  }
};

const observationsReducer = (state = initialState, action) => {
  switch (action.type) {
  case "FETCH_OBSERVATIONS":
    return { ...state, isLoading: true };
  case "FETCH_OBSERVATIONS_SUCCESS":
    return {
      ...state,
      data: normalize(action.payload.data, observations),
      display: normalize(action.payload.data, observations),
      isLoading: false
    };
  case "FETCH_OBSERVATIONS_FAIL":
    return { ...state, error: true };
  case "FILTER_OBSERVATIONS":
    return { ...state, display: normalize(action.payload.data, observations) };
  case "ADD_COMMENT_SUCCESS":
    return {
      ...state,
      data: addOrEditComment(state, action.payload.data),
      display: addOrEditComment(state, action.payload.data),
      isLoading: false
    };
  case "EDIT_COMMENT_SUCCESS":
    return {
      ...state,
      data: addOrEditComment(state, action.payload.data),
      display: addOrEditComment(state, action.payload.data),
      isLoading: false
    };
  case "DELETE_COMMENT_SUCCESS":
    return {
      ...state,
      data: {
        ...state.data,
        entities: removeComments(state, action)
      },
      display: {
        ...state.display,
        entities: removeComments(state, action)
      }
    };
  default:
    return state;
  }
};

export default observationsReducer;

const getTitle = (state, props) => state.observations.data.entities.observations[props].title;

const getContent = (state, props) => state.observations.data.entities.observations[props].content;

const getEvidence = (state, props) => state.observations.data.entities.observations[props].evidence;

const getChild = (state, props) =>
  state.observations.data.entities.children[state.observations.data.entities.observations[props].child];

const getKeyPerson = (state, props) =>
  state.observations.data.entities.key_person[state.observations.data.entities.observations[props].key_person];

const getCreatedAt = (state, props) => {
  let date = moment(state.observations.data.entities.observations[props].created_at.date);
  return date.calendar();
};

const getComments = (state, props) =>
  state.observations.data.entities.observations[props].comments.map(comment => {
    let date = moment(state.observations.data.entities.comments[comment].timestamp);
    return {
      ...state.observations.data.entities.comments[comment],
      createdAt: date.calendar()
    };
  });

const getStatements = (state, props) =>
  state.observations.data.entities.observations[props].statements.map(statement => {
    let category = state.observations.data.entities.category[statement];
    return {
      statement: category,
      agegroup: state.observations.data.entities.agegroups[category.agegroup],
      area: state.observations.data.entities.areas[category.area],
      aspect: state.observations.data.entities.aspects[category.aspect]
    };
  });

const getChildVoice = (state, props) => state.observations.data.entities.observations[props].childComment;

const getStatus = (state, props) => state.observations.data.entities.observations[props].status;

const getDisplayObservations = state => state.observations.display.entities.observations;

const getLikes = (state, props) => state.observations.data.entities.observations[props].likes;

export const getObservation = createCachedSelector(
  getTitle,
  getContent,
  getEvidence,
  getChild,
  getKeyPerson,
  getCreatedAt,
  getComments,
  getChildVoice,
  getStatus,
  getStatements,
  getLikes,
  (title, content, evidence, child, keyPerson, createdAt, comments, childVoice, status, statements, likes) => ({
    title,
    description: content,
    evidence,
    child,
    keyPerson,
    createdAt,
    comments,
    childVoice,
    status,
    statements,
    likes
  })
)((state, observationId) => observationId);

export const getDisplay = createSelector(
  getDisplayObservations,
  observations => ({
    observations
  })
);
