import React from "react";
import { Link } from "react-router";

const ListHeader = ({ title, singular = null, newLink = null, icon, cancel = null }) => (
  <div className="header-local">
    <h1 className={`header-local__title header-local__title--${icon}`}>{title}</h1>
    {newLink !== null ? (
      <div className="header-local__cta">
        <Link className="btn btn--green btn--sm btn--mob-sq" to={newLink}>
          New {singular}
        </Link>
      </div>
    ) : (
      ""
    )}
    {cancel !== null ? (
      <div className="header-local__cta">
        <a href={true} className="btn btn--red btn--sm btn--mob-sq" onClick={cancel}>
          Cancel
        </a>
      </div>
    ) : (
      ""
    )}
  </div>
);

export default ListHeader;
