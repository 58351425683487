import { GET_SINGLE_CHILD, UPDATE_CHILD, DELETE_CHILD } from "./actionTypes";

export function getChild(id) {
  return {
    type: GET_SINGLE_CHILD,
    payload: {
      request: {
        method: "GET",
        url: `children/${id}`,
      },
    },
  };
}

export function updateChild(values) {
  return {
    type: UPDATE_CHILD,
    payload: {
      request: {
        method: "POST",
        url: `children/${values.uuid}`,
        data: values,
      },
    },
  };
}

export function deleteChild(uuid) {
  return {
    type: DELETE_CHILD,
    payload: {
      request: {
        method: "DELETE",
        url: `children/${uuid}`,
      },
    },
  };
}
