import React from "react";
import HasPermission from "../containers/HasPermission";
import Avatar from "./Avatar";

const CarersTab = ({
  carers,
  carerMap,
  handleToggleContextMenu,
  handleDelete,
  invites,
  contextOpen,
  handleResendEmail
}) => {
  let contextClass = "";
  return (
    <div>
      <section className="pref-section">
        <h2 className="pref-section__title">Parent &amp; Carer Accounts</h2>
        <p className="form-section__subtitle">
          Here you can view Parent &amp; Carer accounts. New carer accounts can be added from a child's profile page.
        </p>
      </section>

      <section className="pref-section">
        <h3>Active Carer Accounts</h3>
        <p>
          These are currently active carer accounts. Click on a carer to view &amp; edit their details or deactivate
          their account.
        </p>
        <ul className="data-list data-list--users">
          <li className="data-list__item data-list__header">
            <div className="data-list__item__col data-list--users__name">Name</div>
            <div className="data-list__item__col data-list--users__email">Email</div>
            <div className="data-list__item__col data-list--users__role" />
          </li>
          {carerMap.map(carer => (
            <li className="data-list__item data-list--users__item" key={carers[carer].uuid}>
              <div className="data-list__item__col data-list--users__name">
                <a href={`/user/${carers[carer].user_uuid}/`} className="person person--primary">
                  <Avatar
                    size="small"
                    type="carer"
                    userName={carers[carer].first_name + " " + carers[carer].last_name}
                    image={carers[carer].picture}
                  />

                  <div className="person__name">
                    {carers[carer].first_name}&nbsp;{carers[carer].last_name}
                  </div>
                </a>
              </div>

              <div className="data-list__item__col data-list--users__email">{carers[carer].email}</div>

              <div className="data-list__item__col data-list--users__role" />
            </li>
          ))}
        </ul>
      </section>
      <section className="pref-section no-bdr--b no-pad--b">
        <h3>Pending Invites</h3>
        <p>
          These are carers that have been invited to Edna but they have yet to finish signing up by setting a password.
          You can re-send or delete the invite email by clicking on the actions ellipsis.
        </p>
        <ul className="data-list data-list--users">
          <li className="data-list__item data-list__header">
            <div className="data-list__item__col data-list--users__name">Name</div>
            <div className="data-list__item__col data-list--users__email">Email</div>
            <div className="data-list__item__col data-list--users__role">Actions</div>
          </li>

          {invites
              .filter(x => x.invite_type === "parent")
              .map(invite => {
                if (contextOpen === invite.uuid) {
                  contextClass = "context-menu__menu context-menu__menu--open";
                } else {
                  contextClass = "context-menu__menu";
                }
                return (
                  <li className="data-list__item data-list--users__item" key={invite.uuid}>
                    <div className="data-list__item__col data-list--users__name">
                      <span className="person person--primary">
                        <Avatar
                          size="small"
                          type="carer"
                          userName={invite.first_name + " " + invite.last_name}
                          image=""
                        />

                        <div className="person__name">
                          {invite.first_name}&nbsp; {invite.last_name}
                        </div>
                      </span>
                    </div>

                    <div className="data-list__item__col data-list--users__email">{invite.email}</div>

                    <HasPermission allowedRoles={["super_admin", "admin"]}>
                      <div className="data-list__item__col data-list--users__role">
                        <div className="context-menu">
                          <div
                            className="context-menu__btn"
                            onClick={handleToggleContextMenu}
                            key={invite.uuid}
                            id={invite.uuid}
                          />
                          <ul className={contextClass}>
                            <li className="context-menu__item">
                              <a href={true} onClick={handleResendEmail.bind(this, invite.uuid)}>
                              Resend Invite
                              </a>
                            </li>
                            <li className="context-menu__item context-menu__item--del">
                              <a href={true} onClick={handleDelete.bind(this, invite.uuid)}>
                              Delete invite
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </HasPermission>
                  </li>
                );
              })}
        </ul>
      </section>
    </div>
  );
};

export default CarersTab;
