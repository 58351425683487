import React from 'react'
import {Field, reduxForm, Form} from 'redux-form'
import Input from './Input'
import {required, email} from './Validators'
import logo from '../images/assets/edna-logo-dark.svg'
import {Link} from "react-router";
import { connect } from "react-redux";

class ForgotPasswordForm extends React.Component {

    handleInputChange = (event) => {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        this.setState({
            [name]: value
        })
    }

    render() {
        return (
            <div>
                <div className='signUp signUp--wForm'>
                    <header className='signUp__header'>
                        <img alt='edna logo' className='signUp__header__logo' src={logo}/>
                    </header>
                    <div className='signUp__main'>
                        <div className='signUp__text-area signUp__text-area--intro'>
                            <h1>Forgotten your password?</h1>
                            <p>Don't worry about it, we've got your back. Pop in your email below and we'll send you
                                instructions on how you can reset it.</p>
                        </div>
                        {this.props.errors !== null ?
                          //TODO: Drop in markup for errors
                          <div className="errors">{this.props.errors}</div>
                        :
                          ""
                        }
                        <Form onSubmit={this.props.handleSubmit(this.props.handleFormSubmit)}>
                            <div className='standard-inputs'>
                                <div className='standard-input standard-input--lg'>
                                    <Field name="email"
                                           targetName="email"
                                           type="text"
                                           value=""
                                           label="Email"
                                           hint="The email address associated with your Edna account"
                                           component={Input}
                                           validate={[required, email]}
                                           handleChange={this.handleInputChange}/>
                                </div>
                            </div>
                            <button className='btn btn--green btn--lg btn--mob-fixed' type="submit">Reset Password</button>
                        </Form>
                    </div>
                    <div className='no-account'>
                        Wait! I've remembered it now
                        <Link className='btn btn--sm btn--empty' to="/login">Go back</Link>
                    </div>
                </div>
            </div>
        )
    }
}

ForgotPasswordForm = reduxForm({
    form: 'forgotpassword',
})(ForgotPasswordForm)

ForgotPasswordForm = connect(
  state => ({
      initialValues: state.forgotPassword.data
  })
)(ForgotPasswordForm);

export default ForgotPasswordForm
