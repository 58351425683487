/* eslint-disable no-unreachable */
const initialState = {
    tabs: {
        isActive: "info"
    }
};

const tabsReducer = (state = initialState, action) => {
    switch (action.type) {
    case "SWITCH_TAB":
        return {...state, tabs: {isActive: action.payload.tabs}};
        break;
    default:
        return state;
        break;
    }
    return state;
};

export default tabsReducer;
