import React from "react";
import DefaultChildAvatar from "../images/assets/DefaultChildAvatar.svg";
import CharacteristicEdit from "./CharacteristicEdit";

const COELModal = ({ toggleModal, child, characteristics, handleCOELToggle, selectedCoel }) => (
  <div className="modal modal-open">
    <div className="modal__content">
      <div className="modal__header">
        <h2>Add Characteristics of Effective Learning</h2>
        <a href={true} className="btn-cancel" onClick={toggleModal}>
          Cancel
        </a>
      </div>

      <div className="modal__main">
        <div className="characteristics-child">
          <div className="select-list__child-header">
            <div className="avatar avatar--small">
              {child.picture !== null ? (
                <img alt={child.first_name + " " + child.last_name} src={child.picture} />
              ) : (
                <img src={DefaultChildAvatar} alt="Default avatar" />
              )}
            </div>
            <div className="select-list__child-header--text">
              <div className="child-header__name">
                {child.first_name} {child.last_name}
              </div>
              <div className="child-header__helpertxt">
                How did {child.first_name}
                engage with people and their environment?
              </div>
            </div>
          </div>
          {characteristics.map(characteristic => (
            <CharacteristicEdit
              characteristic={characteristic}
              key={characteristic.uuid}
              handleCOELToggle={handleCOELToggle}
              child={child}
              selectedCoel={selectedCoel}
            />
          ))}
        </div>
      </div>
      <div className="modal__footer">
        <div className="modal__footer__controls">
          <a href={true} className="btn btn--green btn--sm btn--mob-fullw" onClick={toggleModal}>
            Add
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default COELModal;
