import React from "react";
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import Input from "./Input";
import RenderDatePicker from "./RenderDatePicker";

class CreateChildForm3 extends React.Component {
  render() {
    const {addChild, user, workers} = this.props;
    return (
      <div>
        <section className="form-section form-section--title">
          <h2 className="form-section__title">
            {addChild.data.first_name}&nbsp;and {user.data.school.name}
          </h2>
          <p className="form-section__intro">
            Finally lets add some information about {addChild.data.first_name}'s attendance at {user.data.school.name}.
          </p>
        </section>
        <section className="form-section">
          <div className="standard-inputs">
            <div className="standard-input standard-input--lg">
              <label>Start date</label>
              <p>
                The date {addChild.data.first_name}&nbsp; is due to start at {user.data.school.name}&nbsp;or the date they started. If left blank this will default to today's date.
              </p>
              <Field
                name="start_date"
                targetName="start_date"
                component={RenderDatePicker}
                futureDates={true}/>
            </div>
          </div>
        </section>
        <section className="form-section">
          <div className="standard-inputs">
            <div className="standard-input standard-input--lg">
              <label>Key Person</label>
              <p>
                Select who will be {addChild.data.first_name}&nbsp;key person at {user.data.school.name}
              </p>
              <Field name="key-person" component="select">
                <option disabled="disabled" hidden="" selected="selected" value="">
                  Select key person...
                </option>
                {workers
                    .data
                    .map((worker) => {
                      if (worker.active) {
                        return (
                          <option value={worker.uuid}>
                            {worker.first_name} {worker.last_name}
                          </option>
                        );
                      }
                      return null;
                    })}
              </Field>
            </div>
          </div>
        </section>
        <section className="form-section">
          <div className="standard-inputs">
            <div className="standard-input standard-input--lg">
              <label>Time Table</label>
              <p>
                Select the sessions that {addChild.data.first_name}&nbsp;is due to attend at {user.data.school.name}.
              </p>
              <ul className="radio-list">
                <li className="radio-list__item">
                  <div className="radio-list__item__label">Monday</div>
                  <div className="radio-set">
                    <div className="standard-radio">
                      <div className="edna-radio">
                        <label>
                          <Field name="mon_morn" component="input" type="checkbox"/>
                          <div className="styled_radio"/>
                        </label>
                      </div>
                      <label>Morn</label>
                    </div>
                    <div className="standard-radio">
                      <div className="edna-radio">
                        <label>
                          <Field name="mon_aft" component="input" type="checkbox"/>
                          <div className="styled_radio"/>
                        </label>
                      </div>
                      <label>Aft</label>
                    </div>
                  </div>
                </li>
                <li className="radio-list__item">
                  <div className="radio-list__item__label">Tuesday</div>
                  <div className="radio-set">
                    <div className="standard-radio">
                      <div className="edna-radio">
                        <label>
                          <Field name="tues_morn" component="input" type="checkbox"/>
                          <div className="styled_radio"/>
                        </label>
                      </div>
                      <label>Morn</label>
                    </div>
                    <div className="standard-radio">
                      <div className="edna-radio">
                        <label>
                          <Field name="tues_aft" component="input" type="checkbox"/>
                          <div className="styled_radio"/>
                        </label>
                      </div>
                      <label>Aft</label>
                    </div>
                  </div>
                </li>
                <li className="radio-list__item">
                  <div className="radio-list__item__label">Wednesday</div>
                  <div className="radio-set">
                    <div className="standard-radio">
                      <div className="edna-radio">
                        <label>
                          <Field name="wed_morn" component="input" type="checkbox"/>
                          <div className="styled_radio"/>
                        </label>
                      </div>
                      <label>Morn</label>
                    </div>
                    <div className="standard-radio">
                      <div className="edna-radio">
                        <label>
                          <Field name="wed_aft" component="input" type="checkbox"/>
                          <div className="styled_radio"/>
                        </label>
                      </div>
                      <label>Aft</label>
                    </div>
                  </div>
                </li>
                <li className="radio-list__item">
                  <div className="radio-list__item__label">Thursday</div>
                  <div className="radio-set">
                    <div className="standard-radio">
                      <div className="edna-radio">
                        <label>
                          <Field name="thur_morn" component="input" type="checkbox"/>
                          <div className="styled_radio"/>
                        </label>
                      </div>
                      <label>Morn</label>
                    </div>
                    <div className="standard-radio">
                      <div className="edna-radio">
                        <label>
                          <Field name="thur_aft" component="input" type="checkbox"/>
                          <div className="styled_radio"/>
                        </label>
                      </div>
                      <label>Aft</label>
                    </div>
                  </div>
                </li>
                <li className="radio-list__item">
                  <div className="radio-list__item__label">Friday</div>
                  <div className="radio-set">
                    <div className="standard-radio">
                      <div className="edna-radio">
                        <label>
                          <Field name="fri_morn" component="input" type="checkbox"/>
                          <div className="styled_radio"/>
                        </label>
                      </div>
                      <label>Morn</label>
                    </div>
                    <div className="standard-radio">
                      <div className="edna-radio">
                        <label>
                          <Field name="fri_aft" component="input" type="checkbox"/>
                          <div className="styled_radio"/>
                        </label>
                      </div>
                      <label>Aft</label>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className="form-section">
          <div className="standard-inputs">
            <div className="standard-input standard-input--lg">
              <label>Special educational needs or disabilities?</label>
              <p>Does {addChild.data.first_name}&nbsp;have any special educational needs or
disabilities? If so select ‘yes’ below.</p>
              <div className="radio-set">
                <div className="standard-radio">
                  <div className="edna-radio">
                    <label>
                      <Field name="sen" component="input" type="radio" value="1"/>
                      <div className="styled_radio"/>
                    </label>
                  </div>
                  <label>Yes</label>
                </div>
                <div className="standard-radio">
                  <div className="edna-radio">
                    <label>
                      <Field name="sen" component="input" type="radio" value="0"/>
                      <div className="styled_radio"/>
                    </label>
                  </div>
                  <label>No</label>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="form-section">
          <div className="standard-inputs">
            <div className="standard-input standard-input--lg">
              <label>Unique Pupil Number (UPN)</label>
              <p>
                Enter {addChild.data.first_name}&nbsp;Unique Pupil Number. Information on UPNs and how
to generate them can be found&nbsp;
                <a
                  href="https://www.gov.uk/government/publications/unique-pupil-numbers"
                  target="_blank"
                  rel="noopener noreferrer">
                  here.</a>
              </p>
              <Field name="upn" targetName="upn" value="" component={Input}/>
            </div>
          </div>
        </section>
        <section className="form-section">
          <div className="standard-inputs">
            <div className="standard-input standard-input--lg">
              <label>Free school meals</label>
              <p>Does {addChild.data.first_name}&nbsp;receive free school meals?</p>
              <div className="radio-set">
                <div className="standard-radio">
                  <div className="edna-radio">
                    <label>
                      <Field name="meals" component="input" type="radio" value="1"/>
                      <div className="styled_radio"/>
                    </label>
                  </div>
                  <label>Yes</label>
                </div>
                <div className="standard-radio">
                  <div className="edna-radio">
                    <label>
                      <Field name="meals" component="input" type="radio" value="0"/>
                      <div className="styled_radio"/>
                    </label>
                  </div>
                  <label>No</label>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="form-section">
          <div className="standard-inputs">
            <div className="standard-input standard-input--lg">
              <label>ESL</label>
              <p>Does {addChild.data.first_name}&nbsp;speak English as a second language?</p>
              <div className="radio-set">
                <div className="standard-radio">
                  <div className="edna-radio">
                    <label>
                      <Field name="esl" component="input" type="radio" value="1"/>
                      <div className="styled_radio"/>
                    </label>
                  </div>
                  <label>Yes</label>
                </div>
                <div className="standard-radio">
                  <div className="edna-radio">
                    <label>
                      <Field name="esl" component="input" type="radio" value="0"/>
                      <div className="styled_radio"/>
                    </label>
                  </div>
                  <label>No</label>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="form-section">
          <div className="standard-inputs">
            <div className="standard-input standard-input--lg">
              <label>EYPP</label>
              <p>Does {addChild.data.first_name}&nbsp;receive EYPP assistance?</p>
              <div className="radio-set">
                <div className="standard-radio">
                  <div className="edna-radio">
                    <label>
                      <Field name="EYPP" component="input" type="radio" value="1"/>
                      <div className="styled_radio"/>
                    </label>
                  </div>
                  <label>Yes</label>
                </div>
                <div className="standard-radio">
                  <div className="edna-radio">
                    <label>
                      <Field name="EYPP" component="input" type="radio" value="0"/>
                      <div className="styled_radio"/>
                    </label>
                  </div>
                  <label>No</label>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="form-section">
          <div className="standard-inputs">
            <div className="standard-input standard-input--lg">
              <label>Free Childcare</label>
              <p>Does {addChild.data.first_name}&nbsp;receive free childcare assistance?</p>
              <div className="radio-set">
                <div className="standard-radio">
                  <div className="edna-radio">
                    <label>
                      <Field name="free_childcare" component="input" type="radio" value="1"/>
                      <div className="styled_radio"/>
                    </label>
                  </div>
                  <label>Yes</label>
                </div>
                <div className="standard-radio">
                  <div className="edna-radio">
                    <label>
                      <Field name="free_childcare" component="input" type="radio" value="0"/>
                      <div className="styled_radio"/>
                    </label>
                  </div>
                  <label>No</label>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer className="form-footer">
          <div className="form-footer__btns">
            {addChild.isLoading && addChild.status === "finish"
              ? (
                <button
                  className="btn btn--blue btn--disabled btn--spinner btn--lg btn--mob-fixed">Create &amp; finish</button>
              )
              : (
                <button
                  className="btn btn--blue btn--lg btn--mob-fixed"
                  onClick={this
                      .props
                      .handleSubmit(values => this.props.handleFormSubmit({
                        ...values,
                        buttonAction: "finish"
                      }))}>
                  Create &amp; finish
                </button>
              )}
          </div>
          <div className="form-progress">
            <div className="form-progress--steps">Step 3 of 3</div>
            <div className="form-progress--bar">
              <div
                className="form-progress--done"
                style={{
                  width: "100%"
                }}/>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

CreateChildForm3 = reduxForm({form: "create_child", destroyOnUnmount: true, forceUnregisterOnUnmount: true})(CreateChildForm3);

CreateChildForm3 = connect(state => ({initialValues: state.addChild.data}))(CreateChildForm3);

export default CreateChildForm3;
