import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router";
import HasPermission from "../containers/HasPermission";
import DefaultUserAvatar from "../images/assets/DefaultUserAvatar.svg";
import Avatar from "./Avatar";

const UsersTab = ({
  workers,
  invites,
  handleToggleContextMenu,
  handleToggleDeleteModal,
  contextOpen,
  handleResendEmail,
  deleteOpen,
  handleDelete
}) => {
  let contextClass = "";
  return (
    <div>
      <section className="pref-section">
        <h2 className="pref-section__title">User Accounts</h2>
        <p className="form-section__subtitle">Here you can view, edit, update and create new user accounts.</p>
      </section>

      <section className="pref-section">
        <h3>Active Staff Accounts</h3>
        <p>
          These are currently active staff accounts. Click on a user to view &amp; edit their details or deactivate
          their account.
        </p>
        <ul className="data-list data-list--users">
          <li className="data-list__item data-list__header">
            <div className="data-list__item__col data-list--users__name">Name</div>
            <div className="data-list__item__col data-list--users__email">Email</div>
            <div className="data-list__item__col data-list--users__role">Role</div>
          </li>
          {workers.map(worker => {
            if (worker.active) {
              return (
                <li key={worker.uuid} className="data-list__item data-list--users__item">
                  <div className="data-list__item__col data-list--users__name">
                    <a href={`user/${worker.user}`} className="person person--primary">
                      <Avatar
                        size="small"
                        image={worker.picture}
                        userName={worker.first_name + " " + worker.last_name}
                        type="user"
                      />

                      <div className="person__name">
                        {worker.first_name}&nbsp; {worker.last_name}
                      </div>
                    </a>
                  </div>
                  <div className="data-list__item__col data-list--users__email">{worker.email}</div>
                  <div className="data-list__item__col data-list--users__role">{worker.role}</div>
                  {/* <span>{worker.role}</span> */}
                </li>
              );
            } else {
              return false;
            }
          })}
        </ul>
        <Link to="/workers/add" className="btn btn--green btn--sm btn--nobrdr">
          Add New User
        </Link>
      </section>
      <section className="pref-section no-bdr--b no-pad--b">
        <h3>Pending Invites</h3>
        <p>
          These are staff members that you have added to Edna but they have yet to finish signing up by setting a
          password. You can re-send or delete the invite email by selecting ‘Edit’
        </p>
        <ul className="data-list data-list--users">
          <li className="data-list__item data-list__header">
            <div className="data-list__item__col data-list--users__name">Name</div>
            <div className="data-list__item__col data-list--users__email">Email</div>
            <div className="data-list__item__col data-list--users__role">Actions</div>
          </li>

          {invites
              .filter(x => x.invite_type === "worker")
              .map(invite => {
                if (contextOpen === invite.uuid) {
                  contextClass = "context-menu__menu context-menu__menu--open";
                } else {
                  contextClass = "context-menu__menu";
                }
                return (
                  <li className="data-list__item data-list--users__item" key={invite.uuid}>
                    <div className="data-list__item__col data-list--users__name">
                      <span className="person person--primary">
                        <Avatar size="small" image="" userName={invite.first_name + " " + invite.last_name} type="user" />
                        <div className="person__name">
                          {invite.first_name}&nbsp;{invite.last_name}
                        </div>
                      </span>
                    </div>

                    <div className="data-list__item__col data-list--users__email">{invite.email}</div>

                    <HasPermission allowedRoles={["super_admin", "admin"]}>
                      <div className="data-list__item__col data-list--users__role">
                        <div className="context-menu">
                          <div
                            className="context-menu__btn"
                            onClick={handleToggleContextMenu}
                            key={invite.uuid}
                            id={invite.uuid}
                          />
                          <ul className={contextClass}>
                            <li className="context-menu__item">
                              <a href={true} onClick={handleResendEmail.bind(this, invite.uuid)}>
                              Resend Invite
                              </a>
                            </li>
                            <li className="context-menu__item context-menu__item--del">
                              <a href={true} onClick={handleDelete.bind(this, invite.uuid)}>
                              Delete invite
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </HasPermission>
                  </li>
                );
              })}
        </ul>
      </section>

      <section className="pref-section no-bdr--b np-pad--b">
        <h3>Deactivated Accounts</h3>
        <p>These are accounts that have been deactivated. These accounts can no longer access Edna.</p>
        <ul className="data-list data-list--users">
          <li className="data-list__item data-list__header">
            <div className="data-list__item__col data-list--users__name">Name</div>
            <div className="data-list__item__col data-list--users__email">Email</div>
            <div className="data-list__item__col data-list--users__role">Role</div>
          </li>

          {workers.map(worker => {
            if (!worker.active) {
              return (
                <li key={worker.uuid} className="data-list__item data-list--users__item">
                  <div className="data-list__item__col data-list--users__name">
                    <a href={`user/${worker.user}`} className="person person--primary">
                      <div className="avatar avatar--small">
                        {worker.picture !== null ? (
                          <img src={worker.picture} alt={`${worker.first_name} ${worker.last_name}`} />
                        ) : (
                          <img alt="default avatar" src={DefaultUserAvatar} />
                        )}
                      </div>
                      <div className="person__name">
                        {worker.first_name}&nbsp;{worker.last_name}
                      </div>
                    </a>
                  </div>
                  <div className="data-list__item__col data-list--users__email">{worker.email}</div>
                  <div className="data-list__item__col data-list--users__role">{worker.role}</div>
                  {/* <span>{worker.role}</span> */}
                </li>
              );
            } else {
              return false;
            }
          })}
        </ul>
      </section>
    </div>
  );
};

UsersTab.propTypes = {
  workers: PropTypes.arrayOf(
    PropTypes.shape({
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired,
      invites: PropTypes.shape({
        uuid: PropTypes.string.isRequired,
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        processed: PropTypes.bool.isRequired,
        invite_type: PropTypes.string.isRequired,
        role: PropTypes.string.isRequired
      })
    })
  ).isRequired
};
export default UsersTab;
