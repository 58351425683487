import React from "react";
import CharacteristicChildEdit from "./CharacteristicChildEdit";

const CharacteristicEdit = ({
  characteristic,
  handleCOELToggle,
  child,
  selectedCoel
}) => {
  return (
    <ul className="select-list select-list--characteristics">
      <li className="list-seperator" key={characteristic.uuid}>
        {characteristic.name}
      </li>
      {characteristic.children.map(charChild => (
        <CharacteristicChildEdit
          characteristic={characteristic}
          charChild={charChild}
          key={charChild.uuid}
          handleCOELToggle={handleCOELToggle}
          child={child}
          selectedCoel={selectedCoel}
        />
      ))}
    </ul>
  );
};

export default CharacteristicEdit;
