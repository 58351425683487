import React from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { bindActionCreators } from "redux";
import * as addWorkerActions from "../actions/addWorkerActions";
import * as FlashActions from "../actions/flashActions";
import CreateWorkerForm from "../components/CreateWorkerForm";
import Loader from "../components/Loader";

class CreateWorkerContainer extends React.Component {
  componentDidMount = () => {
    this.props.getRoles();
  };

  handleFormSubmit = values => {
    values = {
      ...values,
      inviter: this.props.user.data.uuid
    };
    this.props
        .createWorker(values)
        .then(() => {
          this.props.addMessageToFlash("Success! The invite has been sent", "success", "normal");
          browserHistory.push("/settings");
        })
        .catch(() => {
          this.props.addMessageToFlash("Oops! The invite could not be sent", "error", "normal");
        });
  };

  render() {
    return (
      <div>
        {this.props.addWorker.isComplete && !this.props.addWorker.isLoading ? (
          <CreateWorkerForm
            handleFormSubmit={this.handleFormSubmit}
            roles={this.props.addWorker.roles}
            user={this.props.user}
          />
        ) : (
          <Loader />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({ addWorker: state.addWorker, user: state.user });

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...addWorkerActions,
      ...FlashActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateWorkerContainer);
