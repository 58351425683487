import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ChildActions from "../actions/childActions";
import * as WorkerActions from "../actions/workerActions";
import ChildListHeader from "../components/ChildListHeader";
import ChildrenList from "../components/ChildrenList";
import Loader from "../components/Loader";
import PersonTab from "../components/PersonTab";

class ChildrenContainer extends React.Component {
  state = {
    nameTerm: "",
    workerTerm: "",
    groupTerm: "",
    sortType: "",
    sortDirection: ""
  };

  componentDidMount() {
    this.props.getChildren().then(() => {
      this.props.getWorkers();
    });
  }

  filterChildren = () => {
    this.props.filterChildren(
      this.props.children.data.entities.children,
      this.props.children.data.result,
      this.state
    );
  };

  sortChildren = () => {
    if (typeof this.props.children.display.entities.children !== "undefined") {
      this.props.sortChildren(
        this.props.children.display.entities.children,
        this.state
      );
    }
  };

  handleFilterChange = event => {
    switch (event.target.name) {
      case "name":
        this.setState(
          {
            nameTerm: event.target.value
          },
          this.filterChildren
        );
        break;
      case "worker":
        this.setState(
          {
            workerTerm: event.target.value
          },
          this.filterChildren
        );
        break;
      default:
        break;
    }
  };

  handleSort = event => {
    switch (event.target.name) {
      case "name":
        switch (this.state.sortDirection) {
          case "":
            this.setState(
              {
                sortType: "name",
                sortDirection: "asc"
              },
              this.sortChildren
            );
            break;
          case "asc":
            this.setState(
              {
                sortType: "name",
                sortDirection: "desc"
              },
              this.sortChildren
            );
            break;
          case "desc":
            this.setState(
              {
                sortType: "name",
                sortDirection: ""
              },
              this.sortChildren
            );
            break;
          default:
            return false;
        }
        break;
      case "worker":
        switch (this.state.sortDirection) {
          case "":
            this.setState(
              {
                sortType: "worker",
                sortDirection: "asc"
              },
              this.sortChildren
            );
            break;
          case "asc":
            this.setState(
              {
                sortType: "worker",
                sortDirection: "desc"
              },
              this.sortChildren
            );
            break;
          case "desc":
            this.setState(
              {
                sortType: "worker",
                sortDirection: ""
              },
              this.sortChildren
            );
            break;
          default:
            return false;
        }
        break;
      case "age":
        switch (this.state.sortDirection) {
          case "":
            this.setState(
              {
                sortType: "age",
                sortDirection: "asc"
              },
              this.sortChildren
            );
            break;
          case "asc":
            this.setState(
              {
                sortType: "age",
                sortDirection: "desc"
              },
              this.sortChildren
            );
            break;
          case "desc":
            this.setState(
              {
                sortType: "age",
                sortDirection: ""
              },
              this.sortChildren
            );
            break;
          default:
            return false;
        }
        break;
      default:
        return false;
    }
  };

  render() {
    if (!this.props.workers.isComplete || !this.props.children.isComplete) {
      return <Loader />;
    } else {
      return (
        <div>
          <ChildListHeader
            singular="Child"
            title="Children"
            newLink="/children/create"
            icon="children"
          />
          <PersonTab />{" "}
          {this.props.children.isLoading ? (
            <Loader />
          ) : (
            <ChildrenList
              childMap={this.props.children.display.result.filter(
                child => child !== null
              )}
              children={this.props.children.display.entities.children}
              workers={this.props.workers.data}
              handleFilterChange={this.handleFilterChange}
              handleSort={this.handleSort}
            />
          )}
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  children: state.children,
  workers: state.workers
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...ChildActions,
      ...WorkerActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChildrenContainer);
