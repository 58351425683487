import React from "react";

const CharacteristicChild = ({charChild, handleCOELToggle, child, selectedCoel}) => {

    let charClass = "";
    if (selectedCoel.find(x => x.characteristic === charChild.uuid && x.child === child) !== undefined) {
        charClass = "select-list--characteristic selected";
    } else {
        charClass = "select-list--characteristic";
    }

    return (
      <li className={charClass} key={charChild.uuid}
          onClick={handleCOELToggle.bind(this, {characteristic: charChild.uuid, child: child})}>
          <div className='characteristic-container'>
              {charChild.name}
          </div>
      </li>
    );
};

export default CharacteristicChild;
