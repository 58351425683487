import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import ObservationsTableEmpty from "../components/ObservationsTableEmpty";
import WorkerObservationsTableEmpty from "../components/WorkerObservationTableEmpty";
import { getDisplay } from "../reducers/observations";
import ObservationItem from "./ObservationItem";

const ObservationsTable = ({
  observations,
  childCount,
  worker,
  user,
  displayObservations
}) => {
  let emptyState = "";
  if (typeof worker !== "undefined") {
    emptyState = <WorkerObservationsTableEmpty worker={worker} />;
  } else {
    emptyState = <ObservationsTableEmpty childCount={childCount} />;
  }

  let observationsNumber = 0;

  if (typeof displayObservations.observations !== "undefined") {
    if (Object.keys(displayObservations.observations).length > 0) {
      observationsNumber = Object.keys(displayObservations.observations).map(
        key => {
          if (
            displayObservations.observations[key].status === "published" ||
            (displayObservations.observations[key].status === "draft" &&
              displayObservations.observations[key].key_person ===
                user.person.uuid)
          ) {
            return key;
          } else {
            return false;
          }
        }
      ).length;
    }
  }

  return (
    <ul className="data-list data-list--observations">
      <li className="data-list__item data-list__header">
        <div className="data-list--observations__observation">Observation</div>
        <div className="data-list--observations__meta">
          <div className="data-list--observations__date">Date</div>
          <div className="data-list--observations__person">Observed by</div>
          <div className="data-list--observations__status">Status</div>
          <div className="data-list--observations__curriculum" />
        </div>
      </li>
      {observationsNumber > 0
        ? Object.keys(displayObservations.observations).map(observation => {
          if (
            displayObservations.observations[observation].status ===
                "draft" &&
              displayObservations.observations[observation].key_person !==
                user.person.uuid &&
              displayObservations.observations[observation] !== null
          ) {
            return null;
          }
          return (
            <ObservationItem key={observation} observationId={observation} />
          );
        })
        : emptyState}
    </ul>
  );
};

ObservationsTable.propTypes = {
  childCount: PropTypes.number.isRequired,
  observations: PropTypes.arrayOf(PropTypes.string),
  worker: PropTypes.shape({
    uuid: PropTypes.string.isRequired
  }),
  user: PropTypes.shape({
    uuid: PropTypes.string.isRequired
  })
};

const mapDispatchToProps = dispatch => ({});

const mapStateToProps = state => ({
  displayObservations: getDisplay(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ObservationsTable);
