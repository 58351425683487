import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {browserHistory} from "react-router";
import * as FlashActions from "../actions/flashActions";
import * as BillingActions from "../actions/BillingActions";
import {getWorkers} from "../actions/workerActions";
import AccountLockedAdmin from "../components/AccountLockedAdmin";
import AccountLockedWorker from "../components/AccountLockedWorker";
import Loader from "../components/Loader";
import {userRefresh} from "../actions/userActions";

class AccountLockedContainer extends Component {
  state = {
    plan: null,
    loading: false
  }

  componentDidMount = () => {
    this
      .props
      .getWorkers();
    this
      .props
      .getPlans();
  }

  sendTokenToServer = (token) => {
    this.setState({loading: true});
    if (this.state.plan === null) {
      this
        .props
        .addMessageToFlash('You must choose a plan to proceed', 'error', null);
      return null;
    }
    this
      .props
      .sendToken(token, this.state.plan, this.props.user.data.school.uuid)
      .then(() => {
        // Rehydrate user in store
        this
          .props
          .userRefresh()
          .then(() => {
            this.setState({loading: false});
            browserHistory.replace("/");
          });
      });
  }

  handleChangePlan = (event) => {
    this.setState({plan: event.target.value});
  }

  render() {
    if (this.props.workers.data === null || this.props.billing.plans === null) {
      return <Loader/>;
    } else {
      if (this.props.user.data.role.name === 'super_admin') {
        return <AccountLockedAdmin
          sendTokenToServer={this.sendTokenToServer}
          handleChangePlan={this.handleChangePlan}
          plans={this.props.billing.plans}
          user={this.props.user.data}
          billingLoading={this.state.loading}/>;
      } else {
        return <AccountLockedWorker workers={this.props.workers.data}/>;
      }
    }
  }
}

const mapStateToProps = (state) => ({user: state.user, workers: state.workers, billing: state.billing});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getWorkers,
  ...FlashActions,
  ...BillingActions,
  userRefresh
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AccountLockedContainer);
