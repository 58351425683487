import { GET_WORKERS } from "./actionTypes";

export function getWorkers() {
  return {
    type: GET_WORKERS,
    payload: {
      request: {
        method: "GET",
        url: "workers",
      },
    },
  };
}
