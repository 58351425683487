import { GET_ROLES, CREATE_WORKER } from "./actionTypes";

export function createWorker(values) {
  return {
    type: CREATE_WORKER,
    payload: {
      request: {
        method: "POST",
        url: "workers",
        data: values,
      },
    },
  };
}

export function getRoles() {
  return {
    type: GET_ROLES,
    payload: {
      request: {
        method: "GET",
        url: "roles",
      },
    },
  };
}
