import React from "react";
import Avatar from "./Avatar";

const Carer = ({ child, carer, handleAddParent, handleRemoveParent, loading }) => {
  let selected = false;
  if (child.guardians.filter(x => x.email === carer.email).length > 0) {
    selected = true;
  }

  let childString = "";
  if (carer.children.length > 0) {
    for (let index = 0; index < carer.children.length; index++) {
      if (index === 0) {
        childString += `${carer.children[index].first_name} ${carer.children[index].last_name.substr(0, 1)} `;
      } else {
        childString += `, ${carer.children[index].first_name} ${carer.children[index].last_name.substr(0, 1)} `;
      }
    }
  }
  let classState = "";
  if (loading) {
    classState = "data-list__item data-list--addCarer__item addCarer-loading";
  } else {
    if (selected) {
      classState = "data-list__item data-list--addCarer__item addCarer-selected";
    } else {
      classState = "data-list__item data-list--addCarer__item";
    }
  }

  return (
    <li
      className={classState}
      data-contextid={carer.uuid}
      key={carer.uuid}
      onClick={selected ? handleRemoveParent : handleAddParent}>
      <div className="carer-mainInfo">
        <Avatar size="small" userName={carer.first_name + " " + carer.last_name} image={carer.picture} type="career" />
        <div className="carer-mainInfo__nameEmail">
          <div className="carer-mainInfo__name">
            {carer.first_name}
            &nbsp;
            {carer.last_name}
          </div>
          <div className="carer-mainInfo__email">
            {carer.email}
            &nbsp; - Carer for: {carer.children.length > 0 ? <span className="bold">{childString}</span> : ""}
          </div>
        </div>
      </div>
    </li>
  );
};

Carer.propTypes = {};

export default Carer;
