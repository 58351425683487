import React from "react";
import Avatar from "./Avatar";

const ChildComment = ({ comment, child }) => (
  <div className="observation__content__child-voice">
    <div className="child-voice__avatar">
      <Avatar
        border
        size="medium"
        image={child.picture}
        userName={child.first_name + " " + child.last_name}
        type="child"
      />
    </div>
    <div className="child-voice__text">{comment}</div>
  </div>
);

export default ChildComment;
