import styled, { keyframes } from "styled-components";
import Theme from "../theme";
import SendIcon from "../images/icons/icon-send-msg.png";
import Spinner from "../images/icons/btn-spinner.png";

const Bounce = keyframes`
  16.65%{
    background-position: left 21px center;
  }
  33.3%{
    background-position: left 13px center;
  }
  
  49.95%{
    background-position: left 19px center;
  }
  
  66.6%{
    background-position: left 15px center;
  }
  
  83.25%{
    background-position: left 18px center;
  }
  
  100%{
    background-position: left 14px center;
  }
` 
const Spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const CommentSendButton = styled.div`
  background-color: ${Theme.colorBrandGreen};
  width: 44px;
  min-width: 44px;
  height: 44px;
  border-radius: 23px;
  cursor: pointer;
  transition: .3s;
  background-image: ${props => props.loading === true ? `url(${Spinner})` : `url(${SendIcon})`} ;
  background-repeat: no-repeat;
  background-position: ${props => props.loading === true ? "center center" : "left 14px center"} ;
  background-size: 20px 20px;
  animation:  ${props => props.loading === true ? `${Spin} 1s linear infinite` : "none"} ;
  &:hover{
    animation: ${props => props.loading === true ? `${Spin} 1s linear infinite` : `${Bounce} .5s ease-in-out 1`} ;  
  }
`;

export default CommentSendButton;