import React from "react";
import HasPermission from "../containers/HasPermission";
import ChildCarers from "./ChildCarers";
import ChildObservationsTable from "./ChildObservationsTable";
import ChildProfileDescription from "./ChildProfileDescription";
import ChildProfileDisplay from "./ChildProfileDisplay";
import ChildProfileEdit from "./ChildProfileEdit";
import ChildProfileESL from "./ChildProfileESL";
import ChildProfileEYPP from "./ChildProfileEYPP";
import ChildProfileFreeChildcare from "./ChildProfileFreeChildcare";
import ChildProfileHeader from "./ChildProfileHeader";
import ChildProfileMeals from "./ChildProfileMeals";
import ChildProfilePreferredName from "./ChildProfilePreferredName";
import ChildProfileSEN from "./ChildProfileSEN";
import ChildProfileTabs from "./ChildProfileTabs";
import ChildProfileUPN from "./ChildProfileUPN";
import ChildProgression from "./ChildProgression";
import Dialog from "./Dialog";
import ParentModal from "./ParentModal";
import Timetable from "./Timetable";

const ChildProfile = ({
  child,
  worker,
  handleSwitchTab,
  tabs,
  edit,
  workers,
  handleFormSubmit,
  contextOpen,
  handleToggleContextMenu,
  handleToggleCarerContext,
  handleToggleDeleteModal,
  handleDelete,
  deleteOpen,
  handleToggleParentModal,
  parentOpen,
  handleAddParent,
  handleRemoveParent,
  handleParentInvite,
  carers,
  carerMap,
  filterCarers,
  handleFlags,
  parentsLoading,
  carerContextOpen,
  invites,
  handleResendInvite,
  handleDeleteInvite,
  user
}) => {
  let deleteModal = "";
  let parentModal = "";
  if (deleteOpen) {
    deleteModal = (
      <Dialog deleteType="child" handleDelete={handleDelete} handleToggle={handleToggleDeleteModal} uuid={child.uuid} />
    );
  }

  if (parentOpen) {
    parentModal = (
      <ParentModal
        carers={carers}
        child={child}
        handleToggle={handleToggleParentModal}
        handleAddParent={handleAddParent}
        handleRemoveParent={handleRemoveParent}
        handleParentInvite={handleParentInvite}
        carerMap={carerMap}
        filterCarers={filterCarers}
        parentsLoading={parentsLoading}
      />
    );
  }

  return (
    <div>
      {deleteModal}
      {parentModal}
      <ChildProfileHeader
        child={child}
        worker={worker}
        edit={edit}
        workers={workers}
        contextOpen={contextOpen}
        handleToggleContextMenu={handleToggleContextMenu}
        handleToggleDeleteModal={handleToggleDeleteModal}
        handleToggleParentModal={handleToggleParentModal}
        handleDelete={handleDelete}
      />
      <ChildProfileTabs handleSwitchTab={handleSwitchTab} isActive={tabs.isActive} user={user}/>{" "}
      {tabs.isActive === "info" ? (
        <ChildProfileDisplay>
          {edit ? (
            <ChildProfileEdit workers={workers} handleFormSubmit={handleFormSubmit} child={child} />
          ) : (
            <div className="container--tab child-description">
              <Timetable timetable={child.timetable} />
              <ChildProfileDescription description={child.all_about} forename={child.first_name} edit={edit} />

              <HasPermission allowedRoles={["super_admin", "admin", "worker"]}>
                <ChildCarers
                  carers={child.guardians}
                  childId={child.uuid}
                  handleFlags={handleFlags}
                  handleRemoveParent={handleRemoveParent}
                  handleToggleCarerContext={handleToggleCarerContext}
                  carerContextOpen={carerContextOpen}
                  invites={invites}
                  handleResendInvite={handleResendInvite}
                  handleDeleteInvite={handleDeleteInvite}
                />
              </HasPermission>
              
              <div className="grid grid--gutter50">
                <ChildProfileSEN sen={child.sen} />
                <ChildProfileMeals meals={child.meals} />
              </div>
              <div className="grid grid--gutter50">
                <ChildProfileUPN upn={child.upn} />
                <ChildProfilePreferredName preferredname={child.preferred_name} />
              </div>
              <div className="grid grid--gutter50">
                <ChildProfileESL esl={child.esl} />
                <ChildProfileEYPP eypp={child.EYPP} />
              </div>
              <div className="grid grid--gutter50">
                <ChildProfileFreeChildcare freeChildcare={child.free_childcare} />
              </div>
              
            </div>
          )}
        </ChildProfileDisplay>
      ) : null}
      {tabs.isActive === "observations" ? (
        <ChildProfileDisplay>
          <ChildObservationsTable child={child} observations={child.observations} worker={worker} />
        </ChildProfileDisplay>
      ) : null}
      {tabs.isActive === "progression" ? (
        <ChildProfileDisplay>
          <ChildProgression child={child} />
        </ChildProfileDisplay>
      ) : null}
    </div>
  );
};

export default ChildProfile;
