import React from "react";
import DefaultChildAvatar from "../images/assets/DefaultChildAvatar.svg";

const EditCommentModal = ({toggleModal, child, handleChildComments, childComment}) => (
  <div className="modal modal-open">
    <div className="modal__content">
      <div className="modal__header">
        <h2>Child Comments</h2>
        <a href={true} className="btn-cancel" onClick={toggleModal}>
          Cancel
        </a>
      </div>
      <div className="modal__main">
        <div className="child-comment" key={child}>
          <form action="psot" className="child-comment__form">
            <label htmlFor={`${child.first_name}--comment`}>
              <div className="avatar avatar--small">
                {child.picture !== null
                  ? (<img alt={child.first_name + " " + child.last_name} src={child.picture}/>)
                  : (<img src={DefaultChildAvatar} alt="Default avatar"/>)}
                <img alt="avatar" src={child.picture}/>
              </div>
              <div className="child-comment__text">
                <div className="child-comment__name">
                  {child.first_name}{" "} {child.last_name}
                </div>
                <div className="child-comment__helpertxt">
                  Did {child.first_name}
                  say anything about the activity?
                </div>
              </div>
            </label>
            <textarea name={`${child.first_name}--comment`} onBlur={handleChildComments}>
              {childComment}
            </textarea>
          </form>
        </div>
      </div>
      <div className="modal__footer">
        <div className="modal__footer__controls">
          <button className="btn btn--green btn--sm btn--mob-fullw" onClick={toggleModal}>
            Add Comments
          </button>
        </div>
      </div>
    </div>
  </div>
);

export default EditCommentModal;
