import PropTypes from "prop-types";
import React from "react";
import ObservationsTableEmpty from "../components/ObservationsTableEmpty";
import ObservationItem from "./ObservationItem";

const ChildObservationsTable = ({ observations, child, worker }) => (
  <ul className="data-list data-list--observations">
    <li className="data-list__item data-list__header">
      <div className="data-list--observations__observation">Observation</div>
      <div className="data-list--observations__meta">
        <div className="data-list--observations__date">Date</div>
        <div className="data-list--observations__person">Observed by</div>
        <div className="data-list--observations__status">Status</div>
      </div>
    </li>
    {observations.length > 0 ? (
      observations.map(observation => <ObservationItem key={observation.uuid} observationId={observation.uuid} />)
    ) : (
      <ObservationsTableEmpty child={child} />
    )}
  </ul>
);

ChildObservationsTable.propTypes = {
  childCount: PropTypes.number.isRequired
};

export default ChildObservationsTable;
