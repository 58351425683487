import qs from "qs";
import {
  ADD_CARD,
  CANCEL_PLAN,
  CREATE_SUBSCRIPTION,
  DELETE_CARD,
  GET_CUSTOMER,
  GET_INVOICES,
  GET_PLANS,
  GET_SUB,
  MAKE_CARD_DEFAULT,
  RESUME_PLAN,
  UPDATE_PLAN
} from "./actionTypes";

export function sendToken(token, plan = null, school) {
  return {
    type: CREATE_SUBSCRIPTION,
    payload: {
      request: {
        method: "POST",
        url: `/billing/${school}`,
        data: {
          token,
          plan
        }
      }
    }
  };
}

export function getSub(id) {
  return {
    type: GET_SUB,
    payload: {
      client: "stripe",
      request: {
        method: "GET",
        url: `/subscriptions/${id}`
      }
    }
  };
}

export function getCustomer(id) {
  return {
    type: GET_CUSTOMER,
    payload: {
      client: "stripe",
      request: {
        method: "GET",
        url: `/customers/${id}`
      }
    }
  };
}

export function getInvoices(id) {
  return {
    type: GET_INVOICES,
    payload: {
      client: "stripe",
      request: {
        method: "GET",
        url: `/invoices?customer=${id}`
      }
    }
  };
}

export function getPlans() {
  return {
    type: GET_PLANS,
    payload: {
      client: "stripe",
      request: {
        method: "GET",
        url: "/plans"
      }
    }
  };
}

export function updatePlan(planID, school) {
  return {
    type: UPDATE_PLAN,
    payload: {
      request: {
        method: "POST",
        url: `/billing/${school}`,
        data: {
          plan: planID
        }
      }
    }
  };
}

export function cancelPlan(uuid) {
  return {
    type: CANCEL_PLAN,
    payload: {
      request: {
        method: "DELETE",
        url: `/billing/${uuid}`
      }
    }
  };
}

export function resumePlan(uuid) {
  return {
    type: RESUME_PLAN,
    payload: {
      request: {
        method: "PUT",
        url: `/billing/${uuid}`
      }
    }
  };
}

export function addBillingCard(token, uuid) {
  return {
    type: ADD_CARD,
    payload: {
      request: {
        method: "POST",
        url: `/billing/cards/${uuid}`,
        data: {
          token
        }
      }
    }
  };
}

export function removeCard(customerId, cardId) {
  return {
    type: DELETE_CARD,
    payload: {
      client: "stripe",
      request: {
        method: "DELETE",
        url: `customers/${customerId}/sources/${cardId}`
      }
    }
  };
}

export function makeDefaultCard(customerId, cardId) {
  return {
    type: MAKE_CARD_DEFAULT,
    payload: {
      client: "stripe",
      request: {
        method: "POST",
        url: `customers/${customerId}`,
        data: qs.stringify({ default_source: cardId })
      }
    }
  };
}
