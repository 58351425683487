import React from "react";
import ChildListFilter from "../components/ChildListFilter";
import ChildrenListEmpty from "../components/ChildrenListEmpty";
import ChildListItem from "./ChildListItem";

const ChildrenList = ({ children, childMap, workers, handleFilterChange, handleSort }) => (
  <div>
    <ChildListFilter workers={workers} handleFilterChange={handleFilterChange} />
    <ul className="data-list data-list--children">
      <li className="data-list__item data-list__header">
        <div className="data-list--children__child">
          <a href={true} onClick={handleSort} name="name">
            Name
          </a>
        </div>
        <div className="data-list--children__age">
          <a href={true} onClick={handleSort} name="age">
            Age
          </a>
        </div>
        <div className="data-list--children__person">
          <a href={true} onClick={handleSort} name="worker">
            Key Person
          </a>
        </div>
        <div className="data-list--children__time-table">Timetable</div>
      </li>
      {childMap.length > 0 ? (
        childMap.map(child => <ChildListItem child={children[child]} key={children[child].uuid} />)
      ) : (
        <ChildrenListEmpty />
      )}
    </ul>
  </div>
);

export default ChildrenList;
