import React from "react";

const ChildProfileDescription = ({forename, description}) => (
    <div className='grid grid--gutter50'>
        <div className='grid__cell'>
            <div className='dataItem dataItem--text'>
                <div className='dataItem__label'>
                    About {forename}
                </div>
                <div className='dataItem__data'>
                    <p>{description}</p>
                </div>
            </div>
        </div>
    </div>
);

export default ChildProfileDescription;
