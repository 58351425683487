import React from 'react';
import logo from '../images/assets/edna-logo-dark.svg';

const Footer = props => (
  <footer className="app-footer">
    <div className="app-footer__l">
      <div className="app-footer__logo-v">
        <div className="app-footer__logo">
          <img alt="edna logo" src={logo} />
        </div>
        <div className="app-footer__version">Version 1.5.24</div>
      </div>
      <div className="app-footer__link-section app-footer__link-section--knowledge">
        <h4>Knowledge Base</h4>
        <p>Explore our articles, tutorials & guides</p>
        <a href="https://ednaapp.com/support/">View</a>
      </div>

      <div className="app-footer__link-section app-footer__link-section--support">
        <h4>Support</h4>
        <p>Email our customer support team</p>
        <a href="mailto:support@ednaapp.com">support@ednaapp.com</a>
      </div>
    </div>
    <div className="app-footer__r">
      <nav className="nav-inline nav-global">
        <ul>
          <li>
            <a href="https://ednaapp.com/privacy-policy/">Privacy Policy</a>
          </li>
          <li>
            <a href="https://ednaapp.com/terms-and-conditions/">
              Terms &amp; Conditions
            </a>
          </li>
        </ul>
      </nav>
      <div className="small-print">&copy; Flo Design Limited 2018</div>
    </div>
  </footer>
);

Footer.propTypes = {};

export default Footer;
