import PropTypes from "prop-types";
import React from "react";

const PasswordStrength = ({ strength }) => (
  <div className={`password-strength-meter password-strength-meter--${strength}`} />
);

PasswordStrength.propTypes = {
  strength: PropTypes.number.isRequired
};

export default PasswordStrength;
