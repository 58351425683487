import React from "react";
import {Field, reduxForm, Form} from "redux-form";
import Input from "./Input";
import {required, email} from "./Validators";
import {Link} from "react-router";
import RegisterHeader from "./RegisterHeader";
import Loader from "./Loader";

const LoginForm = (props) => {
  const {handleSubmit, sendForm, user} = props;
  return (
    <div>
      <div className="signUp signUp--wForm">
        <RegisterHeader/>
        <div className="signUp__main">
          <div className="signUp__text-area signUp__text-area--intro">
            <h1>Sign in to Edna</h1>
            <p>Enter your details below.</p>
          </div>
          <Form onSubmit={handleSubmit(sendForm)}>
            <div className="standard-inputs">
              <div className="standard-input standard-input--lg">
                <Field
                  name="email"
                  targetName="email"
                  type="email"
                  value=""
                  label="Email"
                  hint="The email address associated with your Edna account"
                  component={Input}
                  validate={[required, email]}
                  tabIndex="1"/>
              </div>
              <div className="standard-input standard-input--lg">
                <div className="standard-input__link">
                  <Link to="/password/forgot" tabIndex="3">
                    Forgot password?
                  </Link>
                </div>
                <Field
                  name="password"
                  targetName="login-password"
                  type="password"
                  value=""
                  label="Password"
                  component={Input}
                  validate={[required]}
                  tabIndex="2"/>
              </div>
            </div>
            {user.isLoading
              ? (<Loader/>)
              : (
                <button
                  className="btn btn--green btn--lg btn--mob-fixed"
                  type="submit"
                  tabIndex="4">
                  Sign in
                </button>
              )}
          </Form>
        </div>
        <div className="no-account">
          Don't have an account?
          <Link to="/register" className="btn btn--sm btn--empty" tabIndex="5">
            Get Started
          </Link>
        </div>
      </div>
    </div>
  );
};

export default reduxForm({form: "login"})(LoginForm);
