import { Field, Form, Formik } from "formik";
import React from "react";
import Lightbox from "react-images";
import { connect } from "react-redux";
import styled from "styled-components";
import CommentSendButton from "../components/CommentSendButton";
import { getObservation } from "../reducers/observations";
import { getUser } from "../reducers/users";
import Theme from "../theme";
import Avatar from "./Avatar";
import CommentActions from "./CommentActions";
import CommentBtn from "./CommentBtn";
import QuickComments from "./QuickComments";

const Observation = styled.div`
  border-top: 1px solid ${Theme.colorGreyMidLt};
  border-bottom: 1px solid ${Theme.colorGreyMidLt};
  background: ${Theme.colorSysWhite};
  margin-bottom: 5px;
  color: ${Theme.colorGreyDark};
  font-family: ${Theme.fontPrimary};
  @media screen and (min-width: ${Theme.bpMedium}) {
    max-width: 650px;
    border-top: 0;
    border-bottom: 0;
    margin-bottom: 70px;
  }
`;
const Header = styled.header`
  display: flex;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid ${Theme.colorGreyLight};
  @media screen and (min-width: ${Theme.bpMedium}) {
    border-bottom: 0;
    margin-bottom: 25px;
    align-items: flex-start;
    padding: 0px;
  }
`;

const Avatars = styled.div`
  margin: 0 24px 0 0;
  padding-bottom: 15px;
  position: relative;
  @media screen and (min-width: ${Theme.bpMedium}) {
    margin: 0 34px 0 0;
    padding-bottom: 0px;
  }

  > div:last-of-type {
    position: absolute;
    top: 25px;
    left: 25px;
    @media screen and (min-width: ${Theme.bpMedium}) {
      top: 40px;
      left: 40px;
    }
  }
`;

const Title = styled.h2`
  font-size: 0.875rem;
  font-weight: 400;
  margin: 0;
  a {
    font-weight: 900;
    text-decoration: none;
    color: ${Theme.colorGreyDark};
    transition: 0.3s;
    &:hover {
      color: ${Theme.colorBrandGreen};
    }
  }
  @media screen and (min-width: ${Theme.bpMedium}) {
    font-size: 1.5rem;
  }
`;

const Meta = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3px;
  font-size: 0.75rem;
  @media screen and (min-width: ${Theme.bpMedium}) {
    font-size: 1rem;
  }
`;

const MetaItem = styled.div`
  margin-right: 10px;
`;

const Main = styled.div`
  @media screen and (min-width: ${Theme.bpMedium}) {
    margin-left: 100px;
  }
`;

const Gallery = styled.div`
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  @media screen and (min-width: ${Theme.bpMedium}) {
    margin-bottom: 30px;
    border-radius: 5px;
    border: 3px solid ${Theme.colorGreyLight};
  }
`;
const Image = styled.div`
  flex-grow: 1;
  flex-basis: 50%;
  line-height: 0;
  max-height: 100px;
  display: flex;
  align-items: center;
  overflow: hidden;
  img {
    width: 100%;
  }
  &:first-child {
    flex-basis: 100%;
    max-height: 175px;
  }
  @media screen and (min-width: ${Theme.bpSmall}) {
    max-height: 200px;
    &:first-child {
      max-height: 250px;
    }
  }
  @media screen and (min-width: ${Theme.bpMedium}) {
    &:first-child {
      max-height: 300px;
    }
  }
`;
const Description = styled.div`
  font-size: 0.75rem;
  line-height: 1.125rem;
  max-width: 650px;
  margin: 15px 0 20px 0;
  padding: 0px 15px;

  @media screen and (min-width: ${Theme.bpMedium}) {
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 0 0 30px 0;
    padding: 0px;
  }
`;
const ChildVoice = styled.div`
  display: flex;
  align-items: center;
  color: ${Theme.colorBrandGreen};
  background: rgba(55, 188, 155, 0.2);
  border-radius: 5px;
  margin: 0 15px 15px 30px;
  padding: 10px 15px 10px 0;
  font-size: 1rem;
  font-weight: 700;

  > div {
    position: relative;
    right: 20px;
    margin-right: -10px;
  }
  @media screen and (min-width: ${Theme.bpMedium}) {
    margin: 0 0 30px 0;
    font-size: 1.5rem;
    padding: 20px 25px 20px 0;
    > div {
      right: 35px;
      margin-right: -20px;
    }
  }
`;
const VoiceText = styled.span`
  &:before {
    content: '"';
  }
  &:after {
    content: '"';
  }
`;

const Actions = styled.div`
  border-top: 1px solid ${Theme.colorGreyLight};
  border-bottom: 1px solid ${Theme.colorGreyLight};
  margin: 0 15px;
  display: flex;
  align-items: center;
  padding: 10px 0;
  justify-content: space-between;
  @media screen and (min-width: ${Theme.bpMedium}) {
    /* padding: 10px 0; */
    border-top: 3px solid ${Theme.colorGreyLight};
    border-bottom: 3px solid ${Theme.colorGreyLight};
    margin: 0 0 0 100px;
  }
`;

const LikeComment = styled.div`
  display: flex;
`;

const Comments = styled.div`
  margin: 0 15px;
  @media screen and (min-width: ${Theme.bpMedium}) {
    margin: 0;
    /* border-bottom: 3px solid ${Theme.colorGreyLight}; */
    margin-left: 100px;
  }
`;
const SingleComment = styled.div`
  padding: 15px 0;
  display: flex;
  align-items: flex-start;
  @media screen and (min-width: ${Theme.bpMedium}) {
    padding: 20px 0;
  }
`;

const CommentAvatar = styled.div`
  margin-right: 10px;
  flex: 0 0 auto;
  @media screen and (min-width: ${Theme.bpMedium}) {
    margin-right: 20px;
  }
`;
const CommentText = styled.div`
  width: 100%;
  .title {
    font-size: 0.875rem;
    margin-bottom: 5px;
  }
  .text {
    margin-bottom: 10px;
    font-size: 0.75rem;
    line-height: 1.125rem;
  }

  .actions {
    border-top: 1px solid ${Theme.colorGreyLight};
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${Theme.colorGreyMid};
    padding-top: 10px;
    font-size: 0.75rem;
  }

  .time-stamp {
    font-size: 0.75rem;
  }

  .author-controls {
    a {
      font-weight: 400;
      color: ${Theme.colorGreyMid};
      margin-right: 10px;
      cursor: pointer;
      &:hover {
        color: ${Theme.colorBrandGreen};
      }
    }
  }

  .standard-input {
    margin-bottom: 0;
    width: 100%;
    margin-right: 10px;
  }

  form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  @media screen and (min-width: ${Theme.bpMedium}) {
    .title {
      font-size: 1.125rem;
      margin-bottom: 5px;
    }
    .text {
      /* margin-bottom: 20px; */
      font-size: 1rem;
      line-height: 1.5rem;
    }
    .actions {
      /* margin-top: -14px; */
    }
  }
`;

const NewComment = styled.div`
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid $color-grey-light;
  padding: 15px 0px;
  box-sizing: border-box;
`;

const CommentForm = styled.div`
  /* width: 100%; */
  margin-left: 10px;
  flex: 1;
  form {
    display: flex;
  }
  .standard-input {
    margin-bottom: 0;
    width: 100%;
    margin-right: 10px;
    input[type="text"] {
      max-width: none;
    }
  }
  @media screen and (min-width: ${Theme.bpMedium}) {
    margin-left: 15px;
  }
`;

const NewsFeedObservation = ({
  observationId,
  observation,
  openLightbox,
  closeLightbox,
  currentImage,
  lightboxOpen,
  gotoNext,
  gotoPrev,
  editComment,
  handleDeleteComment,
  handleCommentPosting,
  handleSetEditComment,
  handleEditComment,
  user,
  commentOpen,
  handleCommentOpen,
  quickCommentSubmitting
}) => {
  let imagesArr = [];
  if (observation.evidence.length > 0) {
    imagesArr = observation.evidence.map(evidence => {
      return { src: evidence.full_url, uuid: evidence.uuid };
    });
  }

  return (
    <Observation>
      <Header>
        <Avatars>
          <Avatar
            size="medium"
            userName={
              observation.keyPerson.first_name +
              " " +
              observation.keyPerson.last_name
            }
            image={observation.keyPerson.picture}
            type="user"
          />
          <Avatar
            size="small"
            userName={
              observation.child.first_name + " " + observation.child.last_name
            }
            image={observation.child.picture}
            type="child"
            border
          />
        </Avatars>
        <div>
          <Title>
            {observation.keyPerson.first_name}&nbsp; observed&nbsp;
            {observation.child.first_name}&nbsp;
            {observation.title}
          </Title>
          <Meta>
            <MetaItem>{observation.createdAt}</MetaItem>
            <MetaItem>{observation.comments.length}&nbsp; comments</MetaItem>
          </Meta>
        </div>
      </Header>

      <Main>
        {observation.evidence.length > 0 ? (
          <Gallery>
            {observation.evidence.map((image, index) => (
              <Image key={image.id}>
                <a
                  href={`https://res.cloudinary.com/flo-design/image/upload/c_fill,q_auto:good,w_2000/${image.cloudinary_id}.jpg`}
                  onClick={e => openLightbox(index, e)}
                  key={index}
                  className="observation-img"
                >
                  <img
                    src={`https://res.cloudinary.com/flo-design/image/upload/c_fill,q_auto:good,w_640,h_480/${image.cloudinary_id}.jpg`}
                    alt="Observation evidence"
                    data-id={observationId}
                  />
                </a>
              </Image>
            ))}
            <Lightbox
              images={imagesArr}
              currentImage={currentImage}
              isOpen={lightboxOpen === observationId ? true : false}
              onClose={closeLightbox}
              onClickNext={gotoNext}
              onClickPrev={gotoPrev}
            />
          </Gallery>
        ) : (
          ""
        )}

        <Description>{observation.description}</Description>

        {observation.childVoice !== null ? (
          <ChildVoice>
            <Avatar
              size="medium"
              userName={
                observation.child.first_name + " " + observation.child.last_name
              }
              image={observation.child.picture}
              type="child"
              border
            />
            <VoiceText>{observation.childVoice}</VoiceText>
          </ChildVoice>
        ) : (
          ""
        )}
      </Main>

      <Actions>
        <LikeComment>
          <CommentBtn
            handleCommentOpen={handleCommentOpen}
            observationId={observationId}
          />
        </LikeComment>
      </Actions>

      <Comments>
        {commentOpen === observationId ? (
          <NewComment>
            <Avatar
              size="small"
              userName={user.name}
              image={user.picture}
              type="user"
            />

            <CommentForm>
              <Formik
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  handleCommentPosting(values, observationId);
                  setSubmitting(false);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting
                  /* and other goodies */
                }) => {
                  return (
                    <Form>
                      <div className="standard-input">
                        <Field
                          type="text"
                          placeholder="Write a comment..."
                          wrap="soft"
                          name="comment"
                          onChange={handleChange}
                        />
                      </div>
                      {/* <a onClick={handleSubmit} className={btnClass}>
                        Post Comment
                      </a> */}
                      {/* <CommentButton onClick={handleSubmit} loading/> */}
                      <CommentSendButton
                        onClick={handleSubmit}
                        loading={isSubmitting}
                      />
                    </Form>
                  );
                }}
              </Formik>
            </CommentForm>
            <QuickComments
              quickCommentSubmitting={quickCommentSubmitting}
              handleCommentPosting={handleCommentPosting}
              observationId={observationId}
            />
          </NewComment>
        ) : (
          ""
        )}

        {observation.comments
          .sort((a, b) => {
            return new Date(b.timestamp) - new Date(a.timestamp);
          })
          .map(comment => {
            return (
              <SingleComment key={comment.uuid}>
                <CommentAvatar>
                  <Avatar
                    size="small"
                    userName={
                      comment.personable.first_name +
                      " " +
                      comment.personable.last_name
                    }
                    image={comment.personable.picture}
                    type="user"
                  />
                </CommentAvatar>

                <CommentText>
                  {editComment === comment.uuid ? (
                    <Formik
                      initialValues={{ comment: comment.comment }}
                      onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true);
                        handleEditComment(values, comment.uuid);
                        setSubmitting(false);
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                        /* and other goodies */
                      }) => {
                        return (
                          <Form>
                            <div className="standard-input">
                              <Field
                                type="text"
                                placeholder="Write a comment..."
                                wrap="soft"
                                name="comment"
                                onChange={handleChange}
                              />
                            </div>
                            <CommentSendButton
                              onClick={handleSubmit}
                              loading={isSubmitting}
                            />
                          </Form>
                        );
                      }}
                    </Formik>
                  ) : (
                    <div>
                      <div className="title">
                        <a href={`user/${comment.personable.user_id}`}>
                          {comment.personable.first_name +
                            " " +
                            comment.personable.last_name}
                        </a>
                      </div>
                      <div className="text">{comment.comment}</div>
                    </div>
                  )}

                  <CommentActions
                    commentId={comment.uuid}
                    handleSetEditComment={handleSetEditComment}
                    handleDeleteComment={handleDeleteComment}
                    isAdmin={
                      user.role.name === "admin" ||
                      user.role.name === "super_admin"
                        ? true
                        : false
                    }
                    ownComment={
                      comment.personable.user_id === user.userId ? true : false
                    }
                    isEditing={editComment === comment.uuid ? true : false}
                    createdAt={comment.createdAt}
                  />
                </CommentText>
              </SingleComment>
            );
          })}
      </Comments>
    </Observation>
  );
};

NewsFeedObservation.propTypes = {};

const mapStateToProps = (state, props) => ({
  observation: getObservation(state, props.observationId),
  user: getUser(state)
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsFeedObservation);
