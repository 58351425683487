import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';

const PlanPreChange = ({
  nickname,
  planID,
  amount,
  interval,
  childCount,
  cardBrand,
  cardLast4,
  renewal
}) => {
  let price = amount / 100;
  return (
    <div className="modal__main modal__main--pad">
      <ul className="conformation-list">
        <li>You have chosen the&nbsp;
          <span className="bold">{nickname}&nbsp;Plan</span>
        </li>
        {planID === "plan_D5fcw28hyGHLhh"
          ? (
            <li>Your cost per child is
              <span className="bold">&nbsp;£{price.toFixed(0)}&nbsp;</span>per month (Plus VAT)</li>
          )
          : ""}
        <li>You will be billed&nbsp;{interval}ly</li>
        {planID === "plan_D5fcw28hyGHLhh"
          ? (
            <li>You will pay&nbsp;<span className="bold">£{(price * childCount).toFixed(0)}&nbsp;</span>
              on&nbsp;<span className="bold">{renewal.format("DD/MM/YYYY")}</span>&nbsp;(Plus VAT)</li>
          )
          : (
            <li>You will pay&nbsp;<span className="bold">£{price.toFixed(0)}&nbsp;</span>(Plus VAT) on&nbsp;<span className="bold">{renewal.format("DD/MM/YYYY")}</span>&nbsp;
            </li>
          )}
      </ul>
      {planID === "plan_D5fcw28hyGHLhh"
        ? (
          <p>You will be billed on the&nbsp;<span className="bold">{renewal.format("Do")}</span>&nbsp;of each month. Your cost per month is&nbsp;<span className="bold">£{(price * childCount).toFixed(0)}&nbsp;</span>(Plus VAT) which will be billed to your<span className="bold">&nbsp;{cardBrand}&nbsp;card</span>&nbsp;ending&nbsp;<span className="bold">{cardLast4}</span>
          </p>
        )
        : (
          <p>You will be billed on the&nbsp;<span className="bold">{renewal.format("Do")}</span>&nbsp;of each month. Your cost per month is&nbsp;<span className="bold">£{price.toFixed(0)}</span>&nbsp;(Plus VAT) which will be billed to your<span className="bold">&nbsp;{cardBrand}&nbsp;card</span>&nbsp;ending&nbsp;<span className="bold">{cardLast4}</span>
          </p>
        )}
      {planID === "plan_D5fcw28hyGHLhh"
        ? (
          <p>You are paying for&nbsp;<span className="bold">{childCount}&nbsp;{childCount === 1
                ? "child"
                : "children"}</span>.
We will automatically update your monthly payments when you add or remove
children from your Edna account.</p>
        )
        : null}
    </div>
  );
};

PlanPreChange.propTypes = {
  planID: PropTypes.string.isRequired,
  nickname: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  interval: PropTypes.string.isRequired,
  childCount: PropTypes.number.isRequired,
  cardBrand: PropTypes.string.isRequired,
  cardLast4: PropTypes.string.isRequired,
  renewal: PropTypes
    .instanceOf(Moment)
    .isRequired
};

export default PlanPreChange;
