import React from "react";
import { bindActionCreators } from "redux";
import { browserHistory } from "react-router";
import { connect } from "react-redux";
import * as OnboardActions from "../actions/onboardActions";
import * as FlashActions from "../actions/flashActions";
import Loader from "../components/Loader";
import RegisterHeader from "../components/RegisterHeader";

class OnboardingContainer extends React.Component {
  state = {
    school_name: null,
    subdomain: null,
    password: null
  };

  componentDidMount = () => {
    this.props.getInvite(this.props.params.inviteId).then(response => {
      if (this.props.onboarding.error) {
        browserHistory.replace("/login");
      }
    });
  };

  handleFormSubmit = values => {
    values = {
      ...values,
      first_name: this.props.onboarding.data.first_name,
      last_name: this.props.onboarding.data.last_name,
      email: this.props.onboarding.data.email,
      invite_id: this.props.params.inviteId
    };
    this.props
        .processInvite(values)
        .then(response => {
          if (typeof response.http_code === "undefined") {
            this.props.addMessageToFlash(
              "Success! Your invite has been successfully processed. You may log in using the d" +
              "etails you've provided",
              "success",
              "normal"
            );
          }
        })
        .then(() => {
          browserHistory.push("/");
        })
        .catch(() => {});
  };

  render() {
    const { isLoading, data } = this.props.onboarding;
    return (
      <div className="signUp signUp--wForm">
        <RegisterHeader />{" "}
        {!isLoading && data !== null ? (
          React.cloneElement(this.props.children, {
            handleFormSubmit: this.handleFormSubmit,
            onboarding: this.props.onboarding.data
          })
        ) : (
          <Loader />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({ onboarding: state.onboarding });

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...OnboardActions,
      ...FlashActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OnboardingContainer);
