import { GET_USER_PROFILE } from "./actionTypes";

export function getUserProfile (userId) {
    return {
        type: GET_USER_PROFILE,
        payload: {
            request: {
                method: "GET",
                url: "profile/" + userId
            }
        }
    };
}
