import React from "react";
import { Field, Form, reduxForm } from "redux-form";
import zxcvbn from "zxcvbn";
import logo from "../images/assets/edna-logo-dark.svg";
import Input from "./Input";
import { minLength8, required } from "./Validators";

class ResetPassword extends React.Component {
  state = {
    strength: null
  };

  handlePasswordChange = event => {
    console.log(event.target.value);
    this.setState({
      strength: zxcvbn(event.target.value).score
    });
  };

  render() {
    return (
      <div className="signUp signUp--wForm">
        <header className="signUp__header">
          <img alt="edna logo" className="signUp__header__logo" src={logo} />
        </header>
        <div className="signUp__main">
          <div className="signUp__text-area signUp__text-area--intro">
            <h1>Reset your password</h1>
            <p>Enter your password and confirmation below to reset your password</p>
          </div>
          <Form onSubmit={this.props.handleSubmit(this.props.handleFormSubmit)}>
            <div className="standard-inputs">
              <div className="standard-input standard-input--lg">
                <Field
                  name="password"
                  targetName="password"
                  type="password"
                  value=""
                  label="Password"
                  hint="Must be a minimum of 8 characters"
                  component={Input}
                  validate={[required, minLength8]}
                  onChange={this.handlePasswordChange}
                  strength={this.state.strength}
                />
              </div>
              <div className="standard-input standard-input--lg">
                <Field
                  name="password_confirmation"
                  targetName="password_confirmation"
                  type="password"
                  value=""
                  label="Confirm Password"
                  component={Input}
                  validate={[required, minLength8]}
                />
              </div>
            </div>
            <button className="btn btn--green btn--lg btn--mob-fixed" type="submit">
              Reset Password
            </button>
          </Form>
        </div>
      </div>
    );
  }
}

ResetPassword = reduxForm({ form: "reset" })(ResetPassword);

export default ResetPassword;
